import { Button } from "antd";
import { Dispatch, SetStateAction } from "react";
import { TaxesTabs } from "types";

type Props = {
  pickedTab: TaxesTabs;
  setPickedTab: Dispatch<SetStateAction<TaxesTabs>>;
};

const activeStyle = {
  backgroundColor: "#BDD000",
  border: "1px solid #BDD000",
  color: "white",
  outline: "none",
};

const TaxesNavigation = ({ pickedTab, setPickedTab }: Props) => {
  const pickedFeeTaxes = pickedTab === TaxesTabs.FeeTaxes;
  const pickedTaxes = pickedTab === TaxesTabs.Taxes;

  const pickFeeTaxesTab = () => setPickedTab(TaxesTabs.FeeTaxes);
  const pickTaxesTab = () => setPickedTab(TaxesTabs.Taxes);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 16 }}>
        <Button style={pickedFeeTaxes ? activeStyle : {}} onClick={pickFeeTaxesTab}>
          Assign Taxes
        </Button>
        <Button
          style={pickedTaxes ? { ...activeStyle, marginLeft: 8 } : { marginLeft: 8 }}
          onClick={pickTaxesTab}
        >
          Create Taxes
        </Button>
      </div>
    </>
  );
};

export default TaxesNavigation;
