import { Select } from 'antd';
import { FC, useMemo } from 'react';
import useSWR from 'swr';

type Props = {
  schoolId: number | null;
  showArrow: boolean;
  bordered: boolean;
  disabled: boolean;
};

const SchoolShiftInput: FC<Props> = ({ schoolId, ...rest }) => {
  const { data: shiftsData, error: shiftsError } = useSWR<any[]>(
    schoolId ? `school/shifts/${schoolId}` : null
  );

  const isPending = !!schoolId && !shiftsData && !shiftsError;

  const shiftsList = useMemo(
    () =>
      shiftsData?.map(
        (item: any) => ({ label: item.name, value: item.id } ?? [])
      ),
    [shiftsData]
  );
  return (
    <Select
      loading={isPending}
      showSearch
      allowClear
      options={shiftsList}
      filterOption={(input, option) =>
        (option?.label?.toLocaleString() ?? '')
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      {...rest}
    />
  );
};

export default SchoolShiftInput;
