import {
  TeachingAssistantPerformanceEvaluation,
  TeachingStaffPerformanceEvaluation,
} from "types";

export type ExtraEvaluationData = {
  comments: string;
  nextSteps: string;
};

const extraEvaluationData: ExtraEvaluationData = {
  comments: "",
  nextSteps: "",
};

export const assistantStaffEvaluationItems = [
  {
    key: "preparation",
    title: "Preparation and Planning in Assistance",
    items: [
      {
        key: "understandsGoals",
        id: 0,
        description: "Understands instructional goals",
      },
      {
        key: "plans",
        id: 1,
        description:
          "Plans and prepares for activities with supervision of teacher",
      },
      {
        key: "material",
        id: 2,
        description: "Material/aids prepared in advance",
      },
    ],
  },
  {
    key: "responsibilities",
    title: "Responsibilities Professional",
    items: [
      {
        key: "relationship",
        id: 0,
        description: "Has good relationship with colleagues",
      },
      {
        key: "communicates",
        id: 1,
        description: "Communicates confidently and effectively with parents",
      },
      {
        key: "seeks",
        id: 2,
        description: "Seeks additional qualification/training",
      },
      {
        key: "attends",
        id: 3,
        description: "Attends and participates in the weekly meetings",
      },
    ],
  },
  {
    key: "foxes",
    title: "Teaching Interactions For Foxes and Bears",
    items: [
      {
        key: "reads",
        id: 0,
        description: "Reads to children in small groups or individually daily",
      },
      {
        key: "practices",
        id: 1,
        description:
          "Practices appropriate sanitary practices such as always washing child's hands after assisting the child in using toilet/diapering and assisting the child in washing his hands",
      },
      {
        key: "talks",
        id: 2,
        description: "Talks to the children while feeding them",
      },
      {
        key: "assists",
        id: 3,
        description:
          "Assists children in becoming more independent in dressing and eating",
      },
      {
        key: "engages",
        id: 4,
        description:
          "Engages the children in games promoting language and gross motor skills",
      },
      {
        key: "introduces",
        id: 5,
        description:
          "Introduces the children to painting, drawing and dramatic play activities",
      },
    ],
  },
  {
    key: "seals",
    title: "Teaching Interactions For Seals and Owls",
    items: [
      {
        key: "reads",
        id: 0,
        description: "Reads to children in small and big groups daily",
      },
      {
        key: "assists",
        id: 1,
        description: "Assists the teacher in implementing daily lesson plans",
      },
      {
        key: "uses",
        id: 2,
        description: "Uses guided learning centers in appropriate ways",
      },
      {
        key: "engages",
        id: 3,
        description:
          "Engages the children in conversation, both in individual and small group settings",
      },
      {
        key: "intervenes",
        id: 4,
        description: "Intervenes to prevent/resolve conflicts among children",
      },
      {
        key: "promotes",
        id: 5,
        description:
          "Promotes development thorugh singing, dancing, tracking, writing, playing, painting and role play",
      },
    ],
  },
  {
    key: "swans",
    title: "Teaching Interactions For Swans",
    items: [
      {
        key: "providesNurturing",
        id: 0,
        description:
          "Provides nurturing behaviours by picking, holding and rocking the Swans throughtout the day",
      },
      {
        key: "reads",
        id: 1,
        description: "Reads stories to Swans daily while holding them",
      },
      {
        key: "feed",
        id: 2,
        description: "Feed them while holding or one on one in a high chair",
      },
      {
        key: "talks",
        id: 3,
        description: "Talks to the Swans while feeding and diapering",
      },
      {
        key: "plays",
        id: 4,
        description:
          "Plays with the swans on the floor for a portion of the day",
      },
      {
        key: "providesLearning",
        id: 5,
        description: "Provides learning aids to the Swans and talks about them",
      },
      {
        key: "encourages",
        id: 6,
        description:
          "Encourages the Swans to sit, roll over, scoot, crawl stand and walk during as it grows older",
      },
      {
        key: "practices",
        id: 7,
        description:
          "Practices appropriate sanitary practices such as always washing hands after diapering and washing the Swans' hands as well",
      },
    ],
  },
  {
    key: "classroom",
    title: "Supports Classroom Environment",
    items: [
      {
        key: "helps",
        id: 0,
        description: "Helps create an environment of trust and support",
      },
      {
        key: "encourages",
        id: 1,
        description:
          "Encourages learning and creativity through stories, games, songs and role play",
      },
      {
        key: "uses",
        id: 2,
        description: "Uses strategies to promote independence in children",
      },
      {
        key: "supports",
        id: 3,
        description: "Supports the teacher in assesment",
      },
      {
        key: "ensuresHealth",
        id: 4,
        description:
          "Ensures health and safety of children both inside and outside the class",
      },
      {
        key: "keeps",
        id: 5,
        description: "Keeps classroom tidy and functional",
      },
      {
        key: "ensuresBelongings",
        id: 6,
        description:
          "Ensures all belongings of children of children (toys, lunch boxes, drink bottles, clothes, etc) are received and returned home properly",
      },
      {
        key: "isAlert",
        id: 7,
        description: "Is alert to physical comfort of children",
      },
    ],
  },
  {
    key: "recordKeeping",
    title: "Record Keeping",
    items: [
      {
        key: "record",
        id: 0,
        description: "Record schedule of nap, food and nappy/toilet",
      },
      {
        key: "keeps",
        id: 1,
        description: "Keeps the attendance and inventory checklist current",
      },
    ],
  },
];

export const assistantStaffValues: TeachingAssistantPerformanceEvaluation = {
  preparation: {
    understandsGoals: 1,
    plans: 1,
    material: 1,
    ...extraEvaluationData,
  },
  responsibilities: {
    relationship: 1,
    communicates: 1,
    seeks: 1,
    attends: 1,
    ...extraEvaluationData,
  },
  foxes: {
    reads: 1,
    practices: 1,
    talks: 1,
    assists: 1,
    engages: 1,
    introduces: 1,
    ...extraEvaluationData,
  },
  seals: {
    reads: 1,
    assists: 1,
    uses: 1,
    engages: 1,
    intervenes: 1,
    promotes: 1,
    ...extraEvaluationData,
  },
  swans: {
    providesNurturing: 1,
    reads: 1,
    feed: 1,
    talks: 1,
    plays: 1,
    providesLearning: 1,
    encourages: 1,
    practices: 1,
    ...extraEvaluationData,
  },
  classroom: {
    helps: 1,
    encourages: 1,
    uses: 1,
    supports: 1,
    ensuresHealth: 1,
    keeps: 1,
    ensuresBelongings: 1,
    isAlert: 1,
    ...extraEvaluationData,
  },
  recordKeeping: {
    record: 1,
    keeps: 1,
    ...extraEvaluationData,
  },
};

export const teachingStaffEvaluationValues: TeachingStaffPerformanceEvaluation =
  {
    professionalConduct: {
      arrives: 1,
      attendance: 1,
      maintainsConfidentiality: 1,
      maintainsGoodRelationship: 1,
      shows: 1,
      ...extraEvaluationData,
    },
    recordKeeping: {
      attendance: 1,
      maintains: 1,
      ...extraEvaluationData,
    },
    lesson: {
      understands: 1,
      planned: 1,
      plans: 1,
      providesBalanced: 1,
      providesOppurtunities: 1,
      collaborates: 1,
      submits: 1,
      ...extraEvaluationData,
    },
    parental: {
      updatesParents: 1,
      updatesTeacher: 1,
      works: 1,
      tags: 1,
      ...extraEvaluationData,
    },
    instructional: {
      provides: 1,
      defines: 1,
      usesFocused: 1,
      usesVariety: 1,
      usesEffective: 1,
      providesLesson: 1,
      ...extraEvaluationData,
    },
    classroom: {
      hasMaterials: 1,
      usesSignal: 1,
      minimizes: 1,
      redirects: 1,
      establishesRules: 1,
      maintains: 1,
      establishesGroup: 1,
      isAlert: 1,
      usesStrategies: 1,
      ...extraEvaluationData,
    },
    professionalDevelopment: {
      attends: 1,
      shows: 1,
      seeks: 1,
      ...extraEvaluationData,
    },
  };

export const teachingStaffEvaluationItems = [
  {
    key: "professionalConduct",
    title: "Professional Conduct",
    items: [
      {
        key: "attendance",
        id: 0,
        description: "Attendance",
      },
      {
        key: "arrives",
        id: 1,
        description: "Arrives on time",
      },
      {
        key: "maintainsConfidentiality",
        id: 2,
        description: "Maintains confidentiality concerning children",
      },
      {
        key: "maintainsGoodRelationship",
        id: 3,
        description: "Maintains good relation with colleagues",
      },
      {
        key: "shows",
        id: 4,
        description: "Shows respect to parents",
      },
    ],
  },
  {
    key: "recordKeeping",
    title: "Record Keeping",
    items: [
      {
        key: "attendance",
        id: 0,
        description:
          "Attendance and inventory checklists current and complete daily",
      },
      {
        key: "maintains",
        id: 1,
        description:
          "Maintains a developmental skills checklist for each child",
      },
    ],
  },
  {
    key: "lesson",
    title: "Lesson Planning",
    items: [
      {
        key: "understands",
        id: 0,
        description:
          "Understands learning outcomes for the specific age of children",
      },
      {
        key: "planned",
        id: 1,
        description: "Planned activities align with the learning outcomes",
      },

      {
        key: "providesBalanced",
        id: 2,
        description: "Provides a balance of quiet and active activities",
      },
      {
        key: "plans",
        id: 3,
        description: "Plans for the holistic development of child",
      },
      {
        key: "providesOppurtunities",
        id: 4,
        description: "Provides opportunities for creative and imaginative play",
      },
      {
        key: "collaborates",
        id: 5,
        description: "Collaborates with team in planning tasks",
      },
      {
        key: "submits",
        id: 6,
        description: "Submits lesson plan on time for review<",
      },
    ],
  },
  {
    key: "parental",
    title: "Parental and Community Relations",
    items: [
      {
        key: "updatesParents",
        id: 0,
        description:
          "Updates parents of child’s progress through formal and informal meetings",
      },
      {
        key: "updatesTeacher",
        id: 1,
        description:
          "Updates teacher application for each child daily, with useful information",
      },
      {
        key: "works",
        id: 2,
        description: "Works with parents as partners in child’s development",
      },
      {
        key: "tags",
        id: 3,
        description:
          "Tags quality pictures of children daily through the teacher application",
      },
    ],
  },
  {
    key: "instructional",
    title: "Instructional Strategies",
    items: [
      {
        key: "provides",
        id: 0,
        description: "Provides introduction to the lesson/activity",
      },
      {
        key: "defines",
        id: 1,
        description: "Defines and describes new words and concepts",
      },
      {
        key: "usesFocused",
        id: 2,
        description: "Uses Focused Instruction method of teaching",
      },
      {
        key: "usesVariety",
        id: 3,
        description: "Uses a variety of Guided Learning Centers",
      },
      {
        key: "usesEffective",
        id: 4,
        description: "Uses effective communication skills",
      },
      {
        key: "providesLesson",
        id: 5,
        description: "Provides lesson closure and prepares for next activity",
      },
    ],
  },
  {
    key: "classroom",
    title: "Classroom Management and Organization",
    items: [
      {
        key: "hasMaterials",
        id: 0,
        description: "Has materials/aids ready when activity begins",
      },
      {
        key: "usesSignal",
        id: 1,
        description: "Uses signal/cue to secure children’s attention",
      },
      {
        key: "minimizes",
        id: 2,
        description: "Minimizes transition time/ begins promptly",
      },
      {
        key: "redirects",
        id: 3,
        description: "Redirects/stops disrupting behaviour",
      },
      {
        key: "establishesRules",
        id: 4,
        description: "Establishes and consistently and fairly applies rules",
      },
      {
        key: "maintains",
        id: 5,
        description: "Maintains a clean and organized class",
      },
      {
        key: "establishesGroup",
        id: 6,
        description:
          "Establishes whole group, small group and individual work centers",
      },
      {
        key: "isAlert",
        id: 7,
        description: "Is alert to physical comfort of children",
      },
      {
        key: "usesStrategies",
        id: 8,
        description: "Uses strategies to motivate students",
      },
    ],
  },
  {
    key: "professionalDevelopment",
    title: "Professional Development",
    items: [
      {
        key: "attends",
        id: 0,
        description: "Attends all staff professional development meetings",
      },
      {
        key: "shows",
        id: 1,
        description:
          "Shows improvement in areas on which training has been received",
      },
      {
        key: "seeks",
        id: 2,
        description:
          "Seeks additional educational credential/ degree in the field",
      },
    ],
  },
];
