import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

const getImageBase64 = async (
  files: UploadChangeParam<UploadFile<any>>['fileList']
) => {
  const file = files[0];
  let promiseResultBase64 = files.map(async (item) => {
    let fileReader = new FileReader();
    return await new Promise((resolve) => {
      fileReader.onload = (e) => {
        resolve(fileReader.result);
      };
      // @ts-ignore
      fileReader.readAsDataURL(item.originFileObj);
    });
  });
  const results = (await Promise.all(promiseResultBase64)) as string[];

  return {
    name: file.name,
    type: file.type,
    content: results[0].split('base64,')[1],
  };
};

export default getImageBase64;
