import { message } from "antd";
import dayjs from "dayjs";
import { axiosAuth } from "helpers";
import { useEffect, useState } from "react";
import useSWR from "swr";
import {
  ChildFinanceConfig,
  ChildFinanceTerm,
  ChildPreviewFinanceConfig,
  getChildFinanceTerms,
  getChildPreviewFinanceConfig,
} from "types/rest/child";

const initialFinanceConfig: ChildFinanceConfig = {
  paymentType: "TERM",
  oneTimeFee: false,
  annualFeeIds: [],
  termFee: [],
  campFeeIds: [],
  foodFeeIds: [],
  transportFeeIds: [],
  materialFeeIds: [],
  otherFeeIds: [],
  discountIds: [],
};

const useFinancialsFees = ({
  schoolId,
  selectedKidId,
  pickedTermTab,
}: {
  schoolId: number;
  selectedKidId: number;
  pickedTermTab: boolean;
}) => {
  const [pickedTerm, setPickedTerm] = useState<number | null>(null);
  const { data: termsData, revalidate: revalidateTerms } = useSWR<ChildFinanceTerm[]>(
    getChildFinanceTerms(schoolId, selectedKidId)
  );
  const { data, isValidating, revalidate } = useSWR<ChildPreviewFinanceConfig>(
    pickedTerm !== null ? getChildPreviewFinanceConfig(schoolId, selectedKidId, pickedTerm) : null
  );

  const mappedTermsData = termsData?.map(term => ({
    value: term.id,
    label: `${term.name} (${dayjs(term?.startDate).format("DD.MM.YYYY")} - ${dayjs(term?.endDate).format(
      "DD.MM.YYYY"
    )})`,
  }));

  const addFeeToStatement = async () => {
    try {
      await axiosAuth.post(`schools/${schoolId}/childs/${selectedKidId}/term-statements/${pickedTerm}`);
      await revalidateTerms();
      await revalidate();
      setPickedTerm(null);
      message.success(`Fee added to statement.`);
    } catch (e) {
      message.error(`Adding fee to statement failed, please try again, or contact support.`);
    }
  };

  const financeConfigLookups = data?.financeConfigLookups;
  const financeConfig = data?.financeConfig || initialFinanceConfig;
  const statementPreview = data?.statementPreview;

  useEffect(() => {
    if (pickedTermTab && !pickedTerm && termsData && termsData?.length > 0) {
      setPickedTerm(termsData[0].id);
    }
  }, [termsData, pickedTermTab, pickedTerm]);

  return {
    addFeeToStatement,
    data,
    pickedTerm,
    setPickedTerm,
    revalidate,
    mappedTermsData,
    financeConfigLookups,
    financeConfig,
    statementPreview,
    isValidating,
  };
};

export default useFinancialsFees;
