export const getChildPaymentsUrl = (schoolId: string, childId: string) =>
  `school/${schoolId}/financials/${childId}/payments`;

export const getEditChildPaymentUrl = (schoolId: string, childId: string, id: number) =>
  `school/${schoolId}/financials/${childId}/payments/${id}`;

export const paymentPageSize = 20;
export interface IChildPayments {
  id: number;
  date: string;
  payment_method: PaymentMethodDto;
  reference: string;
  type: PaymentCategoryTypeDto;
  amount: number;
}

export interface IChildPaymentsResponse {
  data: IChildPaymentsItem[];
  meta: {
    itemCount: number;
  };
}

export interface IChildPaymentsItem {
  id: number;
  childId: number;
  paymentMethod: PaymentMethodDto;
  amount: number;
  reference: string;
  type: PaymentCategoryTypeDto;
  paymentDate: string;
  createdAt: string;
}

export interface IChildPaymentBody {
  amount: number;
  reference: string;
  paymentDate: Date;
  type: PaymentCategoryTypeDto;
  paymentMethod: PaymentMethodDto;
}

export enum PaymentMethodDto {
  CASH = "CASH",
  CHEQUE = "CHEQUE",
  MASTER_CARD = "MASTER CARD",
  VISA_CARD = "VISA CARD",
  OTHER_CREDIT_CARD = "OTHER CREDIT CARD",
  DIRECT_DEBIT = "DIRECT DEBIT",
  BANK_TRANSFER = "BANK TRANSFER",
  PAYPAL = "PAYPAL",
  OTHER = "OTHER",
}

export enum PaymentCategoryTypeDto {
  PAYMENT = "PAYMENT",
  REFUND = "REFUND",
  ADJUSTMENT_REFUND = "ADJUSTMENT REFUND",
  ADJUSTMENT_INCREASE = "ADJUSTMENT INCREASE",
}

export const paymentMethod = [
  {
    label: PaymentMethodDto.CASH,
    value: PaymentMethodDto.CASH,
  },
  {
    label: PaymentMethodDto.CHEQUE,
    value: PaymentMethodDto.CHEQUE,
  },
  {
    label: PaymentMethodDto.MASTER_CARD,
    value: PaymentMethodDto.MASTER_CARD,
  },
  {
    label: PaymentMethodDto.VISA_CARD,
    value: PaymentMethodDto.VISA_CARD,
  },
  {
    label: PaymentMethodDto.OTHER_CREDIT_CARD,
    value: PaymentMethodDto.OTHER_CREDIT_CARD,
  },
  {
    label: PaymentMethodDto.DIRECT_DEBIT,
    value: PaymentMethodDto.DIRECT_DEBIT,
  },
  {
    label: PaymentMethodDto.BANK_TRANSFER,
    value: PaymentMethodDto.BANK_TRANSFER,
  },
  {
    label: PaymentMethodDto.PAYPAL,
    value: PaymentMethodDto.PAYPAL,
  },
  {
    label: PaymentMethodDto.OTHER,
    value: PaymentMethodDto.OTHER,
  },
];

export const paymentType = [
  {
    label: PaymentCategoryTypeDto.PAYMENT,
    value: PaymentCategoryTypeDto.PAYMENT,
  },
  {
    label: PaymentCategoryTypeDto.REFUND,
    value: PaymentCategoryTypeDto.REFUND,
  },
  {
    label: PaymentCategoryTypeDto.ADJUSTMENT_REFUND,
    value: PaymentCategoryTypeDto.ADJUSTMENT_REFUND,
  },
  {
    label: PaymentCategoryTypeDto.ADJUSTMENT_INCREASE,
    value: PaymentCategoryTypeDto.ADJUSTMENT_INCREASE,
  },
];
