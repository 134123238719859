function groupBy<T>(arr: T[], criteria: (item: T) => string | string) {
    return arr.reduce((obj: Record<string, T[]>, item) => {
        var key =
            typeof criteria === 'function' ? criteria(item) : item[criteria];
        if (!obj.hasOwnProperty(key)) {
            obj[key] = [];
        }
        obj[key].push(item);
        return obj;
    }, {});
}

export default groupBy;
