import { Form, Table, message } from "antd";
import { useState } from "react";
import { Spacer } from "components";
import { axiosAuth } from "helpers";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import { IDiscountCategory, IDiscountRecord } from "../../types";
import { concatedSelectOptions, siblingAndCorporateCategorySelectOptions } from "const";
import { EditableCell } from "../EditableCell/EditableCell";
import { getDiscountTableColumns } from "../../helpers";

interface Props {
  discountCategory: IDiscountCategory;
  dataSource: IDiscountRecord[];
  revalidate: () => Promise<boolean>;
  schoolId?: string;
}

export const DiscountTable = ({ dataSource, revalidate, schoolId, discountCategory }: Props) => {
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const { useForm } = Form;
  const [form] = useForm();
  const history = useHistory();

  const { data: schoolData } = useSWR(schoolId ? `school/${schoolId}` : null);
  const schoolCurrency = schoolData?.currency;

  const isEditing = (record: IDiscountRecord) => record.id === editingKey;

  const handleEditValue = (record: Partial<IDiscountRecord> & { id: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const handleCancel = () => {
    setEditingKey(null);
  };

  const handleDeleteValue = async (record: Partial<IDiscountRecord> & { id: React.Key }) => {
    const { id } = record;
    await axiosAuth.delete(`school/${schoolId}/financials/settings/discounts/${id}`);

    revalidate();
  };

  const save = async (record: IDiscountRecord) => {
    try {
      const { category, type, amount, name } = (await form.validateFields()) as IDiscountRecord;

      setEditingKey(null);

      //I don't know why this happens, but if I don't change anything in the select while editing it, the value select is its label, which is why this feature below
      const rowCategory = concatedSelectOptions.find(option => option.label === category)?.value;

      await axiosAuth.put(`school/${schoolId}/financials/settings/discounts/${record.id}`, {
        amount: Number(amount),
        applyTo: rowCategory ? rowCategory : category,
        name,
        type,
      });

      revalidate();
      message.success("discount edited successfully!");
      history.push(`/main/schools/${schoolId}/financials/discounts`);
    } catch (errInfo) {
      message.error("Error occurs when editing discount");
    }
  };

  const categorySelectOptions =
    discountCategory === "other" ? concatedSelectOptions : siblingAndCorporateCategorySelectOptions;

  const mergedColumns = () => {
    const discountColumns = getDiscountTableColumns({
      editingKey,
      schoolCurrency,
      isEditing,
      save,
      handleCancel,
      handleEditValue,
      handleDeleteValue,
      categorySelectOptions: categorySelectOptions,
    });

    return discountColumns.map(col => {
      const { editable, dataIndex, title, disableNegaitiveDigits, inputType, selectOptions } = col;

      if (!editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: IDiscountRecord) => ({
          record,
          dataIndex,
          title,
          editing: isEditing(record),
          disableNegaitiveDigits,
          inputType,
          selectOptions,
        }),
      };
    });
  };

  return (
    <>
      <Spacer size={10} />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataSource}
          columns={mergedColumns()}
          pagination={false}
        />
      </Form>
    </>
  );
};
