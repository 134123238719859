import { IApplyToCategory, IDiscountType } from "screens/Financials/Discounts/types";

export const typeSelectOptions: { label: string; value: IDiscountType }[] = [
  { label: "Percentage", value: "percentage" },
  { label: "Amount", value: "amount" },
];

export const siblingAndCorporateCategorySelectOptions: { label: string; value: IApplyToCategory }[] = [
  { label: "Term only fees", value: "termOnly" },
  { label: "All fees including registration", value: "allFee" },
];

export const otherCategorySelectOptions: { label: string; value: IApplyToCategory }[] = [
  { label: "Only the application / registration fee", value: "appRegFee" },
  { label: "The annual tuition fee", value: "annualTutFee" },
];

export const concatedSelectOptions =
  siblingAndCorporateCategorySelectOptions.concat(otherCategorySelectOptions);
