import { Button, message, Popconfirm } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import { FC, useState } from "react";

const DeleteButton: FC<{
  type?: "button" | "icon";
  onSubmit: () => Promise<any>;
  disabled?: boolean;
  isArchive?: boolean;
}> = ({ type = "button", onSubmit, isArchive = false, disabled = false }) => {
  const [isPending, setIsPending] = useState(false);
  return (
    <Popconfirm
      title={`Are you sure to ${isArchive ? "archive" : "delete"} this item`}
      onConfirm={async () => {
        try {
          setIsPending(true);
          await onSubmit();
          setIsPending(false);
          message.success("Item deleted succesfully");
        } catch (e) {
          setIsPending(false);
          message.error(`There was an issue with ${isArchive ? "archiving" : "deleting"} this item`);
        }
      }}
      onCancel={() => {}}
      okText="Yes"
      cancelText="No"
    >
      {type === "button" ? (
        <Button loading={isPending} type="text" style={{ color: "red" }} disabled={disabled}>
          {isArchive ? "Archive" : "Delete"}
        </Button>
      ) : (
        <Button
          loading={isPending}
          type="text"
          icon={<DeleteTwoTone twoToneColor="#E50A5C" />}
          disabled={disabled}
        />
      )}
    </Popconfirm>
  );
};

export default DeleteButton;
