import { greaterThanZeroValidator } from "helpers";

export const editableCellRules = (title: string, isNumberInput: boolean, disableNegaitiveDigits: boolean) => {
  const defaultRule = {
    required: true,
    message: `Please Input ${title}!`,
  };

  const rulesArr = [];

  rulesArr.push(defaultRule);

  if (isNumberInput && disableNegaitiveDigits) {
    rulesArr.push({
      validator: (_: any, value: any) => greaterThanZeroValidator(_, value),
    });
  }

  return rulesArr;
};
