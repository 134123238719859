import { Button, Input, Space, Spin, Table, Typography, message } from "antd";
import { Container, DeleteButton, TopNavigation } from "components";
import {
  SearchOutlined,
  UnorderedListOutlined,
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import { FC, useRef, useState } from "react";
import useSWR from "swr";
import LanguageModal, { InitialData } from "./LanguageModal";
import { useHistory } from "react-router";
import { axiosAuth } from "helpers";

const { Title } = Typography;

const { Column } = Table;

export type Language = {
  alpha2: string;
  alpha3b: string;
  createdAt: number;
  createdBy: number | null;
  id: number;
  status: number;
  title: string;
  updatedAt: number;
  updatedBy: number | null;
};

const Languages: FC = () => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<InitialData | null>(null);
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const { data, error, revalidate } = useSWR<Language[]>("language");
  const isPending = !data && !error;

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const generateTranslations = async () => {
    try {
      await axiosAuth.get("/translations/generate");
      message.success("Translations generated succesfully!");
    } catch (e) {}
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
  return (
    <>
      <Spin spinning={isPending}>
        <Container>
          <Table
            title={() => {
              return (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Title
                    level={3}
                    style={{
                      color: "#E50A5C",
                      paddingBottom: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    Languages
                  </Title>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "white",
                      color: "#BDD000",
                      borderColor: "#BDD000",
                      marginLeft: "auto",
                      marginRight: 8,
                    }}
                    onClick={generateTranslations}
                  >
                    Generate new translations
                  </Button>
                  <Button
                    color="#BDD000"
                    type="primary"
                    style={{
                      backgroundColor: "#BDD000",
                      borderColor: "#BDD000",
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setInitialData(null);
                      setTimeout(() => {
                        setIsModalVisible(true);
                      }, 0);
                    }}
                  >
                    Add new
                  </Button>
                </div>
              );
            }}
            dataSource={data}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Alpha 2"
              key="alpha2"
              dataIndex="alpha2"
              render={(alpha2: Language["alpha2"]) => (
                <Space size="middle">{alpha2}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return a.alpha2.localeCompare(b.alpha2);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("alpha2")}
            />
            <Column
              title="Alpha 3-b"
              key="alpha3b"
              dataIndex="alpha3b"
              render={(alpha3b: Language["alpha3b"]) => (
                <Space size="middle">{alpha3b}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return a.alpha3b.localeCompare(b.alpha3b);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("alpha3b")}
            />
            <Column
              title="Title"
              key="title"
              dataIndex="title"
              render={(title: Language["title"]) => (
                <Space size="middle">{title}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return a.title.localeCompare(b.title);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("title")}
            />
            <Column
              onFilter={(value, record: any) => record.status === value}
              filters={[
                { text: "Active", value: 10 },
                { text: "Inactive", value: 0 },
              ]}
              filterMultiple={false}
              title="Status"
              key="status"
              dataIndex="status"
              width="20%"
              render={(status: Language["status"]) => (
                <Space size="middle">
                  {status === 10 ? "Active" : "Inactive"}
                </Space>
              )}
            />
            <Column
              title=""
              width="20%"
              render={(
                data,
                { title, status, alpha2, alpha3b, id }: Language
              ) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        const initialData: InitialData = {
                          alpha2,
                          title,
                          status,
                          alpha3b,
                          id,
                        };
                        setInitialData(initialData);
                        setTimeout(() => {
                          setIsModalVisible(true);
                        }, 0);
                      }}
                    />
                    <DeleteButton
                      onSubmit={async () => {
                        await axiosAuth.get(`language/delete/${id}`);
                        await revalidate();
                      }}
                      type="icon"
                    />
                  </div>
                );
              }}
            />
          </Table>
        </Container>
      </Spin>
      <LanguageModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        afterSubmit={revalidate}
      />
    </>
  );
};

export default Languages;
