import { Button } from "antd";
import styled from "styled-components";
import { EditTwoTone } from "@ant-design/icons";
import { IRecord } from "./parseOtherFees";
import { DeleteButton } from "components";

interface IGetOtherTableColumnsArgs {
  periodName: string;
  isEditMode: boolean;
  editingKey: number | null;
  schoolCurrency: string;
  isEditing: (record: IRecord) => boolean;
  save: (record: IRecord) => Promise<void>;
  handleCancel: () => void;
  handleEditValue: (
    record: Partial<IRecord> & {
      id: React.Key;
    }
  ) => void;
  handleDeleteValue: (
    record: Partial<IRecord> & {
      id: React.Key;
    }
  ) => Promise<void>;
}

export const getOtherTableColumns = ({
  periodName,
  isEditMode,
  editingKey,
  schoolCurrency,
  isEditing,
  save,
  handleCancel,
  handleEditValue,
  handleDeleteValue,
}: IGetOtherTableColumnsArgs) => {
  return [
    {
      title: periodName,
      dataIndex: "feeName",
      width: "28%",
      editable: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "28%",
      editable: true,
      disableNegaitiveDigits: true,
      inputType: "number",
      render: (_: any, record: IRecord) => {
        return (
          <span>
            {record.amount} {schoolCurrency}
          </span>
        );
      },
    },
    {
      dataIndex: "operation",
      render: (_: any, record: IRecord) => {
        const editable = isEditing(record);

        if (!isEditMode) return null;

        return editable ? (
          <RowRightContainer>
            <Button
              color="#BDD000"
              type="primary"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
                marginRight: 8,
              }}
              onClick={() => save(record)}
            >
              Save
            </Button>
            <Button
              color="#E50A5C"
              type="primary"
              style={{
                backgroundColor: "#E50A5C",
                borderColor: "#E50A5C",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </RowRightContainer>
        ) : (
          <RowRightContainer>
            <Button
              type="text"
              icon={<EditTwoTone twoToneColor="#00A1E1" />}
              disabled={editingKey !== null}
              onClick={() => handleEditValue(record)}
            />
            <DeleteButton type="icon" onSubmit={() => handleDeleteValue(record)} />
          </RowRightContainer>
        );
      },
    },
  ];
};

const RowRightContainer = styled.div`
  display: flex;
  justify-content: end;
  align-content: flex-end;
`;
