import { Switch } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Container, PrivateRoute } from "components";

import { FinancialsNavigation } from "./components";
import { CampFees } from "./CampFees";
import OneTimeFees from "./OneTimeFees";
import AnnualFees from "./AnnualFees";
import Discounts from "./Discounts";
import Taxes from "./Taxes";
import { OtherFees } from "./OtherFees";
import { TermFees } from "./TermFees";
import Utilities from "./Utilities";
import { useAppSelector } from "store";
import { EmployeeRole } from "enums";

const Financials = (props: any) => {
  const role = useAppSelector(store => store.user.role);

  const isSuperAdmin = role === EmployeeRole.SUPER_ADMIN;

  if (!isSuperAdmin) return null;

  return (
    <Container>
      <FinancialsNavigation />
      <AnimatePresence>
        <motion.div
          key={props.location.pathname}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Switch>
            <PrivateRoute component={OneTimeFees} path="/main/schools/:id/financials/oneTimeFees" exact />
            <PrivateRoute component={AnnualFees} path="/main/schools/:id/financials/annualFees" exact />
            <PrivateRoute component={TermFees} path="/main/schools/:id/financials/termFees" exact />
            <PrivateRoute component={CampFees} path="/main/schools/:id/financials/campFees" exact />
            <PrivateRoute component={Utilities} path="/main/schools/:id/financials/utilities" exact />
            <PrivateRoute component={OtherFees} path="/main/schools/:id/financials/otherFees" exact />
            <PrivateRoute component={Discounts} path="/main/schools/:id/financials/discounts" exact />
            <PrivateRoute component={Taxes} path="/main/schools/:id/financials/taxes" exact />
          </Switch>
        </motion.div>
      </AnimatePresence>
    </Container>
  );
};

export default Financials;
