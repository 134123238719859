import styled, { css } from 'styled-components';
import PhoneInput from 'react-phone-number-input';

const CustomPhoneInput = styled(PhoneInput)<{ disabled: boolean }>`
  & .PhoneInputInput {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 3px 4px;
    transition: all 0.25s ease-out;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      & .PhoneInputInput {
        background-color: transparent;
        border: 1px solid transparent;
        padding: 3px 0px;
      }
    `};
`;

export default CustomPhoneInput;
