import { Dayjs } from 'dayjs';
import * as React from 'react';
import DatePicker from './DatePicker';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';

export interface TimePickerProps
  extends Omit<RangePickerProps<Dayjs>, 'picker'> {}

const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => {
  return (
    <DatePicker.RangePicker
      {...props}
      picker="time"
      mode={undefined}
      ref={ref}
    />
  );
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
