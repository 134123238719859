import { Button, Input, Space, Spin, Table, Typography } from "antd";
import { Container } from "components";
import { SearchOutlined, EditTwoTone, DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { FC, useRef, useState } from "react";
import HolidayModal, { InitialData } from "./HolidayModal";
import useSWR from "swr";
import dayjs from "dayjs";

const { Title } = Typography;

const { Column } = Table;

export type Country = {
  code: string;
  createdAt: number;
  createdBy: number | null;
  friday: number;
  id: number;
  moday: number;
  saturday: number;
  status: number;
  sunday: number;
  thursday: number;
  title: string;
  tuesday: number;
  updatedAt: number;
  updatedBy: number | null;
  wednesday: number;
};

const Holidays: FC<any> = props => {
  const countryId = props?.match?.params?.holidayId ?? 0;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<InitialData | null>(null);
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const { data, error, revalidate } = useSWR<Country[]>(`holiday/${countryId}`);
  //   const isPending = !data && !error;

  //   const normalizedData = data?.map((item) => {
  //     const { status, ...rest } = item;
  //     const newItem = {
  //       ...rest,
  //       status: status === 10 ? 'Active' : 'Inactive',
  //     };
  //     return newItem;
  //   });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
  return (
    <>
      <Spin spinning={false}>
        <Container>
          <Table
            title={() => {
              return (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Title
                    level={3}
                    style={{
                      color: "#E50A5C",
                      paddingBottom: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    Holidays
                  </Title>
                  <Button
                    color="#BDD000"
                    type="primary"
                    style={{
                      backgroundColor: "#BDD000",
                      borderColor: "#BDD000",
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setInitialData(null);
                      setTimeout(() => {
                        setIsModalVisible(true);
                      }, 0);
                    }}
                  >
                    Add new
                  </Button>
                </div>
              );
            }}
            dataSource={data ?? []}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Title"
              key="title"
              dataIndex="title"
              render={(title: Country["title"]) => <Space size="middle">{title}</Space>}
              width="20%"
              sorter={(a, b) => {
                return a.title.localeCompare(b.title);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("title")}
            />
            <Column
              title="Date Start"
              key="dateStart"
              dataIndex="dateStart"
              render={(dateStart: string) => (
                <Space size="middle">{dateStart ? dayjs(dateStart).format("DD-MM-YYYY") : "(not set)"}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return a.title.localeCompare(b.title);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("dateStart")}
            />
            <Column
              title="Date End"
              key="dateEnd"
              dataIndex="dateEnd"
              render={(dateEnd: string) => (
                <Space size="middle">{dateEnd ? dayjs(dateEnd).format("DD-MM-YYYY") : "(not set)"}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return a.title.localeCompare(b.title);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("dateEnd")}
            />
            <Column
              onFilter={(value, record: any) => record.status === value}
              filters={[
                { text: "Active", value: 10 },
                { text: "Inactive", value: 0 },
              ]}
              filterMultiple={false}
              title="Status"
              key="status"
              dataIndex="status"
              width="20%"
              render={(status: Country["status"]) => (
                <Space size="middle">{status === 10 ? "Active" : "Inactive"}</Space>
              )}
            />
            <Column
              title=""
              width="20%"
              render={data => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        const { dateStart, dateEnd, ...rest } = data;
                        setInitialData({
                          date: dateStart && dateEnd ? [dayjs(dateStart), dayjs(dateEnd)] : null,
                          ...rest,
                        });
                        setTimeout(() => {
                          setIsModalVisible(true);
                        }, 0);
                      }}
                    />
                    <Button type="text" icon={<DeleteTwoTone twoToneColor="#E50A5C" />} />
                  </div>
                );
              }}
            />
          </Table>
        </Container>
      </Spin>
      <HolidayModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        countryId={countryId}
        afterSubmit={revalidate}
      />
    </>
  );
};

export default Holidays;
