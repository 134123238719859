import { Button, Col, Divider, Image, Input, Pagination, Row, Space, Spin, Table, Typography } from "antd";
import { SearchOutlined, EditTwoTone, DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { Spacer } from "components";
import dayjs from "dayjs";
import { useMemo, useRef, useState } from "react";
import useSWR from "swr";
import DayLogsModal, { mapTypes, InitialData } from "./DayLogsModal";

const { Title } = Typography;
const { Column } = Table;

const typesFilter = Object.entries(mapTypes).map(([key, val]) => ({
  text: val,
  value: parseInt(key),
}));

const DayLogs = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<InitialData | null>(null);
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const { data, error } = useSWR<any[]>(
    props?.location?.state?.id ? `day-log/config/${props.location.state.id}` : null
  );
  const isPending = !data && !error;

  const normalizedData = useMemo(
    () =>
      data?.map(item => ({
        ...item,
        dateTime: dayjs.utc(item.dateTime).local().format("YYYY-MM-DD HH:mm:ss"),
      })),
    [data]
  );

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  return (
    <>
      <Spin spinning={isPending}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "4px",
            overflow: "hidden",
            boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
            padding: "24px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Title
              level={3}
              style={{
                color: "#E50A5C",
                paddingBottom: "0px",
                marginBottom: "0px",
              }}
            >
              Day logs
            </Title>
          </div>
          <Divider />
          <Table dataSource={normalizedData}>
            <Column
              title="Type"
              key="type"
              dataIndex="type"
              render={(type: number) => <Space size="middle">{mapTypes[type] ?? "Unknown"}</Space>}
              width="40%"
              onFilter={(value, record: any) => record.type === value}
              filters={typesFilter}
            />
            <Column
              title="Value"
              key="value"
              dataIndex="value"
              render={(value: string) => <Space size="middle">{value}</Space>}
              width="30%"
              {...getColumnSearchProps("value")}
            />
            <Column
              title="Date"
              key="dateTime"
              dataIndex="dateTime"
              render={(dateTime: string) => <Space size="middle">{dateTime}</Space>}
              width="30%"
              {...getColumnSearchProps("dateTime")}
            />
          </Table>
          <Spacer />
        </div>
      </Spin>
      <DayLogsModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
      />
    </>
  );
};

export default DayLogs;
