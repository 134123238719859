import {
  Col,
  Divider,
  Modal,
  Row,
  Spin,
  Typography,
  Form,
  Input,
  Button,
  message,
} from 'antd';
import { ErrorMessage } from 'enums';
import { axiosAuth } from 'helpers';
import { FC, useState } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { Spacer } from './UI';

const { Title, Text } = Typography;

type ProfileData = {
  firstName: string;
  lastName: string;
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

const ProfileDetailsModal: FC<Props> = ({ isVisible, onClose }) => {
  const [isSaveNewPasswordPending, setIsSaveNewPasswordPending] = useState(
    false
  );
  const { data, error } = useSWR<ProfileData>('user/me');
  const isPending = !data && !error;
  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          Profile data
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Spin spinning={isPending}>
        <Row>
          <Col span={24}>
            Name: {data?.firstName ?? ''} {data?.lastName ?? ''}
          </Col>
          <Col span={24}>email: {data?.firstName}</Col>
        </Row>
        <Spacer size={10} />
        <Row align="middle" gutter={[6, 0]}>
          <Col>
            <Text style={{ fontSize: '1.4rem' }}>New password</Text>
          </Col>
          <Col flex={1}>
            <Divider style={{ margin: '0px 0px' }} />
          </Col>
        </Row>
        <Form
          onFinish={async (values) => {
            setIsSaveNewPasswordPending(true);
            await axiosAuth.post('auth/change-password', values);
            setIsSaveNewPasswordPending(false);
            message.success('Password has been changed succesfully');
          }}
        >
          <Form.Item
            label="Old password"
            name="oldPassword"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            labelCol={{ span: 6 }}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New password"
            name="newPassword"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            labelCol={{ span: 6 }}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#BDD000',
                borderColor: '#BDD000',
              }}
            >
              Save new password
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ProfileDetailsModal;

const StyledText = styled(Text)`
  font-size: 1.4rem;
`;
