import { Table, Space } from "antd";
import Column from "antd/lib/table/Column";
import { Spacer } from "components";

interface Props {
  currentClass?: { label: string; value: number };
  currentTime?: { label: string; value: number };
  currentTerm?: { name: string };
}

export const CurrentEvents = ({ currentClass, currentTime, currentTerm }: Props) => {
  const noData = !currentClass && !currentTime && !currentTerm;

  const currentEventsObject = {
    currentTerm: currentTerm?.name,
    currentClass: currentClass?.label,
    daysAndTimes: currentTime?.label,
  };

  const dataSource = !noData ? [currentEventsObject] : [];

  return (
    <>
      <Spacer />
      <Table dataSource={dataSource} pagination={false}>
        <Column
          title="Current Term"
          key="currentTerm"
          dataIndex="currentTerm"
          render={value => <Space size="middle">{value}</Space>}
          width="33%"
        />
        <Column
          title="Currrent Class"
          key="currentClass"
          dataIndex="currentClass"
          render={value => <Space size="middle">{value}</Space>}
          width="33%"
        />
        <Column
          title="Days & Times"
          key="daysAndTimes"
          dataIndex="daysAndTimes"
          render={value => <Space size="middle">{value}</Space>}
          width="33%"
        />
      </Table>
    </>
  );
};
