import { useState } from "react";
import { Card, Spin, Tabs, Typography } from "antd";
import { RouteComponentProps } from "react-router-dom";
import useSWR from "swr";
import CreateCampPanel from "../CreateCampPanel";
import SummaryPanel from "../SummaryPanel";
import { CampRequest, CampResponse } from "types";

const { TabPane } = Tabs;

const { Title } = Typography;

type Props = RouteComponentProps<
  { id?: string; campId: string },
  any,
  { id?: string; campId: string }
>;

const EditCamp = ({ match, location }: Props) => {
  const [activeKey, setActiveKey] = useState("1");
  const [camps, setCamps] = useState<CampRequest | null>(null);
  const [disableSummary, setDisableSummary] = useState(true);
  const [disableCreateTab, setDisableCreateTab] = useState(false);

  const schoolId = match.params.id ?? location.state?.id;
  const campId = match.params.campId ?? location.state?.campId;

  const { data: campData, error: campError } = useSWR<CampResponse>(
    `camps/${campId}`
  );

  const isPending = !schoolId || !campData;

  const handleChangeTab = (item: string) => {
    setActiveKey(item);
  };

  const handleSaveData = (camps: CampRequest) => {
    setCamps(camps);
    setActiveKey("2");
    setDisableSummary(false);
    setDisableCreateTab(true);
  };

  const handleChangeTabs = () => {
    setActiveKey("1");
    setDisableCreateTab(false);
    setDisableSummary(true);
  };

  return (
    <Spin spinning={isPending}>
      {schoolId && (
        <Card>
          <Title level={4} style={{ color: "#E50A5C", margin: "0px" }}>
            Edit camp
          </Title>
          <div style={{ marginTop: 20 }}>
            <Tabs
              type="card"
              activeKey={activeKey}
              onChange={(item) => handleChangeTab(item)}
              tabBarGutter={10}
            >
              <TabPane
                tab="Edit camp"
                key={1}
                closable={false}
                disabled={disableCreateTab}
              >
                <CreateCampPanel
                  saveCamps={(camps) => handleSaveData(camps)}
                  initialFormData={campData ? campData : null}
                />
              </TabPane>
              <TabPane
                tab="Summary"
                key={2}
                closable={false}
                disabled={disableSummary}
              >
                <SummaryPanel
                  camps={camps}
                  changeTabs={handleChangeTabs}
                  schoolId={schoolId}
                  editCampId={campId}
                />
              </TabPane>
            </Tabs>
          </div>
        </Card>
      )}
    </Spin>
  );
};

export default EditCamp;
