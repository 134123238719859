import { Form, Table, message } from "antd";
import { useState } from "react";
import useSWR from "swr";
import { Spacer } from "components";
import { axiosAuth } from "helpers";
import { useHistory } from "react-router-dom";
import { EditableCell } from "screens/Financials/components";
import { getOtherTableColumns } from "../../helpers/getOtherTableColumns";
import { IParsedOtherFeesData, IRecord } from "../../helpers/parseOtherFees";

interface Props {
  dataSource: IParsedOtherFeesData;
  isEditMode: boolean;
  revalidate: () => Promise<boolean>;
  schoolId?: string;
}

export const OtherFeesTable = ({ dataSource, isEditMode, revalidate, schoolId }: Props) => {
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const { useForm } = Form;
  const [form] = useForm();

  const { data: schoolData } = useSWR(schoolId ? `school/${schoolId}` : null);
  const schoolCurrency = schoolData?.currency;

  const isEditing = (record: IRecord) => record.id === editingKey;

  const handleEditValue = (record: Partial<IRecord> & { id: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const handleCancel = () => {
    setEditingKey(null);
  };

  const handleDeleteValue = async (record: Partial<IRecord> & { id: React.Key }) => {
    await axiosAuth.delete(`school/${schoolId}/financials/settings/other-fees/${record.id}`);

    revalidate();
  };

  const save = async (record: IRecord) => {
    try {
      const { amount, feeName } = (await form.validateFields()) as IRecord;
      setEditingKey(null);

      await axiosAuth.put(`school/${schoolId}/financials/settings/other-fees/${record.id}`, {
        amount: Number(amount),
        name: feeName,
      });

      revalidate();
      message.success("Fee edited successfully!");
    } catch (errInfo) {
      message.error("Error occurs when editing fee");
    }
  };

  const mergedColumns = (feePeriod: string) => {
    const termFeesColumns = getOtherTableColumns({
      periodName: feePeriod,
      isEditMode,
      editingKey,
      schoolCurrency,
      isEditing,
      save,
      handleCancel,
      handleEditValue,
      handleDeleteValue,
    });

    return termFeesColumns.map(col => {
      const { editable, dataIndex, title, disableNegaitiveDigits, inputType } = col;

      if (!editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: IRecord) => ({
          record,
          dataIndex,
          title,
          editing: isEditing(record),
          disableNegaitiveDigits,
          inputType,
        }),
      };
    });
  };

  const { oneTime, perTerm } = dataSource;

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={oneTime.data}
          columns={mergedColumns(oneTime.name)}
          pagination={false}
        />
      </Form>
      <Spacer size={40} />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={perTerm.data}
          columns={mergedColumns(perTerm.name)}
          pagination={false}
        />
      </Form>
      <Spacer size={40} />
    </>
  );
};
