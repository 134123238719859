const getSummaryItemColor = (value: number) => {
  if (!value) {
    return "green";
  }
  if (value < 0) {
    return "red";
  } else if (value > 0) {
    return "green";
  }
};

export default getSummaryItemColor;
