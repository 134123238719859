import { Col, Form, Input } from "antd";
import { ReactNode } from "react";

interface Props {
  icon: ReactNode;
  isEditMode: boolean;
  placeholder: string;
  name: string;
  label?: ReactNode;
}

export const SocialSelect = ({ icon, isEditMode, name, placeholder, label }: Props) => {
  return (
    <Col flex={1}>
      <Form.Item name={name} label={label} labelCol={{ span: label ? 24 : undefined }}>
        <Input
          placeholder={placeholder}
          disabled={!isEditMode}
          prefix={icon}
          style={
            isEditMode
              ? {}
              : {
                  border: "none",
                  backgroundColor: "white",
                  padding: "4px 11px 4px 0px",
                }
          }
        />
      </Form.Item>
    </Col>
  );
};
