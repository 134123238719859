import { Button } from "antd";
import CustomModal from "components/CustomModal";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { AddSupraPaymentModal } from "../AddSupraPaymentModal/AddSupraPaymentModal";
import { useForm } from "antd/lib/form/Form";

interface Props {
  schoolId: number;
  revalidate: () => Promise<boolean>;
}

export const AddSupraPayment = ({ schoolId, revalidate }: Props) => {
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [form] = useForm();

  const handleClosePaymentModal = () => {
    setAddPaymentModal(false);
    form.resetFields();
  };
  const handleOnFinishPayment = () => {
    setAddPaymentModal(false);
    form.resetFields();
  };

  return (
    <>
      <Button
        loading={false}
        type="primary"
        icon={<PlusOutlined />}
        style={{
          backgroundColor: "#BDD000",
          borderColor: "#BDD000",
          fontSize: 14,
        }}
        onClick={async () => {
          setAddPaymentModal(true);
        }}
      >
        Add
      </Button>
      <CustomModal title="Add" isVisible={addPaymentModal} onClose={handleClosePaymentModal} width={700}>
        <AddSupraPaymentModal
          schoolId={schoolId}
          onFinish={handleOnFinishPayment}
          revalidate={revalidate}
          form={form}
        />
      </CustomModal>
    </>
  );
};
