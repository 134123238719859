import { Col, Row, Spin, Typography, Form, DatePicker, Input, Checkbox, Button, message } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import dayjs from "dayjs";
import { countWeeksBetweenTwoDates, groupBy } from "helpers";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { CampRequest, CampResponse, CampUnits } from "types";

const { Text } = Typography;

interface CreateCampPaneProps {
  initialFormData?: CampResponse | null;
  saveCamps: (item: CampRequest) => void;
}

const CreateCampPanel = ({ saveCamps, initialFormData }: CreateCampPaneProps) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(
    initialFormData ? initialFormData.units.map((item: CampUnits) => item.name) : []
  );
  const [weekCounter, setWeekCounter] = useState<number>(0);

  const { data: campModules, error: campError } = useSWR<CampUnits[]>(`camps-units`);

  const isModulesLoading = !campModules && !campError;
  const onFinishFailed = (errorInfo: any) => {};
  const [form] = Form.useForm();

  const normalizedData = useMemo(
    () =>
      Object.values(
        groupBy(
          campModules?.map(item => ({
            ...item,
          })) ?? [],
          item => item.unitName
        )
      ),
    [campModules]
  );

  useEffect(() => {
    if (!initialFormData) return;

    form.setFieldsValue(initialFormData);
    form.setFieldsValue({
      date: [moment(initialFormData?.startAt), moment(initialFormData?.endAt)],
    });
    if (initialFormData.startAt && initialFormData.endAt) {
      setWeekCounter(countWeeksBetweenTwoDates(initialFormData?.startAt, initialFormData?.endAt));
    }
  }, [form, initialFormData]);

  useEffect(() => {
    if (initialFormData) {
      const checkedValues = initialFormData.units.map((item: CampUnits) => item.name);
      setCheckedValues(checkedValues);
    }
  }, [initialFormData]);

  const onFinish = async (values: any) => {
    const { date, name } = values;
    if (!date || !name) return;

    const [startDate, endDate] = date;
    if (!campModules) return;

    if (checkedValues.length !== weekCounter) {
      message.error("Unit's number and week's counter have to match");
      return;
    }

    const units = checkedValues.reduce((units: CampUnits[], value) => {
      const module: CampUnits[] = campModules.filter(itemObject => itemObject.name === value);
      if (module.length === 0) return [...units];

      return [
        ...units,
        {
          id: module[0].id,
          weekNumber: module[0].weekNumber,
          unitName: module[0].unitName,
          name: module[0].name,
        },
      ];
    }, []);

    const body: CampRequest = {
      name: name,
      startAt: dayjs(startDate).toDate(),
      endAt: dayjs(endDate).toDate(),
      units: units,
    };

    saveCamps(body);
  };

  const onChange = (checkedValues: CheckboxChangeEvent) => {
    if (!checkedValues) return;
    setCheckedValues(prevState => {
      if (prevState.includes(checkedValues.target.value)) {
        return prevState.filter(item => item !== checkedValues.target.value);
      }
      return [...prevState, checkedValues.target.value];
    });
  };

  const selectAllCategoryItems = (checkedValues: CheckboxChangeEvent) => {
    const { value, checked } = checkedValues.target;
    if (checked) {
      value.forEach((categoryItem: CampUnits) => {
        setCheckedValues(prevState => {
          if (prevState.includes(categoryItem.name)) {
            return [...prevState];
          }
          return [...prevState, categoryItem.name];
        });
      });
    } else {
      value.forEach((categoryItem: CampUnits) => {
        setCheckedValues(prevState => {
          return prevState.filter(item => item !== categoryItem.name);
        });
      });
    }
  };

  const checkIfexists = (name: string) => checkedValues.includes(name);

  return (
    <div style={{ marginTop: 30 }}>
      <Spin spinning={isModulesLoading}>
        <Form
          name="basic"
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
          layout="vertical"
          form={form}
          initialValues={initialFormData ?? {}}
        >
          <Row gutter={10}>
            <Col span={9}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span
                        style={{
                          fontSize: 14,
                          color: "#932B7C",
                          fontWeight: 700,
                        }}
                      >
                        Name of camp
                      </span>
                    }
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <span
                    style={{
                      fontSize: 14,
                      color: "#932B7C",
                      fontWeight: 700,
                    }}
                  >
                    Date
                  </span>
                }
                name="date"
                required
                rules={[{ required: true, message: "Please select date!" }]}
              >
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  onChange={items => {
                    if (!items || items.length < 2) return;
                    const startAt = items[0]?.toDate() ?? new Date();
                    const endAt = items[1]?.toDate() ?? new Date();
                    const countWeeks = countWeeksBetweenTwoDates(startAt, endAt);
                    setWeekCounter(countWeeks);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 50 }}>
            <Col>
              <Text>
                From the dates you have entered this Term consists of{" "}
                <span
                  style={{
                    borderColor: "#E8246D",
                    borderWidth: 1,
                    borderStyle: "solid",
                    padding: 2,
                  }}
                >
                  {weekCounter}
                </span>{" "}
                weeks of parts of weeks.{" "}
              </Text>
            </Col>
          </Row>
          <Row style={{ marginTop: 50, marginBottom: 50 }}>
            <Col>
              You must select the{" "}
              <p
                style={{
                  color: "#E8246D",
                  textDecoration: "underline",
                  display: "inline",
                  fontWeight: "500",
                }}
              >
                same
              </p>{" "}
              number of sequential weekly lesson plans of these weeks.
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {normalizedData.map((item, index) => {
              return (
                <Col span={8}>
                  <div key={index} style={{ display: "flex", flexDirection: "column" }}>
                    <Text
                      style={{
                        fontSize: 14,
                        lineHeight: 2,
                        fontWeight: 700,
                      }}
                    >
                      {item[0].unitName}:
                    </Text>
                    <div>
                      <Checkbox onChange={selectAllCategoryItems} value={item}>
                        Select all
                      </Checkbox>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <Form.Item style={{ display: "flex" }} name={item[0].unitName}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          {item.map(unit => {
                            return (
                              <div style={{ marginLeft: 0 }}>
                                <Checkbox
                                  onChange={onChange}
                                  value={unit.name}
                                  key={unit.id}
                                  checked={checkIfexists(unit.name) ? true : false}
                                >
                                  {unit.name}
                                </Checkbox>
                              </div>
                            );
                          })}
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                    marginTop: 20,
                  }}
                  htmlType="submit"
                >
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default CreateCampPanel;
