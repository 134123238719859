import { Steps } from 'antd';
import { Container, Spacer } from 'components';
import { useState } from 'react';
import PlansList from './PlansList';
import WeekList from './WeekList';

const { Step } = Steps;

type Item = {
  id: number;
  name: string;
};

const PlanTemplates = () => {
  const [current, setCurrent] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<Item | null>(null);

  const renderContent = () => {
    if (current === 0) {
      return (
        <PlansList
          onDetailsClick={({ name = '', id }) => {
            setSelectedPlan({ id, name });
            setCurrent(1);
          }}
        />
      );
    }
    if (current === 1) {
      return <WeekList planId={selectedPlan?.id} />;
    }
    return null;
  };

  return (
    <>
      <Spacer />
      <Container>
        <Steps current={current} progressDot>
          <Step
            title="Plan templates"
            onClick={() => {
              setCurrent(0);
              setSelectedPlan(null);
            }}
            description={selectedPlan?.name ? `(${selectedPlan.name})` : ''}
          />
          <Step
            title="Weeks"
            onClick={() => {
              if (selectedPlan) {
                setCurrent(1);
              }
            }}
            disabled={!selectedPlan}
          />
        </Steps>
        <Spacer size={40} />
        {renderContent()}
      </Container>
    </>
  );
};

export default PlanTemplates;
