import { Button } from "antd";
import CustomModal from "components/CustomModal";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import AddPaymentModal from "screens/Kid/Financials/Payments/AddPaymentModal/AddPaymentModal";

interface Props {
  kidId: string;
  revalidate: () => Promise<boolean>;
  schoolId?: string;
}

export const AddPayment = ({ kidId, revalidate, schoolId }: Props) => {
  const [addPaymentModal, setAddPaymentModal] = useState(false);

  const handleClosePaymentModal = () => {
    setAddPaymentModal(false);
  };
  const handleOnFinishPayment = () => {
    setAddPaymentModal(false);
    revalidate();
  };

  return (
    <>
      <Button
        loading={false}
        type="primary"
        icon={<PlusOutlined />}
        style={{
          backgroundColor: "#BDD000",
          borderColor: "#BDD000",
          fontSize: 14,
        }}
        onClick={async () => {
          setAddPaymentModal(true);
        }}
      >
        Add
      </Button>
      <CustomModal title="Add" isVisible={addPaymentModal} onClose={handleClosePaymentModal} width={700}>
        <AddPaymentModal schoolId={schoolId} kidId={kidId} onFinish={handleOnFinishPayment} />
      </CustomModal>
    </>
  );
};
