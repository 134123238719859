import { Formik, Form, FormikHelpers } from "formik";
import CustomModal from "components/CustomModal";
import * as Yup from "yup";
import { FormikInput } from "components/FormFields";
import { Button, message } from "antd";
import { axiosAuth } from "helpers";
import { editOneTimeFeeUrl, getOneTimeFeeUrl } from "types/rest/oneTimeFee";

interface Props {
  isVisible: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  schoolId?: string;
  isEdit: boolean;
  feeId?: number;
}

interface InitialValues {
  amount: null;
}

const schema = Yup.object().shape({
  amount: Yup.number().required("Required"),
});

const OneTimeFeeModal = ({ isVisible, handleCancel, schoolId, handleSubmit, isEdit, feeId }: Props) => {
  const handleSubmitFee = async (values: InitialValues, helpers: FormikHelpers<InitialValues>) => {
    if (!schoolId) return;

    if (Number(values?.amount) <= 0) {
      message.error("The provided value must be greather than 0.");
      return;
    }

    if (isEdit && feeId) {
      await axiosAuth.put(editOneTimeFeeUrl(schoolId, feeId), {
        amount: Number(values.amount),
      });

      helpers.resetForm();
      handleSubmit();
      return;
    }

    await axiosAuth.post(getOneTimeFeeUrl(schoolId), {
      amount: Number(values.amount),
    });

    helpers.resetForm();
    handleSubmit();
  };

  return (
    <CustomModal
      isVisible={isVisible}
      title={`${isEdit ? "Edit" : "Create"} one times fee`}
      onClose={handleCancel}
    >
      <Formik
        initialValues={{ amount: null }}
        validationSchema={schema}
        onSubmit={async (values, helpers) => {
          handleSubmitFee(values, helpers);
        }}
      >
        {({ setFieldValue, handleSubmit, errors }) => {
          return (
            <Form>
              <FormikInput
                name="amount"
                label="What is the amount?:"
                type="number"
                onChange={value => setFieldValue("amount", value)}
                required
                placeholder="example"
              />
              {errors.amount ? (
                <div style={{ color: "#EF0B0BD9", textAlign: "center" }}>This field is required!</div>
              ) : null}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="text"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                    color: "white",
                  }}
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default OneTimeFeeModal;
