import { Switch, Typography } from "antd";
import { ViewType } from "enums";
import { RouteComponentProps } from "react-router-dom";
import useSWR from "swr";
import { Spacer } from "components";
import { CampFeesTable } from "./components/CampFeesTable/CampFeesTable";
import { parseCampFees } from "./helpers/parseCampFees";
import { useState } from "react";
import styled from "styled-components";
import { NoDataInfo } from "../components";

export interface ICampFeesData {
  amount: number;
  camp: string;
  id: number;
  timeName: string;
  timeId: number;
}

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string }>;

export const CampFees = (props: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { Title } = Typography;
  const schoolId = props.match.params.id;

  const { data: campFeesData, revalidate } = useSWR<ICampFeesData[]>(
    `school/${schoolId}/financials/settings/camps-fees`
  );

  return (
    <>
      <RowRightContainer>
        <Switch
          checkedChildren="Edit mode"
          unCheckedChildren="Edit mode"
          defaultChecked
          onChange={val => setIsEditMode(val)}
          checked={isEditMode}
        />
      </RowRightContainer>
      <Spacer size={20} />
      <Title
        level={3}
        style={{
          color: "#E50A5C",
          paddingBottom: "0px",
          marginBottom: "0px",
          backgroundColor: "#FFF",
          textAlign: "center",
          paddingBlock: 28,
        }}
      >
        Camp Fees
      </Title>
      <Spacer size={40} />

      {campFeesData && campFeesData.length > 0 ? (
        parseCampFees(campFeesData).map(({ camp, times }) => (
          <CampFeesTable
            campName={camp}
            dataSource={times}
            key={camp}
            isEditMode={isEditMode}
            revalidate={revalidate}
            schoolId={schoolId}
          />
        ))
      ) : (
        <NoDataInfo title="You have not added any camps or camp times for this school" />
      )}
    </>
  );
};

const RowRightContainer = styled.div`
  display: flex;
  justify-content: end;
  align-content: flex-end;
`;
