import { Button, Col, Form, Input, Popconfirm, Row, Typography, message } from "antd";
import { FC, useState } from "react";
import useSWR from "swr";
import dayjs from "dayjs";

import { PlusOutlined, DownloadOutlined, FileOutlined, ShareAltOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { useForm } from "antd/lib/form/Form";

import { axiosAuth } from "helpers";
import { Spacer } from "components/UI";
import DeleteButton from "components/DeleteButton";
import {
  getDeleteReportUrl,
  getReportsListUrl,
  getSendReportUrl,
  getShareReportUrl,
  getSignedReportUrl,
} from "types";
import { ErrorMessage } from "enums";

const { Title } = Typography;

export type SkillsGroupsList = {
  id: number;
  rank: number;
  status: number;
  type: number;
  name: string;
  items: {
    id: number;
    name: string;
    developmentItem: {
      answer: number;
      id: number;
      name: string;
    }[];
  }[];
};

type Res = SkillsGroupsList[];

type ProgressReport = {
  id: number;
  createdAt: number;
  updatedAt: number;
  isShared: boolean;
  reportComments: {
    id: number;
    developmentCategoryId: number;
    comment: string;
  }[];
};

type Props = {
  kidId?: number;
};

const ProgressReports: FC<Props> = props => {
  const [form] = useForm();

  const kidId = props.kidId;

  const { data } = useSWR<Res>(kidId ? `development-category/config/${kidId}` : null);

  const { data: progressReportsData, revalidate: revalidateProgress } = useSWR<ProgressReport[]>(
    kidId ? getReportsListUrl(kidId) : null
  );

  const sortedProgressReportsData = progressReportsData?.sort((a, b) => b?.updatedAt - a?.updatedAt);

  const [changingCommentPending, setChangingCommentPending] = useState(false);

  const normalizedSkillsHeaders = data?.map(({ name, id }) => {
    return {
      title: name,
      id,
    };
  });

  const submitForm = () => {
    form.submit();
  };

  const convertToSendData = (values: Record<number, string>) => {
    const comments = Object.entries(values).map(([key, value]) => {
      return {
        developmentCategoryId: Number(key),
        comment: value,
      };
    });

    return {
      childId: kidId,
      comments: comments.filter(comment => comment?.comment && comment.comment !== ""),
    };
  };

  const generateProgressReport = async (reportId: number) => {
    try {
      const res = await axiosAuth.get(getSignedReportUrl(reportId));
      const url = res?.data.url;
      if (url) {
        window.open(url, "_blank");
      }
    } catch (e) {
      message.error("Generating progress report failed, please try again, or contact support.");
    }
  };

  const deleteProgressReport = async (reportId: number) => {
    try {
      const res = await axiosAuth.delete(getDeleteReportUrl(reportId));
      await revalidateProgress();
    } catch (e) {
      message.error("Deleting progress report failed, please try again, or contact support.");
    }
  };

  const shareProgressReport = async (reportId: number) => {
    try {
      const res = await axiosAuth.post(getShareReportUrl(reportId));
      await revalidateProgress();
    } catch (e) {
      message.error("Share'ing progress report failed, please try again, or contact support.");
    }
  };

  const submitComments = async (values: any) => {
    setChangingCommentPending(true);
    const { attendance, ...rest } = values;

    const sendValues = convertToSendData(rest);
    try {
      await axiosAuth.post(getSendReportUrl(), {
        ...sendValues,
        attendancePercent: Number(attendance),
      });
      setChangingCommentPending(false);
      revalidateProgress();
    } catch (e) {
      message.error("Generating progress report failed, please try again, or contact support.");
    }
  };

  return (
    <>
      <Col
        style={{
          backgroundColor: "white",
          borderRadius: "4px",
          overflow: "hidden",
          boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
          padding: "24px",
        }}
      >
        <Title level={4} style={{ color: "#E50A5C", margin: "0px", textAlign: "center" }}>
          Progress Reports
        </Title>
        <Spacer size={40} />
        <Row>
          <Col span={24}>
            <Text>Additional Child Information In PDF</Text>
            <Form name="progress_reports_items" form={form} onFinish={submitComments}>
              <Form.Item
                fieldKey="attendance"
                name="attendance"
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <p>Attendance</p>
                <Input
                  placeholder="Input for attendance in percentage"
                  onChange={e =>
                    form.setFieldsValue({
                      attendance: e.target.value,
                    })
                  }
                />
              </Form.Item>
              {normalizedSkillsHeaders?.map(({ title, id }) => (
                <Form.Item fieldKey={id} name={id}>
                  <p>{title}</p>
                  <Input
                    placeholder="Input for additional information that you want to add to generated pdf for skill"
                    onChange={e =>
                      form.setFieldsValue({
                        [id]: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              ))}
              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 auto",
                  width: "min-content",
                }}
              >
                <Button
                  type="dashed"
                  htmlType="submit"
                  // onClick={submitForm}
                  block
                  loading={changingCommentPending}
                  icon={<PlusOutlined />}
                >
                  Generate Progress Report
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Spacer size={16} />
        <Row>
          {sortedProgressReportsData?.map(({ id, reportComments, createdAt, isShared, updatedAt }) => (
            <>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FileOutlined style={{ marginRight: "5px" }} />
                    <Text style={{ width: "100%" }}>
                      ({dayjs.unix(updatedAt).format("DD-MM-YYYY")}) Progress Report
                    </Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    {!isShared && (
                      <Popconfirm
                        title="Are you sure you want to share this report with parent?"
                        onConfirm={() => shareProgressReport(id)}
                      >
                        <div
                          style={{
                            marginRight: "20px",
                            color: "#00A1E1",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.6rem",
                            cursor: "pointer",
                          }}
                        >
                          <ShareAltOutlined />
                        </div>
                      </Popconfirm>
                    )}
                    <div
                      style={{
                        marginRight: "20px",
                        color: "#00A1E1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1.6rem",
                        cursor: "pointer",
                      }}
                      onClick={() => generateProgressReport(id)}
                    >
                      <DownloadOutlined />
                    </div>
                    <DeleteButton type="icon" onSubmit={() => deleteProgressReport(id)} />
                  </div>
                </div>
              </Col>
            </>
          ))}
        </Row>
      </Col>
    </>
  );
};
export default ProgressReports;
