import { Switch, useLocation } from "react-router-dom";
import { PrivateRoute, TopNavigation } from "components";
import { Layout } from "antd";
import { FC } from "react";
import { Terms, Times, Extras, Shifts, Suppliers, Vacations } from "../index";
import DashboardNavigation from "components/DashboardNavigation";
import Kids from "screens/Kids";
import Carers from "screens/Carers";
import Staff from "screens/Staff";
import Classes from "screens/Classes";
import { AnimatePresence, motion } from "framer-motion";
import Kid from "screens/Kid";
import AddKid from "screens/Kid/AddKid";
import AddCarer from "screens/AddCarer";
import AddStaff from "screens/AddStaff";
import AddClass from "screens/AddClass";
import { ViewType } from "enums";
import AddSupplier from "screens/AddSupplier";
import AddSchool from "screens/AddSchool";
import CampsList from "screens/Camps/CampsList";
import AddCamp from "screens/Camps/AddCamp";
import EditCamp from "screens/Camps/EditCamp";
import TermList from "screens/TermsV2/TermsList";
import AddTerm from "screens/TermsV2/AddTerm";
import EditTerm from "screens/TermsV2/EditTerm";
import Financials from "screens/Financials/Financials";
import { FinancialsSingleSchool } from "screens/Invoice/FinancialsSingleSchool/FinancialsSingleSchool";

const { Header, Sider, Content } = Layout;

const School: FC = aaa => {
  const location = useLocation();
  const normalizedPathname = location.pathname.split("/")[4];
  return (
    <>
      <TopNavigation />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sider
          style={{
            height: "100vh",
            position: "sticky",
            top: "0",
          }}
        >
          <DashboardNavigation />
        </Sider>
        <Layout
          style={{
            minHeight: "100vh",
          }}
        >
          <Content
            style={{
              padding: "24px 16px 0",
              minHeight: "100%",
              backgroundColor: "#f8f8f8",
            }}
          >
            <AnimatePresence>
              <motion.div
                key={normalizedPathname}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Switch>
                  <PrivateRoute
                    path="/main/schools/:id/kids"
                    component={(props: any) => <Kids {...props} viewType={ViewType.SCHOOL_DETAILS} />}
                    exact
                  />
                  <PrivateRoute path="/main/schools/:id/addKid" component={AddKid} exact />
                  <PrivateRoute path="/main/schools/:id/addCarer" component={AddCarer} exact />
                  <PrivateRoute path="/main/schools/:id/carers/:carerId" component={AddCarer} exact />
                  <PrivateRoute path="/main/schools/:id/addCarer/:carerId" component={AddCarer} exact />
                  <PrivateRoute path="/main/schools/:id/addClass" component={AddClass} exact />
                  <PrivateRoute path="/main/schools/:id/addStaff" component={AddStaff} exact />
                  <PrivateRoute path="/main/schools/:id/camps/addCamp" component={AddCamp} exact />
                  <PrivateRoute path="/main/schools/:id/terms/addTerm" component={AddTerm} exact />
                  <PrivateRoute path="/main/schools/:id/camps/edit/:campId" component={EditCamp} exact />
                  <PrivateRoute path="/main/schools/:id/terms/edit/:termId" component={EditTerm} exact />
                  <PrivateRoute
                    path="/main/schools/:id/addStaff/:staffId"
                    component={(props: any) => <AddStaff {...props} viewType={ViewType.SCHOOL_DETAILS} />}
                    exact
                  />
                  <PrivateRoute path="/main/schools/:id/kids/:kidId" component={Kid} />
                  <PrivateRoute
                    path="/main/schools/:id/carers"
                    component={(props: any) => <Carers {...props} viewType={ViewType.SCHOOL_DETAILS} />}
                    exact
                  />
                  <PrivateRoute
                    path="/main/schools/:id/staff"
                    component={(props: any) => <Staff {...props} viewType={ViewType.SCHOOL_DETAILS} />}
                    exact
                  />
                  <PrivateRoute path="/main/schools/:id/classes" component={Classes} exact />
                  <PrivateRoute path="/main/schools/:id/classes/:classId" component={AddClass} exact />
                  <PrivateRoute path="/main/schools/:id/suppliers" component={Suppliers} exact />
                  <PrivateRoute path="/main/schools/:id/terms" component={Terms} exact />
                  <PrivateRoute path="/main/schools/:id/times" component={Times} exact />
                  <PrivateRoute path="/main/schools/:id/extras" component={Extras} exact />
                  <PrivateRoute path="/main/schools/:id/shifts" component={Shifts} exact />
                  <PrivateRoute path="/main/schools/:id/camps" component={CampsList} exact />
                  <PrivateRoute path="/main/schools/:id/v2/terms" component={TermList} exact />
                  <PrivateRoute path="/main/schools/:id/vacation" component={Vacations} exact />
                  <PrivateRoute
                    path="/main/schools/:id/addSupplier/:supplierId"
                    component={AddSupplier}
                    exact
                  />
                  <PrivateRoute path="/main/schools/:id/addSupplier" component={AddSupplier} exact />
                  <PrivateRoute path="/main/schools/:id/details" component={AddSchool} exact />
                  <PrivateRoute path="/main/schools/:id/financials" component={Financials} />
                  <PrivateRoute
                    path="/main/schools/:id/singleSchoolFinancials"
                    component={FinancialsSingleSchool}
                    exact
                  />
                </Switch>
              </motion.div>
            </AnimatePresence>
          </Content>
        </Layout>
      </div>
    </>
  );
};

export default School;
