import { Button, Card, Input, InputRef, Space, Spin, Table, Typography } from "antd";
import { useRef } from "react";
import dayjs from "dayjs";
import { SearchOutlined, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { DeleteButton } from "components";
import { axiosAuth } from "helpers";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useSWR from "swr";
import { TermRequest } from "types/rest/terms";
import { sortDates } from "helpers/sortDates";
import { useAntdModal } from "hooks";
import OldTerms from "../OldTerms/OldTerms";
const { Title } = Typography;

type Props = RouteComponentProps<{ id?: string }, any, { id?: string }>;

const TermList = ({ match, location }: Props) => {
  const { closeModal, openModal, visible } = useAntdModal();
  const history = useHistory();
  const searchInput = useRef<null | InputRef>(null);

  const schoolId = match.params.id ?? location.state?.id;

  const { data: terms, error: termError, revalidate } = useSWR<TermRequest[]>(`terms-v2/school/${schoolId}`);

  const isPending = !schoolId || !terms;

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  return (
    <>
      <Spin spinning={isPending}>
        <Card>
          <Table
            title={() => {
              return (
                <div style={{ display: "flex" }}>
                  <Title
                    level={3}
                    style={{
                      color: "#E50A5C",
                      paddingBottom: "0px",
                      marginBottom: "0px",
                      marginRight: "auto",
                    }}
                  >
                    Terms
                  </Title>
                  <Button onClick={openModal}>Check Old Terms</Button>
                  <Button
                    color="#BDD000"
                    type="primary"
                    style={{
                      backgroundColor: "#BDD000",
                      borderColor: "#BDD000",
                      marginLeft: 8,
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      history.push(`/main/schools/${schoolId}/terms/addTerm`);
                    }}
                  >
                    Add new
                  </Button>
                </div>
              );
            }}
            dataSource={terms ?? []}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Name"
              key="name"
              dataIndex="name"
              render={(name: string) => <Space size="middle">{name}</Space>}
              width="50%"
              sorter={(a, b) => {
                return a.name.localeCompare(b.name);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("name")}
            />
            <Column
              title="Start date"
              key="startAt"
              dataIndex="startAt"
              render={(startAt: Date) => (
                <Space size="middle">
                  {startAt ? `${dayjs(startAt).format("MMM DD,YYYY")}` : "(not set)"}
                </Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return sortDates(a.startAt, b.startAt);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("startAt")}
            />
            <Column
              title="End date"
              key="endAt"
              dataIndex="endAt"
              render={(endAt: Date) => (
                <Space size="middle">{endAt ? `${dayjs(endAt).format("MMM DD,YYYY")}` : "(not set)"}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return sortDates(a.startAt, b.startAt);
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("endAt")}
            />

            <Column
              title=""
              width="20%"
              render={data => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        history.push(`/main/schools/${schoolId}/terms/edit/${data.id}`);
                      }}
                    />
                    <DeleteButton
                      onSubmit={async () => {
                        await axiosAuth.delete(`terms-v2/${data.id}`);
                        await revalidate();
                      }}
                      type="icon"
                    />
                  </div>
                );
              }}
            />
          </Table>
        </Card>
      </Spin>
      <OldTerms handleClose={closeModal} schoolId={Number(schoolId)} visible={visible} />
    </>
  );
};

export default TermList;
