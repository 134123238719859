import { StaffType } from "types";

export const getStaffListUrl = () => "employee/list";
export type StaffListRes = StaffType[];

export const getStaffFromSpecificSchoolUrl = (schoolId: string) =>
  `school/employees/${schoolId}`;

export const getSearchStaffUrl = () => "employee/search";

export type SearchStaffBody = {
  firstName: string | undefined;
  lastName: string | undefined;
  country: number[] | undefined;
  language: number[] | undefined;
  role: number[] | undefined;
  status: number;
};

export type SearchStaffRes = any;

export const getSearchStaffFromSpecificSchoolUrl = (schoolId: string) =>
  `employee/search/${schoolId}`;

export const getSendEvaluationUrl = (
  employeeId: number,
  isAssistant: boolean
) =>
  isAssistant
    ? `assistant-assessment/employee/${Number(employeeId)}`
    : `teacher-assessment/employee/${Number(employeeId)}`;

export const getEvaluationsUrl = (employeeId: number, isAssistant: boolean) =>
  isAssistant
    ? `assistant-assessment/employee/${Number(employeeId)}`
    : `teacher-assessment/employee/${Number(employeeId)}`;

export const getDeleteEvaluationUrl = (
  assesmentId: string,
  isAssistant: boolean
) =>
  isAssistant
    ? `assistant-assessment/${assesmentId}`
    : `teacher-assessment/${assesmentId}`;

export const getSingleEvaluationUrl = (
  assessmentId: string,
  isAssistant: boolean
) =>
  isAssistant
    ? `assistant-assessment/${assessmentId}`
    : `teacher-assessment/${assessmentId}`;

export const getSendSmartGoalUrl = (employeeId: number) =>
  `smart-goal/employee/${employeeId}`;

export const getDeleteSmartGoalUrl = (smartGoalId: number) =>
  `smart-goal/${smartGoalId}`;

export const getSmartGoalListUrl = (employeeId: number) =>
  `smart-goal/employee/${employeeId}`;

export const getSingleSmartGoalUrl = (smartGoalId: string) =>
  `smart-goal/${smartGoalId}`;
