import { FC, useEffect, useState } from 'react';
import { Modal, Button, Typography, Form, Input, Select, Row, Col } from 'antd';

import { groupsList } from 'const';
import { axiosAuth } from 'helpers';
import { ErrorMessage } from 'enums';

const { Title } = Typography;

export type InitialData = Record<string, any>;

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
  afterSubmit: () => Promise<boolean>;
};

const PlansListModal: FC<Props> = ({
  isVisible,
  onClose,
  initialData,
  afterSubmit,
}) => {
  const [isUpdatePending, setIsUpdatePending] = useState(false);

  const [form] = Form.useForm();
  const title = initialData ? 'Update plan template' : 'Create plan template';

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = async (values: any) => {
    setIsUpdatePending(true);
    if (initialData) {
      await axiosAuth.post(`plan-template/update/${initialData.id}`, values);
    } else {
      await axiosAuth.post(`plan-template/create`, values);
    }
    await afterSubmit();
    setIsUpdatePending(false);
    onClose();
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form
        name="basic"
        initialValues={initialData ? initialData : {}}
        onFinish={onFinish}
        form={form}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="type"
              name="type"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Select options={groupsList} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Select>
                <Select.Option value={10}>Active</Select.Option>
                <Select.Option value={0}>Inactive</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isUpdatePending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#BDD000',
              borderColor: '#BDD000',
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PlansListModal;
