import { Button, Col, Modal, Row, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

interface CustomModalProps {
  title: string;
  isVisible: boolean;
  buttonTitle?: string;
  children: React.ReactNode;
  onClose: () => void;
  onSubmit?: () => void;
  loading?: boolean;
  error?: string | null;
  width?: number;
}

const CustomModal = ({
  title,
  isVisible,
  onClose,
  onSubmit,
  buttonTitle,
  loading,
  children,
  error,
  width,
}: CustomModalProps) => {
  return (
    <Modal
      width={width}
      title={
        <Title
          level={4}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
      centered
    >
      <Row>
        <Col span={24}>{children}</Col>
      </Row>
      <Row justify="end">
        <Col span={20} style={{ color: "#FF4D4FD9", fontSize: 12 }}>
          {error && (
            <>
              <FontAwesomeIcon icon={faInfoCircle} /> {error}
            </>
          )}
        </Col>
        {buttonTitle && (
          <Col span={4}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
              }}
              onClick={onSubmit}
            >
              {buttonTitle}
            </Button>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default CustomModal;
