import { FC, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  Select,
  Row,
  DatePicker,
  Checkbox,
  Col,
  InputNumber,
} from 'antd';
import { Spacer, TimePicker } from 'components';
import { axiosAuth } from 'helpers';
import dayjs from 'dayjs';
import { ErrorMessage } from 'enums';

const { Title } = Typography;

export type InitialData = any;

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
  schoolId: number;
  afterSubmit: () => Promise<void | boolean>;
};

const ExtrasModal: FC<Props> = ({
  isVisible,
  onClose,
  initialData,
  schoolId,
  afterSubmit,
}) => {
  const [isExtrasPending, setIsExtrasPending] = useState(false);
  const [form] = Form.useForm();
  const title = initialData ? 'Update extra' : 'Create extra';

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = async (values: any) => {
    setIsExtrasPending(true);
    const { time, date, selectedDays, ...rest } = values;
    const [basicTimeStart, basicTimeEnd] = time;
    const [basicDateStart, basicDateEnd] = date;
    let basicDays: Record<string, number> = {
      dateMon: 0,
      dateTue: 0,
      dateWed: 0,
      dateThu: 0,
      dateFri: 0,
      dateSat: 0,
      dateSun: 0,
    };
    selectedDays.forEach((day: string) => {
      basicDays[day] = 1;
    });
    if (initialData) {
      await axiosAuth.post(`extra/update/${initialData.id}`, {
        timeStart: dayjs(basicTimeStart).format('HH:mm'),
        timeEnd: dayjs(basicTimeEnd).format('HH:mm'),
        dateStart: dayjs(basicDateStart).toDate(),
        dateEnd: dayjs(basicDateEnd).toDate(),
        schoolId,
        ...basicDays,
        ...rest,
      });
    } else {
      await axiosAuth.post(`extra/create/${schoolId}`, {
        timeStart: dayjs(basicTimeStart).format('HH:mm'),
        timeEnd: dayjs(basicTimeEnd).format('HH:mm'),
        dateStart: dayjs(basicDateStart).toDate(),
        dateEnd: dayjs(basicDateEnd).toDate(),
        ...basicDays,
        ...rest,
      });
    }
    await afterSubmit();
    setIsExtrasPending(false);
    onClose();
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form
        name="basic"
        initialValues={initialData ?? {}}
        onFinish={onFinish}
        form={form}
      >
        <Row>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            style={{ flex: 1, marginRight: '20px' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            style={{ flex: 1 }}
          >
            <Select>
              <Select.Option value={10}>Active</Select.Option>
              <Select.Option value={0}>Inactive</Select.Option>
            </Select>
          </Form.Item>
        </Row>

        <Spacer size={10} />
        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
        >
          <DatePicker.RangePicker style={{ width: '100%' }} />
        </Form.Item>
        <Spacer size={10} />
        <Form.Item
          label="Days"
          name="selectedDays"
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          style={{ flex: 1 }}
        >
          <Checkbox.Group>
            <Row>
              <Col span={8}>
                <Checkbox value="dateMon" style={{ lineHeight: '32px' }}>
                  Monday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="dateTue" style={{ lineHeight: '32px' }}>
                  Tuesday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="dateWed" style={{ lineHeight: '32px' }}>
                  Wednesday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="dateThu" style={{ lineHeight: '32px' }}>
                  Thursday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="dateFri" style={{ lineHeight: '32px' }}>
                  Friday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="dateSat" style={{ lineHeight: '32px' }}>
                  Saturday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="dateSun" style={{ lineHeight: '32px' }}>
                  Sunday
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Spacer size={10} />
        <Col span={24}>
          <Form.Item
            label="Time"
            name="time"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <TimePicker style={{ width: '100%' }} format="HH:mm" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="fee"
            label="Fee"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={1}
              precision={2}
              step={10}
            />
          </Form.Item>
        </Col>
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isExtrasPending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#BDD000',
              borderColor: '#BDD000',
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExtrasModal;
