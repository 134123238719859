import { Input, InputProps } from "antd";
import { useField } from "formik";
import React, { FC } from "react";

type Props = {
  name: string;
  label: string;
  onChange: (value: any) => void;
  type?: InputProps["type"];
  required?: boolean;
  placeholder?: string;
};

const FormikInput: FC<Props> = ({ name, label, onChange, type, required, placeholder }) => {
  const [field] = useField(name);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "10px 0",
      }}
    >
      <p style={{ marginRight: 16, whiteSpace: "nowrap" }}>
        {required && <span style={{ color: "#EF0B0BD9" }}>* </span>}
        {label}
      </p>
      <Input
        type={type}
        placeholder={placeholder}
        value={field.value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
};

export default FormikInput;
