import { InputNumber, Input, Form, Select } from "antd";
import { HTMLAttributes, ReactNode } from "react";
import { editableCellRules } from "screens/Financials/Utilities/helpers";

type IInputType = "number" | "text" | "select";
type ISelectOptions = { label: string; value: string }[];

interface EditableCellProps extends HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: IInputType;
  children: ReactNode;
  disableNegaitiveDigits: boolean;
  selectOptions: ISelectOptions;
}

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  disableNegaitiveDigits,
  selectOptions,
}: EditableCellProps) => {
  const inputNode = getProperNodeInput(inputType, selectOptions);
  const isNumberInput = inputType === "number";

  const { Item } = Form;

  return (
    <td>
      {editing ? (
        <Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={editableCellRules(title, isNumberInput, disableNegaitiveDigits)}
        >
          {inputNode}
        </Item>
      ) : (
        children
      )}
    </td>
  );
};

const getProperNodeInput = (
  inputType: IInputType,

  selectOptions: ISelectOptions
) => {
  switch (inputType) {
    case "number":
      return <InputNumber />;
    case "text":
      return <Input />;
    case "select":
      return (
        <Select
          showSearch
          options={selectOptions}
          filterOption={(input, option) =>
            (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      );
    default:
      return <Input />;
  }
};
