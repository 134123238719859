import { Button, Space, Table, Typography } from "antd";
import { DeleteButton, Spacer } from "components";
import groupBy from "helpers/groupBy";
import {
  EditTwoTone,
  DeleteTwoTone,
  UnorderedListOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { FC, useMemo, useState } from "react";
import useSWR from "swr";
import DevelopmentCategoryModal from "./DevelopmentCategoryModal";
import { groupName } from "const";
import { axiosAuth } from "helpers";

const { Column } = Table;
const { Title, Text } = Typography;

type Props = {
  onDetailsClick: (subCategoryId: DevelopmentCategoryType) => void;
};

type DevelopmentCategoryType = {
  id: number;
  rank: number;
  status: number;
  type: number;
  updatedAt: number;
  updatedBy: number;
  createdAt: number;
  createdBy: number;
  developmentCategoryLang: {
    developmentCategoryId: number;
    id: number;
    lang: string;
    name: string;
  }[];
  name?: string;
};

const DevelopmentCategory: FC<Props> = ({ onDetailsClick }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<{} | null>(null);

  const { data, revalidate } = useSWR<DevelopmentCategoryType[]>(
    "development-category/list"
  );

  const normalizedData = useMemo(
    () =>
      Object.values(
        groupBy(
          data?.map((item) => ({
            ...item,
            name: item.developmentCategoryLang[0].name,
          })) ?? [],
          (item) => item.type.toString()
        )
      ),
    [data]
  );

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title
          level={3}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          Skill Domains
        </Title>
        <Button
          color="#BDD000"
          type="primary"
          style={{
            backgroundColor: "#BDD000",
            borderColor: "#BDD000",
          }}
          icon={<PlusOutlined />}
          onClick={() => {
            setInitialData(null);
            setTimeout(() => {
              setIsModalVisible(true);
            }, 0);
          }}
        >
          Add new
        </Button>
      </div>
      <Spacer />
      {normalizedData.map((group) => (
        <>
          <Table
            dataSource={group}
            pagination={false}
            title={() => (
              <Text style={{ fontSize: "2rem" }}>
                {groupName[group[0].type]}
              </Text>
            )}
          >
            <Column
              title="rank"
              key="rank"
              dataIndex="rank"
              render={(rank: string) => <Space size="middle">{rank}</Space>}
              width="10%"
            />
            <Column
              title="name"
              key="name"
              dataIndex="name"
              render={(name: string) => <Space size="middle">{name}</Space>}
              width="50%"
            />
            <Column
              title="status"
              key="status"
              dataIndex="status"
              render={(status: number) => (
                <Space size="middle">
                  {status === 10 ? "Active" : "Inactive"}
                </Space>
              )}
              width="10%"
            />
            <Column
              title=""
              key="actions"
              dataIndex=""
              render={(data: DevelopmentCategoryType) => {
                const names: Record<string, string> = {};
                data.developmentCategoryLang.forEach((item) => {
                  names[item.lang] = item.name;
                });
                return (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setInitialData({
                          ...names,
                          ...data,
                        });
                        setTimeout(() => {
                          setIsModalVisible(true);
                        }, 0);
                      }}
                    />
                    <Button
                      type="text"
                      icon={
                        <UnorderedListOutlined style={{ color: "#00A1E1" }} />
                      }
                      onClick={() => onDetailsClick(data)}
                    />
                    <DeleteButton
                      onSubmit={async () => {
                        await axiosAuth.get(
                          `development-category/delete/${data.id}`
                        );
                        await revalidate();
                      }}
                      type="icon"
                    />
                  </div>
                );
              }}
              width="10%"
            />
          </Table>
          <Spacer size={40} />
        </>
      ))}
      <DevelopmentCategoryModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
      />
    </>
  );
};

export default DevelopmentCategory;
