import Logo from 'img/logo.png';
import { FC, useState } from 'react';
import { logout } from 'store/user';
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from 'store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import ProfileDetailsModal from 'components/ProfileDetailsModal';

const TopNavigation: FC = () => {
  const [
    isProfileDetailsModalVisible,
    setIsProfileDetailsModalVisible,
  ] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  return (
    <>
      <div
        style={{
          backgroundColor: '#E50A5C',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 15px',
        }}
      >
        <div>
          <img
            src={Logo}
            onClick={() => history.push('/main/schools')}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              height: '60px',
            }}
          >
            <p
              onClick={() => setIsProfileDetailsModalVisible(true)}
              style={{
                marginRight: '40px',
                color: 'white',
                fontSize: '1.5rem',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{ marginRight: '3px' }}
              />
              <span>profile details</span>
            </p>
            <p
              style={{
                cursor: 'pointer',
                color: 'white',
                fontSize: '1.5rem',
                alignItems: 'center',
                display: 'flex',
              }}
              onClick={() => {
                dispatch(logout());
              }}
            >
              <FontAwesomeIcon
                icon={faSignOutAlt}
                style={{ marginRight: '3px' }}
              />
              <span>log out</span>
            </p>
          </div>
        </div>
      </div>
      <ProfileDetailsModal
        isVisible={isProfileDetailsModalVisible}
        onClose={() => setIsProfileDetailsModalVisible(false)}
      />
    </>
  );
};

export default TopNavigation;
