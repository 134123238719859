import { useState } from "react";
import { EditTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";
import { Button, Form, message, Table, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DeleteButton } from "components";
import { axiosAuth } from "helpers";
import moment from "moment";
import styled from "styled-components";
import {
  getEditChildPaymentUrl,
  IChildPaymentsItem,
  paymentMethod,
  paymentPageSize,
  paymentType,
} from "types";
import { EditableCell } from "../EditableCell/EditableCell";
import { NoDataInfo } from "screens/Financials/components";

interface IPaymentsListProps {
  payments: IChildPaymentsItem[];
  schoolId?: string;
  childId?: string;
  revalidate: () => Promise<boolean>;
  totalSize: number;
  changePage: (page: number) => void;
  page: number;
}
const { Text } = Typography;

const PaymentsList = ({
  payments,
  schoolId,
  childId,
  revalidate,
  totalSize,
  changePage,
  page,
}: IPaymentsListProps) => {
  const [form] = useForm();
  const [editingKey, setEditingKey] = useState<number | null>(null);

  const isEditing = (record: IChildPaymentsItem) => record.id === editingKey;

  const edit = (record: Partial<IChildPaymentsItem> & { id: React.Key }) => {
    form.setFieldsValue({
      ...record,
      paymentDate: moment(record.paymentDate),
    });
    setEditingKey(record.id);
  };

  const save = async (record: IChildPaymentsItem) => {
    const row = (await form.validateFields()) as IChildPaymentsItem;

    try {
      if (!schoolId || !childId) return;

      const body = {
        amount: row.amount,
        reference: row.reference,
        paymentDate: new Date(row.paymentDate),
        paymentMethod: row.paymentMethod,
      };

      await axiosAuth.put(getEditChildPaymentUrl(schoolId, childId, record.id), body);
      revalidate();
      setEditingKey(null);
      message.success("Payment has been changed");
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const cancel = () => {
    setEditingKey(null);
  };

  const deletePayment = async (id: number) => {
    if (!schoolId || !childId) return;

    await axiosAuth.delete(getEditChildPaymentUrl(schoolId, childId, id));
    revalidate();
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "paymentDate",
      width: "20%",
      editable: true,
      inputType: "date",
      render: (_: any, record: IChildPaymentsItem) => {
        return dayjs(record.paymentDate).format("YYYY-MM-DD");
      },
    },
    {
      title: "Method",
      dataIndex: "paymentMethod",
      width: "30%",
      editable: true,
      inputType: "select",
      selectOptions: paymentMethod,
    },
    {
      title: "Reference",
      dataIndex: "reference",
      width: "25%",
      editable: true,
      inputType: "text",
    },
    {
      title: "Title",
      dataIndex: "type",
      width: "30%",
      editable: false,
      inputType: "select",
      selectOptions: paymentType,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "25%",
      editable: true,
      inputType: "number",
    },
    // edit and delete temporary commented - backend is not ready for this feature.
    // {
    //   title: "",
    //   dataIndex: "operation",
    //   render: (_: any, record: IChildPaymentsItem) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <RowRightContainer>
    //         <Button
    //           color="#BDD000"
    //           type="primary"
    //           style={{
    //             backgroundColor: "#BDD000",
    //             borderColor: "#BDD000",
    //             marginRight: 8,
    //           }}
    //           onClick={async () => save(record)}
    //         >
    //           Save
    //         </Button>
    //         <Button
    //           color="#E50A5C"
    //           type="primary"
    //           style={{
    //             backgroundColor: "#E50A5C",
    //             borderColor: "#E50A5C",
    //           }}
    //           onClick={cancel}
    //         >
    //           Cancel
    //         </Button>
    //       </RowRightContainer>
    //     ) : (
    //       <RowRightContainer>
    //         <Button
    //           type="text"
    //           icon={<EditTwoTone twoToneColor="#00A1E1" />}
    //           style={{ marginRight: "10px", backgroundColor: "transparent" }}
    //           onClick={() => edit(record)}
    //         />

    //         <DeleteButton onSubmit={() => deletePayment(record.id)} type="icon" />
    //       </RowRightContainer>
    //     );
    //   },
    // },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IChildPaymentsItem) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        selectOptions: col.selectOptions,
      }),
    };
  });

  const handleChangePage = (page: number) => {
    changePage(page);
  };

  if (!payments) return null;
  if (payments.length === 0) return <NoDataInfo title="No payment has been added" />;

  return (
    <div>
      <Form form={form}>
        <Table
          dataSource={
            payments.sort((firstPayment, secondPayment) => {
              const first = dayjs(firstPayment.paymentDate);
              const second = dayjs(secondPayment.paymentDate);
              return dayjs(second).unix() - dayjs(first).unix();
            }) || []
          }
          pagination={{
            pageSize: paymentPageSize,
            total: totalSize,
            onChange: handleChangePage,
            current: page,
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
        />
      </Form>
    </div>
  );
};

export default PaymentsList;

const RowRightContainer = styled.div`
  display: flex;
  justify-content: end;
  align-content: flex-end;
`;
