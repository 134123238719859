import { Button, Input, Space, Spin, Table, Typography } from 'antd';
import { Container, DeleteButton, Spacer, TopNavigation } from 'components';
import dayjs from 'dayjs';
import {
  SearchOutlined,
  UnorderedListOutlined,
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { FC, useRef, useState } from 'react';
import TermsModal, { InitialData } from './TermsModal';
import useSWR from 'swr';
import { axiosAuth } from 'helpers';

const { Title } = Typography;

const { Column } = Table;

const mapStatus = {
  10: 'Active',
  0: 'Inactive',
};

type Term = {
  createdAt: number;
  createdBy: number;
  endDate: string;
  startDate: string;
  id: number;
  name: string;
  schoolId: number;
  status: keyof typeof mapStatus;
  updatedAt: number;
  updatedBy: number;
};

const Terms: FC = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<InitialData | null>(null);
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const { data, error, revalidate } = useSWR<Term[]>(
    `school/terms/${props.match.params.id}`
  );
  const isPending = !data && !error;

  const normalizedData = data?.map((item) => {
    const { status, startDate, endDate, ...rest } = item;
    const newItem = {
      ...rest,
      startDate: startDate ? dayjs(startDate).format('MMM D, YYYY') : null,
      endDate: endDate ? dayjs(endDate).format('MMM D, YYYY') : null,
      status,
    };
    return newItem;
  });
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
  return (
    <>
      <Spin spinning={isPending}>
        <Spacer />
        <Container>
          <Table
            title={() => {
              return (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Title
                    level={3}
                    style={{
                      color: '#E50A5C',
                      paddingBottom: '0px',
                      marginBottom: '0px',
                    }}
                  >
                    Terms
                  </Title>
                  <Button
                    color="#BDD000"
                    type="primary"
                    style={{
                      backgroundColor: '#BDD000',
                      borderColor: '#BDD000',
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setInitialData(null);
                      setTimeout(() => {
                        setIsModalVisible(true);
                      }, 0);
                    }}
                  >
                    Add new
                  </Button>
                </div>
              );
            }}
            dataSource={normalizedData}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Name"
              key="name"
              dataIndex="name"
              render={(title: Term['name']) => (
                <Space size="middle">{title}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return a.name.localeCompare(b.name);
              }}
              sortDirections={['descend']}
              {...getColumnSearchProps('name')}
            />
            <Column
              title="Start date"
              key="startDate"
              dataIndex="startDate"
              render={(startDate: Term['startDate']) => (
                <Space size="middle">{startDate}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return (
                  dayjs(a.startDate, 'MMM D, YYYY').unix() -
                  dayjs(b.startDate, 'MMM D, YYYY').unix()
                );
              }}
              sortDirections={['descend']}
              {...getColumnSearchProps('startDate')}
            />
            <Column
              title="End date"
              key="endDate"
              dataIndex="endDate"
              render={(endDate: Term['endDate']) => (
                <Space size="middle">{endDate}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return (
                  dayjs(a.endDate, 'MMM D, YYYY').unix() -
                  dayjs(b.endDate, 'MMM D, YYYY').unix()
                );
              }}
              sortDirections={['descend']}
              {...getColumnSearchProps('endDate')}
            />
            <Column
              onFilter={(value, record: any) => record.status.includes(value)}
              filters={[
                { text: 'Active', value: 10 },
                { text: 'Inactive', value: 0 },
              ]}
              filterMultiple={false}
              title="Status"
              key="status"
              dataIndex="status"
              width="20%"
              render={(status: Term['status']) => (
                <Space size="middle">
                  {status === 10 ? 'Active' : 'Inactive'}
                </Space>
              )}
            />
            <Column
              title=""
              width="20%"
              render={(data) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'flex-end',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        const group: Record<number, number> = {};
                        data.termPlan.forEach((item: any) => {
                          group[item.type] = item.templateId;
                        });
                        const { startDate, endDate } = data;
                        setInitialData({
                          ...data,
                          date:
                            startDate && endDate
                              ? [dayjs(startDate), dayjs(endDate)]
                              : null,
                          group,
                        });
                        setTimeout(() => {
                          setIsModalVisible(true);
                        }, 0);
                      }}
                    />

                    <DeleteButton
                      onSubmit={async () => {
                        await axiosAuth.get(`term/delete/${data.id}`);
                        await revalidate();
                      }}
                      type="icon"
                    />
                  </div>
                );
              }}
            />
          </Table>
        </Container>
      </Spin>
      <TermsModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        schoolId={props.match.params.id}
        afterSubmit={revalidate}
      />
    </>
  );
};

export default Terms;
