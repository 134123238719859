import { Col, Input, Form } from "antd";
import { CustomLabel } from "components";
import { ErrorMessage } from "enums";

interface Props {
  isEditMode: boolean;
}

const numberRegex = /^[0-9]*$/;

export const AccountNumber = ({ isEditMode }: Props) => {
  return (
    <Col span={12}>
      <Form.Item
        name="accountNumber"
        labelCol={{ span: 24 }}
        label={<CustomLabel>Account number</CustomLabel>}
        rules={[
          { required: true, message: ErrorMessage.REQUIRED },
          {
            validator: (_, value) => {
              if (!value || numberRegex.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject("Please enter only numbers");
            },
          },
        ]}
      >
        <Input
          style={
            isEditMode
              ? {
                  width: "100%",
                }
              : {
                  border: "none",
                  backgroundColor: "white",
                  padding: 1,
                  color: "#000",
                }
          }
          disabled={!isEditMode}
        />
      </Form.Item>
    </Col>
  );
};
