import { FC } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const UnexpectedError: FC = () => {
  return (
    <Text
      strong
      style={{
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '1.3rem',
      }}
    >
      Something went wrong. Please try again. If this problem persists let us
      know via report form
    </Text>
  );
};

export default UnexpectedError;
