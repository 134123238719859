import { Empty } from "antd";
import { Spacer } from "components";

interface Props {
    title: string;
}

export const NoDataInfo = ({ title }: Props) => {
    return (
        <>
            <Spacer size={40} />
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                    height: 100,
                }}
                description={<span>{title}</span>}
            />
        </>
    );
};
