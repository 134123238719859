import { Col, Form, Input } from "antd";
import { CustomLabel } from "components";
import { ErrorMessage } from "enums";

interface Props {
  isEditMode: boolean;
}

export const EmailAddressForAccounts = ({ isEditMode }: Props) => {
  return (
    <Col span={6}>
      <Form.Item
        name="emailAddressForAccounts"
        labelCol={{ span: 24 }}
        label={<CustomLabel>Invoicing email</CustomLabel>}
        rules={[
          {
            required: true,
          },
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          { required: true, message: ErrorMessage.REQUIRED },
        ]}
      >
        <Input
          disabled={!isEditMode}
          style={
            isEditMode
              ? {}
              : {
                  border: "none",
                  backgroundColor: "white",
                  padding: "4px 11px 4px 0px",
                }
          }
        />
      </Form.Item>
    </Col>
  );
};
