import { Col, Input, Form } from "antd";
import { CustomLabel } from "components";
import { ErrorMessage } from "enums";

interface Props {
  isEditMode: boolean;
  name: string;
  label: string;
  colSpan: number;
}

export const InputWithValidation = ({ isEditMode, name, label, colSpan }: Props) => {
  return (
    <Col span={colSpan}>
      <Form.Item
        name={name}
        labelCol={{ span: 24 }}
        label={<CustomLabel>{label}</CustomLabel>}
        rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
      >
        <Input
          disabled={!isEditMode}
          style={
            isEditMode
              ? {}
              : {
                  border: "none",
                  backgroundColor: "white",
                  padding: "4px 11px 4px 0px",
                }
          }
        />
      </Form.Item>
    </Col>
  );
};
