import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Spacer } from "components/UI";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import { getAddAcademicYear } from "types/rest/school";

const { Title } = Typography;

interface Props {
  isVisible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  schoolId: string;
}

const AcademicYearModal = ({
  isVisible,
  onCancel,
  onSubmit,
  schoolId,
}: Props) => {
  const [form] = useForm();

  const handleSubmit = async (value: { name: string }) => {
    const { name } = value;

    await axiosAuth.post(getAddAcademicYear(schoolId), {
      name,
    });

    form.resetFields();
    onSubmit();
  };
  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          Add school year
        </Title>
      }
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form name="basic" onFinish={handleSubmit} form={form}>
        <Row>
          <Col span={20}>
            <Form.Item
              label="Name"
              name="name"
              style={{ flex: 1, marginRight: "20px" }}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Spacer size={10} />
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={false}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AcademicYearModal;
