import { useState } from "react";
import { Card, Spin, Tabs, Typography } from "antd";
import { RouteComponentProps } from "react-router-dom";
import useSWR from "swr";
import { TermRequest, TermResponse } from "types/rest/terms";
import TermInfoPanel from "../TermInfoPanel/TermInfoPanel";
import TermSummaryPanel from "../TermSummaryPanel";

const { TabPane } = Tabs;

const { Title } = Typography;

type Props = RouteComponentProps<
  { id?: string; termId: string },
  any,
  { id?: string; termId: string }
>;

const EditTerm = ({ match, location }: Props) => {
  const [activeKey, setActiveKey] = useState("1");
  const [terms, setTerms] = useState<TermRequest | null>(null);
  const [disableSummary, setDisableSummary] = useState(true);
  const [disableCreateTab, setDisableCreateTab] = useState(false);

  const schoolId = match.params.id ?? location.state?.id;
  const termId = match.params.termId ?? location.state?.termId;

  const { data: termData, error: termError } = useSWR<TermResponse>(
    `terms-v2/${termId}`
  );

  const isPending = !schoolId || !termData;

  const handleChangeTab = (item: string) => {
    setActiveKey(item);
  };

  const handleSaveData = (terms: TermRequest) => {
    setTerms(terms);
    setActiveKey("2");
    setDisableSummary(false);
    setDisableCreateTab(true);
  };

  const handleChangeTabs = () => {
    setActiveKey("1");
    setDisableCreateTab(false);
    setDisableSummary(true);
  };

  return (
    <Spin spinning={isPending}>
      {schoolId && (
        <Card>
          <Title level={4} style={{ color: "#E50A5C", margin: "0px" }}>
            Edit term
          </Title>
          <div style={{ marginTop: 20 }}>
            <Tabs
              type="card"
              activeKey={activeKey}
              onChange={(item) => handleChangeTab(item)}
              tabBarGutter={10}
            >
              <TabPane
                tab="Edit term"
                key={1}
                closable={false}
                disabled={disableCreateTab}
              >
                <TermInfoPanel
                  saveTerm={(terms) => handleSaveData(terms)}
                  initialFormData={termData ? termData : undefined}
                  schoolId={schoolId}
                />
              </TabPane>
              <TabPane
                tab="Summary"
                key={2}
                closable={false}
                disabled={disableSummary}
              >
                <TermSummaryPanel
                  terms={terms}
                  changeTabs={handleChangeTabs}
                  schoolId={schoolId}
                  editTermId={termId}
                />
              </TabPane>
            </Tabs>
          </div>
        </Card>
      )}
    </Spin>
  );
};

export default EditTerm;
