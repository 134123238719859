import { Button } from "antd";
import { Dispatch, SetStateAction } from "react";
import getPickedUtilityTabs from "../../helpers/getPickedUtilityTabs";
import { UtilityTabs } from "types";

const activeStyle = {
  backgroundColor: "#BDD000",
  border: "1px solid #BDD000",
  outline: "none",
};

type Props = {
  pickedTab: UtilityTabs;
  setPickedTab: Dispatch<SetStateAction<UtilityTabs>>;
};

const UtilitiesTabsNavigation = ({ pickedTab, setPickedTab }: Props) => {
  const { pickedFoodTab, pickedTransportTab, pickedMaterialsTab } = getPickedUtilityTabs(pickedTab);
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        type={pickedFoodTab ? "primary" : "default"}
        style={{
          marginRight: "20px",
          ...(pickedFoodTab ? activeStyle : {}),
        }}
        onClick={() => setPickedTab(UtilityTabs.Food)}
      >
        Food
      </Button>
      <Button
        type={pickedTransportTab ? "primary" : "default"}
        style={{
          marginRight: "20px",
          ...(pickedTransportTab ? activeStyle : {}),
        }}
        onClick={() => setPickedTab(UtilityTabs.Transport)}
      >
        Transport
      </Button>
      <Button
        type={pickedMaterialsTab ? "primary" : "default"}
        style={{
          marginRight: "20px",
          ...(pickedMaterialsTab ? activeStyle : {}),
        }}
        onClick={() => setPickedTab(UtilityTabs.Materials)}
      >
        Materials
      </Button>
    </div>
  );
};

export default UtilitiesTabsNavigation;
