import { Button, Input, Space, Spin, Table, Typography } from 'antd';
import { Container, DeleteButton, Spacer, TopNavigation } from 'components';
import dayjs from 'dayjs';
import {
  SearchOutlined,
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { FC, useRef, useState } from 'react';
import ExtrasModal, { InitialData } from './ExtrasModal';
import useSWR from 'swr';
import { axiosAuth } from 'helpers';

const { Title } = Typography;

const { Column } = Table;

type Time = any;

const Extras: FC = (props: any) => {
  const schoolId = props.match.params.id ?? 0;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<InitialData | null>(null);
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const { data, error, revalidate } = useSWR<Time[]>(
    `school/extras/${schoolId}`
  );
  const isPending = !data && !error;

  const normalizedData = data?.map((item) => {
    const {
      timeStart,
      timeEnd,
      dateMon,
      dateTue,
      dateWed,
      dateThu,
      dateFri,
      dateSat,
      dateSun,
      ...rest
    } = item;
    const selectedDays = [];
    const selectedDaysForm = [];
    if (dateMon) {
      selectedDays.push('Mon');
      selectedDaysForm.push('dateMon');
    }
    if (dateTue) {
      selectedDays.push('Tue');
      selectedDaysForm.push('dateTue');
    }
    if (dateWed) {
      selectedDays.push('Wed');
      selectedDaysForm.push('dateWed');
    }
    if (dateThu) {
      selectedDays.push('Thu');
      selectedDaysForm.push('dateThu');
    }
    if (dateFri) {
      selectedDays.push('Fri');
      selectedDaysForm.push('dateFri');
    }
    if (dateSat) {
      selectedDays.push('Sat');
      selectedDaysForm.push('dateSat');
    }
    if (dateSun) {
      selectedDays.push('Sun');
      selectedDaysForm.push('dateSun');
    }
    const newItem = {
      ...rest,
      timeStart: timeStart ? dayjs(timeStart, 'HH:mm:ss').format('HH:mm') : '',
      timeEnd: timeEnd ? dayjs(timeEnd, 'HH:mm:ss').format('HH:mm') : '',
      days: selectedDays.length > 0 ? selectedDays.join(', ') : '(not set)',
      selectedDays: selectedDaysForm,
    };
    return newItem;
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
  return (
    <>
      <Spin spinning={isPending}>
        <Container>
          <Spacer />
          <Table
            title={() => {
              return (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Title
                    level={3}
                    style={{
                      color: '#E50A5C',
                      paddingBottom: '0px',
                      marginBottom: '0px',
                    }}
                  >
                    Extras
                  </Title>
                  <Button
                    color="#BDD000"
                    type="primary"
                    style={{
                      backgroundColor: '#BDD000',
                      borderColor: '#BDD000',
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setInitialData(null);
                      setTimeout(() => {
                        setIsModalVisible(true);
                      }, 0);
                    }}
                  >
                    Add new
                  </Button>
                </div>
              );
            }}
            dataSource={normalizedData}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Name"
              key="name"
              dataIndex="name"
              render={(title: Time['name']) => (
                <Space size="middle">{title}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return a.name.localeCompare(b.name);
              }}
              sortDirections={['descend']}
              {...getColumnSearchProps('name')}
            />
            <Column
              title="Dates"
              key="dates"
              render={({ dateStart, dateEnd }) => {
                return (
                  <Space size="middle">
                    {dateStart && dateEnd
                      ? `${dayjs(dateStart).format('MMM DD,YYYY')} - ${dayjs(
                          dateEnd
                        ).format('MMM DD,YYYY')}`
                      : '(not set)'}
                  </Space>
                );
              }}
              width="20%"
            />
            <Column
              title="Days"
              key="days"
              dataIndex="days"
              render={(days: string) => <Space size="middle">{days}</Space>}
              width="20%"
              sortDirections={['descend']}
              onFilter={(value, record: any) => record.days.includes(value)}
              filters={[
                { text: 'Monday', value: 'Mon' },
                { text: 'Tuesday', value: 'Tue' },
                { text: 'Wednesday', value: 'Wed' },
                { text: 'Thursday', value: 'Thu' },
                { text: 'Friday', value: 'Fri' },
                { text: 'Saturday', value: 'Sat' },
                { text: 'Sunday', value: 'Sun' },
              ]}
            />
            <Column
              title="Times"
              key="times"
              render={({ timeStart, timeEnd }) => (
                <Space size="middle">
                  {timeStart && timeEnd
                    ? `${timeStart} - ${timeEnd}`
                    : '(not set)'}
                </Space>
              )}
              width="20%"
            />
            <Column
              title="Fee"
              key="fee"
              dataIndex="fee"
              render={(fee) => <Space size="middle">{fee}</Space>}
              width="20%"
              {...getColumnSearchProps('fee')}
            />
            <Column
              title=""
              width="20%"
              render={(data) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'flex-end',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        const { dateStart, dateEnd, timeStart, timeEnd } = data;
                        setInitialData({
                          ...data,
                          time:
                            timeStart && timeEnd
                              ? [
                                  dayjs(timeStart, 'HH:mm'),
                                  dayjs(timeEnd, 'HH:mm'),
                                ]
                              : null,
                          date:
                            dateStart && dateEnd
                              ? [
                                  dayjs(dateStart, 'HH:mm'),
                                  dayjs(dateEnd, 'HH:mm'),
                                ]
                              : null,
                        });
                        setTimeout(() => {
                          setIsModalVisible(true);
                        }, 0);
                      }}
                    />
                    <DeleteButton
                      onSubmit={async () => {
                        await axiosAuth.get(`extra/delete/${data.id}`);
                        await revalidate();
                      }}
                      type="icon"
                    />
                  </div>
                );
              }}
            />
          </Table>
        </Container>
      </Spin>
      <ExtrasModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        schoolId={schoolId}
        afterSubmit={revalidate}
      />
    </>
  );
};

export default Extras;
