import { Button, Space, Table, Typography } from 'antd';
import { DeleteButton, Spacer } from 'components';
import { EditTwoTone, DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import useSWR from 'swr';
import WeekListModal from './WeekListModal';
import { axiosAuth } from 'helpers';

const { Column } = Table;
const { Title } = Typography;

type Props = {
  planId: number | null | undefined;
};

type WeekItemType = {
  createdAt: number;
  createdBy: number;
  id: number;
  status: number;
  templateId: number;
  updatedAt: number;
  updatedBy: number;
  weekNumber: number;
};

const WeekList: FC<Props> = ({ planId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<{} | null>(null);

  const { data, error, revalidate } = useSWR<WeekItemType[]>(
    planId ? `plan-week/${planId}` : null
  );

  const isPending = !data && !error;
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title
          level={3}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          Weeks
        </Title>
        <Button
          color="#BDD000"
          type="primary"
          style={{
            backgroundColor: '#BDD000',
            borderColor: '#BDD000',
          }}
          icon={<PlusOutlined />}
          onClick={() => {
            setInitialData(null);
            setTimeout(() => {
              setIsModalVisible(true);
            }, 0);
          }}
        >
          Add new
        </Button>
      </div>
      <Spacer />
      <Table
        dataSource={data ?? []}
        pagination={{ pageSize: 20 }}
        loading={isPending}
      >
        <Column
          title="Week number"
          key="weekNumber"
          dataIndex="weekNumber"
          render={(weekNumber: string) => (
            <Space size="middle">{weekNumber}</Space>
          )}
          width="80%"
        />
        <Column
          title="status"
          key="status"
          dataIndex="status"
          render={(status: number) => (
            <Space size="middle">{status === 10 ? 'Active' : 'Inactive'}</Space>
          )}
          width="40%"
        />
        <Column
          title=""
          key="actions"
          dataIndex=""
          render={(dataItem: WeekItemType) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignContent: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  type="text"
                  icon={<EditTwoTone twoToneColor="#00A1E1" />}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    setInitialData({
                      ...dataItem,
                    });
                    setTimeout(() => {
                      setIsModalVisible(true);
                    }, 0);
                  }}
                />
                <DeleteButton
                  type="icon"
                  onSubmit={async () => {
                    await axiosAuth.get(`plan-week/delete/${dataItem.id}`);
                    await revalidate();
                  }}
                />
              </div>
            );
          }}
          width="10%"
        />
      </Table>
      <Spacer size={40} />
      <WeekListModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        planId={planId}
      />
    </>
  );
};

export default WeekList;
