import { FC, useEffect } from "react";
import { Modal, Button, Typography, Form, Input, Select, Row, DatePicker } from "antd";
import { Spacer } from "components";
import { ErrorMessage } from "enums";

const { Title } = Typography;
const { TextArea } = Input;

export const mapTypes: Record<number, string> = {
  10: "Mood",
  30: "Sleep",
  31: "Woke up",
  21: "Snack",
  20: "Eat",
  40: "Toilet",
  50: "Note",
  41: "Diaper (yellow)",
  42: "Diaper (brown)",
  60: "Pick up child",
  61: "Drop off child",
  70: "medal",
  0: "Other",
};

const typesList = Object.entries(mapTypes).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export type InitialData = {
  type: string;
  date: string;
  value: string;
  status: number;
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
};

const { Option } = Select;

const DayLogsModal: FC<Props> = ({ isVisible, onClose, initialData }) => {
  const [form] = Form.useForm();
  const title = initialData ? "Update day log" : "Create new day log";

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form
        name="basic"
        initialValues={initialData ?? {}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            style={{ flex: 1, marginRight: "20px" }}
          >
            <Select options={typesList} />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select allowClear>
              <Option value={10}>Active</Option>
              <Option value={0}>Inactive</Option>
            </Select>
          </Form.Item>
        </Row>
        <Spacer size={10} />
        <Form.Item label="Date" name="dateTime" rules={[{ required: true, message: ErrorMessage.REQUIRED }]}>
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Spacer size={10} />
        <Form.Item label="Value" name="value" rules={[{ required: true, message: ErrorMessage.REQUIRED }]}>
          <TextArea />
        </Form.Item>
        <Spacer size={10} />
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DayLogsModal;
