import { Button, Input, InputRef, Space } from "antd";
import { ColumnType } from "antd/lib/table";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { RefObject } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { ISingleSchoolFinancialRecord } from "../FinancialsSingleSchool/FinancialsSingleSchool";
import { IMultiSchoolFinancialRecord } from "../FinancialsMutliSchool/FinancialMutliSchool";
import { ISupraFinancialData } from "../FinancialsSupra/FinancialsSupra";

type IDataIndex =
  | keyof ISingleSchoolFinancialRecord
  | keyof IMultiSchoolFinancialRecord
  | keyof ISupraFinancialData;

export interface IGetColumnSearch {
  dataIndex: IDataIndex;
  searchInput: RefObject<InputRef>;
  handleSearch: (confirm: (param?: FilterConfirmProps) => void) => void;
  handleReset: (confirm: (param?: FilterConfirmProps | undefined) => void, clearFilters?: () => void) => void;
}

export const getColumnSearch = ({
  dataIndex,
  searchInput,
  handleSearch,
  handleReset,
}: IGetColumnSearch): ColumnType<any> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div style={{ padding: "14px 8px" }} onKeyDown={event => event.stopPropagation()}>
      <Input
        ref={searchInput}
        placeholder="Search by name or last name"
        value={selectedKeys[0]}
        onChange={event => setSelectedKeys(event.target.value ? [event.target.value] : [])}
        onPressEnter={() => handleSearch(confirm)}
        style={{ marginBottom: 16, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            handleReset(confirm, clearFilters);
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
          }}
        >
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined
      style={{ color: filtered ? "#1890ff" : undefined, marginRight: 6, marginLeft: 6, fontSize: 14 }}
      data-testid="search-icon-button"
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()),
  render: text => text,
});
