import { Button, Input, Space, Spin, Table, Typography } from "antd";
import { Container, TopNavigation } from "components";
import { SearchOutlined, EditTwoTone, DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { FC, useRef, useState } from "react";
import useSWR from "swr";

const { Title } = Typography;

const { Column } = Table;

export type User = {
  authKey: string;
  createdAt: number;
  createdBy: number | null;
  email: string;
  firstName: string;
  id: number;
  isDeleted: number;
  lastName: string;
  passwordHash: string;
  passwordResetToken: string;
  phone: string;
  role: number;
  status: number;
  updatedAt: number;
  updatedBy: number | null;
};

const Users: FC = () => {
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const { data, error } = useSWR<User[]>("user/list");
  const isPending = !data && !error;
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });
  const normalizedData = data?.map(item => {
    const { status, role, ...rest } = item;
    const newItem = {
      ...rest,
      status: status === 10 ? "Active" : "Inactive",
      role: role === 100 ? "Admin" : "User",
    };
    return newItem;
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setState(prev => ({ ...prev, searchText: "" }));
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
  return (
    <>
      <Spin spinning={isPending}>
        <TopNavigation />
        <Container>
          <Table
            title={() => {
              return (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Title
                    level={3}
                    style={{
                      color: "#E50A5C",
                      paddingBottom: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    Users
                  </Title>
                  <Button
                    color="#BDD000"
                    type="primary"
                    style={{
                      backgroundColor: "#BDD000",
                      borderColor: "#BDD000",
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      //   setInitialData(null);
                      //   setTimeout(() => {
                      //     setIsModalVisible(true);
                      //   }, 0);
                    }}
                  >
                    Add new
                  </Button>
                </div>
              );
            }}
            dataSource={normalizedData}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="First Name"
              key="firstName"
              dataIndex="firstName"
              render={(firstName: User["firstName"]) => <Space size="middle">{firstName}</Space>}
              width="15%"
              sorter={(a, b) => {
                if (a.firstName && b.firstName) {
                  return a.firstName.localeCompare(b.firstName);
                }
                return null;
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("firstName")}
            />
            <Column
              title="Last Name"
              key="lastName"
              dataIndex="lastName"
              render={(lastName: User["lastName"]) => <Space size="middle">{lastName}</Space>}
              width="15%"
              sorter={(a, b) => {
                if (a.lastName && b.lastName) {
                  return a.lastName.localeCompare(b.lastName);
                }
                return null;
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("lastName")}
            />
            <Column
              title="E-mail"
              key="email"
              dataIndex="email"
              render={(email: User["email"]) => <Space size="middle">{email}</Space>}
              width="25%"
              sorter={(a, b) => {
                if (a.email && b.email) {
                  return a.email.localeCompare(b.email);
                }
                return null;
              }}
              sortDirections={["descend"]}
              {...getColumnSearchProps("email")}
            />
            <Column
              onFilter={(value, record: any) => record.role.includes(value)}
              filters={[
                { text: "Admin", value: "Admin" },
                { text: "User", value: "User" },
              ]}
              filterMultiple={false}
              title="Role"
              key="role"
              dataIndex="role"
              width="15%"
              render={(status: User["status"]) => <Space size="middle">{status}</Space>}
            />
            <Column
              onFilter={(value, record: any) => record.status.includes(value)}
              filters={[
                { text: "Active", value: "Active" },
                { text: "Inactive", value: "Inactive" },
              ]}
              filterMultiple={false}
              title="Status"
              key="status"
              dataIndex="status"
              width="15%"
              render={(status: User["status"]) => <Space size="middle">{status}</Space>}
            />
            <Column
              title=""
              width="15%"
              render={(data, { status }: User) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        // const initialData: InitialData = {
                        //   title,
                        //   status,
                        // };
                        // setInitialData(initialData);
                        // setTimeout(() => {
                        //   setIsModalVisible(true);
                        // }, 0);
                      }}
                    />
                    <Button type="text" icon={<DeleteTwoTone twoToneColor="#E50A5C" />} />
                  </div>
                );
              }}
            />
          </Table>
        </Container>
      </Spin>
    </>
  );
};

export default Users;
