import { Button, Input, Space, Spin, Table, Typography } from 'antd';
import { Container, DeleteButton, Spacer, TopNavigation } from 'components';
import dayjs from 'dayjs';
import {
  SearchOutlined,
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { FC, useRef, useState } from 'react';
import ShiftsModal, { InitialData } from './ShiftsModal';
import useSWR from 'swr';
import { axiosAuth } from 'helpers';

const { Title } = Typography;

const { Column } = Table;

const mapStatus = {
  10: 'Active',
  0: 'Inactive',
};

type Time = {
  createdAt: number;
  createdBy: number;
  friday: number;
  id: number;
  moday: number;
  name: string;
  saturday: number;
  schoolId: number;
  status: number;
  sunday: number;
  thursday: number;
  timeEnd: string | null;
  timeStart: string | null;
  tuesday: number;
  updatedAt: number;
  updatedBy: number;
  wednesday: number;
};

const Shifts: FC = (props: any) => {
  const schoolId = props.match.params.id;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<InitialData | null>(null);
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const { data, error, revalidate } = useSWR<Time[]>(
    `school/shifts/${schoolId}`
  );
  const isPending = !data && !error;

  const normalizedData = !!data
    ? data.map((item) => {
        const {
          timeStart,
          timeEnd,
          moday,
          wednesday,
          tuesday,
          thursday,
          friday,
          saturday,
          sunday,
          ...rest
        } = item;
        const timeStartList = timeStart ? timeStart.split(':') : null;
        const timeEndList = timeEnd ? timeEnd.split(':') : null;
        const newItem = {
          ...rest,
          timeStart: timeStartList
            ? `${timeStartList[0]}:${timeStartList[1]}`
            : '',
          timeEnd: timeEndList ? `${timeEndList[0]}:${timeEndList[1]}` : '',
        };
        return newItem;
      })
    : [];

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
  return (
    <>
      <Spin spinning={isPending}>
        <Container>
          <Spacer />
          <Table
            title={() => {
              return (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Title
                    level={3}
                    style={{
                      color: '#E50A5C',
                      paddingBottom: '0px',
                      marginBottom: '0px',
                    }}
                  >
                    Shifts
                  </Title>
                  <Button
                    color="#BDD000"
                    type="primary"
                    style={{
                      backgroundColor: '#BDD000',
                      borderColor: '#BDD000',
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setInitialData(null);
                      setTimeout(() => {
                        setIsModalVisible(true);
                      }, 0);
                    }}
                  >
                    Add new
                  </Button>
                </div>
              );
            }}
            dataSource={normalizedData}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Name"
              key="name"
              dataIndex="name"
              render={(title: Time['name']) => (
                <Space size="middle">{title}</Space>
              )}
              width="20%"
              sorter={(a, b) => {
                return a.name.localeCompare(b.name);
              }}
              sortDirections={['descend']}
              {...getColumnSearchProps('name')}
            />
            <Column
              title="Time start"
              key="timeStart"
              dataIndex="timeStart"
              render={(title: Time['timeStart']) => (
                <Space size="middle">{title}</Space>
              )}
              width="20%"
              sortDirections={['descend']}
              {...getColumnSearchProps('timeStart')}
            />
            <Column
              title="Time end"
              key="timeEnd"
              dataIndex="timeEnd"
              render={(title: Time['timeEnd']) => (
                <Space size="middle">{title}</Space>
              )}
              width="20%"
              sortDirections={['descend']}
              {...getColumnSearchProps('timeEnd')}
            />
            <Column
              onFilter={(value, record: any) => record.status.includes(value)}
              filters={[
                { text: 'Active', value: 10 },
                { text: 'Inactive', value: 0 },
              ]}
              filterMultiple={false}
              title="Status"
              key="status"
              dataIndex="status"
              width="25%"
              render={(status: number) => (
                <Space size="middle">
                  {status === 10 ? 'ACtive' : 'Inactive'}
                </Space>
              )}
            />
            <Column
              title=""
              width="20%"
              render={(data) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'flex-end',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        setInitialData({
                          id: data.id,
                          time:
                            data.timeStart && data.timeEnd
                              ? [
                                  dayjs(data.timeStart, 'HH:mm'),
                                  dayjs(data.timeEnd, 'HH:mm'),
                                ]
                              : null,
                          name: data.name,
                          status: data.status,
                        });
                        setTimeout(() => {
                          setIsModalVisible(true);
                        }, 0);
                      }}
                    />
                    <DeleteButton
                      onSubmit={async () => {
                        await axiosAuth.get(`shift/delete/${data.id}`);
                        await revalidate();
                      }}
                      type="icon"
                    />
                  </div>
                );
              }}
            />
          </Table>
        </Container>
      </Spin>
      <ShiftsModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        schoolId={schoolId}
        afterSubmit={revalidate}
      />
    </>
  );
};

export default Shifts;
