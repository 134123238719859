import { Button, Select, Table, message } from "antd";
import { useState } from "react";
import { Tax, FeeTaxes, TaxesDataFees, updateTaxesFeesListUrl } from "types";
import { axiosAuth } from "helpers";
import { getNormalizedTaxesData, handleUpdateMultipleTaxesFromGroup, updateTaxFee } from "../../helpers";
import TaxesHeader from "../TaxesHeader/TaxesHeader";
import { Spacer } from "components";

type Props = {
  taxesFeeData: TaxesDataFees | undefined;
  taxesData: Tax[];
  schoolId: number;
  handleRevalidate: () => Promise<void>;
};

const initialFeeTaxData = {
  annualFee: [],
  oneTimeFee: [],
  campsFee: [],
  termsFee: [],
  othersFee: [],
  foodFee: [],
  materialFee: [],
  transportFee: [],
};

const TaxesFeeTable = ({ taxesFeeData, schoolId, taxesData, handleRevalidate }: Props) => {
  const [savingTaxesFetching, setSavingTaxesFetching] = useState(false);
  const [feeTaxData, setFeeTaxData] = useState<FeeTaxes>(initialFeeTaxData);

  const handleSavingTaxes = async () => {
    try {
      setSavingTaxesFetching(true);

      const { foodFee, materialFee, transportFee, ...rest } = feeTaxData;

      const body = {
        ...rest,
        utilityFee: [...foodFee, ...materialFee, ...transportFee],
      };

      await axiosAuth.post(updateTaxesFeesListUrl(Number(schoolId)), body);
      handleRevalidate();
      setSavingTaxesFetching(false);
      message.success("Taxes updated successfully.");
    } catch (e) {
      message.error("Updating taxes failed, please try again, or contact support.");
      setSavingTaxesFetching(false);
    }
  };

  const normalizedTaxesData = getNormalizedTaxesData(taxesFeeData);

  const taxOptions =
    taxesData?.map(item => ({
      value: item.id,
      label: `${item.amount}% (${item.name})`,
    })) || [];

  return (
    <>
      <TaxesHeader
        onClick={handleSavingTaxes}
        title="Fee Taxes"
        buttonProps={{ text: "Save taxes", disabled: !schoolId, loading: savingTaxesFetching }}
      />
      <Table
        dataSource={normalizedTaxesData}
        pagination={false}
        className="taxesTable"
        expandable={{
          defaultExpandAllRows: true,
          expandedRowRender: record => {
            const { data: recordData } = record;

            return recordData.map(item => {
              const pickedTaxId = feeTaxData[record.key as keyof FeeTaxes].find(
                (feeTax: { feeId: number; taxId: number }) => feeTax.feeId === item.feeId
              )?.taxId;

              const groupTaxData = normalizedTaxesData.find(
                normalizedData => normalizedData.key === record.key
              );

              const previousPickedTax = groupTaxData?.data.find(tax => tax.feeId === item.feeId)?.taxId;

              const defTaxId = pickedTaxId || previousPickedTax || "";

              const name = item.name ? item.name : "ONE-TIME ONLY REGISTRATION OR APPLICATION FEE";

              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderBottom: "1px solid #f0f0f0",
                    padding: 16,
                  }}
                >
                  <p>{name}</p>
                  <Select
                    onSelect={taxId => {
                      setFeeTaxData(prevFeeTaxes =>
                        updateTaxFee({
                          prevFeeTaxes,
                          taxId: Number(taxId),
                          groupKey: record.key as keyof FeeTaxes,
                          feeId: item.feeId,
                          schoolId,
                        })
                      );
                    }}
                    options={taxOptions}
                    style={{ width: 200 }}
                    defaultValue={defTaxId}
                    value={defTaxId}
                  />
                </div>
              );
            });
          },
          rowExpandable: record => record.data.length !== 0,
        }}
      >
        <Table.Column title="Name" dataIndex="name" key="name" width="20%" />
        <Table.Column
          title="Tax"
          key="key"
          width="80%"
          align="right"
          render={record =>
            record.data.length === 0 ? undefined : (
              <>
                <Select
                  onSelect={taxId => {
                    setFeeTaxData(prevFeeTaxes =>
                      handleUpdateMultipleTaxesFromGroup({
                        groupKey: record.key as keyof FeeTaxes,
                        taxId: Number(taxId),
                        groupTaxes: normalizedTaxesData,
                        prevFeeTaxes: prevFeeTaxes,
                      })
                    );
                  }}
                  style={{ width: 200 }}
                  options={taxOptions}
                />
              </>
            )
          }
        />
      </Table>
      <Spacer size={48} />
    </>
  );
};

export default TaxesFeeTable;
