import { useState } from "react";

const useAntdModal = () => {
  const [visible, setVisible] = useState(false);
  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return { visible, openModal, closeModal };
};

export default useAntdModal;
