import { Button, Card, Row, Spin, Typography, Col, message, Switch } from "antd";
import { useEffect, useState } from "react";
import { EditTwoTone, PlusOutlined } from "@ant-design/icons";
import { Spacer } from "components";
import useSWR from "swr";
import { RouteComponentProps, useHistory } from "react-router-dom";
import CustomModal from "components/CustomModal";
import OneTimeFeeModal from "./OneTimeFeeModal";
import { axiosAuth } from "helpers";
import { FinancialsSchoolSettingsType, SchoolFinancialSetting } from "types/rest/financials";
import {
  getOneTimeFeeUrl,
  getRequiredSettingsUrl,
  getSchoolFinancialSettings,
  OneTimeFeeRequest,
  OneTimeFeeResponse,
} from "types/rest/oneTimeFee";
import { FinancialCheckInformation } from "../components";

const { Title, Text } = Typography;
type Props = RouteComponentProps<{ id?: string }, any, { id?: string }>;

const OneTimeFees = ({ match, location }: Props) => {
  const history = useHistory();
  const schoolId = match.params.id ?? location.state?.id;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isRequiredModalVisible, setIsRequiredModalVisible] = useState(false);
  const [hasRegistretionFee, setHasRegistratonFee] = useState(false);

  const { data: schoolFinancialSettings, error: schoolSettingsError } = useSWR<SchoolFinancialSetting>(
    schoolId ? getRequiredSettingsUrl(schoolId) : null
  );

  const { data: schoolData } = useSWR(schoolId ? `school/${schoolId}` : null);
  const schoolCurrency = schoolData?.currency;

  const {
    data: oneTimeFeeData,
    error,
    revalidate,
  } = useSWR<OneTimeFeeResponse>(schoolId ? getOneTimeFeeUrl(schoolId) : null);

  const { data: financialSchoolData, error: schoolError } = useSWR<FinancialsSchoolSettingsType>(
    schoolId ? getSchoolFinancialSettings(schoolId) : null
  );

  const isPending =
    (!financialSchoolData && !schoolError) || (!schoolFinancialSettings && !schoolSettingsError);

  const sendSchoolSettings = async () => {
    const request: OneTimeFeeRequest = {
      oneTimeFees: !hasRegistretionFee,
    };
    await axiosAuth.put(`school/${schoolId}/financials/settings`, request);
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleEditModal = () => {
    setIsEditModalVisible(true);
  };

  const handleSubmit = () => {
    message.success(`The One time fees  has been save`);
    revalidate();
    setIsModalVisible(false);
    setIsEditModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditModalVisible(false);
  };

  const handleSwitchOneTimeFee = (val: boolean) => {
    setHasRegistratonFee(val);
    sendSchoolSettings();
  };

  const handleCloseRequiredModal = () => {
    setIsRequiredModalVisible(false);
    history.push(`/main/schools/${schoolId}/details`);
  };

  useEffect(() => {
    if (financialSchoolData) {
      setHasRegistratonFee(financialSchoolData.oneTimeFees);
    }
  }, [financialSchoolData]);

  useEffect(() => {
    if (!schoolFinancialSettings) return;
    const { termsCount, holidays } = schoolFinancialSettings;
    if (termsCount === 0 || holidays === 0) {
      setIsRequiredModalVisible(true);
      return;
    }
    setIsRequiredModalVisible(false);
  }, [schoolFinancialSettings]);

  return (
    <>
      <Spin spinning={isPending}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Text style={{ fontSize: 14, marginRight: 15 }}>
            Charge a one-time only registration, enrollment or application fee
          </Text>
          <Switch
            checkedChildren=""
            unCheckedChildren=""
            defaultChecked
            onChange={val => {
              handleSwitchOneTimeFee(val);
            }}
            checked={hasRegistretionFee ?? false}
          />
        </div>
        <Card>
          <div style={{ textAlign: "center" }}>
            <Text style={{ color: "#E8246D", fontWeight: "bold" }}>
              One - time only registration / enrollment / application fee
            </Text>
            <Button
              color="#BDD000"
              type="primary"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
                alignSelf: "flex-end",
                position: "absolute",
                right: 20,
                top: 20,
              }}
              icon={<PlusOutlined />}
              onClick={handleOpenModal}
              disabled={hasRegistretionFee && !oneTimeFeeData ? false : true}
            >
              Add
            </Button>
            <Spacer size={30} />
            <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>
              <div>
                <Text>
                  You have created{" "}
                  <span
                    style={{
                      borderColor: "#E8246D",
                      borderWidth: 1,
                      borderStyle: "solid",
                      padding: 2,
                    }}
                  >
                    {schoolFinancialSettings?.termsCount ?? "-"}
                  </span>{" "}
                  Academic Terms.
                </Text>
              </div>
              <div>
                <Text>
                  You have created{" "}
                  <span
                    style={{
                      borderColor: "#E8246D",
                      borderWidth: 1,
                      borderStyle: "solid",
                      padding: 2,
                    }}
                  >
                    {schoolFinancialSettings?.campsCount ?? "-"}
                  </span>{" "}
                  Camps.
                </Text>
              </div>
            </div>
          </div>
          <Spacer size={20} />
        </Card>
        <Spacer size={20} />

        <Row>
          <Col span={12}>
            <Text style={{ marginLeft: 25, fontSize: 14, fontWeight: 500 }}>Title</Text>
          </Col>
          <Col span={8} style={{ fontSize: 14, fontWeight: 500 }}>
            Amount
          </Col>
        </Row>
        <Spacer size={20} />
        <div style={{ backgroundColor: "#FFFFFF", padding: 25 }}>
          <Row style={{ alignContent: "center" }}>
            <Col span={12}>
              <Text style={{ fontSize: 14, fontWeight: 400 }}>
                ONE-TIME ONLY REGISTRATION, APPLICATION OR ENROLLMENT FEE
              </Text>
            </Col>
            <Col span={8} style={{ fontSize: 14, fontWeight: 400 }}>
              {oneTimeFeeData?.amount && oneTimeFeeData.amount + " " + schoolCurrency}
            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                type="text"
                icon={<EditTwoTone size={10} twoToneColor="#00A1E1" />}
                onClick={handleEditModal}
                style={{ backgroundColor: "#FFFFFF" }}
                disabled={!hasRegistretionFee}
              />
            </Col>
          </Row>
        </div>
      </Spin>
      <OneTimeFeeModal
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        isVisible={isModalVisible || isEditModalVisible}
        schoolId={schoolId}
        isEdit={isEditModalVisible}
        feeId={oneTimeFeeData?.id}
      />
      <CustomModal isVisible={false} onClose={handleCloseRequiredModal} title="Info">
        <div
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          You{" "}
          <span
            style={{
              color: "#EF0B0BD9",
              borderBottom: "#EF0B0BD9",
              borderBottomWidth: 1,
              borderStyle: "solid",
            }}
          >
            must complete
          </span>{" "}
          the Settings for Terms {"&"} Holidays first.
        </div>
      </CustomModal>
    </>
  );
};

export default OneTimeFees;
