import { FC, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  Select,
  Row,
  DatePicker,
  Col,
} from 'antd';
import { Spacer } from 'components';
import { groupsList } from 'const';
import { ErrorMessage } from 'enums';

const { Title, Text } = Typography;
const { TextArea } = Input;

export type InitialData = {};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
};

const DevelopmentCategoryModal: FC<Props> = ({
  isVisible,
  onClose,
  initialData,
}) => {
  const [isUpdatePending, setIsUpdatePending] = useState(false);

  const [form] = Form.useForm();
  const title = initialData ? 'Update skill category' : 'Create skill category';

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = (values: any) => {
    setIsUpdatePending(true);
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form
        name="basic"
        initialValues={initialData ?? {}}
        onFinish={onFinish}
        form={form}
      >
        <Text>Name</Text>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              label={
                <label style={{ color: 'gray', fontSize: '1.4rem' }}>
                  [English]
                </label>
              }
              name="en"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <label style={{ color: 'gray', fontSize: '1.4rem' }}>
                  [French]
                </label>
              }
              name="fr"
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <label style={{ color: 'gray', fontSize: '1.4rem' }}>
                  [Arabic]
                </label>
              }
              name="ar"
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <label style={{ color: 'gray', fontSize: '1.4rem' }}>
                  [Vietnamese]
                </label>
              }
              name="vi"
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item
              label="Type"
              name="type"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              style={{ flex: 1 }}
              labelCol={{ span: 24 }}
            >
              <Select options={groupsList} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Status"
              name="status"
              style={{ flex: 1 }}
              labelCol={{ span: 24 }}
            >
              <Select>
                <Select.Option value={10}>Active</Select.Option>
                <Select.Option value={0}>Inactive</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isUpdatePending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#BDD000',
              borderColor: '#BDD000',
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DevelopmentCategoryModal;
