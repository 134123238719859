export enum AnnualFeeType {
  MEDICAL_FEE = "MEDICAL_FEE",
  RECURRING_REGISTRATION_FEE = "RECURRING_REGISTRATION_FEE",
  OTHERS_FEE = "OTHERS_FEE",
}

export const getAnnualFeesUrl = (schoolId: string) => `school/${schoolId}/financials/settings/annual-fees`;

export const getCreateNewAnnualFeeUrl = (schoolId: string) =>
  `school/${schoolId}/financials/settings/annual-fees`;

export const getUpdateAnnualFeeUrl = (schoolId: string, feeId: number) =>
  `school/${schoolId}/financials/settings/annual-fees/${feeId}`;

export const getDeleteAnnualFeeUrl = (schoolId: string, feeId: number) =>
  `school/${schoolId}/financials/settings/annual-fees/${feeId}`;

export type CreateAnnualSchoolSettingsRequest = {
  annualFeesRecurringRegistration: boolean;
  annualFeesMedical: boolean;
  annualFeesOther: boolean;
};

export type AnnualFeeItem = Omit<AnnualFeeResponse, "type">;

export type AnnualFeeResponse = {
  id: number;
  type: AnnualFeeType.MEDICAL_FEE | AnnualFeeType.OTHERS_FEE | AnnualFeeType.RECURRING_REGISTRATION_FEE;
  name: string;
  amount: number;
};

export type AnnualFeeParsedResponse = {
  type: AnnualFeeType.MEDICAL_FEE | AnnualFeeType.OTHERS_FEE | AnnualFeeType.RECURRING_REGISTRATION_FEE;
  items: Omit<AnnualFeeResponse, "type">[];
};

export type CreateAnnualFeeRequest = Omit<AnnualFeeResponse, "id">;
