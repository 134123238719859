import { ICampFeesData } from "../CampFees";

interface IParsedCampFeesData {
  camp: string;
  times: {
    id: number;
    amount: number;
    timeName: string;
    timeId: number;
  }[];
}

export const parseCampFees = (campFeesData: ICampFeesData[]) => {
  return campFeesData.reduce((acc: IParsedCampFeesData[], campData) => {
    const { id, amount, camp, timeName, timeId } = campData;

    const foundIndex = acc.findIndex(x => x.camp === camp);
    if (foundIndex >= 0) {
      acc[foundIndex].times.push({
        id,
        amount,
        timeName,
        timeId,
      });
    } else {
      acc.push({
        camp,
        times: [
          {
            id,
            amount,
            timeName,
            timeId,
          },
        ],
      });
    }
    return acc;
  }, []);
};
