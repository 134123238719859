import { Button, Table } from "antd";
import { DeleteButton } from "components";
import { EditTwoTone } from "@ant-design/icons";
import { axiosAuth } from "helpers";
import { NoDataInfo } from "screens/Financials/components";
import { Utility, deleteUtilityFee } from "types";
import useSWR from "swr";

type Props = {
  handleEditUtility: (record: Utility) => void;
  handleRevalidate: () => void;
  schoolId: number;
  data?: Utility[];
  times?: {
    value: number;
    label: string;
  }[];
};

function UtilitiesTable({ data, times, schoolId, handleEditUtility, handleRevalidate }: Props) {
  const { data: schoolData } = useSWR(schoolId ? `school/${schoolId}` : null);
  const schoolCurrency = schoolData?.currency;

  if (!data || data.length === 0) {
    return <NoDataInfo title="You don't have any fees added yet" />;
  }

  const getTimeName = (id: number) => {
    const time = times?.find(time => time.value === id);
    if (!time) return "";
    return time.label;
  };

  const handleDeleteUtilityFee = async (id: number | string) => {
    await axiosAuth.delete(deleteUtilityFee(schoolId, Number(id)));
    handleRevalidate();
  };

  return (
    <Table dataSource={data}>
      <Table.Column
        title="Times/days"
        dataIndex="timeId"
        key="timeId"
        render={record => <p>{getTimeName(record)}</p>}
        width="30%"
      />
      <Table.Column title="Name" dataIndex="name" key="name" width="30%" />
      <Table.Column
        title="Amount"
        dataIndex="amount"
        key="amount"
        width="20%"
        render={record => (
          <span>
            {record} {schoolCurrency}
          </span>
        )}
      />
      <Table.Column
        width="20%"
        render={(record: Utility) => (
          <div
            style={{
              display: "flex",
              alignContent: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="text"
              icon={<EditTwoTone twoToneColor="#00A1E1" />}
              style={{ marginRight: "10px" }}
              onClick={() => {
                handleEditUtility(record);
              }}
            />
            <DeleteButton onSubmit={() => handleDeleteUtilityFee(record.id)} type="icon" />
          </div>
        )}
      />
    </Table>
  );
}

export default UtilitiesTable;
