import { CustomLabel } from "components";
import { LinkedinFilled, InstagramFilled, FacebookFilled } from "@ant-design/icons";

export const socialsData = [
  {
    label: <CustomLabel>Social media</CustomLabel>,
    name: "facebookLink",
    placeholder: "Facebook",
    icon: (
      <FacebookFilled
        style={{
          color: "gray",
          fontSize: "1.8rem",
        }}
      />
    ),
  },
  {
    name: "linkedinLink",
    placeholder: "Linkedin",
    icon: (
      <LinkedinFilled
        style={{
          color: "gray",
          fontSize: "1.8rem",
        }}
      />
    ),
  },
  {
    name: "instagramLink",
    placeholder: "Instagram",
    icon: (
      <InstagramFilled
        style={{
          color: "gray",
          fontSize: "1.8rem",
        }}
      />
    ),
  },
];
