import { Typography, Switch } from "antd";
import { Spacer } from "components";
import { ViewType } from "enums";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import { NoDataInfo } from "../components";
import { OtherFeesTable } from "./components";
import { parseOtherFees } from "./helpers/parseOtherFees";
import { AddNewFeeModal, IPaymentPeriod } from "./components/AddNewFeeModal/AddNewFeeModal";

export interface IOtherFeesData {
  id: number;
  name: string;
  amount: number;
  paymentPeriod: IPaymentPeriod;
  createdAt: number;
}

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string }>;

export const OtherFees = (props: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { Title } = Typography;
  const schoolId = props.match.params.id;

  const { data: otherFeesData, revalidate } = useSWR<IOtherFeesData[]>(
    `school/${schoolId}/financials/settings/other-fees`
  );

  if (!otherFeesData || otherFeesData.length === 0) {
    return (
      <>
        <Title
          level={3}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
            backgroundColor: "#FFF",
            textAlign: "center",
            paddingBlock: 28,
            position: "relative",
          }}
        >
          <span>Other Fees</span>
          <AddNewFeeModal schoolId={schoolId} revalidate={revalidate} />
        </Title>
        <NoDataInfo title="You have not added any other fees for this school" />
      </>
    );
  }

  const parsedOtherFeesData = parseOtherFees(otherFeesData);

  return (
    <>
      <RowRightContainer>
        <Switch
          checkedChildren="Edit mode"
          unCheckedChildren="Edit mode"
          defaultChecked
          onChange={val => setIsEditMode(val)}
          checked={isEditMode}
        />
      </RowRightContainer>
      <Spacer size={20} />
      <Title
        level={3}
        style={{
          color: "#E50A5C",
          paddingBottom: "0px",
          marginBottom: "0px",
          backgroundColor: "#FFF",
          textAlign: "center",
          paddingBlock: 28,
          position: "relative",
        }}
      >
        <span>Other Fees</span>
        <AddNewFeeModal schoolId={schoolId} revalidate={revalidate} />
      </Title>
      <Spacer size={40} />
      <OtherFeesTable
        dataSource={parsedOtherFeesData}
        isEditMode={isEditMode}
        revalidate={revalidate}
        schoolId={schoolId}
      />
    </>
  );
};

const RowRightContainer = styled.div`
  display: flex;
  justify-content: end;
  align-content: flex-end;
`;
