import { Table } from "antd";
import { IRecord } from "../../Summary";
import { getSummaryTableColumns } from "screens/Kid/Financials/helpers";

export interface IModifiedRecord extends Omit<IRecord, "date"> {
  date: string;
}
interface Props {
  dataSource?: IModifiedRecord[];
  schoolCurrency: string;
}

export const SummaryTable = ({ dataSource, schoolCurrency }: Props) => {
  const campFeesColumns = getSummaryTableColumns({
    schoolCurrency,
  });

  return <Table dataSource={dataSource} columns={campFeesColumns} />;
};
