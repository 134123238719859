import useSWR from "swr";
import { Card, Col, Row, Spin, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generateCalendar from "antd/es/calendar/generateCalendar";
import "antd/es/calendar/style";
import { Spacer } from "components";
import styled from "styled-components";
import { FC, useMemo } from "react";

type AttendanceType = {
  childId: number;
  createdAt: number;
  createdBy: number;
  date: string;
  employeeId: number;
  id: number;
  status: number;
  temperature: null;
  updatedAt: number;
  updatedBy: number;
  registerIn: string;
  registerOut: string;
}[];

const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);

const { Text, Title } = Typography;

const prevMonths = Array.from(new Array(12)).map((item, index) => {
  return dayjs().subtract(index, "month");
});

const monthsToShow = [...prevMonths].sort((a, b) => b.unix() - a.unix());

type Props = {
  attendanceUrl: string | null;
  role?: "child" | "teacher";
};

const Attendance: FC<Props> = ({ attendanceUrl, role }) => {
  // const { data, error } = useSWR<AttendanceType>(
  //   props?.location?.state?.id
  //     ? `attendance/config/child/${props.location.state.id}`
  //     : null
  // );

  const { data, error } = useSWR<AttendanceType>(attendanceUrl);

  const isPending = !data && !error;

  const normalizedAttendanceData = useMemo(() => {
    const list: Record<string, AttendanceType[0]> = {};

    data?.forEach(item => {
      list[dayjs(item.date).format("DD-MM-YYYY")] = item;
    });
    return list;
  }, [data]);

  return (
    <Spin spinning={isPending}>
      <Card>
        <Title level={4} style={{ color: "#E50A5C", margin: "0px" }}>
          Attendances
        </Title>
        <Spacer size={30} />
        <Row gutter={[48, 48]}>
          {monthsToShow.map((month, index) => (
            <Col key={index} lg={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 8 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: "1.4rem",
                  }}
                >
                  {month.format("MMM, YYYY")}
                </Text>
              </div>
              <Spacer size={5} />
              <div>
                <StyledCalendar
                  fullscreen={false}
                  onPanelChange={() => {}}
                  headerRender={() => null}
                  validRange={[dayjs(month).startOf("month"), dayjs(month).endOf("month")]}
                  onSelect={() => {}}
                  dateCellRender={item => {
                    const cellData = normalizedAttendanceData[dayjs(item).format("DD-MM-YYYY")];

                    if (!cellData) {
                      return null;
                    }

                    if (cellData.registerIn && cellData.registerOut && role === "teacher") {
                      return (
                        <div
                          style={{
                            position: "absolute",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            backgroundColor: "#BDD000",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            opacity: "1",
                            zIndex: -1,
                          }}
                        />
                      );
                    }

                    if (role === "child") {
                      return (
                        <div
                          style={{
                            position: "absolute",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            backgroundColor: "#BDD000",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            opacity: "1",
                            zIndex: -1,
                          }}
                        />
                      );
                    }

                    return null;
                  }}
                  value={undefined}
                  defaultValue={month}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Card>
    </Spin>
  );
};

export default Attendance;

const StyledCalendar = styled(Calendar)`
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: none;
    color: black;
  }
`;
