import { Button, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { axiosAuth } from 'helpers';

type Props = {
  email: string;
};
const ResetPassword: FC<Props> = ({ email }) => {
  const [isPending, setIsPending] = useState(false);

  return (
    <Button
      type="link"
      icon={<MailOutlined />}
      style={{ color: '#E50A5C' }}
      loading={isPending}
      onClick={async () => {
        try {
          setIsPending(true);
          await axiosAuth.post('auth/forgot-password', { email });
          message.success(`The new password has been send to email: ${email}`);
          setIsPending(false);
        } catch (e) {
          setIsPending(false);
        }
      }}
    >
      Reset password
    </Button>
  );
};

export default ResetPassword;
