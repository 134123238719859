import { FC, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  Select,
  Row,
  Col,
  Image,
  Upload,
} from 'antd';
import { EditFilled, DeleteTwoTone } from '@ant-design/icons';
import { Spacer } from 'components';
import { axiosAuth, normFile } from 'helpers';
import { ErrorMessage } from 'enums';

const { Title, Text } = Typography;

export type InitialData = Record<string, any>;

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
  planId: number | null | undefined;
};
const languages = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'fr',
    label: 'French',
  },
  {
    value: 'ar',
    label: 'Arabic',
  },
  {
    value: 'vi',
    label: 'Vietnamese',
  },
];

const WeekListModal: FC<Props> = ({
  isVisible,
  onClose,
  initialData,
  planId,
}) => {
  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(languages[0].value);
  const [form] = Form.useForm();
  const title = initialData ? 'Edit week data' : 'Add new week';

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = async (values: any) => {
    setIsUpdatePending(true);
    const { newImage, ...rest } = values;
    if (initialData) {
      await axiosAuth.post(`plan-week/update/${initialData.id}`, {
        ...rest,
        lang: activeLanguage,
      });
    } else {
      await axiosAuth.post(`plan-week/create/${planId}`, {
        ...rest,
        lang: activeLanguage,
      });
    }
    setIsUpdatePending(false);
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Row justify="end">
        {languages.map(({ label, value }) => (
          <Button
            type={value === activeLanguage ? 'primary' : 'text'}
            style={
              value === activeLanguage
                ? {
                    backgroundColor: '#E50A5C',
                    border: 'none',
                    color: 'white',
                  }
                : {
                    color: '#E50A5C',
                  }
            }
            onClick={() => setActiveLanguage(value)}
          >
            {label}
          </Button>
        ))}
      </Row>
      <Spacer />
      <Form
        name="basic"
        initialValues={initialData ? initialData : {}}
        onFinish={onFinish}
        form={form}
      >
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item
              label="Week number"
              name="weekNumber"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              labelCol={{ span: 24 }}
            >
              <Select>
                <Select.Option value={10}>Active</Select.Option>
                <Select.Option value={0}>Inactive</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="newImage"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Upload
                name="avatar"
                listType="picture"
                beforeUpload={() => false}
              >
                <Button
                  icon={<EditFilled />}
                  type="dashed"
                  style={{ width: '100%' }}
                >
                  {`PDF file [${
                    languages.find(({ value }) => value === activeLanguage)
                      ?.label
                  }]`}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item wrapperCol={{ offset: 20 }}>
              <Button
                loading={isUpdatePending}
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#BDD000',
                  borderColor: '#BDD000',
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default WeekListModal;
