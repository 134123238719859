import { FC, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Typography,
  Divider,
  Row,
  Col,
  Checkbox,
  Modal,
  Upload,
  message,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { axios, normFile } from 'helpers';
import { useForm } from 'antd/lib/form/Form';
import getImageBase64 from 'helpers/getImageBase64';
import { getSlackMessageUrl, SlackMessageBody } from 'types';

const { Title } = Typography;

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

type FormValues = {
  shortDesc: string;
  longDesc: string;
  email: string;
  platforms: string[];
  image: UploadChangeParam<UploadFile<any>>['fileList'];
};

const BugReportModal: FC<Props> = ({ isVisible, onClose }) => {
  const [isSendReportPending, setIsSendReportPending] = useState(false);
  const [form] = useForm();

  const onFinish = async (values: FormValues) => {
    try {
      setIsSendReportPending(true);
      let image:
        | {
            name: string;
            type: string | undefined;
            content: string;
          }
        | undefined;

      if (values.image) {
        image = await getImageBase64(values.image);
      }
      let body: SlackMessageBody = {
        title: `${values.shortDesc}`,
        body: `${values.longDesc} Platforms: ${values.platforms.join(
          ', '
        )}. Affected account: ${values.email}`,
      };
      if (image) {
        body.image = image;
      }
      await axios.post(getSlackMessageUrl(), body);
      setIsSendReportPending(false);
      form.resetFields();
      message.success('Report sent succesfully');
      setTimeout(() => {
        onClose();
      }, 0);
    } catch (e) {
      setIsSendReportPending(false);
    }
  };

  return (
    <Modal visible={isVisible} onCancel={onClose} footer={null}>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Title level={5}>Report a bug</Title>
        <Divider />
        <Form.Item
          label="Short description"
          name="shortDesc"
          rules={[
            { required: true, message: 'Please input short description!' },
          ]}
          tooltip="Describe the bug in few words (150 characters)"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Long description"
          name="longDesc"
          rules={[
            { required: true, message: 'Please input long description!' },
          ]}
          tooltip="Describe the bug and how you experienced it"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Row justify="space-between">
          <Col span={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input valid email!',
                  type: 'email',
                },
              ]}
              tooltip="Please add the email of the account which experienced the bug"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[24, 0]}>
          <Col span={12}>
            <label>Platforms:</label>
            <Form.Item
              name="platforms"
              valuePropName="checked"
              rules={[
                { required: true, message: 'Please input affected platforms!' },
              ]}
            >
              <Checkbox.Group>
                <Row>
                  <Col span={24}>
                    <Checkbox value="Web">Web</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="Parent App - iOS">
                      Parent App - iOS
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="Parent App - Android">
                      Parent App - Android
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="Teacher App - Android">
                      Teacher App - Android
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              labelCol={{ span: 24 }}
            >
              <Upload
                name="avatar"
                listType="picture"
                beforeUpload={() => false}
              >
                <Button
                  icon={<InboxOutlined />}
                  type="dashed"
                  style={{ width: '100%' }}
                >
                  Attach photo
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Button
            loading={isSendReportPending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#BDD000',
              borderColor: '#BDD000',
            }}
          >
            Send
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default BugReportModal;
