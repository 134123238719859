import { Col, Form, InputNumber } from "antd";
import { CustomLabel } from "components";
import { ErrorMessage } from "enums";
import { greaterThanZeroValidator } from "helpers";

interface Props {
  isEditMode?: boolean;
}

export const Royalty = ({ isEditMode }: Props) => {
  return (
    <Col span={6}>
      <Form.Item
        name="royalty"
        labelCol={{ span: 24 }}
        label={<CustomLabel>Royalty</CustomLabel>}
        rules={[
          { required: true, message: ErrorMessage.REQUIRED },
          {
            validator: (_, value) => greaterThanZeroValidator(_, value),
          },
        ]}
      >
        <InputNumber
          style={
            isEditMode
              ? {
                  width: "100%",
                }
              : {
                  border: "none",
                  backgroundColor: "white",
                  width: "100%",
                  padding: 0,
                }
          }
          max={100}
          addonAfter="%"
          disabled={!isEditMode}
        />
      </Form.Item>
    </Col>
  );
};
