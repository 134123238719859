import { Button, Menu } from "antd";
import { Link } from "react-router-dom";
import { TabType } from "../../Financials";
import ChildCheckInformation from "../ChildCheckInformation";
import { Kid } from "screens/Kids/Kids";

const activeButtonStyle = {
  backgroundColor: "#BDD000",
  border: "1px solid #BDD000",
  outline: "none",
};

const nonActiveButtonStyle = {
  backgroundColor: "#fff",
  border: "1px solid #d9d9d9",
  outline: "none",
};

const activeLinkStyle = {
  color: "#fff",
};

const nonActiveLinkStyle = {
  color: "#000",
};

type Props = {
  kidData: Kid;
  url: string;
  handleTabChange: (tab: TabType) => void;
  pickedTab: TabType;
};

export const FinancialsTabsNavigation = ({ kidData, url, handleTabChange, pickedTab }: Props) => {
  return (
    <Menu
      defaultSelectedKeys={["summary"]}
      mode="horizontal"
      style={{
        display: "flex",
        justifyContent: "end",
        width: 540,
        backgroundColor: "transparent",
        border: "none",
      }}
    >
      <ChildCheckInformation kidData={kidData} />
      <Menu.Item
        key="summary"
        className="custom-ant-menu-item"
        onClick={() => handleTabChange("summary")}
        style={{ padding: "0 14px" }}
      >
        <Button
          type="primary"
          htmlType="submit"
          style={{ ...(pickedTab === "summary" ? activeButtonStyle : nonActiveButtonStyle) }}
        >
          <Link
            to={{ pathname: `${url}/summary`, state: kidData }}
            style={{ ...(pickedTab === "summary" ? activeLinkStyle : nonActiveLinkStyle) }}
          >
            Summary
          </Link>
        </Button>
      </Menu.Item>
      <Menu.Item key="fees" className="custom-ant-menu-item" style={{ padding: "0 14px" }}>
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => handleTabChange("fees")}
          style={{ ...(pickedTab === "fees" ? activeButtonStyle : nonActiveButtonStyle) }}
        >
          <Link
            to={{ pathname: `${url}/fees`, state: kidData }}
            style={{ ...(pickedTab === "fees" ? activeLinkStyle : nonActiveLinkStyle) }}
          >
            Fees
          </Link>
        </Button>
      </Menu.Item>
      <Menu.Item key="payments" className="custom-ant-menu-item" style={{ padding: 0, paddingLeft: "14px" }}>
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => handleTabChange("payments")}
          style={{ ...(pickedTab === "payments" ? activeButtonStyle : nonActiveButtonStyle) }}
        >
          <Link
            to={{ pathname: `${url}/payments`, state: kidData }}
            style={{ ...(pickedTab === "payments" ? activeLinkStyle : nonActiveLinkStyle) }}
          >
            Payments
          </Link>
        </Button>
      </Menu.Item>
    </Menu>
  );
};
