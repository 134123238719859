import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface CounterState {
  value: number;
}

const initialState: CounterState = {
  value: 0,
};

export const fetchPokemon = createAsyncThunk(
  'users/fetchByIdStatus',
  async (userId, thunkAPI) => {
    const response = await axios.get('https://pokeapi.co/api/v2/pokemon/ditto');
    return response.data;
  }
);

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPokemon.pending, (state, action) => {
      state.value += 2;
      console.log('pending', action);
    });
    builder.addCase(fetchPokemon.fulfilled, (state, action) => {
      state.value += 10;

      console.log('fulfilled', action);
    });
    builder.addCase(fetchPokemon.rejected, (state, action) => {
      console.log('rejected', action);
    });
  },
});

export const { increment, decrement, incrementByAmount } = counterSlice.actions;

export default counterSlice.reducer;
