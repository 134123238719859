import { Button, Input, Space, Table, Typography } from 'antd';
import { DeleteButton, Spacer } from 'components';
import groupBy from 'helpers/groupBy';
import {
  EditTwoTone,
  DeleteTwoTone,
  UnorderedListOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { FC, useRef, useState } from 'react';
import useSWR from 'swr';
import PlansListModal from './PlansListModal';
import { groupName, groupsList } from 'const';
import { axiosAuth } from 'helpers';

const { Column } = Table;
const { Title } = Typography;

type Props = {
  onDetailsClick: (subCategory: PlanItemType) => void;
};

type PlanItemType = {
  createdAt: number;
  createdBy: number;
  id: number;
  name: 'Term 1 - 2017/2018';
  status: 10;
  type: 10;
  updatedAt: 1513259402;
  updatedBy: 1;
};

const PlansList: FC<Props> = ({ onDetailsClick }) => {
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<{} | null>(null);

  const { data, error, revalidate } = useSWR<PlanItemType[]>('plan-template');
  const isPending = !data && !error;

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title
          level={3}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          Plan templates
        </Title>
        <Button
          color="#BDD000"
          type="primary"
          style={{
            backgroundColor: '#BDD000',
            borderColor: '#BDD000',
          }}
          icon={<PlusOutlined />}
          onClick={() => {
            setInitialData(null);
            setTimeout(() => {
              setIsModalVisible(true);
            }, 0);
          }}
        >
          Add new
        </Button>
      </div>
      <Spacer />
      <Table
        dataSource={data ?? []}
        pagination={{ pageSize: 20 }}
        loading={isPending}
      >
        <Column
          title="type"
          key="type"
          dataIndex="type"
          render={(type: number) => (
            <Space size="middle">{groupName[type] ?? '(not set)'}</Space>
          )}
          width="10%"
          onFilter={(value, record: any) => record.type === value}
          filterMultiple={false}
          filters={groupsList.map(({ label, value }) => ({
            value,
            text: label,
          }))}
        />
        <Column
          title="name"
          key="name"
          dataIndex="name"
          render={(name: string) => <Space size="middle">{name}</Space>}
          width="40%"
          sorter={(a, b) => {
            return a.name.localeCompare(b.name);
          }}
          {...getColumnSearchProps('name')}
        />
        <Column
          onFilter={(value, record: any) => record.status === value}
          filterMultiple={false}
          title="status"
          key="status"
          dataIndex="status"
          render={(status: number) => (
            <Space size="middle">{status === 10 ? 'Active' : 'Inactive'}</Space>
          )}
          width="10%"
          filters={[
            { text: 'Active', value: 10 },
            { text: 'Inactive', value: 0 },
          ]}
        />
        <Column
          title=""
          key="actions"
          dataIndex=""
          render={(dataItem: PlanItemType) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignContent: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  type="text"
                  icon={<EditTwoTone twoToneColor="#00A1E1" />}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    setInitialData({
                      ...dataItem,
                    });
                    setTimeout(() => {
                      setIsModalVisible(true);
                    }, 0);
                  }}
                />
                <Button
                  type="text"
                  icon={<UnorderedListOutlined style={{ color: '#00A1E1' }} />}
                  onClick={() => onDetailsClick(dataItem)}
                  style={{ marginRight: '10px' }}
                />
                <DeleteButton
                  onSubmit={async () => {
                    await axiosAuth.get(`plan-template/delete/${dataItem.id}`);
                    await revalidate();
                  }}
                  type="icon"
                />
              </div>
            );
          }}
          width="10%"
        />
      </Table>
      <Spacer size={40} />
      <PlansListModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        afterSubmit={revalidate}
      />
    </>
  );
};

export default PlansList;
