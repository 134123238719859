import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'store';
import { SWRConfig } from 'swr';
import { swrFetcher } from 'helpers/axios';
import BaseApp from 'BaseApp';

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        shouldRetryOnError: false,
        fetcher: swrFetcher,
        revalidateOnFocus: false,
      }}
    >
      <Provider store={store}>
        <div className="App">
          <BaseApp />
        </div>
      </Provider>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
