import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Spin,
  Switch,
  Typography,
} from 'antd';
import {
  CustomBgImage,
  CustomLabel,
  CustomPhoneInput,
  Spacer,
} from 'components';
import { ErrorMessage } from 'enums';
import { axiosAuth } from 'helpers';
import { useEffect, useState } from 'react';
import { Kid } from 'screens/Kids/Kids';

import useSWR from 'swr';

const { Title, Text } = Typography;
const { TextArea } = Input;

const Health = (props: any) => {
  const [isDoctorFormPending, setIsDoctorFormPending] = useState(false);
  const [isDoctorFormEditMode, setIsDoctorFormEditMode] = useState(false);
  const [isChildDetailsFormPending, setIsChildDetailsFormPending] = useState(
    false
  );
  const [isChildDetailsFormEditMode, setIsChildDetailsFormEditMode] = useState(
    false
  );

  const [doctorForm] = Form.useForm();
  const [medicalProfileForm] = Form.useForm();
  const { data, error, revalidate } = useSWR<Kid>(
    props?.location?.state?.id
      ? `child/config/${props.location.state.id}`
      : null
  );
  const isPending = !data && !error;

  const initialValues = {
    ...data,
  };

  useEffect(() => {
    doctorForm.resetFields();
    medicalProfileForm.resetFields();
  }, [JSON.stringify(initialValues)]);

  return (
    <Spin spinning={isPending}>
      <>
        <Form
          name="doctorForm"
          form={doctorForm}
          onFinish={async (values) => {
            setIsDoctorFormPending(true);
            await axiosAuth.post(
              `child/config/health/${props.location.state.id}`,
              values
            );
            await revalidate();
            setIsDoctorFormPending(false);
          }}
          initialValues={{ ...initialValues }}
          style={{
            backgroundColor: 'white',
            borderRadius: '4px',
            overflow: 'hidden',
            boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.02)',
            padding: '24px',
          }}
        >
          <Row>
            <Col flex={1}>
              <Title level={3} style={{ color: '#E50A5C', margin: '0px' }}>
                Doctor / Clinic information / Emergency
              </Title>
            </Col>
            <Col>
              <Switch
                checkedChildren="Edit mode"
                unCheckedChildren="Edit mode"
                defaultChecked
                onChange={(val) => setIsDoctorFormEditMode(val)}
                checked={isDoctorFormEditMode}
              />
            </Col>
          </Row>
          <Divider />
          <Row gutter={[48, 0]}>
            <Col span={24}>
              <Form.Item
                name="doctorName"
                labelCol={{ span: 24 }}
                label={<CustomLabel>Doctor Name</CustomLabel>}
              >
                <Input
                  disabled={!isDoctorFormEditMode}
                  style={
                    isDoctorFormEditMode
                      ? {}
                      : {
                          border: 'none',
                          backgroundColor: 'white',
                          padding: '4px 11px 4px 0px',
                        }
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="doctorPhone"
                labelCol={{ span: 24 }}
                label={<CustomLabel>Doctor phone</CustomLabel>}
              >
                <CustomPhoneInput
                  international
                  value=""
                  onChange={() => {}}
                  disabled={!isDoctorFormEditMode}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="doctorEmail"
                labelCol={{ span: 24 }}
                label={<CustomLabel>Doctor E-mail</CustomLabel>}
                rules={[{ type: 'email', message: ErrorMessage.EMAILA }]}
              >
                <Input
                  disabled={!isDoctorFormEditMode}
                  style={
                    isDoctorFormEditMode
                      ? {}
                      : {
                          border: 'none',
                          backgroundColor: 'white',
                          padding: '4px 11px 4px 0px',
                        }
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="clinicName"
                labelCol={{ span: 24 }}
                label={<CustomLabel>Clinic name</CustomLabel>}
              >
                <Input
                  disabled={!isDoctorFormEditMode}
                  style={
                    isDoctorFormEditMode
                      ? {}
                      : {
                          border: 'none',
                          backgroundColor: 'white',
                          padding: '4px 11px 4px 0px',
                        }
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="clinicAddress"
                labelCol={{ span: 24 }}
                label={<CustomLabel>Clinic address</CustomLabel>}
              >
                <Input
                  disabled={!isDoctorFormEditMode}
                  style={
                    isDoctorFormEditMode
                      ? {}
                      : {
                          border: 'none',
                          backgroundColor: 'white',
                          padding: '4px 11px 4px 0px',
                        }
                  }
                />
              </Form.Item>
            </Col>
            {isDoctorFormEditMode && (
              <Col span={24}>
                <Form.Item>
                  <Button
                    loading={isDoctorFormPending}
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: '#BDD000',
                      borderColor: '#BDD000',
                    }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
        <Spacer size={40} />
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '4px',
            overflow: 'hidden',
            boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.02)',
            padding: '24px',
          }}
        >
          <Title level={3} style={{ color: '#E50A5C', margin: '0px' }}>
            Emergency contacts / Family
          </Title>
          <Divider />
          <div style={{ display: 'flex' }}>
            {data?.childPerson?.map(
              ({
                person: {
                  personImage,
                  firstName,
                  lastName,
                  id,
                  homePhone,
                  mobilePhone,
                  workPhone,
                },
              }) => (
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginRight: '40px',
                  }}
                  key={id}
                >
                  <div style={{ width: '8rem' }}>
                    <CustomBgImage
                      type="carer"
                      src={personImage ?? ''}
                      size={8}
                    />
                  </div>
                  <div style={{ marginLeft: '10px' }}>
                    <Title level={5} style={{ margin: '0px' }}>
                      {firstName}
                    </Title>
                    <Title level={5} style={{ margin: '0px' }}>
                      {lastName}
                    </Title>
                    <Text type="secondary">
                      {mobilePhone || homePhone || workPhone}
                    </Text>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <Spacer size={40} />
        <Form
          name="medicalProfile"
          form={medicalProfileForm}
          style={{
            backgroundColor: 'white',
            borderRadius: '4px',
            overflow: 'hidden',
            boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.02)',
            padding: '24px',
          }}
          initialValues={data ? { ...data } : {}}
          onFinish={async (values) => {
            setIsChildDetailsFormPending(true);
            await axiosAuth.post(`child/update/${props.location.state.id}`, {
              ...values,
            });
            await revalidate();
            setIsChildDetailsFormPending(false);
          }}
        >
          <Row>
            <Col flex={1}>
              <Title level={3} style={{ color: '#E50A5C', margin: '0px' }}>
                Medical Profile
              </Title>
            </Col>
            <Col>
              <Switch
                checkedChildren="Edit mode"
                unCheckedChildren="Edit mode"
                defaultChecked
                onChange={(val) => setIsChildDetailsFormEditMode(val)}
                checked={isChildDetailsFormEditMode}
              />
            </Col>
          </Row>
          <Divider />
          <Form.Item
            label="Known medical problems"
            name="knownMedicalProblems"
            valuePropName="checked"
            normalize={(e) => parseInt(e)}
          >
            <Checkbox disabled={!isChildDetailsFormEditMode} />
          </Form.Item>
          <Form.Item
            label="Known medical problems details"
            name="knownMedicalProblemsDetails"
            labelCol={{ span: 24 }}
          >
            <TextArea
              placeholder="-"
              disabled={!isChildDetailsFormEditMode}
              style={
                isChildDetailsFormEditMode
                  ? {}
                  : {
                      border: 'none',
                      backgroundColor: 'white',
                      padding: '4px 11px 4px 0px',
                    }
              }
            />
          </Form.Item>
          <Form.Item
            help="Use commas to separate illnesses"
            label="Previous illnesses"
            name="previousIllnesses"
            labelCol={{ span: 24 }}
          >
            <Input
              placeholder="-"
              disabled={!isChildDetailsFormEditMode}
              style={
                isChildDetailsFormEditMode
                  ? {}
                  : {
                      border: 'none',
                      backgroundColor: 'white',
                      padding: '4px 11px 4px 0px',
                    }
              }
            />
          </Form.Item>
          <Form.Item
            label="Previous Illnesses Details"
            name="previousIllnessesDetails"
            labelCol={{ span: 24 }}
          >
            <TextArea
              placeholder="-"
              disabled={!isChildDetailsFormEditMode}
              style={
                isChildDetailsFormEditMode
                  ? {}
                  : {
                      border: 'none',
                      backgroundColor: 'white',
                      padding: '4px 11px 4px 0px',
                    }
              }
            />
          </Form.Item>
          <Form.Item
            label="Serious accidents"
            name="seriousAccidents"
            labelCol={{ span: 24 }}
          >
            <TextArea
              placeholder="-"
              disabled={!isChildDetailsFormEditMode}
              style={
                isChildDetailsFormEditMode
                  ? {}
                  : {
                      border: 'none',
                      backgroundColor: 'white',
                      padding: '4px 11px 4px 0px',
                    }
              }
            />
          </Form.Item>
          <Form.Item
            help="Use commas to separate allergies"
            label="Allergies"
            name="allergies"
            labelCol={{ span: 24 }}
          >
            <Input
              placeholder="-"
              disabled={!isChildDetailsFormEditMode}
              style={
                isChildDetailsFormEditMode
                  ? {}
                  : {
                      border: 'none',
                      backgroundColor: 'white',
                      padding: '4px 11px 4px 0px',
                    }
              }
            />
          </Form.Item>
          <Form.Item
            label="Personality traits"
            name="personalityTraits"
            labelCol={{ span: 24 }}
          >
            <TextArea
              placeholder="-"
              disabled={!isChildDetailsFormEditMode}
              style={
                isChildDetailsFormEditMode
                  ? {}
                  : {
                      border: 'none',
                      backgroundColor: 'white',
                      padding: '4px 11px 4px 0px',
                    }
              }
            />
          </Form.Item>
          <Form.Item
            label="Medication"
            name="medication"
            labelCol={{ span: 24 }}
          >
            <TextArea
              placeholder="-"
              disabled={!isChildDetailsFormEditMode}
              style={
                isChildDetailsFormEditMode
                  ? {}
                  : {
                      border: 'none',
                      backgroundColor: 'white',
                      padding: '4px 11px 4px 0px',
                    }
              }
            />
          </Form.Item>
          <Form.Item
            label="Authorized for disburse"
            name="authorizedForDisburse"
            valuePropName="checked"
            normalize={(e) => parseInt(e)}
          >
            <Checkbox disabled={!isChildDetailsFormEditMode} />
          </Form.Item>
          <Form.Item
            label="Schedule disbursing medication"
            name="scheduleDisbursingMedication"
            labelCol={{ span: 24 }}
          >
            <TextArea
              placeholder="-"
              disabled={!isChildDetailsFormEditMode}
              style={
                isChildDetailsFormEditMode
                  ? {}
                  : {
                      border: 'none',
                      backgroundColor: 'white',
                      padding: '4px 11px 4px 0px',
                    }
              }
            />
          </Form.Item>
          <Form.Item
            label="Other special needs"
            name="otherSpecialNeeds"
            labelCol={{ span: 24 }}
          >
            <TextArea
              disabled={!isChildDetailsFormEditMode}
              placeholder="-"
              style={
                isChildDetailsFormEditMode
                  ? {}
                  : {
                      border: 'none',
                      backgroundColor: 'white',
                      padding: '4px 11px 4px 0px',
                    }
              }
            />
          </Form.Item>
          {isChildDetailsFormEditMode && (
            <Form.Item>
              <Button
                loading={isChildDetailsFormPending}
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#BDD000',
                  borderColor: '#BDD000',
                }}
              >
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
        <Spacer />
        <Spacer size={40} />
      </>
    </Spin>
  );
};

export default Health;
