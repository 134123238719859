import { useState } from "react";
import useSWR from "swr";
import { UtilitiesTabsNavigation, UtilitiesModal, UtilitiesTable, UtilityHeader } from "./components";
import { getUtilityCategoryName } from "./helpers";
import { Time, Utility, UtilityTabs, getUtilityFeesList } from "types";

const Utilities = (props: any) => {
  const schoolId = props.match.params.id;
  const [pickedTab, setPickedTab] = useState<UtilityTabs>(UtilityTabs.Food);
  const [editUtilityData, setEditUtilityData] = useState<Utility | undefined>(undefined);
  const [utilityModalVisible, setUtilityModalVisible] = useState<boolean>(false);

  const openUtilityModal = () => {
    setEditUtilityData(undefined);
    setTimeout(() => {
      setUtilityModalVisible(true);
    }, 100);
  };

  const closeUtilityModal = () => {
    setEditUtilityData(undefined);
    handleRevalidate();
    setUtilityModalVisible(false);
  };

  const { data: utilitiesData, revalidate: revalidateUtilities } = useSWR<Utility[]>(
    schoolId ? getUtilityFeesList(schoolId) : null
  );

  const { data: timesData } = useSWR<Time[]>(`school/times/${schoolId}`);

  const handleEditUtility = (record: Utility) => {
    setEditUtilityData(record);
    setTimeout(() => {
      setUtilityModalVisible(true);
    }, 50);
  };

  const handleRevalidate = () => {
    setEditUtilityData(undefined);
    revalidateUtilities();
  };

  const utilitiesTabData = utilitiesData?.filter(
    utility => utility.category === getUtilityCategoryName(pickedTab)
  );

  const formattedTimesData =
    timesData?.map(time => ({
      value: time.id,
      label: time.name,
    })) || [];

  return (
    <div>
      <UtilitiesTabsNavigation pickedTab={pickedTab} setPickedTab={setPickedTab} />
      <UtilityHeader pickedTab={pickedTab} openUtilityModal={openUtilityModal} />
      <UtilitiesTable
        data={utilitiesTabData}
        times={formattedTimesData}
        handleEditUtility={handleEditUtility}
        schoolId={schoolId}
        handleRevalidate={handleRevalidate}
      />
      <UtilitiesModal
        tab={pickedTab}
        visible={utilityModalVisible}
        closeModal={closeUtilityModal}
        timesOptions={formattedTimesData}
        editUtilityData={editUtilityData}
        schoolId={schoolId}
      />
    </div>
  );
};

export default Utilities;
