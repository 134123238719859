export const getSchoolYearUrl = (schoolId: string) =>
  `school/${schoolId}/school-academic-year`;

export const getAddAcademicYear = (schoolId: string) =>
  `school/${schoolId}/school-academic-year`;

export interface AcademicYearResponse {
  name: string;
  id: number;
}
