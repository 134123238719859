import { Table, Space } from "antd";
import Column from "antd/lib/table/Column";
import { Spacer } from "components";

interface Props {
  pendingEvents: { name: string }[];
}

export const PendingEvents = ({ pendingEvents }: Props) => {
  return (
    <>
      <Spacer />
      <Table
        dataSource={pendingEvents}
        pagination={{
          pageSize: 5,
        }}
      >
        <Column
          title="Pending Terms, Camps, Programs"
          key="name"
          dataIndex="name"
          render={value => <Space size="middle">{value}</Space>}
        />
      </Table>
    </>
  );
};
