import {
  Button,
  Input,
  Space,
  Spin,
  Table,
  Typography,
  Select,
  message,
} from 'antd';
import { Container, TopNavigation } from 'components';
import {
  SearchOutlined,
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { FC, useRef, useState } from 'react';
import useSWR from 'swr';
import DocumentModal, { InitialData } from './DocumentModal';
import { useHistory } from 'react-router';
import { child, employee, normChild, normEmployee } from './mock';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import {
  allDocumentTypes,
  allDocumentTypesFilter,
  allDocumentTypesList,
} from 'const';
import { axios } from 'helpers';

const { Option } = Select;
const { Title } = Typography;

const { Column } = Table;

export type DocumentType = {
  child: { firstName: string; lastName: string } | null;
  childId: number;
  createdAt: number;
  createdBy: number;
  employeId: number | null;
  employee: { firstName: string; lastName: string } | null;
  file: string;
  fileName: string;
  id: number;
  status: number;
  type: number;
  updatedAt: number;
  updatedBy: number;
  schoolId?: number;
  partnerId?: number;
};

const Countries: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<InitialData | null>(null);
  const { data, error, revalidate } = useSWR<DocumentType[]>('document/list');
  const isPending = !data && !error;
  const normalizedData = data?.map((item) => {
    const { child, employee, ...rest } = item;
    const newItem = {
      ...rest,
      childName: child ? `${child.firstName} ${child?.lastName}` : null,
      employeeName: employee
        ? `${employee.firstName} ${employee?.lastName}`
        : null,
    };
    return newItem;
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Select
          showSearch
          placeholder={`Search ${dataIndex}`}
          onChange={(e: React.Key) => {
            setSelectedKeys(e ? [e] : []);
            setTimeout(() => {
              handleSearch(e, confirm, dataIndex);
            }, 0);
          }}
          onSearch={(e) => {
            setSelectedKeys(e ? [e] : []);
          }}
          options={dataIndex === 'childName' ? normChild : normEmployee}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
      }
    },
  });
  return (
    <>
      <Spin spinning={isPending}>
        <Container>
          <Table
            title={() => {
              return (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Title
                    level={3}
                    style={{
                      color: '#E50A5C',
                      paddingBottom: '0px',
                      marginBottom: '0px',
                    }}
                  >
                    Documents
                  </Title>
                  <Button
                    color="#BDD000"
                    type="primary"
                    style={{
                      backgroundColor: '#BDD000',
                      borderColor: '#BDD000',
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setInitialData(null);
                      setTimeout(() => {
                        setIsModalVisible(true);
                      }, 0);
                    }}
                  >
                    Add new
                  </Button>
                </div>
              );
            }}
            dataSource={normalizedData}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Child"
              key="childName"
              dataIndex="childName"
              render={(childName) =>
                childName ? (
                  <Space size="middle">{childName}</Space>
                ) : (
                  <Space size="middle">(not set)</Space>
                )
              }
              width="20%"
              sorter={(a, b) => {
                if (a.childName && b.childName) {
                  return a.childName.localeCompare(b.childName);
                }
                return null;
              }}
              sortDirections={['descend', 'ascend']}
              {...getColumnSearchProps('childName')}
            />
            <Column
              title="Employee"
              key="employeeName"
              dataIndex="employeeName"
              render={(employeeName) =>
                employeeName ? (
                  <Space size="middle">{employeeName}</Space>
                ) : (
                  <Space size="middle">(not set)</Space>
                )
              }
              width="20%"
              sorter={(a, b) => {
                if (a.employeeName && b.employeeName) {
                  return a.employeeName.localeCompare(b.employeeName);
                }
                return null;
              }}
              sortDirections={['descend', 'ascend']}
              {...getColumnSearchProps('employeeName')}
            />
            <Column
              onFilter={(value, record: any) => record.type === value}
              filters={allDocumentTypesFilter}
              filterMultiple
              title="Type"
              key="type"
              dataIndex="type"
              width="25%"
              render={(type: keyof typeof allDocumentTypes) => (
                <Space size="middle">{allDocumentTypes[type]}</Space>
              )}
            />
            <Column
              onFilter={(value, record: any) => record.status === value}
              filters={[
                { text: 'Active', value: 10 },
                { text: 'Inactive', value: 0 },
              ]}
              filterMultiple={false}
              title="Status"
              key="status"
              dataIndex="status"
              width="10%"
              render={(status: DocumentType['status']) => (
                <Space size="middle">
                  {status === 10 ? 'Active' : 'Inactive'}
                </Space>
              )}
            />
            <Column
              title=""
              width="15%"
              render={(
                data,
                {
                  child,
                  status,
                  type,
                  employee,
                  childName,
                  employeeName,
                  childId,
                  employeId,
                  schoolId,
                  partnerId,
                  id,
                  file,
                  fileName,
                }: DocumentType & {
                  childName?: string;
                  employeeName?: string;
                }
              ) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'flex-end',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <a
                      rel="noreferrer"
                      href={file}
                      target="_blank"
                      style={{
                        marginRight: '20px',
                        color: '#00A1E1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '1.6rem',
                      }}
                    >
                      <DownloadOutlined />
                    </a>
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        let userType = 'child';
                        if (employeId) {
                          userType = 'staff';
                        }
                        if (schoolId) {
                          userType = 'school';
                        }
                        if (partnerId) {
                          userType = 'partner';
                        }
                        const initialData: InitialData = {
                          status,
                          type:
                            allDocumentTypes[
                              type as keyof typeof allDocumentTypes
                            ],
                          userType,
                          uploadId: childId,
                          documentId: id,
                        };
                        setInitialData(initialData);
                        setTimeout(() => {
                          setIsModalVisible(true);
                        }, 0);
                      }}
                    />
                    <Button
                      type="text"
                      icon={<DeleteTwoTone twoToneColor="#E50A5C" />}
                    />
                  </div>
                );
              }}
            />
          </Table>
        </Container>
      </Spin>
      <DocumentModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        afterSubmit={revalidate}
      />
    </>
  );
};

export default Countries;
