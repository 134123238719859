import { Button, Table } from "antd";
import { DeleteButton } from "components";
import { EditTwoTone } from "@ant-design/icons";
import { Tax } from "types";
import { TaxesHeader } from "..";

type Props = {
  taxesData: Tax[];
  handleOpenAddTaxModal: (record?: Tax) => void;
  handleRemoveTax: (record: Tax) => void;
};

const TaxesTable = ({ taxesData, handleOpenAddTaxModal, handleRemoveTax }: Props) => {
  return (
    <>
      <TaxesHeader
        title="Taxes"
        buttonProps={{ text: "Add new tax" }}
        onClick={() => handleOpenAddTaxModal()}
      />
      <Table dataSource={taxesData || []} style={{ marginTop: 32 }}>
        <Table.Column title="Existing taxes" dataIndex="name" key="name" width="20%" />
        <Table.Column title="Percentage" dataIndex="amount" key="amount" width="70%" />
        <Table.Column
          title=""
          align="right"
          render={(record: Tax) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignContent: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="text"
                  icon={<EditTwoTone twoToneColor="#00A1E1" />}
                  style={{ marginRight: "10px" }}
                  onClick={() => handleOpenAddTaxModal(record)}
                />
                <DeleteButton onSubmit={async () => handleRemoveTax(record)} type="icon" />
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};

export default TaxesTable;
