import { FC, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  Select,
  Checkbox,
  Row,
  Col,
} from 'antd';
import { Spacer } from 'components';
import { axiosAuth } from 'helpers';
import { ErrorMessage } from 'enums';

const { Title } = Typography;

export type InitialData = {
  title: string;
  code: string;
  status: string | number;
  workDays: string[];
  id: number;
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
  afterSubmit: () => Promise<boolean>;
};

const CountryModal: FC<Props> = ({
  isVisible,
  onClose,
  initialData,
  afterSubmit,
}) => {
  const [isCountryUploadPending, setIsCountryUploadPending] = useState(false);
  const [form] = Form.useForm();
  const title = initialData ? 'Update Country' : 'Create Country';

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = async (values: any) => {
    setIsCountryUploadPending(true);
    let days: Record<string, number> = {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    };
    values.workDays.forEach((key: string) => {
      days[key] = 1;
    });
    const { workDays, ...rest } = values;
    if (initialData) {
      await axiosAuth.post(`country/update/${initialData.id}`, {
        ...rest,
        ...days,
      });
    } else {
      await axiosAuth.post(`country/create`, {
        ...rest,
        ...days,
      });
    }
    await afterSubmit();
    setIsCountryUploadPending(false);
    onClose();
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form
        name="basic"
        initialValues={initialData ?? {}}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Title"
          name="title"
          style={{ flex: 1 }}
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
        >
          <Input />
        </Form.Item>
        <Row>
          <Form.Item
            label="Code"
            name="code"
            style={{ flex: 1, marginRight: '20px' }}
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            style={{ flex: 1 }}
          >
            <Select>
              <Select.Option value={10}>Active</Select.Option>
              <Select.Option value={0}>Inactive</Select.Option>
            </Select>
          </Form.Item>
        </Row>
        <Form.Item
          label="Work days"
          name="workDays"
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          style={{ flex: 1 }}
        >
          <Checkbox.Group>
            <Row>
              <Col span={8}>
                <Checkbox value="monday" style={{ lineHeight: '32px' }}>
                  Monday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="tuesday" style={{ lineHeight: '32px' }}>
                  Tuesday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="wednesday" style={{ lineHeight: '32px' }}>
                  Wednesday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="thursday" style={{ lineHeight: '32px' }}>
                  Thursday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="friday" style={{ lineHeight: '32px' }}>
                  Friday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="saturday" style={{ lineHeight: '32px' }}>
                  Saturday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="sunday" style={{ lineHeight: '32px' }}>
                  Sunday
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Spacer size={10} />
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isCountryUploadPending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#BDD000',
              borderColor: '#BDD000',
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CountryModal;
