import { Button, message, Space, Spin, Table, Popconfirm, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { axiosAuth } from "helpers";
import { useHistory } from "react-router-dom";
import { CampRequest } from "types";

interface SummaryPanelProps {
  camps: CampRequest | null;
  changeTabs: () => void;
  schoolId?: string;
  editCampId?: string;
}

const SummaryPanel = ({ camps, changeTabs, schoolId, editCampId }: SummaryPanelProps) => {
  const history = useHistory();

  const isPending = !camps;

  return (
    <Spin spinning={isPending}>
      <Table
        title={() => {
          return (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Popconfirm
                title="Are you sure to save this camp"
                onConfirm={async () => {
                  try {
                    if (editCampId) {
                      await axiosAuth.put(`camps/${editCampId}`, camps);
                      message.success("Camp succesfully updated");
                      history.push(`/main/schools/${schoolId}/camps`);
                      return;
                    }
                    await axiosAuth.post(`camps/school/${schoolId}`, camps);
                    message.success("Camp created succesfully");
                    history.push(`/main/schools/${schoolId}/camps`);
                  } catch (err: any) {
                    message.error(err?.response.data.message);
                  }
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  color="#BDD000"
                  type="primary"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                  icon={<PlusOutlined />}
                  disabled={!schoolId}
                >
                  Save
                </Button>
              </Popconfirm>
            </div>
          );
        }}
        dataSource={camps?.units.sort((a, b) => {
          return a.weekNumber - b.weekNumber;
        })}
        pagination={{
          pageSize: 20,
        }}
      >
        <Column
          title="Unit"
          key="unitName"
          render={({ unitName }) => {
            return <Space size="middle">{unitName}</Space>;
          }}
          width="50%"
        />
        <Column
          title="Themes"
          key="name"
          dataIndex="name"
          render={(name, id: number) => (
            <Space key={id} size="middle">
              {name}
            </Space>
          )}
          width="30%"
        />
      </Table>
      <Row>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            style={{
              backgroundColor: "#E50A5C",
              borderColor: "#E50A5C",
              marginTop: 20,
              marginRight: 16,
            }}
            onClick={changeTabs}
          >
            Back
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default SummaryPanel;
