import { IOtherFeesData } from "../OtherFees";

export interface IRecord {
  id: number;
  feeName: string;
  amount: number;
}

interface IParsedOtherFee {
  name: string;
  data: IRecord[];
}

export interface IParsedOtherFeesData {
  perTerm: IParsedOtherFee;
  oneTime: IParsedOtherFee;
}

export const parseOtherFees = (otherFeesData: IOtherFeesData[]): IParsedOtherFeesData => {
  const parsedData: IParsedOtherFeesData = {
    perTerm: {
      name: "Per term",
      data: [],
    },
    oneTime: {
      name: "One time only",
      data: [],
    },
  };

  for (const item of otherFeesData) {
    const { id, name, amount, paymentPeriod } = item;
    const feeData: IRecord = {
      id,
      feeName: name,
      amount,
    };

    if (paymentPeriod === "PER_TERM") {
      parsedData.perTerm.data.push(feeData);
    } else if (paymentPeriod === "ONE_TIME") {
      parsedData.oneTime.data.push(feeData);
    }
  }

  return parsedData;
};
