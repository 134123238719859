import { Button, Col, Radio, Row, message } from "antd";
import { Form, Formik } from "formik";
import Text from "antd/lib/typography/Text";
import {
  ChildFinanceConfig,
  ChildPreviewFinanceConfig,
  DiscountCategory,
  getChildFinanceConfig,
} from "types/rest/child";
import { Spacer } from "components";
import { axiosAuth } from "helpers";
import { FeeContainer, FeeMenuTitle } from "../../styles";
import FeeConfigurationDetail from "../FeeConfigurationDetail/FeeConfigurationDetail";
import { FeeConfigurationBoolean, FeeConfigurationDiscounts } from "..";
import filterDiscountByCategory from "../../helpers/filterDiscountByCategory";

type Props = {
  financeConfig: ChildFinanceConfig;
  schoolId: number;
  selectedKidId: number;
  revalidate: any;
  shouldDisableSave: boolean;
  pickedTerm: number | null;
  configuration?: ChildPreviewFinanceConfig["financeConfigLookups"];
};

const FeeConfiguration = ({
  configuration,
  financeConfig,
  schoolId,
  selectedKidId,
  revalidate,
  shouldDisableSave,
  pickedTerm,
}: Props) => {
  const {
    annualFees = [],
    campFees = [],
    otherFees = [],
    foodFees = [],
    transportFees = [],
    materialFees = [],
    discounts = [],
  } = configuration || {};

  const updateFeeConfiguration = async (
    values: ChildFinanceConfig & {
      discounts: { corporate: null | number; sibling: null | number; other: null | number };
    }
  ) => {
    const { discountIds, discounts, ...rest } = values;
    const newDiscountIds = Object.values(discounts).filter(item => item !== null);

    try {
      await axiosAuth.put(getChildFinanceConfig(schoolId, selectedKidId), {
        ...rest,
        discountIds: newDiscountIds,
      });
      revalidate();
      message.success("Fee configuration updated successfully");
    } catch (e) {
      message.error("Update fee configuration failed, please try again later, or contact support.");
    }
  };

  console.log(configuration);

  const filteredDiscounts = discounts?.filter(item => financeConfig.discountIds?.includes(item.id));

  const siblingDiscounts = filterDiscountByCategory(filteredDiscounts, DiscountCategory.Sibling);
  const otherDiscounts = filterDiscountByCategory(filteredDiscounts, DiscountCategory.Other);
  const corporateDiscounts = filterDiscountByCategory(filteredDiscounts, DiscountCategory.Corporate);

  const initialDiscounts = {
    sibling: siblingDiscounts.length > 0 ? siblingDiscounts[0].id : null,
    corporate: corporateDiscounts.length > 0 ? corporateDiscounts[0].id : null,
    other: otherDiscounts.length > 0 ? otherDiscounts[0].id : null,
  };

  const disabledStyles = shouldDisableSave
    ? {
        backgroundColor: "#dddddd",
        borderColor: "#dddddd",
        color: "black",
      }
    : {};

  return (
    <Formik
      initialValues={{ ...financeConfig, discounts: initialDiscounts }}
      enableReinitialize
      onSubmit={updateFeeConfiguration}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Row>
            <Col span={24}>
              <FeeContainer>
                <Text style={{ fontSize: "1.4rem", lineHeight: 1.4 }}>
                  Select how the parent will be billed to the school?
                </Text>
                <Spacer size={8} />
                <Radio.Group
                  style={{ flexDirection: "row", display: "flex" }}
                  onChange={e => setFieldValue("paymentType", e.target.value)}
                  value={values.paymentType}
                >
                  <Radio value={"TERM"}>Per term</Radio>
                  <Radio value={"MONTH"}>Per month</Radio>
                </Radio.Group>
              </FeeContainer>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FeeMenuTitle level={4}>Standard</FeeMenuTitle>
              <FeeConfigurationBoolean
                setFieldValue={setFieldValue}
                fieldKey="oneTimeFee"
                title="One time fee"
                visible={configuration?.oneTimeFees}
              />
              <FeeConfigurationDetail
                title="Annual fees"
                data={annualFees}
                group="annualFeeIds"
                setFieldValue={setFieldValue}
              />
              <FeeConfigurationBoolean setFieldValue={setFieldValue} fieldKey="termFee" title="Terms Fee" />
              <FeeConfigurationDetail
                title="Camp fees"
                data={campFees}
                group="campFeeIds"
                setFieldValue={setFieldValue}
              />
              <FeeConfigurationDetail
                title="Other fees"
                data={otherFees}
                group="otherFeeIds"
                setFieldValue={setFieldValue}
              />
              <FeeConfigurationDiscounts discounts={discounts} setFieldValue={setFieldValue} />
            </Col>
            <Col span={12}>
              <FeeMenuTitle level={4}>Optional</FeeMenuTitle>
              <FeeConfigurationDetail
                title="Food"
                data={foodFees}
                group="foodFeeIds"
                setFieldValue={setFieldValue}
              />
              <FeeConfigurationDetail
                title="Transport"
                data={transportFees}
                group="transportFeeIds"
                setFieldValue={setFieldValue}
              />
              <FeeConfigurationDetail
                title="Materials"
                data={materialFees}
                group="materialFeeIds"
                setFieldValue={setFieldValue}
              />
            </Col>
          </Row>

          <Row justify="end">
            <Button
              type="primary"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
                ...disabledStyles,
              }}
              disabled={shouldDisableSave}
              htmlType="submit"
            >
              Save
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default FeeConfiguration;
