import React, { useState } from "react";
import { Button, Card, Form, message, Table, Typography } from "antd";
import { EditTwoTone, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  AnnualFeeItem,
  AnnualFeeParsedResponse,
  AnnualFeeType,
  getDeleteAnnualFeeUrl,
  getUpdateAnnualFeeUrl,
} from "types";
import { useForm } from "antd/es/form/Form";

import { DeleteButton } from "components";
import { axiosAuth } from "helpers";
import CustomModal from "components/CustomModal";
import AnnualModal from "../AnnualModal";
import { EditableCell } from "../EditableCell/EditableCell";

const { Text } = Typography;

interface Props {
  fee: AnnualFeeParsedResponse | null;
  type: AnnualFeeType;
  hasRegistretionFee: boolean;
  revalidate: () => Promise<boolean>;
  schoolId?: string;
  schoolCurrency?: string;
}

const feesTableTitles = {
  [AnnualFeeType.MEDICAL_FEE]: "Medical fee",
  [AnnualFeeType.OTHERS_FEE]: "Other fee",
  [AnnualFeeType.RECURRING_REGISTRATION_FEE]: "Recurring registration / enrollment / application fee",
};

const feesModalTitles = {
  [AnnualFeeType.MEDICAL_FEE]: "Add medical fee",
  [AnnualFeeType.OTHERS_FEE]: "Add other fee",
  [AnnualFeeType.RECURRING_REGISTRATION_FEE]: "Add registration / enrollment / application fee",
};

const feesTitles = {
  [AnnualFeeType.MEDICAL_FEE]: "MEDICAL FEE",
  [AnnualFeeType.RECURRING_REGISTRATION_FEE]: "ANNUAL REGISTRATION, APPLICATION OR ENROLLMENT FEE",
};

const AnnualFee = ({ fee, type, hasRegistretionFee, schoolId, revalidate, schoolCurrency }: Props) => {
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingKey, setEditingKey] = useState<number | null>(null);

  const onFinish = async () => {
    setIsModalVisible(false);
    revalidate();
  };

  const isAddingDisable = (type !== AnnualFeeType.OTHERS_FEE && !!fee) || !hasRegistretionFee;

  const isEditing = (record: AnnualFeeItem) => record.id === editingKey;

  const cancel = () => {
    setEditingKey(null);
  };

  const edit = (record: Partial<AnnualFeeItem> & { id: React.Key }) => {
    form.setFieldsValue({ name: "", amount: "", ...record });
    setEditingKey(record.id);
  };

  const save = async (record: AnnualFeeItem) => {
    try {
      const row = (await form.validateFields()) as AnnualFeeItem;

      if (!schoolId) return;
      const body = {
        amount: row.amount,
      };
      await axiosAuth.put(getUpdateAnnualFeeUrl(schoolId, record.id), body);
      setEditingKey(null);
      await revalidate();
      message.success("Annul fee has been updated");
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const deleteFee = async (id: number) => {
    if (!hasRegistretionFee || !schoolId) return;
    await axiosAuth.delete(getDeleteAnnualFeeUrl(schoolId, id));
    await revalidate();
  };

  const columns = [
    {
      title: "title",
      dataIndex: "name",
      width: "35%",
      editable: false,
      render: (_: any, record: AnnualFeeItem) => {
        if (record.name === AnnualFeeType.RECURRING_REGISTRATION_FEE)
          return <span>{feesTitles[record.name]}</span>;
        if (record.name === AnnualFeeType.MEDICAL_FEE) return <span>{feesTitles[record.name]}</span>;

        return record.name;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "35%",
      editable: true,
      render: (_: any, record: AnnualFeeItem) => {
        return (
          <span>
            {record.amount} {schoolCurrency}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_: any, record: AnnualFeeItem) => {
        const editable = isEditing(record);
        return editable ? (
          <RowRightContainer>
            <Button
              color="#BDD000"
              type="primary"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
                marginRight: 8,
              }}
              onClick={async () => save(record)}
            >
              Save
            </Button>
            <Button
              color="#E50A5C"
              type="primary"
              style={{
                backgroundColor: "#E50A5C",
                borderColor: "#E50A5C",
              }}
              onClick={cancel}
            >
              Cancel
            </Button>
          </RowRightContainer>
        ) : (
          <RowRightContainer>
            <Button
              type="text"
              icon={<EditTwoTone twoToneColor="#00A1E1" />}
              style={{ marginRight: "10px", backgroundColor: "transparent" }}
              onClick={() => edit(record)}
              disabled={!!editingKey || !hasRegistretionFee}
            />
            {type === AnnualFeeType.OTHERS_FEE && (
              <DeleteButton
                onSubmit={() => deleteFee(record.id)}
                type="icon"
                disabled={!hasRegistretionFee}
              />
            )}
          </RowRightContainer>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AnnualFeeItem) => ({
        record,
        inputType: col.dataIndex === "amount" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Card
        style={{
          marginTop: type === AnnualFeeType.RECURRING_REGISTRATION_FEE ? 20 : 100,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Text style={{ color: "#E8246D", fontWeight: "bold" }}>{feesTableTitles[type]}</Text>
          <Button
            color="#BDD000"
            type="primary"
            style={{
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
              alignSelf: "flex-end",
              position: "absolute",
              right: 20,
              top: 20,
            }}
            icon={<PlusOutlined />}
            disabled={isAddingDisable}
            onClick={() => setIsModalVisible(true)}
          >
            {type === AnnualFeeType.OTHERS_FEE ? "Add another" : "Add"}
          </Button>
        </div>
      </Card>
      <Form form={form}>
        <Table
          dataSource={fee?.items || []}
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
              wrapper: ({ children, ...rest }: any) => {
                return !fee?.items ? (
                  <>
                    <div
                      style={{
                        backgroundColor: "white",
                        height: 100,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  </>
                ) : (
                  <tbody {...rest}>{children}</tbody>
                );
              },
            },
          }}
          columns={mergedColumns}
        />
      </Form>
      <CustomModal
        title={feesModalTitles[type]}
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSubmit={() => setIsModalVisible(false)}
      >
        <AnnualModal feeType={type} schoolId={schoolId ?? ""} onFinish={onFinish} />
      </CustomModal>
    </>
  );
};

export default AnnualFee;

const RowRightContainer = styled.div`
  display: flex;
  justify-content: end;
  align-content: flex-end;
`;
