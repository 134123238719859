import { Button } from "antd";
import Title from "antd/lib/typography/Title";
import { PlusOutlined } from "@ant-design/icons";

type Props = {
  title: string;
  onClick: () => void;
  buttonProps: {
    text: string;
    disabled?: boolean;
    loading?: boolean;
  };
};

const TaxesHeader = ({ title, onClick, buttonProps: { text, disabled = false, loading = false } }: Props) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#FFF",
          paddingBlock: 28,
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* From design perspective it should be the same as the button below to make sure equal paddings */}

        <Button
          style={{
            backgroundColor: "#BDD000",
            borderColor: "#BDD000",
            color: "white",
            marginLeft: 16,
            visibility: "hidden",
          }}
          icon={<PlusOutlined />}
          loading={loading}
        >
          {text}
        </Button>

        <Title
          level={3}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          {title}
        </Title>
        <Button
          style={{
            backgroundColor: "#BDD000",
            borderColor: "#BDD000",
            color: "white",
            marginRight: 16,
          }}
          icon={<PlusOutlined />}
          onClick={onClick}
          loading={loading}
          disabled={disabled}
        >
          {text}
        </Button>
      </div>
    </>
  );
};

export default TaxesHeader;
