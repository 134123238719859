import { FC, useEffect, useState } from "react";
import { Modal, Button, Typography, Form, Input, Select, Row, Checkbox, Col, message } from "antd";
import { Spacer, TimePicker } from "components";
import dayjs from "dayjs";
import { axiosAuth } from "helpers";
import { ErrorMessage } from "enums";

const { Title } = Typography;

export type InitialData = {
  title: string;
  code: string;
  status: string | number;
  workDays: string[];
  timeEnd: string;
  timeStart: string;
  id: number;
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
  schoolId?: string;
  afterSubmit: () => Promise<boolean>;
};

export const TermTimesModal: FC<Props> = ({ isVisible, onClose, initialData, schoolId, afterSubmit }) => {
  const [isPending, setIsPending] = useState(false);
  const [form] = Form.useForm();
  const title = initialData ? "Update time" : "Create time";
  const initialValues = {
    ...initialData,
    time:
      initialData?.timeStart && initialData.timeEnd
        ? [dayjs(initialData?.timeStart, "HH:mm"), dayjs(initialData?.timeEnd, "HH:mm")]
        : undefined,
  };

  const initialValuesString = JSON.stringify(initialValues);

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [form, initialValuesString]);

  const handleModalClose = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = async (values: any) => {
    setIsPending(true);
    const { time, selectedDays, ...rest } = values;
    const [basicTimeStart, basicTimeEnd] = time;
    let basicDays: Record<string, number> = {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    };
    selectedDays.forEach((day: string) => {
      basicDays[day] = 1;
    });
    try {
      if (initialData) {
        await axiosAuth.post(`time/update/${initialData.id}`, {
          timeStart: dayjs(basicTimeStart).format("HH:mm"),
          timeEnd: dayjs(basicTimeEnd).format("HH:mm"),
          ...basicDays,
          ...rest,
          schoolId,
        });
      } else {
        await axiosAuth.post(`time/create/${schoolId}`, {
          timeStart: dayjs(basicTimeStart).format("HH:mm"),
          timeEnd: dayjs(basicTimeEnd).format("HH:mm"),
          ...basicDays,
          ...rest,
        });
      }
      await afterSubmit();
      const modalMessage = initialData ? "Time has been modified" : "Time has been created";
      message.success(modalMessage);
      form.resetFields();
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      setIsPending(false);
      onClose();
    }
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          {title}
        </Title>
      }
      open={isVisible}
      onCancel={handleModalClose}
      footer={false}
    >
      <Form name="basic" initialValues={initialValues ?? {}} onFinish={onFinish} form={form}>
        <Row>
          <Form.Item
            label="Name"
            name="name"
            style={{ flex: 1, marginRight: "20px" }}
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            style={{ flex: 1 }}
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <Select>
              <Select.Option value={10}>Active</Select.Option>
              <Select.Option value={0}>Inactive</Select.Option>
            </Select>
          </Form.Item>
        </Row>
        <Spacer size={10} />
        <Form.Item label="Time" name="time" rules={[{ required: true, message: ErrorMessage.REQUIRED }]}>
          <TimePicker style={{ width: "100%" }} format="HH:mm" />
        </Form.Item>
        <Spacer size={10} />
        <Form.Item
          label="Days"
          name="selectedDays"
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          style={{ flex: 1 }}
        >
          <Checkbox.Group>
            <Row>
              <Col span={8}>
                <Checkbox value="monday" style={{ lineHeight: "32px" }}>
                  Monday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="tuesday" style={{ lineHeight: "32px" }}>
                  Tuesday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="wednesday" style={{ lineHeight: "32px" }}>
                  Wednesday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="thursday" style={{ lineHeight: "32px" }}>
                  Thursday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="friday" style={{ lineHeight: "32px" }}>
                  Friday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="saturday" style={{ lineHeight: "32px" }}>
                  Saturday
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="sunday" style={{ lineHeight: "32px" }}>
                  Sunday
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Spacer size={10} />
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isPending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
