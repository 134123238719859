import { Button, Col, Form, Row, Select, Typography, Upload, message } from "antd";
import { Spacer } from "components/UI";
import { FC, useState } from "react";
import {
    PlusOutlined,
    UploadOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditTwoTone,
    FileOutlined,
} from "@ant-design/icons";
import { DocumentType } from "screens/Documents/Documents";
import DocumentModal, { InitialData } from "screens/Documents/DocumentModal";
import Text from "antd/lib/typography/Text";
import { useForm } from "antd/lib/form/Form";
import { axiosAuth, normFile } from "helpers";
import { allDocumentTypes } from "const";
import DeleteButton from "components/DeleteButton";
import { ErrorMessage } from "enums";

const { Title } = Typography;

type Props = {
    documentsTypes: {
        label: string;
        value: number;
    }[];
    documentsList: DocumentType[];
    mandatoryDocuments?: {
        label: string;
        value: number;
    }[];
    uploadId: number;
    userType: string;
    afterSubmit: () => Promise<void | boolean>;
};

const Documents: FC<Props> = ({
    documentsTypes,
    documentsList,
    uploadId,
    userType,
    afterSubmit,
    mandatoryDocuments = [],
}) => {
    const [isUploadDocumentPending, setIsUploadDocumentPending] = useState(false);
    const [form] = useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [initialData, setInitialData] = useState<InitialData | null>(null);

    const documentValues: Record<number, string> = documentsTypes.reduce((prev, next) => {
        return {
            ...prev,
            [next.value]: next.label,
        };
    }, {});

    const withMandatoryDocuments = mandatoryDocuments.length > 0;

    const handleSubmit = async (values: any) => {
        try {
            setIsUploadDocumentPending(true);
            const promises = values.documents.map(async (item: any) => {
                let bodyFormData = new FormData();
                bodyFormData.append("document", item.originFileObj);
                bodyFormData.append("type", `${item.documentType}`);
                bodyFormData.append("status", "10");
                bodyFormData.append("fileName", item.name);
                bodyFormData.append(`${userType}Id`, `${uploadId}`);
                return await axiosAuth.post("document/create", bodyFormData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
            });
            await Promise.all(promises);
            await afterSubmit();
            setTimeout(() => {
                form.resetFields();
            }, 0);
            setIsUploadDocumentPending(false);

            message.success("documents added successfully");
        } catch (error) {
            message.error("Error when adding documents");
        }
    };

    return (
        <>
            <Form
                form={form}
                autoComplete="off"
                onFinish={handleSubmit}
                style={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    overflow: "hidden",
                    boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
                    padding: "24px",
                }}
            >
                <Title level={4} style={{ color: "#E50A5C", margin: "0px", textAlign: "center" }}>
                    Documents
                </Title>
                {withMandatoryDocuments && (
                    <>
                        <Spacer size={10} />
                        <Text style={{ color: "#E50A5C", margin: "0px", textAlign: "center" }}>
                            You must upload these scanned documents before you can assign your teacher or
                            assistant teacher to a classroom
                        </Text>
                    </>
                )}

                <Spacer size={40} />
                {withMandatoryDocuments && (
                    <>
                        <Row>
                            {mandatoryDocuments.map(({ value }) => (
                                <>
                                    <Col span={15}>{documentValues[value]}</Col>
                                    <Col span={9}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Text style={{ color: "#E50A5C" }}>Required*</Text>
                                        </div>
                                    </Col>
                                </>
                            ))}
                        </Row>
                        <Spacer />
                    </>
                )}
                <Row>
                    {documentsList.map(({ fileName, id, type, status, file }) => (
                        <>
                            <Col span={15}>{documentValues[type]}</Col>
                            <Col span={9}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            maxWidth: "200px",
                                        }}
                                    >
                                        <FileOutlined style={{ marginRight: "5px" }} />
                                        <Text ellipsis={{ tooltip: true }} style={{ width: "100%" }}>
                                            {fileName}
                                        </Text>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignContent: "flex-end",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <a
                                            rel="noreferrer"
                                            href={file}
                                            target="_blank"
                                            style={{
                                                marginRight: "20px",
                                                color: "#00A1E1",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "1.6rem",
                                            }}
                                        >
                                            <DownloadOutlined />
                                        </a>
                                        <Button
                                            type="text"
                                            icon={<EditTwoTone twoToneColor="#00A1E1" />}
                                            style={{ marginRight: "10px" }}
                                            onClick={() => {
                                                const initialData: InitialData = {
                                                    status,
                                                    type: allDocumentTypes[
                                                        type as keyof typeof allDocumentTypes
                                                    ],
                                                    userType,
                                                    uploadId,
                                                    documentId: id,
                                                };
                                                setInitialData(initialData);
                                                setTimeout(() => {
                                                    setIsModalVisible(true);
                                                }, 0);
                                            }}
                                        />
                                        <DeleteButton
                                            type="icon"
                                            onSubmit={async () => {
                                                await axiosAuth.get(`document/delete/${id}`);
                                                await afterSubmit();
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </>
                    ))}
                </Row>
                <Spacer />
                <Form.List name="documents">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <div
                                    key={field.key}
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        alignContent: "center",
                                        flexDirection: "row",
                                        position: "relative",
                                        marginBottom: "35px",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Form.Item
                                        shouldUpdate
                                        {...field}
                                        name={[field.name, "documentType"]}
                                        fieldKey={[field.fieldKey!, "documentType"]}
                                        rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                                    >
                                        <Select options={documentsTypes} style={{ width: 200 }} />
                                    </Form.Item>
                                    <Form.Item noStyle shouldUpdate={true}>
                                        {({ getFieldValue, setFieldsValue }) => {
                                            const documents = getFieldValue("documents");
                                            return (
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "originFileObj"]}
                                                    valuePropName="fileList"
                                                    style={{
                                                        marginBottom: "0px",
                                                        marginRight: "20px",
                                                    }}
                                                    getValueFromEvent={normFile}
                                                    rules={[
                                                        { required: true, message: ErrorMessage.REQUIRED },
                                                    ]}
                                                >
                                                    <Upload
                                                        showUploadList={false}
                                                        beforeUpload={() => false}
                                                        onChange={(info) => {
                                                            const newFilesList = normFile(info);
                                                            const newFiles = documents.map(
                                                                (item: any, index: number) => {
                                                                    return {
                                                                        ...item,
                                                                        ...newFilesList[index],
                                                                    };
                                                                }
                                                            );
                                                            setFieldsValue({
                                                                documents: newFiles,
                                                            });
                                                        }}
                                                    >
                                                        <Button icon={<UploadOutlined />}>
                                                            Select document
                                                        </Button>
                                                    </Upload>
                                                    <Text
                                                        ellipsis
                                                        style={{
                                                            position: "absolute",
                                                            top: "100%",
                                                            left: "0%",
                                                            width: "150px",
                                                        }}
                                                    >
                                                        {documents[field.name]?.name}
                                                    </Text>
                                                    <DeleteOutlined
                                                        onClick={() => {
                                                            remove(field.name);
                                                        }}
                                                        style={{ color: "#E50A5C", marginLeft: "20px" }}
                                                    />
                                                </Form.Item>
                                            );
                                        }}
                                    </Form.Item>
                                </div>
                            ))}
                            <Form.Item
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button type="dashed" onClick={add} block icon={<PlusOutlined />}>
                                    Add document
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Row justify="end">
                    <Form.Item>
                        <Button
                            loading={isUploadDocumentPending}
                            type="primary"
                            htmlType="submit"
                            style={{
                                backgroundColor: "#BDD000",
                                borderColor: "#BDD000",
                            }}
                        >
                            Save new files
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
            <DocumentModal
                isVisible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                initialData={initialData}
                afterSubmit={afterSubmit}
            />
        </>
    );
};
export default Documents;
