import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Card, Typography, Alert } from "antd";
import Logo from "img/logo3.png";
import { CustomCheckbox, Footer, Spacer } from "components";
import { axios } from "helpers/axios";
import { getLoginUrl, LoginBody } from "types";
import { useAppDispatch, useAppSelector } from "store";
import { useHistory } from "react-router";
import { ErrorMessage, StorageKeys } from "enums";
import ResetPasswordModal from "components/ResetPasswordModal";
import { fetchAccountData } from "store/user";
import { mainAdminPanelRoles } from "const/access";

const { Title } = Typography;

const Login = () => {
  const { role, basicSchoolId } = useAppSelector(store => store.user);
  const [isResetPasswordModalVisible, setIsResetPasswordModalVisible] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [isLoginPending, setIsLoginPending] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onFinish = async ({ email, password }: any) => {
    setIsLoginPending(true);
    setLoginError(null);
    const body: LoginBody = {
      email,
      password,
    };
    const res = await (await axios.post(getLoginUrl(), body)).data;

    if ("message" in res) {
      setLoginError(res.message);
    } else {
      const { accessToken, refreshToken } = res;
      localStorage.setItem(StorageKeys.AUTH_TOKEN, accessToken);
      localStorage.setItem(StorageKeys.REFRESH_TOKEN_KEY, refreshToken);
      await dispatch(fetchAccountData());
      history.replace("/main/schools");
    }
    setIsLoginPending(false);
  };

  useEffect(() => {
    if (role) {
      if (mainAdminPanelRoles.includes(role)) {
        history.replace("/main/schools");
      } else {
        history.replace(`/main/schools/${basicSchoolId}/kids`);
      }
    }
  }, [role, history, basicSchoolId]);

  return (
    <>
      <Row justify="center" align="middle" style={{ height: "80vh", flexDirection: "column" }}>
        <Col>
          <img src={Logo} alt="Kipina logo" style={{ marginBottom: "4rem" }} />
        </Col>
        <Col>
          <Card style={{ borderLeft: "5px solid #E50A5C", width: "50rem" }}>
            <Title
              level={4}
              style={{
                color: "#E50A5C",
                textAlign: "left",
                marginBottom: "2rem",
              }}
            >
              Sign in
            </Title>
            <Form
              name="basic"
              initialValues={{
                remember: true,
                email: "",
                password: "",
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: ErrorMessage.REQUIRED },
                  { type: "email", message: ErrorMessage.REQUIRED },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="email" />
              </Form.Item>
              <Spacer size={10} />
              <Form.Item
                name="password"
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input.Password visibilityToggle placeholder="password" />
              </Form.Item>
              <Spacer size={10} />
              <Row justify="space-between">
                <Col>
                  <Form.Item name="remember" valuePropName="checked">
                    <CustomCheckbox color="#BDD000">Remember me</CustomCheckbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    type="link"
                    style={{ paddingRight: 0 }}
                    onClick={() => setIsResetPasswordModalVisible(true)}
                  >
                    Forgot Password
                  </Button>
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  <Form.Item style={{ marginBottom: "0px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      color="#BDD000"
                      style={{
                        backgroundColor: "#BDD000",
                        borderColor: "#BDD000",
                      }}
                      loading={isLoginPending}
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Spacer />
              {loginError && <Alert message={loginError} type="error" />}
            </Form>
          </Card>
        </Col>
      </Row>
      <Footer />
      <ResetPasswordModal
        isVisible={isResetPasswordModalVisible}
        onClose={() => setIsResetPasswordModalVisible(false)}
      />
    </>
  );
};

export default Login;
