import { FC } from 'react';
import styled from 'styled-components';
import carerImg from '../img/placeholder/carer.png';
import schoolImg from '../img/placeholder/school.png';
import kidImg from '../img/placeholder/kid.png';
import defaultImg from '../img/placeholder/kid.png';

const placeholders = {
  carer: carerImg,
  schoolImg: schoolImg,
  kidImg: kidImg,
  default: defaultImg,
};

type Props = {
  src: string;
  type?: keyof typeof placeholders;
  size?: number;
};

const CustomImage: FC<Props> = ({ src, type = 'default', size = 20 }) => {
  return <StyledImgContainer src={src} type={type} size={size} />;
};
export default CustomImage;

const StyledImgContainer = styled.div<{
  src: string;
  type: keyof typeof placeholders;
  size: number;
}>`
  background-size: cover;
  width: 100%;
  height: ${({ size }) => `${size}rem`};
  background-position: center;
  background-image: ${({ src, type }) =>
    `url(${src}), url(${placeholders[type]})`};
`;
