import { Button, Space, Table, Typography } from 'antd';
import { DeleteButton, Spacer } from 'components';
import { EditTwoTone, DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { FC, useMemo, useState } from 'react';
import useSWR from 'swr';
import DevelopmentItemModal from './DevelopmentItemModal';
import { axiosAuth } from 'helpers';

const { Column } = Table;
const { Title } = Typography;

type Props = {
  itemId: number | null;
};

type DevelopmentItemType = {
  id: number;
  rank: number;
  status: number;
  type: number;
  updatedAt: number;
  updatedBy: number;
  createdAt: number;
  createdBy: number;
  developmentItemLang: {
    developmentItemLang: number;
    id: number;
    lang: string;
    name: string;
  }[];
  name?: string;
};

const DevelopmentItem: FC<Props> = ({ itemId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<{} | null>(null);

  const { data, revalidate } = useSWR<DevelopmentItemType[]>(
    itemId ? `development-item/${itemId}` : null
  );

  const normalizedData = useMemo(
    () =>
      data?.map((item) => ({
        ...item,
        name: item.developmentItemLang[0].name,
      })),
    [data]
  );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title
          level={3}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          Skill Indicators
        </Title>
        <Button
          color="#BDD000"
          type="primary"
          style={{
            backgroundColor: '#BDD000',
            borderColor: '#BDD000',
          }}
          icon={<PlusOutlined />}
          onClick={() => {
            setInitialData(null);
            setTimeout(() => {
              setIsModalVisible(true);
            }, 0);
          }}
        >
          Add new
        </Button>
      </div>
      <Spacer />
      <Table dataSource={normalizedData} pagination={false}>
        <Column
          title="rank"
          key="rank"
          dataIndex="rank"
          render={(rank: string) => <Space size="middle">{rank}</Space>}
          width="10%"
        />
        <Column
          title="name"
          key="name"
          dataIndex="name"
          render={(name: string) => <Space size="middle">{name}</Space>}
          width="50%"
        />
        <Column
          title="status"
          key="status"
          dataIndex="status"
          render={(status: number) => (
            <Space size="middle">{status === 10 ? 'Active' : 'Inactive'}</Space>
          )}
          width="10%"
        />
        <Column
          title=""
          key="actions"
          dataIndex=""
          render={(data: DevelopmentItemType) => {
            const names: Record<string, string> = {};
            data.developmentItemLang.forEach((item) => {
              names[item.lang] = item.name;
            });
            return (
              <div
                style={{
                  display: 'flex',
                  alignContent: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  type="text"
                  icon={<EditTwoTone twoToneColor="#00A1E1" />}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    setInitialData({
                      ...names,
                      ...data,
                    });
                    setTimeout(() => {
                      setIsModalVisible(true);
                    }, 0);
                  }}
                />
                <DeleteButton
                  onSubmit={async () => {
                    await axiosAuth.get(`development-item/delete/${data.id}`);
                    await revalidate();
                  }}
                  type="icon"
                />
              </div>
            );
          }}
          width="10%"
        />
      </Table>
      <Spacer size={40} />
      <DevelopmentItemModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
      />
    </>
  );
};

export default DevelopmentItem;
