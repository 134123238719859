import { Button, Card, Space, Spin, Table, Typography, Form, Select, Row, Col, message } from "antd";
import { EditTwoTone, FilePdfOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import useSWR from "swr";
import { groupName, groupsList } from "const/groupName";
import { Container, CustomLabel } from "components";
import TermUnitsModal from "./TermUnitsModal";

const { Title } = Typography;

interface UnitsResponse {
  id: number;
  name: string;
  unitName: string;
  weekNumber: number;
  pdfFile: string;
  classType: number;
}

const termsPdf = process.env.REACT_APP_S3_TERMS || "";
const campsPdf = process.env.REACT_APP_S3_CAMPS || "";

const useUnitsData = (selectedType: string, id: number) => {
  const query =
    selectedType === "Terms"
      ? `/terms/v2/units/class/${id}`
      : selectedType === "Camps"
      ? `/camps-units/class/${id}`
      : null;
  const { data, error, revalidate } = useSWR<UnitsResponse[]>(query);

  return {
    data,
    isLoading: !error && !data && selectedType.length !== 0,
    isError: error,
    revalidate,
  };
};

const typeList = [
  { label: "Terms", value: "Terms" },
  { label: "Camps", value: "Camps" },
];

const TermUnits = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedClassId, setSelectedClasssId] = useState<number>(0);
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);
  const [selectedUnitName, setSelectedUnitName] = useState("");
  const { data, isLoading, isError, revalidate } = useUnitsData(selectedType, selectedClassId);

  const [form] = useForm();

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const onSubmit = () => {
    setIsModalVisible(false);
    revalidate();
    message.success("File has been uploaded");
  };

  const handleSubmitForm = (data: { type: string; units: number }) => {
    setSelectedType(data.type);
    setSelectedClasssId(data.units);
    setSelectedUnitId(null);
    setSelectedUnitName("");
  };

  return (
    <Spin spinning={isLoading}>
      <Card>
        <Form form={form} onFinish={handleSubmitForm}>
          <Row gutter={12}>
            <Col span={6}>
              <Form.Item
                name="type"
                rules={[{ required: true, message: "Required" }]}
                labelCol={{ span: 24 }}
                label={<CustomLabel>Type </CustomLabel>}
              >
                <Select
                  showSearch
                  allowClear
                  options={typeList}
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="units"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: "Required" }]}
                label={<CustomLabel>Class name </CustomLabel>}
              >
                <Select
                  showSearch
                  allowClear
                  options={groupsList}
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card>
        <Container>
          <Table
            title={() => {
              return (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Title
                    level={3}
                    style={{
                      color: "#E50A5C",
                      paddingBottom: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    {selectedType} {selectedType && " - " + groupName[selectedClassId]}
                  </Title>
                </div>
              );
            }}
            dataSource={data || []}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Weekly Themes"
              key="name"
              dataIndex="name"
              render={name => <Space size="middle">{name}</Space>}
              width="30%"
            />

            <Column
              title="Unit"
              key="unitName"
              dataIndex="unitName"
              render={unitName => <Space size="middle">{unitName}</Space>}
              width="30%"
            />
            <Column
              title="Week"
              key="weekNumber"
              dataIndex="weekNumber"
              render={weekNumber => <Space size="middle">{weekNumber}</Space>}
              width="20%"
            />
            <Column
              title="PDF"
              key="pdfFile"
              render={data => {
                if (!selectedType) return;
                if (!data.pdfFile) return;
                const fileId = JSON.parse(data.pdfFile)["en"];

                if (fileId) {
                  return (
                    <a
                      href={selectedType === "Terms" ? termsPdf + fileId : campsPdf + fileId}
                      target="_blank"
                    >
                      <FilePdfOutlined />
                    </a>
                  );
                }

                return null;
              }}
              width="20%"
            />
            <Column
              title=""
              width="10%"
              render={data => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        setIsModalVisible(true);
                        setSelectedUnitId(data.id);
                        setSelectedUnitName(data.unitName);
                      }}
                    />
                  </div>
                );
              }}
            />
          </Table>
          <TermUnitsModal
            isVisible={isModalVisible}
            onClose={closeModal}
            selectedClass={groupName[selectedClassId]}
            id={selectedUnitId}
            selectedType={selectedType}
            unitName={selectedUnitName}
            onSubmit={onSubmit}
          />
        </Container>
      </Card>
    </Spin>
  );
};

export default TermUnits;
