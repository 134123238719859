import { FC, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  Select,
  Row,
  Col,
  Spin,
  DatePicker,
} from 'antd';
import { Spacer } from 'components';
import useSWR from 'swr';
import { axiosAuth, groupBy } from 'helpers';
import { RouteComponentProps } from 'react-router';
import dayjs from 'dayjs';
import { ErrorMessage } from 'enums';

const { Title } = Typography;
const { TextArea } = Input;

export type InitialData = {
  title: string;
  code: string;
  status: string | number;
  workDays: string[];
  id: number;
};

type PlanTemplate = {
  createdAt: number;
  createdBy: number;
  id: number;
  name: string;
  status: number;
  type: number;
  updatedAt: number;
  updatedBy: number;
}[];

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
  schoolId: number;
  afterSubmit: () => Promise<boolean>;
};

const TermsModal: FC<Props> = ({
  isVisible,
  onClose,
  initialData,
  schoolId,
  afterSubmit,
}) => {
  const [isUpdatePending, setIsUpdatePending] = useState(false);

  const { data, error } = useSWR<PlanTemplate>('plan-template');
  const [form] = Form.useForm();
  const title = initialData ? 'Update Term' : 'Create Term';

  const isPlanTemplatePending = !data && !error;

  const planTemplatesLists = useMemo(() => {
    if (!data) {
      return {};
    }
    return groupBy(
      data.map(({ name, id, type }) => ({ label: name, value: id, type })),
      (item: any) => item.type
    );
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = async (values: any) => {
    setIsUpdatePending(true);
    const { name, group, date = [], status } = values;
    const [startDate, endDate] = date;
    const typesOfTermGroup = Object.entries(group)
      .map(([key, val]) => ({
        templateId: val,
        type: parseInt(key),
      }))
      .filter((item) => !!item?.templateId);

    if (initialData) {
      await axiosAuth.post(`term/update/${initialData.id}`, {
        typesOfTermGroup,
        name,
        startDate: dayjs(startDate).add(1, 'day').toDate(),
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        status,
        schoolId,
      });
    } else {
      await axiosAuth.post(`term/create/${schoolId}`, {
        typesOfTermGroup,
        name,
        startDate: dayjs(startDate).add(1, 'day').toDate(),
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        status,
      });
    }
    await afterSubmit();
    setIsUpdatePending(false);
    onClose();
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Spin spinning={isPlanTemplatePending}>
        <Form
          name="basic"
          initialValues={initialData ?? {}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Row>
            <Col span={16}>
              <Form.Item
                label="Name"
                name="name"
                style={{ flex: 1, marginRight: '20px' }}
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Status" name="status">
                <Select>
                  <Select.Option value={10}>Active</Select.Option>
                  <Select.Option value={0}>Inactive</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Spacer size={10} />
          <Form.Item
            label="Date"
            name="date"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <DatePicker.RangePicker style={{ width: '100%' }} />
          </Form.Item>
          <Spacer size={10} />
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <Form.Item
                label="Swans"
                name={['group', '10']}
                labelCol={{ span: 24 }}
              >
                <Select options={planTemplatesLists[10] ?? []} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Foxes"
                name={['group', '20']}
                labelCol={{ span: 24 }}
              >
                <Select options={planTemplatesLists[20] ?? []} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Bears"
                name={['group', '30']}
                labelCol={{ span: 24 }}
              >
                <Select options={planTemplatesLists[30] ?? []} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Seals"
                name={['group', '40']}
                labelCol={{ span: 24 }}
              >
                <Select options={planTemplatesLists[40] ?? []} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Owls"
                name={['group', '50']}
                labelCol={{ span: 24 }}
              >
                <Select options={planTemplatesLists[50] ?? []} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Squirrels"
                name={['group', '60']}
                labelCol={{ span: 24 }}
              >
                <Select options={planTemplatesLists[60] ?? []} />
              </Form.Item>
            </Col>
          </Row>
          <Spacer size={10} />
          <Form.Item wrapperCol={{ offset: 20 }}>
            <Button
              loading={isUpdatePending}
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#BDD000',
                borderColor: '#BDD000',
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default TermsModal;
