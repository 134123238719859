import Title from "antd/lib/typography/Title";
import { Radio, RadioChangeEvent } from "antd";
import { Spacer } from "components";
import { FeeContainer } from "../../styles";
import { useField } from "formik";

type Props = {
  setFieldValue: (field: string, value: boolean, shouldValidate?: boolean | undefined) => void;
  fieldKey: string;
  title: string;
  visible?: boolean;
};

const FeeConfigurationBoolean = ({ setFieldValue, fieldKey, title, visible = true }: Props) => {
  const [field] = useField(fieldKey);

  const handleChangeValue = (e: RadioChangeEvent) => {
    setFieldValue(fieldKey, e.target.value);
  };

  if (!visible) return null;

  return (
    <FeeContainer>
      <Title level={4} style={{ lineHeight: 1, marginBlockEnd: 0 }}>
        {title}
      </Title>
      <Spacer size={8} />
      <Radio.Group
        style={{ display: "flex", flexDirection: "column" }}
        value={field.value}
        onChange={handleChangeValue}
      >
        <Radio value={true}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Yes</p>
          </div>
        </Radio>
        <Radio value={false}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>No</p>
          </div>
        </Radio>
      </Radio.Group>
    </FeeContainer>
  );
};

export default FeeConfigurationBoolean;
