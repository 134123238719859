import {
  AnnualFeeParsedResponse,
  AnnualFeeResponse,
  AnnualFeeType,
} from "types";

const parseAnnualFeesData = (
  data: AnnualFeeResponse[],
  type: AnnualFeeType
) => {
  const parsedData = data.reduce(
    (sum: AnnualFeeParsedResponse[], value: AnnualFeeResponse) => {
      if (value.type !== type) return [...sum];

      const findType = sum.findIndex((item) => item.type === value.type);

      if (findType < 0) {
        sum.push({
          type: value.type,
          items: [
            {
              id: value.id,
              name: value.name,
              amount: value.amount,
            },
          ],
        });
      } else {
        sum[findType].items.push({
          id: value.id,
          name: value.name,
          amount: value.amount,
        });
      }

      const result = [...sum];

      return result;
    },
    []
  );

  return parsedData;
};

export default parseAnnualFeesData;
