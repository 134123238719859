import { ReactNode } from "react";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";

type InputType = "number" | "text" | "select" | "date";
type SelectOptions = { label: string; value: string }[];

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: InputType;
  children: ReactNode;
  selectOptions: SelectOptions;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  selectOptions,
}) => {
  const getInput = (inputType: InputType, selectOptions: SelectOptions) => {
    switch (inputType) {
      case "number":
        return <InputNumber />;
      case "text":
        return <Input />;
      case "select":
        return <Select options={selectOptions} />;
      case "date":
        return <DatePicker format={"YYYY-MM-DD"} />;
      default:
        return <Input />;
    }
  };
  const inputNode = getInput(inputType, selectOptions);

  return (
    <td>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
