import { ColumnsType } from "antd/lib/table";
import { getUniqueSchool } from "../../helpers";
import { IGetColumnSearch } from "../../helpers/getColumnSearch";
import { ISchoolBalance } from "../FinancialsSupra";
import kidImg from "../../../../img/placeholder/kid.png";
import { Link } from "react-router-dom";
import { AddSupraPayment } from "../components";
import getSummaryItemColor from "screens/Kid/Financials/helpers/getSummaryItemColor";
import { invoiceCurrency } from "screens/Invoice/const";

interface IGetInvoiceTableColumns extends Omit<IGetColumnSearch, "dataIndex"> {
  dataSource: ISchoolBalance[];
  revalidate: () => Promise<boolean>;
}

export const getSupraInvoiceTableColumns = (args: IGetInvoiceTableColumns): ColumnsType<ISchoolBalance> => {
  return [
    {
      title: "School",
      dataIndex: "schoolName",
      filters: getUniqueSchool(args.dataSource).map(schoolName => ({ text: schoolName, value: schoolName })),
      filterSearch: true,
      onFilter: (value, record) => record.schoolName.includes(value.toString()),
      sorter: (a, b) => a.schoolName.localeCompare(b.schoolName),
      render: (_: any, { schoolImage, schoolName, schoolId }) => {
        return (
          <Link to={`/main/schools/${schoolId}/kids`} style={{ color: "#000" }}>
            <img
              style={{
                width: 20,
                height: 20,
                borderRadius: "5%",
                marginRight: 10,
                position: "relative",
                top: 4,
              }}
              src={schoolImage ? schoolImage : kidImg}
              alt="avatar"
            />
            {schoolName}
          </Link>
        );
      },
    },
    //TODO future feature
    // {
    //   title: "Partner",
    //   dataIndex: "partner",
    //   filters: getUniquePartner(args.dataSource).map(partner => ({ text: partner, value: partner })),
    //   filterSearch: true,
    //   onFilter: (value, record) => record.partner.includes(value.toString()),
    //   sorter: (a, b) => a.partner.localeCompare(b.partner),
    // },
    {
      title: "Last payment",
      dataIndex: "lastPayment",
      sorter: (a, b) => a.lastPayment - b.lastPayment,
      render: (_: any, record) =>
        record.lastPayment && (
          <span style={{ color: getSummaryItemColor(record?.lastPayment) }}>
            {record.lastPayment.toFixed(2)} {invoiceCurrency}
          </span>
        ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      sorter: (a, b) => a.balance - b.balance,
      render: (_: any, record: ISchoolBalance) =>
        record.balance && (
          <span style={{ color: getSummaryItemColor(record?.balance) }}>
            {record.balance.toFixed(2)} {invoiceCurrency}
          </span>
        ),
    },

    {
      title: "Overdue",
      dataIndex: "overdue",
      sorter: (a, b) => a.overdue - b.overdue,
      render: (_: any, record: ISchoolBalance) =>
        record.overdue && (
          <span style={{ color: getSummaryItemColor(record?.overdue) }}>
            {record.overdue.toFixed(2)} {invoiceCurrency}
          </span>
        ),
    },
    {
      title: "Add Payment",
      dataIndex: "addPayment",
      render: (_: any, { schoolId }) => {
        return <AddSupraPayment schoolId={schoolId} revalidate={args.revalidate} />;
      },
    },
  ];
};
