import { Menu } from "antd";
import { Spacer } from "components";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import FinancialCheckInformation from "../FinancialCheckInformation/FinancialCheckInformation";

const FinancialsNavigation = () => {
  const location = useLocation();
  const match = useRouteMatch<{ id: string }>();
  const { id } = match.params;

  const defaultLocation = location.pathname.split("/");
  const defaultPath = `${defaultLocation[0]}/${defaultLocation[1]}/${defaultLocation[2]}/${defaultLocation[3]}/${defaultLocation[4]}`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Menu
        defaultSelectedKeys={["oneTimeFees"]}
        mode="horizontal"
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <FinancialCheckInformation schoolId={Number(id)} />
        <Menu.Item key="oneTimeFees">
          <Link to={`${defaultPath}/oneTimeFees`}>One Time Fees</Link>
        </Menu.Item>
        <Menu.Item key="annualFees">
          <Link to={`${defaultPath}/annualFees`}>Annual Fees</Link>
        </Menu.Item>
        <Menu.Item key="termFees">
          <Link to={`${defaultPath}/termFees`}>Term Fees</Link>
        </Menu.Item>
        <Menu.Item key="campFees">
          <Link to={`${defaultPath}/campFees`}>Camp Fees</Link>
        </Menu.Item>
        <Menu.Item key="utilities">
          <Link to={`${defaultPath}/utilities`}>Food/Transport/Materials</Link>
        </Menu.Item>
        <Menu.Item key="otherFees">
          <Link to={`${defaultPath}/otherFees`}>Other Fees</Link>
        </Menu.Item>
        <Menu.Item key="discounts">
          <Link to={`${defaultPath}/discounts`}>Discounts</Link>
        </Menu.Item>
        <Menu.Item key="taxes">
          <Link to={`${defaultPath}/taxes`}>Taxes</Link>
        </Menu.Item>
      </Menu>
      <Spacer />
    </div>
  );
};

export default FinancialsNavigation;
