import { Typography } from "antd";
import { Spacer } from "components";
import { NewDiscountModal } from "../NewDiscountModal/NewDiscountModal";
import { DiscountTable } from "../DiscountTable/DiscountTable";
import { IDiscountCategory, IDiscountRecord } from "../../types";

interface Props {
  title: string;
  discountCategory: IDiscountCategory;
  dataSource: IDiscountRecord[];
  modalTitle: string;
  revalidate: () => Promise<boolean>;
  schoolId?: string;
  disabled?: boolean;
  spacerSize?: number;
}

export const DiscountSection = ({
  schoolId,
  dataSource,
  revalidate,
  title,
  discountCategory,
  modalTitle,
  disabled,
  spacerSize = 110,
}: Props) => {
  const { Title } = Typography;

  return (
    <>
      <Spacer size={spacerSize} />
      <Title
        level={3}
        style={{
          color: "#E50A5C",
          paddingBottom: "0px",
          marginBottom: "0px",
          backgroundColor: "#FFF",
          textAlign: "center",
          paddingBlock: 28,
          position: "relative",
        }}
      >
        <span>{title}</span>
        <NewDiscountModal
          schoolId={schoolId}
          revalidate={revalidate}
          title={modalTitle}
          discountCategory={discountCategory}
          disabled={disabled}
        />
      </Title>
      <DiscountTable
        dataSource={dataSource}
        revalidate={revalidate}
        schoolId={schoolId}
        discountCategory={discountCategory}
      />
    </>
  );
};
