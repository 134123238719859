import { concatedSelectOptions } from "const";
import { IDiscountData } from "../Discounts";
import { IDiscountRecord } from "../types";

export interface IParsedDiscountsData {
  siblings: IDiscountRecord[];
  corporate: IDiscountRecord[];
  other: IDiscountRecord[];
}

export const parseDiscounts = (discountsData?: IDiscountData[]): IParsedDiscountsData => {
  const parsedData: IParsedDiscountsData = {
    siblings: [],
    corporate: [],
    other: [],
  };

  if (!discountsData) return parsedData;

  for (const item of discountsData) {
    const { id, amount, discountCategory, type, name, applyTo } = item;

    const selectedCategory = concatedSelectOptions.find(option => option.value === applyTo);

    const discountData: IDiscountRecord = {
      id,
      name,
      amount,
      category: selectedCategory?.label ?? "",
      type,
    };

    switch (discountCategory) {
      case "corporate":
        parsedData.corporate.push(discountData);
        break;
      case "other":
        parsedData.other.push(discountData);
        break;
      case "sibling":
        parsedData.siblings.push(discountData);
        break;
      default:
        break;
    }
  }

  return parsedData;
};
