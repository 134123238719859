import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Typography, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import { useState } from "react";
import { IApplyToCategory, IDiscountCategory, IDiscountType } from "../../types";
import { concatedSelectOptions, siblingAndCorporateCategorySelectOptions, typeSelectOptions } from "const";

interface IFormValues {
  name: string;
  value: number;
  applyTo: IApplyToCategory;
  type: IDiscountType;
}

interface Props {
  title: string;
  schoolId?: string;
  revalidate: () => Promise<boolean>;
  discountCategory: IDiscountCategory;
  disabled?: boolean;
}

export const NewDiscountModal = ({
  title,
  schoolId,
  revalidate,
  discountCategory,
  disabled = false,
}: Props) => {
  const [form] = useForm();
  const [open, setOpen] = useState(false);
  const [chosenType, setChosenType] = useState<IDiscountType | undefined>(undefined);

  const { Title } = Typography;

  const showModal = () => {
    setOpen(true);
  };

  const onFinish = async (values: IFormValues) => {
    try {
      await axiosAuth.post(`school/${schoolId}/financials/settings/discounts`, {
        ...values,
        discountCategory,
      });

      revalidate();
      setOpen(false);
      message.success("New discount saved");
      form.resetFields();
    } catch (error) {
      message.error("Error when saving new discount");
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleTypePick = (type: IDiscountType) => {
    setChosenType(type);
  };

  const applyToSelectOptions =
    discountCategory === "other" ? concatedSelectOptions : siblingAndCorporateCategorySelectOptions;

  return (
    <>
      <Button
        disabled={disabled}
        type="primary"
        onClick={showModal}
        style={{
          backgroundColor: "#BDD000",
          borderColor: "#BDD000",
          position: "absolute",
          right: 20,
        }}
      >
        + Add new
      </Button>
      <Modal
        centered
        visible={open}
        width={850}
        onCancel={handleCancel}
        footer={null}
        title={
          <Title
            level={4}
            style={{
              color: "#E50A5C",
              paddingBottom: "0px",
              marginBottom: "0px",
            }}
          >
            {title}
          </Title>
        }
      >
        <Form name="basic" onFinish={onFinish} form={form}>
          <Row style={{ marginBottom: 10, display: "flex", justifyContent: "space-between" }}>
            <Col span={11}>
              <Form.Item
                label="Type"
                name="type"
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <Select
                  placeholder="Select discount type"
                  onChange={(value: IDiscountType) => handleTypePick(value)}
                  showSearch
                  options={typeSelectOptions}
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Value"
                name="amount"
                rules={[
                  {
                    type: "number",
                    min: 1,
                    message: "minimum value is 1",
                  },
                  {
                    type: "number",
                    max: chosenType === "percentage" ? 100 : undefined,
                    message: "maximum value for percentage is 100",
                  },
                  { required: true, message: ErrorMessage.REQUIRED },
                ]}
              >
                <InputNumber style={{ width: "100%" }} precision={0} placeholder="Enter amount/percentage" />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: 10, display: "flex", justifyContent: "space-between" }}>
            <Col span={11}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <Input placeholder="Enter discount name" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Apply to"
                name="applyTo"
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <Select
                  showSearch
                  placeholder="Select discount apply to"
                  options={applyToSelectOptions}
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
              }}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
