import { ITermFeesData } from "../TermFees";

interface IParsedTermFeesData {
  term: string;
  times: {
    id: number;
    amount: number;
    time: string;
  }[];
}

export const parseTermFees = (termFeesData: ITermFeesData[]) => {
  return termFeesData.reduce((acc: IParsedTermFeesData[], termData) => {
    const { id, amount, term, time } = termData;

    const foundIndex = acc.findIndex(x => x.term === term);
    if (foundIndex >= 0) {
      acc[foundIndex].times.push({
        id,
        amount,
        time,
      });
    } else {
      acc.push({
        term,
        times: [
          {
            id,
            amount,
            time,
          },
        ],
      });
    }
    return acc;
  }, []);
};
