import {
  ChangeUserPasswordErrorRes,
  ChangeUserPasswordSuccessRes,
  LoginErrorRes,
  LoginSuccessRes,
} from "types/models";

export const getLoginUrl = () => "auth/web/login";

export type LoginBody = {
  email: string;
  password: string;
};

export type LoginRes = LoginSuccessRes | LoginErrorRes;

export const getChangeUserPasswordUrl = () => "auth/change-password";

export type ChangeUserPasswordBody = {
  oldPassword: string;
  newPassword: string;
};

export type ChangeUserPasswordRes =
  | ChangeUserPasswordSuccessRes
  | ChangeUserPasswordErrorRes;

export const getVerifyTokenUrl = () => "auth/web/refresh";

export type VerifyTokenRes =
  | {
      statusCode: 401;
      message: "Unauthorized";
    }
  | LoginSuccessRes;
