import { Spacer } from "components";
import { CampTimeTable, TermTimeTable } from "./components";
import { ViewType } from "enums";
import { RouteComponentProps } from "react-router-dom";

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string }>;

export const Times = (props: Props) => {
  const schoolId = props.match.params.id;

  return (
    <>
      <TermTimeTable schoolId={schoolId} />
      <Spacer size={60} />
      <CampTimeTable schoolId={schoolId} />
    </>
  );
};
