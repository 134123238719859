import { UtilityCategory, UtilityTabs } from "types";

const getUtilityCategoryName = (tab: UtilityTabs) => {
  switch (tab) {
    case UtilityTabs.Food:
      return UtilityCategory.Food;
    case UtilityTabs.Materials:
      return UtilityCategory.Materials;
    case UtilityTabs.Transport:
      return UtilityCategory.Transport;
    default:
      return UtilityCategory.Food;
  }
};

export default getUtilityCategoryName;
