import React, { useState } from 'react';
import { Row, Col, Typography, Divider, Button, Modal } from 'antd';
import ReactPlayer from 'react-player';
import Logo from 'img/logo3.png';
import { Spacer } from './UI';
import BugReportModal from './BugReportModal';

const { Title } = Typography;

const Footer = () => {
  const [isReportBugModalVisible, setIsReportBugModalVisible] = useState(false);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [activeVideo, setActiveVideo] = useState<string | undefined>(undefined);

  const onModalClose = () => {
    setActiveVideo(undefined);
    setTimeout(() => {
      setIsVideoModalVisible(false);
    }, 0);
  };

  return (
    <div style={{ backgroundColor: 'white', paddingTop: '4rem' }}>
      <div
        style={{
          maxWidth: '1240px',
          display: 'block',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Row style={{ padding: '2rem 2rem' }}>
          <Col lg={6}>
            <Title level={3} style={{ textAlign: 'left', color: '#E50A5C' }}>
              My account
            </Title>
            <Divider />
            <Row justify="start">
              <Col span={24} style={{ textAlign: 'left' }}>
                <Button
                  type="link"
                  style={{
                    padding: 0,
                    color: '#95989A',
                  }}
                  onClick={() => {}}
                >
                  Log in
                </Button>
              </Col>
              <Col span={24} style={{ textAlign: 'left' }}>
                <Button
                  type="link"
                  style={{ padding: 0, color: '#95989A' }}
                  onClick={() => {
                    setIsReportBugModalVisible(true);
                  }}
                >
                  Report a bug
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Spacer size={30} />
        <Row justify="center" align="middle">
          <img
            src={Logo}
            alt="Kipina logo"
            style={{ marginBottom: '4rem', width: 90 }}
          />
        </Row>
        <Modal
          visible={isVideoModalVisible}
          footer={null}
          onCancel={onModalClose}
          bodyStyle={{ padding: 0 }}
          width={1200}
        >
          <ReactPlayer
            url={activeVideo}
            controls
            width={1200}
            height="100%"
            playing={!!activeVideo}
          />
        </Modal>
        <BugReportModal
          isVisible={isReportBugModalVisible}
          onClose={() => setIsReportBugModalVisible(false)}
        />
      </div>
    </div>
  );
};

export default Footer;
