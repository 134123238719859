import { Col, Form, Select } from "antd";
import { CustomLabel } from "components";

interface Props {
  campTimesList?: {
    label: string;
    value: number;
    type: number;
  }[];
  selectEditModeProps: {
    showArrow: boolean;
    bordered: boolean;
    disabled: boolean;
  };
}

export const CampTime = ({ campTimesList, selectEditModeProps }: Props) => {
  return (
    <Col span={6}>
      <Form.Item name="campTimeId" labelCol={{ span: 24 }} label={<CustomLabel>Camp time</CustomLabel>}>
        <Select
          showSearch
          allowClear
          options={campTimesList}
          filterOption={(input, option) =>
            (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          {...selectEditModeProps}
        />
      </Form.Item>
    </Col>
  );
};
