import { Button, Col, Form, Input, message, Row } from "antd";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import {
  AnnualFeeType,
  CreateAnnualFeeRequest,
  getCreateNewAnnualFeeUrl,
} from "types";

interface Props {
  feeType: AnnualFeeType;
  schoolId: string;
  onFinish: () => void;
}

const AnnualModal = ({ feeType, schoolId, onFinish }: Props) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    const body: CreateAnnualFeeRequest = {
      amount: values.amount,
      name: values.name || feeType,
      type: feeType,
    };

    try {
      await axiosAuth.post(getCreateNewAnnualFeeUrl(schoolId), body);
      form.resetFields();
      onFinish();
      message.success("Annul fee has been added");
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  return (
    <Form form={form} onFinish={(values) => handleSubmit(values)}>
      {feeType === AnnualFeeType.OTHERS_FEE && (
        <Row>
          <Col span={20}>
            <Form.Item
              name="name"
              label="Name this Fee: "
              labelCol={{ span: 10 }}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Input placeholder="example" />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={20}>
          <Form.Item
            name="amount"
            label="What is the amount?: "
            labelCol={{ span: 10 }}
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
          >
            <Input placeholder="example" type="number" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="text"
                htmlType="submit"
                style={{
                  backgroundColor: "#BDD000",
                  borderColor: "#BDD000",
                }}
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AnnualModal;
