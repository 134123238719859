import { Col, Form, Checkbox } from "antd";
//Does not works in test import { CustomLabel } from "components";
import CustomLabel from "../../../../components/CustomLabel";

interface Props {
  campsList?: {
    label: string;
    value: number;
  }[];
  disabled: boolean;
}

export const AddCamps = ({ campsList, disabled }: Props) => {
  return (
    <Col span={6}>
      <Form.Item name="campsId" labelCol={{ span: 24 }} label={<CustomLabel>Add Camps</CustomLabel>}>
        <Checkbox.Group
          options={campsList}
          style={{ display: "flex", flexDirection: "column" }}
          disabled={disabled}
        />
      </Form.Item>
    </Col>
  );
};
