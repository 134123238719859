import { Button, message, Space, Spin, Table, Popconfirm, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { axiosAuth } from "helpers";
import { useHistory } from "react-router-dom";
import { TermRequest } from "types/rest/terms";

interface Props {
  terms: TermRequest | null;
  changeTabs: () => void;
  schoolId?: string;
  editTermId?: string;
}

const TermSummaryPanel = ({ terms, changeTabs, schoolId, editTermId }: Props) => {
  const history = useHistory();

  const isPending = !terms;

  const handleConfirm = async () => {
    try {
      if (editTermId) {
        await axiosAuth.put(`terms-v2/${editTermId}`, terms);
        message.success("Term succesfully updated");
        history.push(`/main/schools/${schoolId}/v2/terms`);
        return;
      }
      await axiosAuth.post(`terms-v2/school/${schoolId}`, terms);
      message.success("Term created succesfully");
      history.push(`/main/schools/${schoolId}/v2/terms`);
    } catch (err: any) {
      message.error(err?.response.data.message);
    }
  };

  return (
    <Spin spinning={isPending}>
      <Table
        title={() => {
          return (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Popconfirm
                title="Are you sure to save this term"
                onConfirm={handleConfirm}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  color="#BDD000"
                  type="primary"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                  icon={<PlusOutlined />}
                  disabled={!schoolId}
                >
                  Save
                </Button>
              </Popconfirm>
            </div>
          );
        }}
        dataSource={terms?.units.sort((a, b) => {
          return a.weekNumber - b.weekNumber;
        })}
        pagination={{
          pageSize: 20,
        }}
      >
        <Column
          title="Unit"
          key="unitName"
          render={({ unitName, key }) => {
            return (
              <Space key={key} size="middle">
                {unitName}
              </Space>
            );
          }}
          width="50%"
        />
        <Column
          title="Weekly Themes"
          key="name"
          dataIndex="name"
          render={(name, id: number) => (
            <Space key={id} size="middle">
              {name}
            </Space>
          )}
          width="30%"
        />
      </Table>
      <Row>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            style={{
              backgroundColor: "#E50A5C",
              borderColor: "#E50A5C",
              marginTop: 20,
              marginRight: 16,
            }}
            onClick={changeTabs}
          >
            Back
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default TermSummaryPanel;
