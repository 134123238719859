import WarningIcon from "../../../../img/warningIcon.png";
interface Props {
  feeType: "camp" | "term";
}

export const EmptyValuesInfo = ({ feeType }: Props) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", marginLeft: 8 }}>
        <p style={{ color: "red", fontSize: 14, marginRight: 8 }}>
          {`There are empty ${feeType} amounts that need to be filled.`}
        </p>
        <img src={WarningIcon} width={20} height={20} alt="warning" />
      </div>
    </>
  );
};
