import { Button, Card, Col, Row, Spin, Switch, Typography, message } from "antd";
import generateCalendar from "antd/lib/calendar/generateCalendar";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import { Container, Spacer } from "components";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import styled from "styled-components";
import useSWR from "swr";
import { RouteComponentProps } from "react-router-dom";
import { axiosAuth } from "helpers";

const { Text, Title } = Typography;

interface IHolidayStartResponse {
  firstTerm?: string;
}

const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);

const Vacations = ({ match }: RouteComponentProps<{ id: string }>) => {
  const schoolId = match.params.id;

  const [sendingData, setSendingData] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [dates, setDates] = useState<string[]>([]);

  const { data: calendarStartDay, error } = useSWR<IHolidayStartResponse>(
    `terms-v2/school/${schoolId}/first-term`
  );

  const month = Array.from(new Array(24)).map((item, index) => {
    const startDay = calendarStartDay?.firstTerm
      ? dayjs(calendarStartDay.firstTerm).format("YYYY-MM-DD")
      : "";
    const now = dayjs().startOf("year").format("YYYY-MM-DD");
    const startDate = startDay.length > 0 ? dayjs(startDay) : dayjs(now);

    return startDate.add(index, "month");
  });

  const { data, revalidate } = useSWR<string[]>(`holiday/school/${schoolId}`);

  const isPending = !data || (!calendarStartDay && !error);

  useEffect(() => {
    if (data) {
      setDates(data);
    }
  }, [data]);

  const handleSaveHolidays = async () => {
    try {
      setSendingData(true);
      await axiosAuth.post(`holiday/school/${schoolId}`, {
        dates: dates,
      });
      revalidate();
      setSendingData(false);
      message.success("Holidays created succesfully");
    } catch (err) {
      setSendingData(false);
      message.error("Something went wrong");
    }
  };

  if (isPending) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 100,
        }}
      >
        <Spin spinning />
      </div>
    );
  }

  return (
    <Spin spinning={isPending}>
      <Card>
        <Container style={{ display: "flex", justifyContent: "space-between" }}>
          <Title level={4} style={{ color: "#E50A5C", margin: "0px" }}>
            Holidays
          </Title>
          <Button
            loading={sendingData}
            type="primary"
            style={{
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
            }}
            onClick={handleSaveHolidays}
          >
            Save
          </Button>
        </Container>
      </Card>
      <Spacer size={10} />
      <Card style={{ paddingLeft: 50, paddingRight: 50 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 20,
          }}
        >
          <Switch
            checkedChildren="Edit mode"
            unCheckedChildren="Edit mode"
            defaultChecked
            onChange={val => setIsEditMode(val)}
            checked={isEditMode}
          />
        </div>
        <Row gutter={[48, 48]}>
          {month.map((month, index) => {
            return (
              <Col key={index} lg={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 8 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: "1.4rem",
                    }}
                  >
                    {month.format("MMM, YYYY")}
                  </Text>
                </div>
                <Spacer size={5} />
                <div style={{ margin: 40 }}>
                  <StyledCalendar
                    fullscreen={false}
                    onPanelChange={() => {}}
                    headerRender={() => null}
                    validRange={[dayjs(month).startOf("month"), dayjs(month).endOf("month")]}
                    onSelect={item => {
                      if (!isEditMode) return;
                      const selectedDay = dayjs(item).format("YYYY-MM-DD");
                      setDates(prevState => {
                        if (prevState.includes(selectedDay)) {
                          // if element is on the list, remove it
                          const newDates = prevState.filter(item => item !== selectedDay);
                          return [...newDates];
                        }
                        return [...prevState, selectedDay];
                      });
                    }}
                    dateCellRender={item => {
                      const formattedDay = dayjs(item).format("YYYY-MM-DD");

                      if (dates.includes(formattedDay)) {
                        return (
                          <div
                            style={{
                              position: "absolute",
                              width: "25px",
                              height: "25px",
                              borderRadius: "50%",
                              backgroundColor: "#BDD000",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              opacity: "1",
                              zIndex: -1,
                            }}
                          />
                        );
                      }

                      return null;
                    }}
                    value={undefined}
                    defaultValue={month}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </Card>
    </Spin>
  );
};

export default Vacations;

const StyledCalendar = styled(Calendar)`
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: none;
    color: black;
  }
`;
