import { Col, Form, Checkbox } from "antd";
import { CustomLabel } from "components";

interface Props {
  termsList?: {
    label: string;
    value: number;
  }[];
  disabled: boolean;
}

export const AddTerms = ({ termsList, disabled }: Props) => {
  return (
    <Col span={6}>
      <Form.Item name="termId" labelCol={{ span: 24 }} label={<CustomLabel>Add Terms</CustomLabel>}>
        <Checkbox.Group
          options={termsList}
          style={{ display: "flex", flexDirection: "column" }}
          disabled={disabled}
        />
      </Form.Item>
    </Col>
  );
};
