import {
  Modal,
  Button,
  Typography,
  Form,
  Row,
  Col,
  Upload,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { EditFilled } from "@ant-design/icons";
import { ErrorMessage } from "enums";
import { axiosAuth, normFile } from "helpers";
import { useEffect, useState } from "react";

interface Props {
  selectedType: string;
  isVisible: boolean;
  onClose: () => void;
  selectedClass: string;
  id: number | null;
  unitName: string;
  onSubmit: () => void;
}

const { Title } = Typography;

const TermUnitsModal = ({
  isVisible,
  onClose,
  id,
  selectedClass,
  selectedType,
  unitName,
  onSubmit,
}: Props) => {
  const [form] = useForm();
  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const [showUploadList, setShowUploadList] = useState(false);

  const handleSubmitForm = async (values: any) => {
    if (!selectedType) {
      return;
    }
    setIsUpdatePending(true);
    const { newImage, ...rest } = values;

    let bodyFormData = new FormData();
    bodyFormData.append("document", newImage[0].originFileObj);

    const url =
      selectedType === "Terms"
        ? `/terms/v2/units/upload-pdf/${id}`
        : `/camps-units/upload-pdf/${id}`;

    try {
      await axiosAuth.post(url, bodyFormData);
      onSubmit();
    } catch (err) {
      message.error("Something went wrong. Please try again later.");
    }

    setIsUpdatePending(false);
  };

  useEffect(() => {
    setShowUploadList(false);
  }, [id]);

  return (
    <Modal
      visible={isVisible}
      title={
        <Title
          level={4}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          Add file to {selectedClass} - {unitName}
        </Title>
      }
      onCancel={onClose}
      footer={false}
    >
      <Form name="basic" onFinish={handleSubmitForm} form={form}>
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="newImage"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Upload
                name="file"
                beforeUpload={() => false}
                accept=".pdf"
                onChange={(info) => {
                  setShowUploadList(true);
                }}
                showUploadList={showUploadList}
              >
                <Button
                  icon={<EditFilled />}
                  type="dashed"
                  style={{ width: "100%" }}
                >
                  PDF file
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item wrapperCol={{ offset: 20 }}>
              <Button
                loading={isUpdatePending}
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#BDD000",
                  borderColor: "#BDD000",
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default TermUnitsModal;
