import { Typography } from "antd";
import styled from "styled-components";
import { ExportToCsv, SummaryInfo } from "../components";
import { SingleSchoolFinancialsTable } from "./components";
import useSWR from "swr";
import { ViewType } from "enums";
import { RouteComponentProps } from "react-router-dom";
import { NoDataInfo } from "screens/Financials/components";

export interface IInvoiceData {
  items: ISingleSchoolFinancialRecord[];
  refunds: number;
  revenue: number;
  overDueAmount: number;
}

export interface ISingleSchoolFinancialRecord {
  schoolId: number;
  childId: number;
  name: string;
  lastPayment: number;
  totalBalance: number;
  monthlyBalance: number;
  overDueAmount: number;
  overDueDays: number;
  //To check
  image: string;
  class: string;
}

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string }>;

export const FinancialsSingleSchool = ({ match }: Props) => {
  const { Title } = Typography;
  const schoolId = match.params.id;

  const { data: invoiceData, revalidate } = useSWR<IInvoiceData>(`schools/${schoolId}/financials/revenues`);

  if (!invoiceData) {
    return (
      <NoDataContainer>
        <NoDataInfo title="No financial history data for this school" />
      </NoDataContainer>
    );
  }

  const { refunds, revenue, overDueAmount } = invoiceData;

  const summaryData = [
    { name: "Refunds", price: refunds },
    { name: "Revenue", price: revenue },
    { name: "Overdue", price: overDueAmount },
    //TODO future fields
    // { name: "Monthly Revenue", price: 0 },
    // { name: "Overall Revenue", price: 0 },
  ];

  return (
    <>
      <InvoiceContainer>
        <Title level={3} style={{ textAlign: "left", color: "#E50A5C" }}>
          Summary
        </Title>
        <SummaryContainer>
          <AmountContainer>
            {summaryData.map(({ name, price }) => (
              <SummaryInfo title={name} price={price ? price : 0} />
            ))}
          </AmountContainer>
          <ExportToCsv dataSource={invoiceData.items} fileTitle={"Invoice_summary.csv"} />
        </SummaryContainer>
      </InvoiceContainer>
      <SingleSchoolFinancialsTable
        dataSource={invoiceData.items}
        schoolId={schoolId}
        revalidate={revalidate}
      />
    </>
  );
};

const SummaryContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;
const AmountContainer = styled.div`
  display: flex;
`;

const InvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 32px;
  border-radius: 10px;
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
