import { FC, useEffect, useState } from 'react';
import { Modal, Button, Typography, Form, Input, Select, Row, Col } from 'antd';
import { TimePicker } from 'components';
import { axiosAuth } from 'helpers';
import dayjs, { Dayjs } from 'dayjs';
import { ErrorMessage } from 'enums';

const { Title } = Typography;

export type InitialData = {
  id: number;
  name: string;
  status: number;
  time: [Dayjs, Dayjs] | null;
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
  schoolId: string;
  afterSubmit: () => Promise<boolean>;
};

const ShiftsModal: FC<Props> = ({
  isVisible,
  onClose,
  initialData,
  schoolId,
  afterSubmit,
}) => {
  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const [form] = Form.useForm();
  const title = initialData ? 'Update shift' : 'Create shift';

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = async (values: any) => {
    const { time, ...rest } = values;
    const [timeStart, timeEnd] = time;
    setIsSubmitPending(true);
    if (initialData) {
      await axiosAuth.post(`shift/update/${initialData.id}`, {
        timeStart: dayjs(timeStart).format('HH:mm'),
        timeEnd: dayjs(timeEnd).format('HH:mm'),
        ...rest,
      });
    } else {
      await axiosAuth.post(`shift/create/${schoolId}`, {
        schoolId,
        timeStart: dayjs(timeStart).format('HH:mm'),
        timeEnd: dayjs(timeEnd).format('HH:mm'),
        ...rest,
      });
    }
    await afterSubmit();
    onClose();
    setIsSubmitPending(false);
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form
        name="basic"
        initialValues={initialData ?? {}}
        onFinish={onFinish}
        form={form}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              style={{ flex: 1, marginRight: '20px' }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              style={{ flex: 1 }}
            >
              <Select>
                <Select.Option value={10}>Active</Select.Option>
                <Select.Option value={0}>Inactive</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Time"
              name="time"
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <TimePicker style={{ width: '100%' }} format="HH:mm" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isSubmitPending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#BDD000',
              borderColor: '#BDD000',
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ShiftsModal;
