export const groupName: Record<number, string> = {
  10: 'Swans',
  20: 'Foxes',
  30: 'Bears',
  40: 'Seals',
  50: 'Owls',
  60: 'Squirrels',
};

export const groupsList = Object.entries(groupName).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));
