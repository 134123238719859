import bears from '../img/classIcons/bears.png';
import foxes from '../img/classIcons/foxes.png';
import owls from '../img/classIcons/owls.png';
import seals from '../img/classIcons/seals.png';
import squirrels from '../img/classIcons/squirrels.png';
import swans from '../img/classIcons/swans.png';

export const classIcons: Record<number, string> = {
  10: swans,
  20: foxes,
  30: bears,
  40: seals,
  50: owls,
  60: squirrels,
};
