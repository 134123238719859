import { FC, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  Select,
  Row,
  DatePicker,
} from 'antd';
import { Spacer } from 'components';
import { axiosAuth } from 'helpers';
import { ErrorMessage } from 'enums';

const { Title } = Typography;
const { TextArea } = Input;

export type InitialData = any;

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
  afterSubmit: () => Promise<void | boolean>;
  subCategoryId: number;
};

const SubCategoryModal: FC<Props> = ({
  isVisible,
  onClose,
  initialData,
  subCategoryId,
  afterSubmit,
}) => {
  const [isUpdatePending, setIsUpdatePending] = useState(false);

  const [form] = Form.useForm();
  const title = initialData
    ? 'Update learning outcome'
    : 'Create learning outcome';

  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = async (values: any) => {
    setIsUpdatePending(true);
    if (initialData) {
      await axiosAuth.post(
        `journey-sub-category/update/${initialData.id}`,
        values
      );
    } else {
      await axiosAuth.post(
        `journey-sub-category/create/${subCategoryId}`,
        values
      );
    }
    await afterSubmit();
    setIsUpdatePending(false);
    onClose();
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form
        name="basic"
        initialValues={initialData ?? {}}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
        >
          <Select>
            <Select.Option value={10}>Active</Select.Option>
            <Select.Option value={0}>Inactive</Select.Option>
          </Select>
        </Form.Item>
        <Spacer size={10} />
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isUpdatePending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#BDD000',
              borderColor: '#BDD000',
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubCategoryModal;
