import Title from "antd/lib/typography/Title";
import styled from "styled-components";

export const FeeContainer = styled.div`
  background-color: white;
  padding: 14px 10px;
  border-radius: 4px;
  margin: 10px 8px;
`;

export const FeeMenu = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px 10px;
  border-radius: 4px;
  margin: 10px 0px;
`;

export const FeeMenuTitle = styled(Title)`
  color: #e50a5c !important;
  margin-block-end: 0;
  line-height: 1 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  margin: 0 8px;
  position: relative;
`;
