export const getSlackMessageUrl = () => 'slack-messages/web';

export type SlackMessageBody = {
  title: string;
  body: string;
  image?: {
    name: string;
    type: string | undefined;
    content: string;
  };
};
