import { Col, Form, Select, Button } from "antd";
import { CustomLabel, Spacer } from "components";
import { Link } from "react-router-dom";
import { Kid } from "screens/Kids/Kids";

interface Props {
  carersList?: {
    label: string;
    value: number;
  }[];
  isEditMode: boolean;
  selectedKidId?: string;
  kid?: Kid;
  selectEditModeProps: {
    showArrow: boolean;
    bordered: boolean;
    disabled: boolean;
  };
}

export const Carer = ({ isEditMode, selectEditModeProps, carersList, kid, selectedKidId }: Props) => {
  return (
    <Col span={6}>
      <Form.Item name="carer" labelCol={{ span: 24 }} label={<CustomLabel>Carers</CustomLabel>}>
        <Select
          placeholder="Link created carers"
          allowClear
          mode="multiple"
          options={carersList}
          filterOption={(input, option) =>
            (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          {...selectEditModeProps}
        />
      </Form.Item>
      {isEditMode && selectedKidId && (
        <>
          <Link
            to={{
              pathname: `/main/schools/${kid?.schoolId}/addCarer`,
              state: {
                preselectedChildId: kid?.id ? [kid.id] : undefined,
              },
            }}
          >
            <Button
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
                color: "white",
              }}
            >
              Add new carer
            </Button>
          </Link>
          <Spacer />
        </>
      )}
    </Col>
  );
};
