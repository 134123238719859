import { Col, Form, Checkbox, Upload, Button } from "antd";
import { normFile } from "helpers";
import { EditFilled } from "@ant-design/icons";

interface Props {
  isEditMode: boolean;
  selectedKidId?: string;
}

export const EditPhoto = ({ isEditMode, selectedKidId }: Props) => {
  return (
    <Col span={6}>
      <Form.Item noStyle shouldUpdate>
        {isEditMode && (
          <>
            {!!selectedKidId && (
              <Form.Item name="isDeleted" shouldUpdate valuePropName="checked">
                <Checkbox>Delete current photo</Checkbox>
              </Form.Item>
            )}
            <Form.Item
              name="newImage"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              labelCol={{ span: 24 }}
            >
              <Upload name="avatar" listType="picture" beforeUpload={() => false}>
                <Button icon={<EditFilled />} type="dashed" style={{ width: "100%" }}>
                  New photo
                </Button>
              </Upload>
            </Form.Item>
          </>
        )}
      </Form.Item>
    </Col>
  );
};
