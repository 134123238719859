import { Timeline, Form, Row, Col, Input, Button, Typography } from "antd";
import { Spacer } from "components";
import dayjs from "dayjs";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import { IInitialValues } from "screens/Kid/AddKid";
import { Kid } from "screens/Kids/Kids";

interface Props {
  kid: Kid;
  selectedKidId?: string;
  revalidate: () => void;
  initialValues: IInitialValues;
}

export const Notes = ({ kid, revalidate, selectedKidId, initialValues }: Props) => {
  const { Title } = Typography;

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "4px",
        overflow: "hidden",
        boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
        padding: "24px",
      }}
    >
      <Title level={4} style={{ color: "#E50A5C", margin: "0px", textAlign: "center" }}>
        Notes
      </Title>
      <Spacer size={40} />
      <Timeline mode="alternate">
        {kid.note.map(({ createdAt, message }) => (
          <Timeline.Item label={dayjs(new Date(createdAt * 1000)).format("DD-MM-YYYY")}>
            {message}
          </Timeline.Item>
        ))}
      </Timeline>
      <Form
        onFinish={async values => {
          const { image, ...rest } = initialValues;
          await axiosAuth.post(`child/update/${selectedKidId}`, {
            ...rest,
            ...values,
          });
          await revalidate();
        }}
        initialValues={{
          ...initialValues,
        }}
      >
        <Row>
          <Col flex={1}>
            <Form.Item name="note" rules={[{ required: true, message: ErrorMessage.REQUIRED }]}>
              <Input placeholder="create new note" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#BDD000",
                  borderColor: "#BDD000",
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
