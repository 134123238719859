import { Button, Col, Form, Row, message } from "antd";
import { Formik } from "formik";
import { axiosAuth } from "helpers";
import { DiscountConfig, getChildCampFinanceConfig } from "types/rest/child";
import FeeConfigurationDiscounts from "../FeeConfigurationDiscounts/FeeConfigurationDiscounts";

type Props = {
  shouldDisableSave: boolean;
  schoolId: number;
  selectedKidId: number;
  revalidate: () => Promise<boolean>;
  campFeeConfig?: { discounts: DiscountConfig[] };
};

const CampFeeConfiguration = ({
  campFeeConfig,
  shouldDisableSave,
  schoolId,
  selectedKidId,
  revalidate,
}: Props) => {
  const { discounts = [] } = campFeeConfig || {};

  const initialDiscounts = {
    sibling: null,
    corporate: null,
    other: null,
  };

  const updateFeeConfiguration = async (values: {
    discounts: { corporate: null | number; sibling: null | number; other: null | number };
  }) => {
    try {
      const newDiscountIds = Object.values(values.discounts).filter(item => item !== null);
      await axiosAuth.put(getChildCampFinanceConfig(schoolId, selectedKidId), {
        discountIds: newDiscountIds,
      });
      revalidate();
      message.success("Fee configuration updated successfully");
    } catch (e) {}
  };

  const disabledStyles = shouldDisableSave
    ? {
        backgroundColor: "#dddddd",
        borderColor: "#dddddd",
        color: "black",
      }
    : {};

  return (
    <>
      <Formik
        initialValues={{ discounts: initialDiscounts }}
        enableReinitialize
        onSubmit={updateFeeConfiguration}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form>
            <Row>
              <Col span={24}>
                <FeeConfigurationDiscounts discounts={discounts} setFieldValue={setFieldValue} />
              </Col>
            </Row>
            <Row justify="end">
              <Col span={24}>
                <Button
                  type="primary"
                  onClick={() => handleSubmit()}
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                    ...disabledStyles,
                  }}
                  disabled={shouldDisableSave}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CampFeeConfiguration;
