import { message } from "antd";
import a from "axios";
import { StorageKeys } from "enums";
import { store } from "store";
import { logout } from "store/user";
import { getVerifyTokenUrl, VerifyTokenRes } from "types";

const baseURL =
  process.env.REACT_APP_BACKEND_API_URL ||
  "http://kipina-2136321854.eu-central-1.elb.amazonaws.com/api/";

export const axios = a.create({
  baseURL,
});

export const axiosAuth = a.create({
  baseURL,
});

axiosAuth.interceptors.request.use(
  async function requestSuccess(response) {
    const authToken = localStorage.getItem(StorageKeys.AUTH_TOKEN);
    response.headers.Authorization = `Bearer ${authToken}`;
    return response;
  },
  function requestError(error) {
    return Promise.reject(error);
  }
);

axiosAuth.interceptors.response.use(
  function onResponse(response) {
    return response;
  },
  async function onResponseError(error) {
    try {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refresh = localStorage.getItem(StorageKeys.REFRESH_TOKEN_KEY);
        if (!refresh) {
          return Promise.reject(error);
        }

        const response = (
          await axios.get<VerifyTokenRes>(getVerifyTokenUrl(), {
            headers: {
              Authorization: `Bearer ${refresh}`,
            },
          })
        ).data;

        if ("message" in response) {
          localStorage.removeItem(StorageKeys.AUTH_TOKEN);
          localStorage.removeItem(StorageKeys.REFRESH_TOKEN_KEY);
          store.dispatch(logout());
          return Promise.reject(error);
        }

        localStorage.setItem(StorageKeys.AUTH_TOKEN, response.accessToken);
        originalRequest.headers.Authorization = `Bearer ${response.accessToken}`;
        return axiosAuth(originalRequest);
      }
      return Promise.reject(error);
    } catch (e: any) {
      if (e.response.status === 401) {
        store.dispatch(logout());
      } else {
        message.error("An unexpected error occurred");
      }
      return Promise.reject(error);
    }
  }
);

export const swrFetcher = async (url: string) => {
  return axiosAuth({
    url,
  }).then((res) => {
    return res.data;
  });
};
