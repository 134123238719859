import { message } from "antd";
import dayjs from "dayjs";
import { axiosAuth } from "helpers";
import { useEffect, useState } from "react";
import useSWR from "swr";
import {
  ChildCampPreviewFinance,
  ChildFinanceCamp,
  getChildFinanceCamps,
  getChildPreviewCampFinanceConfig,
} from "types/rest/child";

const useCampsFees = ({
  pickedCampTab,
  schoolId,
  selectedKidId,
}: {
  pickedCampTab: boolean;
  schoolId: number;
  selectedKidId: number;
}) => {
  const [pickedCamp, setPickedCamp] = useState<number | null>(null);
  const { data: campsData } = useSWR<ChildFinanceCamp[]>(getChildFinanceCamps(schoolId, selectedKidId));
  const { data: campsPreviewData, revalidate: revalidateCamps } = useSWR<ChildCampPreviewFinance>(
    pickedCamp !== null ? getChildPreviewCampFinanceConfig(schoolId, selectedKidId, pickedCamp) : null
  );
  const mappedCampsData = campsData?.map(camp => ({
    value: camp.id,
    label: `${camp.name} (${dayjs(camp?.startDate).format("DD.MM.YYYY")} - ${dayjs(camp?.endDate).format(
      "DD.MM.YYYY"
    )})`,
  }));

  const campConfigLookups = campsPreviewData?.financeConfigLookups;
  const campPreview = campsPreviewData?.statementPreview;

  const addCampFeeToStatement = async () => {
    try {
      await axiosAuth.post(`schools/${schoolId}/childs/${selectedKidId}/camp/${pickedCamp}/camps-statement`);
      await revalidateCamps();
      setPickedCamp(null);
      message.success(`Camp fee added to statement.`);
    } catch (e) {
      message.error(`Adding fee to statement failed, please try again, or contact support.`);
    }
  };

  useEffect(() => {
    if (pickedCampTab && !pickedCamp && campsData && campsData?.length > 0) {
      setPickedCamp(campsData[0].id);
    }
  }, [campsData, pickedCampTab, pickedCamp]);

  return {
    pickedCamp,
    setPickedCamp,
    addCampFeeToStatement,
    mappedCampsData,
    campConfigLookups,
    campPreview,
    campsPreviewData,
    revalidateCamps,
  };
};

export default useCampsFees;
