export enum UtilityCategory {
  Food = "FOOD",
  Materials = "MATERIAL",
  Transport = "TRANSPORT",
}

export type Utility = {
  amount: number;
  category: UtilityCategory;
  id: number;
  schoolId: number;
  timeId: number;
};

export enum UtilityTabs {
  Food = "Food",
  Materials = "Material",
  Transport = "Transport",
}

export enum TaxesTabs {
  FeeTaxes = "FeeTaxes",
  Taxes = "Taxes",
}

export type Time = {
  createdAt: number;
  createdBy: number;
  friday: number;
  id: number;
  monday: number;
  name: string;
  saturday: number;
  schoolId: number;
  status: number;
  sunday: number;
  thursday: number;
  timeEnd: string | null;
  timeStart: string | null;
  tuesday: number;
  updatedAt: number;
  updatedBy: number;
  wednesday: number;
  type: number;
};
