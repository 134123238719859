import { Button, Col, Form, Input, Row, Typography, message } from "antd";
import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { useForm } from "antd/lib/form/Form";
import useSWR from "swr";
import { FC, useState } from "react";
import dayjs from "dayjs";
import { SmartGoalsTypes, getSendSmartGoalUrl, getSingleSmartGoalUrl, getSmartGoalListUrl } from "types";

import { axiosAuth } from "helpers";
import DeleteButton from "components/DeleteButton";
import { Spacer } from "components/UI";
import { smartGoalsItems, smartGoalsValues } from "const/smartGoals";

const { Title } = Typography;

type SmartGoalList = {
  id: string;
  createdAt: number;
  updatedAt: number;
}[];

type Props = {
  employeeId?: number;
};

const SmartGoals: FC<Props> = ({ employeeId }) => {
  const [form] = useForm();
  const [submitting, setSubmitting] = useState(false);
  const { data, mutate } = useSWR<SmartGoalList>(employeeId ? getSmartGoalListUrl(employeeId) : null);

  const sendSmartGoals = async (values: SmartGoalsTypes) => {
    try {
      setSubmitting(true);
      await axiosAuth.post(employeeId ? getSendSmartGoalUrl(employeeId) : "", values);
      await mutate();
      setSubmitting(false);
    } catch (error) {
      message.error("Sending smart goals failed, please try again, or contact support.");
    }
  };

  const generateSmartGoal = async (id: string) => {
    try {
      const res = await axiosAuth.get(getSingleSmartGoalUrl(id));
      const url = res?.data.url;
      if (url) {
        window.open(url, "_blank");
      }
    } catch (e) {
      message.error("Generating smart goals failed, please try again, or contact support.");
    }
  };

  return (
    <>
      <Col
        style={{
          backgroundColor: "white",
          borderRadius: "4px",
          overflow: "hidden",
          boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
          padding: "24px",
        }}
      >
        <Title level={4} style={{ color: "#E50A5C", margin: "0px", textAlign: "center" }}>
          Smart Goals
        </Title>
        <Spacer size={40} />
        <Row>
          <Col span={24}>
            <Text>Smart Goals information in PDF</Text>
            <Form
              name="progress_reports_items"
              form={form}
              initialValues={smartGoalsValues}
              onFinish={sendSmartGoals}
            >
              {smartGoalsItems.items.map(({ description, key }) => (
                <Form.Item fieldKey={key} name={key}>
                  <p>{description}</p>
                  <Input
                    onChange={e =>
                      form.setFieldsValue({
                        [key]: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              ))}
              <Row justify="end">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitting}
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                >
                  Generate Smart Goal
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
        <Spacer size={16} />
        <Row>
          {data && data.length > 0 ? (
            data.map(({ id, createdAt, updatedAt }) => (
              <>
                <Col span={24}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FileOutlined style={{ marginRight: "5px" }} />
                      <Text style={{ width: "100%" }}>
                        {dayjs.unix(updatedAt).format("DD-MM-YYYY")} Smart Goal
                      </Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          color: "#00A1E1",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "1.6rem",
                        }}
                        onClick={() => generateSmartGoal(id)}
                      >
                        <DownloadOutlined />
                      </div>
                      <DeleteButton
                        type="icon"
                        onSubmit={async () => {
                          await axiosAuth.get(`document/delete/${id}`);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </>
            ))
          ) : (
            <Title level={5} style={{ margin: "0 auto 16px" }}>
              You don't have any smart goals generated yet
            </Title>
          )}
        </Row>
      </Col>
    </>
  );
};

export default SmartGoals;
