import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import Title from "antd/lib/typography/Title";
import { WarningTwoTone } from "@ant-design/icons";
import { Spacer } from "components";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import { Tax, createTaxBody, getCreateTaxUrl, getUpdateTaxUrl } from "types";
import { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";

type Props = {
  visible: boolean;
  closeModal: () => void;
  schoolId: number;
  revalidate: any;
  editTaxData?: Tax;
  clearEditTaxData: () => void;
};

const AddTaxModal = ({ visible, closeModal, schoolId, revalidate, editTaxData, clearEditTaxData }: Props) => {
  const [form] = useForm();

  const handleCloseModal = () => {
    form.resetFields();
    clearEditTaxData();
    closeModal();
  };

  const addNewTax = async ({ amount, name }: { amount: string; name: string }) => {
    try {
      const body: createTaxBody = {
        name,
        amount: Number(amount),
      };
      await axiosAuth.post(getCreateTaxUrl(Number(schoolId)), body);
      await revalidate();
      handleCloseModal();
    } catch (e) {
      message.error("Adding new tax failed, please try again, or contact support.");
    }
  };

  const editTax = async ({ amount, name }: { amount: string; name: string }) => {
    try {
      const body: createTaxBody = {
        name,
        amount: Number(amount),
      };

      await axiosAuth.put(getUpdateTaxUrl(Number(schoolId), Number(editTaxData?.id)), body);
      await revalidate();
      handleCloseModal();
    } catch (e) {
      message.error("Editing new tax failed, please try again, or contact support.");
    }
  };

  const isEditing = !!editTaxData;

  useEffect(() => {
    if (editTaxData) {
      form.setFieldsValue(editTaxData);
    }
  }, [form, editTaxData]);

  return (
    <Modal visible={visible} onCancel={handleCloseModal} footer={null} width={704}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%", flexGrow: 1 }}>
        <Title
          level={3}
          style={{
            color: "#E50A5C",
          }}
        >
          {isEditing ? "Edit Tax" : "Add new tax"}
        </Title>
        <Spacer size={10} />
        <div style={{ width: "107%", height: 0.5, backgroundColor: "lightgrey", marginLeft: "-3.5%" }} />
        <Spacer size={16} />
        <p>Enter new tax</p>
        <Spacer size={16} />
        <Form
          initialValues={{ ...editTaxData } || { amount: 1, name: "" }}
          onFinish={editTaxData ? editTax : addNewTax}
          form={form}
        >
          <Row>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              <p style={{ whiteSpace: "nowrap", paddingRight: 8 }}>
                <span style={{ color: "#e50a5c" }}>*</span>Percentage:
              </p>
              <Form.Item
                name="amount"
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                style={{ width: "100%" }}
                shouldUpdate
              >
                <Input name="amount" type="number" min={1} max={100} style={{ paddingRight: 20 }} />
              </Form.Item>
            </Col>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              <p style={{ whiteSpace: "nowrap", paddingLeft: 20, paddingRight: 8 }}>
                <span style={{ color: "#e50a5c" }}>*</span>Tax name:
              </p>
              <Form.Item
                name="name"
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                style={{ width: "100%" }}
                shouldUpdate
              >
                <Input name="name" />
              </Form.Item>
            </Col>
          </Row>
          <Spacer size={32} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <WarningTwoTone twoToneColor="#e50a5c" />
            <p style={{ color: "#e50a5c", paddingLeft: 8 }}>
              Percentage and tax name must be entered before saving
            </p>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
                marginLeft: "auto",
              }}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddTaxModal;
