import { Button, Col, Popconfirm, Row, Select, Table } from "antd";
import Title from "antd/lib/typography/Title";

import useSWR from "swr";
import dayjs from "dayjs";
import { RouteComponentProps } from "react-router-dom";
import { useState } from "react";

import { ViewType } from "enums";

import { Kid } from "screens/Kids/Kids";

import kidImg from "img/placeholder/kid.png";
import WarningIcon from "./warningIcon.png";

import { FeeConfiguration, CampFeeConfiguration } from "./components";

import { useCampsFees, useFinancialsFees } from "./hooks";

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{}, {}, { schoolId: number; id: number }>;

const activeButtonStyle = {
  backgroundColor: "#BDD000",
  border: "1px solid #BDD000",
  color: "white",
  outline: "none",
};

enum ChildFeeTab {
  Term = "TERM",
  Camp = "CAMP",
}

export const Fees = (props: Props) => {
  const schoolId = props.location.state.schoolId;
  const selectedKidId = props.location.state.id;
  const [childFeeTab, setChildFeeTab] = useState(ChildFeeTab.Term);

  const pickedTermTab = childFeeTab === ChildFeeTab.Term;
  const pickedCampTab = childFeeTab === ChildFeeTab.Camp;

  const {
    mappedCampsData,
    pickedCamp,
    revalidateCamps,
    setPickedCamp,
    addCampFeeToStatement,
    campConfigLookups,
    campPreview,
  } = useCampsFees({ pickedCampTab, selectedKidId, schoolId });

  const {
    isValidating,
    statementPreview,
    addFeeToStatement,
    financeConfig,
    financeConfigLookups,
    mappedTermsData,
    pickedTerm,
    setPickedTerm,
    revalidate,
    data,
  } = useFinancialsFees({ selectedKidId, schoolId, pickedTermTab });

  const { data: kidData } = useSWR<Kid>(`child/config/${selectedKidId}`);

  if (isValidating && !data) {
    return <div>Loading...</div>;
  }

  const pickChildFeeTermTab = () => setChildFeeTab(ChildFeeTab.Term);
  const pickChildFeeCampTab = () => setChildFeeTab(ChildFeeTab.Camp);

  const { term = null, items = [] } = statementPreview || {};

  const { items: campItems = [], camps } = campPreview || {};

  const shouldDisableSave = term === null || term === undefined;
  const shouldDisableCampSave = camps === null || camps === undefined;

  const shouldDisableGeneratingFee = items.length === 0 || term === null || term === undefined;
  const shouldDisableGeneratingCampFee =
    campItems.length === 0 || pickedCamp === null || pickedCamp === undefined;

  const shouldDisableFeeStatement = pickedTermTab
    ? shouldDisableGeneratingFee
    : shouldDisableGeneratingCampFee;

  const disabledStyles = shouldDisableFeeStatement
    ? {
        backgroundColor: "#dddddd",
        borderColor: "#dddddd",
        color: "black",
      }
    : {};

  return (
    <div>
      <Row>
        <Col span={14} style={{ marginTop: -60 }}>
          <Button
            style={childFeeTab === ChildFeeTab.Term ? activeButtonStyle : {}}
            onClick={pickChildFeeTermTab}
          >
            Terms
          </Button>
          <Button
            style={
              childFeeTab === ChildFeeTab.Camp ? { ...activeButtonStyle, marginLeft: 8 } : { marginLeft: 8 }
            }
            onClick={pickChildFeeCampTab}
          >
            Camps
          </Button>
        </Col>
        <Col span={pickedTermTab ? 13 : 17} style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              backgroundColor: "#FFF",
              padding: "20px 16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img src={kidData?.image || kidImg} width={130} height={130} alt="Kid " />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Title
                level={3}
                style={{
                  color: "#E50A5C",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  textAlign: "center",
                }}
              >
                {childFeeTab === ChildFeeTab.Term ? "Term fees configuration" : "Camp fees configuration"}
              </Title>
              <Title
                level={5}
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  textAlign: "center",
                }}
              >
                Child: {kidData?.firstName} {kidData?.lastName}
              </Title>
              {pickedTermTab && term !== null && term ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: 16,
                  }}
                >
                  <Select
                    options={mappedTermsData}
                    value={pickedTerm}
                    onChange={value => setPickedTerm(value)}
                    dropdownMatchSelectWidth={false}
                  />
                  {!term.termHasAssignedFee && (
                    <div style={{ display: "flex", alignItems: "center", margin: "10px auto 0" }}>
                      <img src={WarningIcon} width={24} height={24} alt="warning" />{" "}
                      <Title
                        level={5}
                        style={{ color: "red", margin: 0, marginBlockStart: 0, marginBlockEnd: 0 }}
                      >
                        Term has no fees assigned
                      </Title>
                    </div>
                  )}
                </div>
              ) : pickedCampTab ? (
                <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
                  {mappedCampsData && mappedCampsData.length > 0 ? (
                    <Select
                      options={mappedCampsData}
                      value={pickedCamp}
                      onChange={value => setPickedCamp(value)}
                      dropdownMatchSelectWidth={false}
                    />
                  ) : (
                    <Title level={4} style={{ textAlign: "center" }}>
                      No camp selected in child profile
                    </Title>
                  )}
                </div>
              ) : (
                <Title level={4} style={{ textAlign: "center" }}>
                  {childFeeTab === ChildFeeTab.Term && "No term selected in child profile"}
                </Title>
              )}
            </div>
            <img
              src={kidData?.image}
              width={130}
              height={130}
              style={{ visibility: "hidden" }}
              alt="Child profile pict"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Popconfirm
              onConfirm={pickedTermTab ? addFeeToStatement : addCampFeeToStatement}
              title="Are you sure you want to add fees to summary? This will be immediately shared this with a carer."
              okText="Yes"
              cancelText="No"
              disabled={shouldDisableFeeStatement}
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: "#BDD000",
                  borderColor: "#BDD000",
                  color: "white",
                  marginTop: 16,
                  marginBottom: 16,
                  ...disabledStyles,
                }}
                disabled={shouldDisableFeeStatement}
              >
                Add fees to summary
              </Button>
            </Popconfirm>
          </div>
          <Table dataSource={pickedTermTab ? items : campItems} pagination={{ pageSize: 20 }}>
            <Table.Column
              title="Date"
              dataIndex="createdAt"
              key="date"
              width="20%"
              render={record => dayjs(record).format("DD-MM-YYYY")}
            />
            <Table.Column title="Name" dataIndex="itemName" key="name" width="60%" />
            <Table.Column title="Amount" dataIndex="itemAmount" key="amount" width="20%" />
          </Table>
        </Col>
        {pickedTermTab && (
          <Col span={10} offset={1} style={{ backgroundColor: "#EAE9E9", padding: "10px", borderRadius: 8 }}>
            <FeeConfiguration
              financeConfig={financeConfig}
              configuration={financeConfigLookups}
              revalidate={revalidate}
              shouldDisableSave={shouldDisableSave}
              selectedKidId={selectedKidId}
              schoolId={schoolId}
              pickedTerm={pickedTerm}
            />
          </Col>
        )}
        {pickedCampTab && (
          <Col span={6} offset={1} style={{ backgroundColor: "#EAE9E9", padding: "10px", borderRadius: 8 }}>
            <CampFeeConfiguration
              campFeeConfig={campConfigLookups}
              shouldDisableSave={shouldDisableCampSave}
              selectedKidId={selectedKidId}
              schoolId={schoolId}
              revalidate={revalidateCamps}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
