import { useState } from "react";
import { Button, Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import { getChildPaymentsUrl, IChildPaymentBody, PaymentCategoryTypeDto, paymentMethod } from "types";
import styled from "styled-components";

interface IProps {
  schoolId?: string;
  kidId?: string;
  onFinish: () => void;
}

const AddRefundModal = ({ schoolId, kidId, onFinish }: IProps) => {
  const [form] = useForm();
  const [sectionType, setPaymentType] = useState<"Adjustment" | "Refund">("Refund");
  const firstDayOfTheMonth = dayjs().startOf("month");
  const endDayOfTheMonth = dayjs().endOf("month");
  const getPaymentType = (amountSymbol: number) => {
    if (sectionType === "Adjustment" && Number(amountSymbol) === 1) {
      return PaymentCategoryTypeDto.ADJUSTMENT_INCREASE;
    }

    return PaymentCategoryTypeDto.ADJUSTMENT_REFUND;
  };

  const handleSubmit = async (values: IChildPaymentBody & { amountSymbol?: number }) => {
    const { amount, paymentDate, paymentMethod, reference, amountSymbol } = values;

    const requestBody: IChildPaymentBody = {
      amount: Number(amount),
      paymentDate: dayjs(paymentDate).toDate(),
      paymentMethod,
      reference,
      type: amountSymbol ? getPaymentType(amountSymbol) : PaymentCategoryTypeDto.REFUND,
    };

    try {
      if (!schoolId || !kidId) return;

      await axiosAuth.post(getChildPaymentsUrl(schoolId, kidId), requestBody);
      form.resetFields();
      message.success("Payment has been saved");
      onFinish();
    } catch (err: any) {
      message.error("Something went wrong");
      form.resetFields();
    }
  };

  return (
    <div>
      <Form form={form} onFinish={handleSubmit}>
        <Row style={{ marginBottom: 20 }}>
          <Col>
            <Button
              style={
                sectionType === "Refund"
                  ? { marginLeft: 10, backgroundColor: "#1890FF", color: "white" }
                  : { marginLeft: 10 }
              }
              onClick={() => setPaymentType("Refund")}
            >
              Refund
            </Button>
            <Button
              style={
                sectionType === "Adjustment"
                  ? { marginLeft: 10, backgroundColor: "#1890FF", color: "white" }
                  : { marginLeft: 10 }
              }
              onClick={() => setPaymentType("Adjustment")}
            >
              Adjustment
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="paymentDate"
              label="Date: "
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <DatePicker
                onChange={e => {}}
                style={{ width: "100%" }}
                disabledDate={date =>
                  dayjs(date.format()).isBefore(firstDayOfTheMonth) ||
                  dayjs(date.format()).isAfter(endDayOfTheMonth)
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="paymentMethod"
              label="Method: "
              labelCol={{ span: 10 }}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Select
                showSearch
                options={paymentMethod}
                filterOption={(input, option) =>
                  (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ display: "flex" }}>
            <div>
              <Row>
                {sectionType === "Adjustment" && (
                  <Col>
                    <Form.Item
                      name="amountSymbol"
                      label="Amount"
                      rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                      style={{ marginRight: 5 }}
                    >
                      <Select
                        options={[
                          { label: "+", value: "1" },
                          { label: "-", value: "2" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={sectionType === "Adjustment" ? 11 : 24}>
                  <Form.Item
                    name="amount"
                    label={sectionType === "Adjustment" ? "" : "Amount"}
                    rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                  >
                    <Input placeholder="example" type="number" />
                  </Form.Item>
                </Col>
              </Row>
              {sectionType === "Adjustment" && (
                <AmountSignDescription>
                  If '+' is selected, the balance will be increased by the specified amount, while if '-' is
                  selected, the balance will be decreased by the specified amount.
                </AmountSignDescription>
              )}
            </div>
          </Col>
          <Col span={12}>
            <Form.Item
              name="reference"
              label="Reference: "
              labelCol={{ span: 10 }}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Input placeholder="example" type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="text"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddRefundModal;

const AmountSignDescription = styled.span`
  font-size: 12px;
`;
