import { Button, Form, Input, message, Modal, Typography } from 'antd';
import { ErrorMessage } from 'enums';
import { axios, axiosAuth } from 'helpers';
import { FC, useState } from 'react';
import { Spacer } from './UI';

const { Title, Text } = Typography;

type Props = {
  isVisible: boolean;
  onClose: () => void;
};
const ResetPasswordModal: FC<Props> = ({ isVisible, onClose }) => {
  const [isPending, setIsPending] = useState(false);

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          Reset password
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form
        name="basic"
        onFinish={async (values) => {
          try {
            setIsPending(true);
            await axios.post('auth/forgot-password', {
              email: values.email,
            });
            message.success(
              `The new password has been send to email: ${values.email}`
            );
            setIsPending(false);
            onClose();
          } catch (e) {
            setIsPending(false);
          }
        }}
      >
        <Text>
          Please fill out your email. A new password will be send there
        </Text>
        <Spacer size={10} />
        <Form.Item
          name="email"
          label="email"
          rules={[
            { required: true, message: ErrorMessage.REQUIRED },
            { type: 'email', message: ErrorMessage.EMAILA },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isPending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#BDD000',
              borderColor: '#BDD000',
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetPasswordModal;
