import { Col, DatePicker, Form } from "antd";
import { CustomLabel } from "components";

interface Props {
  selectEditModeProps: {
    showArrow: boolean;
    bordered: boolean;
    disabled: boolean;
  };
}

export const InvoicingDate = ({ selectEditModeProps }: Props) => {
  return (
    <Col span={6}>
      <Form.Item
        label={<CustomLabel>Invoicing date</CustomLabel>}
        labelCol={{ span: 24 }}
        name="invoicingDate"
        required
        rules={[{ required: true, message: "Please select date!" }]}
      >
        <DatePicker style={{ width: "100%" }} {...selectEditModeProps} />
      </Form.Item>
    </Col>
  );
};
