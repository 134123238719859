const staffQualification = {
  10: 'Bachelors Degree Other',
  20: 'Bachelors Degree Education',
  30: 'Post Graduate Certificate Other',
  35: 'Post Graduate Certificate Education',
  40: 'Post Graduate Diploma Other',
  45: 'Post Graduate Diploma Education',
  50: 'Masters Other',
  55: 'Masters Education',
  80: 'PHD Other',
  85: 'PHD Education',
};

const staffQualificationsList = Object.entries(staffQualification).map(
  ([key, val]) => ({
    label: val,
    value: parseInt(key),
  })
);

export default staffQualificationsList;
