import { Button, Col, Form, Input, InputNumber, Modal, Radio, Row, Typography, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export type IPaymentPeriod = "PER_TERM" | "ONE_TIME";

interface IFormValues {
  newFeeName: string;
  amount: number;
  paymentPeriod: IPaymentPeriod;
}

interface Props {
  schoolId?: string;
  revalidate: () => Promise<boolean>;
}

export const AddNewFeeModal = ({ schoolId, revalidate }: Props) => {
  const history = useHistory();
  const [form] = useForm();
  const [open, setOpen] = useState(false);

  const { Title } = Typography;

  const showModal = () => {
    setOpen(true);
  };

  const onFinish = async (values: IFormValues) => {
    try {
      const { amount, newFeeName, paymentPeriod } = values;

      await axiosAuth.post(`school/${schoolId}/financials/settings/other-fees`, {
        amount,
        name: newFeeName,
        paymentPeriod: paymentPeriod,
      });

      revalidate();
      setOpen(false);
      message.success("New other fee saved");
      history.push(`/main/schools/${schoolId}/financials/otherFees`);
      form.resetFields();
    } catch (error) {
      message.error("Error occurs when adding new fee");
    }
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        style={{
          backgroundColor: "#BDD000",
          borderColor: "#BDD000",
          position: "absolute",
          right: 20,
        }}
      >
        + Add Another
      </Button>
      <Modal
        visible={open}
        width={655}
        onCancel={handleCancel}
        footer={null}
        title={
          <Title
            level={4}
            style={{
              color: "#E50A5C",
              paddingBottom: "0px",
              marginBottom: "0px",
            }}
          >
            {"Create Other Fee"}
          </Title>
        }
      >
        <span>Enter any other fees you charge here (examples: uniforms, laundry)</span>
        <Form name="basic" onFinish={onFinish} form={form}>
          <Row style={{ marginTop: 40, marginBottom: 10, display: "flex", justifyContent: "space-between" }}>
            <Col span={11}>
              <Form.Item
                label="Name of fee"
                name="newFeeName"
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <Input placeholder="Enter name of fee" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Amount"
                name="amount"
                rules={[
                  {
                    type: "number",
                    min: 1,
                    message: "minimum amount is 1",
                  },
                  { required: true, message: ErrorMessage.REQUIRED },
                ]}
              >
                <InputNumber style={{ width: "100%" }} precision={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              label="Section"
              name="paymentPeriod"
              rules={[{ required: true, message: "Please select an period!" }]}
            >
              <Radio.Group>
                <Radio value={"PER_TERM"}>Per term</Radio>
                <Radio value={"ONE_TIME"}>One time only</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
          <Form.Item wrapperCol={{ offset: 20 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
