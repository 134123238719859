import { Button, Modal, Steps, Typography, message } from "antd";
import { Form, Formik } from "formik";
import { FC, useState } from "react";

import {
  assistantStaffEvaluationItems,
  assistantStaffValues,
  teachingStaffEvaluationItems,
  teachingStaffEvaluationValues,
} from "const";
import { EmployeeRole } from "enums";
import {
  TeachingAssistantPerformanceEvaluation,
  TeachingStaffPerformanceEvaluation,
  getSendEvaluationUrl,
} from "types";
import { axiosAuth } from "helpers";

import { FormikInput } from "components/FormFields";

import EvaluationSelect from "./EvaluationSelect";

const { Step } = Steps;

const { Title } = Typography;

type Props = {
  visible: boolean;
  closeModal: () => void;
  mutate: any;
  employeeId?: number;
  role?: EmployeeRole;
};

const EvaluationModal: FC<Props> = ({ visible, role, closeModal, mutate, employeeId }) => {
  const [current, setCurrent] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const isAssistantTeacher = role === EmployeeRole.ASSISTANT_TEACHER;

  const submitEvaluation = async (
    values: TeachingAssistantPerformanceEvaluation | TeachingStaffPerformanceEvaluation
  ) => {
    try {
      setSubmitting(true);
      await axiosAuth.post(getSendEvaluationUrl(Number(employeeId), isAssistantTeacher), values);
      await mutate();
      setSubmitting(false);
      closeModal();
    } catch (e) {
      message.error("Generating evaluation failed, please try again, or contact support.");
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = isAssistantTeacher ? assistantStaffEvaluationItems : teachingStaffEvaluationItems;

  const values = isAssistantTeacher ? assistantStaffValues : teachingStaffEvaluationValues;

  const steps = items.map(item => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <Modal visible={visible} footer={<></>} onCancel={closeModal}>
      <Steps current={current} direction="horizontal" size="small">
        {steps.map(item => (
          <Step key={item.key} title="" />
        ))}
      </Steps>
      <div style={{ marginTop: 24 }}>
        <Title level={3}>{items[current].title}</Title>
        <Formik initialValues={values} onSubmit={submitEvaluation}>
          {({ setFieldValue, handleSubmit }) => (
            <>
              <Form>
                {items[current].items.map(item => (
                  <EvaluationSelect
                    label={item.description}
                    name={`${items[current].key}.${item.key}`}
                    onChange={value => setFieldValue(`${items[current].key}.${item.key}`, value)}
                  />
                ))}
                <FormikInput
                  name={`${items[current].key}.comments`}
                  onChange={value => setFieldValue(`${items[current].key}.comments`, value)}
                  label="Additional Comments"
                />
                <FormikInput
                  name={`${items[current].key}.nextSteps`}
                  onChange={value => setFieldValue(`${items[current].key}.nextSteps`, value)}
                  label="Next Steps"
                />
              </Form>
              <div>
                {current > 0 && (
                  <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                    Previous
                  </Button>
                )}
                {current < items.length - 1 && (
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#BDD000",
                      borderColor: "#BDD000",
                    }}
                    onClick={() => {
                      next();
                    }}
                  >
                    Next
                  </Button>
                )}
                {current === items.length - 1 && (
                  <Button
                    type="primary"
                    loading={submitting}
                    onClick={() => handleSubmit()}
                    style={{
                      backgroundColor: "#BDD000",
                      borderColor: "#BDD000",
                    }}
                  >
                    Done
                  </Button>
                )}
              </div>
            </>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default EvaluationModal;
