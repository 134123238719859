import { ViewType } from "enums";
import { RouteComponentProps } from "react-router-dom";
import { DiscountSection } from "./components";
import useSWR from "swr";
import { IApplyToCategory, IDiscountCategory, IDiscountType } from "./types";
import { Spacer } from "components";
import { parseDiscounts } from "./helpers";

export interface IDiscountData {
  id: number;
  amount: number;
  name: string;
  discountCategory: IDiscountCategory;
  type: IDiscountType;
  applyTo: IApplyToCategory;
}

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id?: string }>;

const Discounts = ({ match }: Props) => {
  const schoolId = match.params.id;

  const { data: discounts, revalidate } = useSWR<IDiscountData[]>(
    `school/${schoolId}/financials/settings/discounts`
  );

  const { corporate, other, siblings } = parseDiscounts(discounts);

  return (
    <>
      <DiscountSection
        title="Sibling discount"
        discountCategory="sibling"
        modalTitle="Add sibling discount"
        schoolId={schoolId}
        dataSource={siblings}
        revalidate={revalidate}
        disabled={siblings.length > 0}
        spacerSize={20}
      />
      <DiscountSection
        title="Corporate discount"
        discountCategory="corporate"
        modalTitle="Add corporate discount"
        schoolId={schoolId}
        dataSource={corporate}
        revalidate={revalidate}
      />
      <DiscountSection
        title="Other discount"
        discountCategory="other"
        modalTitle="Add other discount"
        schoolId={schoolId}
        dataSource={other}
        revalidate={revalidate}
      />
      <Spacer size={120} />
    </>
  );
};

export default Discounts;
