import { Button } from "antd";
import { DeleteButton } from "components";
import { EditTwoTone } from "@ant-design/icons";
import styled from "styled-components";
import { IDiscountRecord } from "../types";

interface IGetTableColumnsArgs {
  editingKey: number | null;
  schoolCurrency: string;
  isEditing: (record: IDiscountRecord) => boolean;
  save: (record: IDiscountRecord) => Promise<void>;
  handleCancel: () => void;
  handleEditValue: (
    record: Partial<IDiscountRecord> & {
      id: React.Key;
    }
  ) => void;
  handleDeleteValue: (
    record: Partial<IDiscountRecord> & {
      id: React.Key;
    }
  ) => Promise<void>;
  categorySelectOptions?: { label: string; value: string }[];
}

export const getDiscountTableColumns = ({
  editingKey,
  schoolCurrency,
  isEditing,
  save,
  handleCancel,
  handleEditValue,
  handleDeleteValue,
  categorySelectOptions,
}: IGetTableColumnsArgs) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      width: "28%",
      editable: true,
      inputType: "text",
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "28%",
      editable: true,
      inputType: "select",
      selectOptions: categorySelectOptions,
    },
    {
      title: "Amount / Percentage",
      dataIndex: "amount",
      width: "28%",
      editable: true,
      disableNegaitiveDigits: true,
      inputType: "number",
      render: (_: any, record: IDiscountRecord) => {
        return (
          <span>
            {record.amount} {record.type === "percentage" ? "%" : schoolCurrency}
          </span>
        );
      },
    },
    {
      dataIndex: "operation",
      width: "25%",
      render: (_: any, record: IDiscountRecord) => {
        const editable = isEditing(record);

        return editable ? (
          <RowRightContainer>
            <Button
              color="#BDD000"
              type="primary"
              style={{
                backgroundColor: "#BDD000",
                borderColor: "#BDD000",
                marginRight: 8,
              }}
              onClick={() => save(record)}
            >
              Save
            </Button>
            <Button
              color="#E50A5C"
              type="primary"
              style={{
                backgroundColor: "#E50A5C",
                borderColor: "#E50A5C",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </RowRightContainer>
        ) : (
          <RowRightContainer>
            <Button
              style={{ marginRight: 8 }}
              type="text"
              icon={<EditTwoTone twoToneColor="#00A1E1" />}
              disabled={editingKey !== null}
              onClick={() => handleEditValue(record)}
            />
            <DeleteButton type="icon" onSubmit={() => handleDeleteValue(record)} />
          </RowRightContainer>
        );
      },
    },
  ];
};

const RowRightContainer = styled.div`
  display: flex;
  justify-content: end;
  align-content: flex-end;
`;
