import { FC, memo } from 'react';
import styled from 'styled-components';

const Dot: FC<{ isActive: boolean; onClick: () => void }> = memo(
  ({ isActive, onClick }) => {
    return (
      <DotContainer onClick={onClick}>
        <DotContent isActive={isActive} />
      </DotContainer>
    );
  }
);

export default Dot;

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
const DotContent = styled.div<{ isActive: boolean }>`
  transition: all 0.15s ease-in;
  width: 28px;
  height: 28px;
  background-color: ${({ isActive }) => (isActive ? '#BDD000' : '#EDEDED')};
  border-radius: 50%;
`;
