import { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { useAppSelector } from "store";

type Props = RouteProps & {
  component: any;
};

const PrivateRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const isAuth = useAppSelector(state => state.user.role);

  return (
    <Route
      {...rest}
      render={props =>
        isAuth && Component ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
