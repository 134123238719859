import { UtilityTabs } from "types";

const getPickedUtilityTabs = (pickedTab: UtilityTabs) => {
  const pickedFoodTab = pickedTab === UtilityTabs.Food;
  const pickedMaterialsTab = pickedTab === UtilityTabs.Materials;
  const pickedTransportTab = pickedTab === UtilityTabs.Transport;
  return {
    pickedFoodTab,
    pickedMaterialsTab,
    pickedTransportTab,
  };
};

export default getPickedUtilityTabs;
