import { Button, Col, Form, Row, Typography, message } from "antd";
import { FC, useState } from "react";
import dayjs from "dayjs";
import useSWR from "swr";
import { PlusOutlined, DownloadOutlined, FileOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import {
  TeachingAssistantPerformanceEvaluation,
  TeachingStaffPerformanceEvaluation,
  getDeleteEvaluationUrl,
  getEvaluationsUrl,
  getSingleEvaluationUrl,
} from "types";

import { useAppSelector } from "store";
import { EmployeeRole } from "enums";
import { axiosAuth } from "helpers";
import { Spacer } from "components/UI";
import DeleteButton from "components/DeleteButton";
import { EvaluationModal } from "./components";

const { Title } = Typography;

type Props = {
  employeeId?: number;
  role?: EmployeeRole;
};

interface ParsedData<T> {
  class: string;
  classTeacher: string;
  data: T;
  evaluationScore: number;
  name: string;
}

type ParsedDataEvaluation = ParsedData<
  TeachingAssistantPerformanceEvaluation | TeachingStaffPerformanceEvaluation
>;

type EvaluationList = {
  id: string;
  createdAt: number;
  data: string;
  updatedAt: number;
  fileStorageId: string;
}[];

const Evaluation: FC<Props> = ({ employeeId, role }) => {
  const userRole = useAppSelector(store => store.user.role);
  const [modalVisible, setModalVisible] = useState(false);
  const [editData, setEditData] = useState<
    TeachingAssistantPerformanceEvaluation | TeachingStaffPerformanceEvaluation | undefined
  >();
  const canGenerateEvaluation =
    userRole === EmployeeRole.ADMINISTRATOR ||
    userRole === EmployeeRole.SCHOOL_ADMINISTRATOR ||
    userRole === EmployeeRole.SCHOOL_MANAGER ||
    userRole === EmployeeRole.SUPER_ADMIN ||
    userRole === EmployeeRole.TERRITORY_MANAGER ||
    userRole === EmployeeRole.TERRITORY_OWNER;

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const isAssistantTeacher = role === EmployeeRole.ASSISTANT_TEACHER;

  const { data = [], mutate } = useSWR<EvaluationList>(
    employeeId ? getEvaluationsUrl(employeeId, isAssistantTeacher) : null
  );

  const convertedEvaluationData = data
    .map(item => {
      const { data } = item;
      const parsedData = JSON.parse(data) as ParsedDataEvaluation;

      return {
        ...item,
        data: parsedData,
      };
    })
    .sort(item => (item.updatedAt ? -1 : 1));

  const generateEvaluation = async (id: string) => {
    try {
      const res = await axiosAuth.get(getSingleEvaluationUrl(id, isAssistantTeacher));

      const url = res?.data.url;
      if (url) {
        window.open(url, "_blank");
      }
    } catch (e) {
      message.error("Generating evaluation failed, please try again, or contact support.");
    }
  };

  const handleDeleteEvaluation = async (id: string) => {
    try {
      await axiosAuth.delete(getDeleteEvaluationUrl(id, isAssistantTeacher));
      mutate();
    } catch (e) {
      message.error("Deleting evaluation failed, please try again, or contact support.");
    }
  };

  return (
    <>
      <Col
        style={{
          backgroundColor: "white",
          borderRadius: "4px",
          overflow: "hidden",
          boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
          padding: "24px",
        }}
      >
        <Title level={4} style={{ color: "#E50A5C", margin: "0px", textAlign: "center" }}>
          Evaluation
        </Title>
        <Spacer size={40} />

        <Spacer size={16} />
        <Row>
          {convertedEvaluationData && convertedEvaluationData.length > 0 ? (
            convertedEvaluationData.map(({ id, updatedAt, data }) => (
              <>
                <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                  <FileOutlined style={{ marginRight: "5px", alignSelf: "center" }} />
                  <Text style={{ fontSize: 20 }}>
                    {dayjs.unix(updatedAt).format("DD-MM-YYYY")} Evaluation
                  </Text>
                  <Text style={{ fontSize: 16, marginLeft: 4 }}>Score: {data.evaluationScore}</Text>
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      gap: 8,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        color: "#00A1E1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1.6rem",
                        cursor: "pointer",
                      }}
                      onClick={() => generateEvaluation(id)}
                    >
                      <DownloadOutlined type="icon" style={{ fontSize: 20 }} />
                    </div>
                    <DeleteButton type="icon" onSubmit={() => handleDeleteEvaluation(id)} />
                  </div>
                </Col>
              </>
            ))
          ) : (
            <Title level={5} style={{ margin: "0 auto 16px" }}>
              You don't have any evaluation generated yet
            </Title>
          )}
        </Row>
        {canGenerateEvaluation && (
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            wrapperCol={{ span: 6 }}
          >
            <Button type="dashed" onClick={openModal} block icon={<PlusOutlined />}>
              Generate Evaluation
            </Button>
          </Form.Item>
        )}
      </Col>
      <EvaluationModal
        visible={modalVisible}
        role={role}
        closeModal={closeModal}
        employeeId={employeeId}
        mutate={mutate}
      />
    </>
  );
};

export default Evaluation;
