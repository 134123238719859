import { Button, Modal, Space, Table } from "antd";
import Title from "antd/lib/typography/Title";
import dayjs from "dayjs";
import useSWR from "swr";

const mapStatus = {
  10: "Active",
  0: "Inactive",
};

type Term = {
  createdAt: number;
  createdBy: number;
  endDate: string;
  startDate: string;
  id: number;
  name: string;
  schoolId: number;
  status: keyof typeof mapStatus;
  updatedAt: number;
  updatedBy: number;
};

const { Column } = Table;

const OldTerms = ({
  schoolId,
  visible,
  handleClose,
}: {
  schoolId: number;
  visible: boolean;
  handleClose: () => void;
}) => {
  const { data, error, revalidate } = useSWR<Term[]>(`school/terms/${schoolId}`);
  const isPending = !data && !error;

  if (!data) {
    return null;
  }

  const normalizedData = data?.map(item => {
    const { status, startDate, endDate, ...rest } = item;
    const newItem = {
      ...rest,
      startDate: startDate ? dayjs(startDate).format("MMM D, YYYY") : null,
      endDate: endDate ? dayjs(endDate).format("MMM D, YYYY") : null,
      status,
    };
    return newItem;
  });

  return (
    <Modal open={visible} onCancel={handleClose} onOk={handleClose} width={800}>
      <Table
        title={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title
                level={3}
                style={{
                  color: "#E50A5C",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                Old Terms
              </Title>
            </div>
          );
        }}
        dataSource={normalizedData}
        pagination={{
          pageSize: 20,
        }}
      >
        <Column
          title="Name"
          key="name"
          dataIndex="name"
          render={(title: Term["name"]) => <Space size="middle">{title}</Space>}
          width="20%"
          sorter={(a: any, b: any) => {
            return a.name.localeCompare(b.name);
          }}
          sortDirections={["descend"]}
        />
        <Column
          title="Start date"
          key="startDate"
          dataIndex="startDate"
          render={(startDate: Term["startDate"]) => <Space size="middle">{startDate}</Space>}
          width="20%"
          sorter={(a: any, b: any) => {
            return dayjs(a.startDate, "MMM D, YYYY").unix() - dayjs(b.startDate, "MMM D, YYYY").unix();
          }}
          sortDirections={["descend"]}
        />
        <Column
          title="End date"
          key="endDate"
          dataIndex="endDate"
          render={(endDate: Term["endDate"]) => <Space size="middle">{endDate}</Space>}
          width="20%"
          sorter={(a: any, b: any) => {
            return dayjs(a.endDate, "MMM D, YYYY").unix() - dayjs(b.endDate, "MMM D, YYYY").unix();
          }}
          sortDirections={["descend"]}
        />
        <Column
          onFilter={(value, record: any) => record.status.includes(value)}
          filters={[
            { text: "Active", value: 10 },
            { text: "Inactive", value: 0 },
          ]}
          filterMultiple={false}
          title="Status"
          key="status"
          dataIndex="status"
          width="20%"
          render={(status: Term["status"]) => (
            <Space size="middle">{status === 10 ? "Active" : "Inactive"}</Space>
          )}
        />
      </Table>
    </Modal>
  );
};

export default OldTerms;
