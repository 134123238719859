import { Col, InputNumber, Form } from "antd";
import { CustomLabel } from "components";
import { ErrorMessage } from "enums";

interface Props {
  isEditMode: boolean;
}

export const SortCode = ({ isEditMode }: Props) => {
  return (
    <Col span={6}>
      <Form.Item
        name="sortCode"
        labelCol={{ span: 24 }}
        label={<CustomLabel>Sort code</CustomLabel>}
        rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
      >
        <InputNumber
          style={
            isEditMode
              ? {
                  width: "100%",
                }
              : {
                  border: "none",
                  backgroundColor: "white",
                  width: "100%",
                  padding: 0,
                  color: "#000",
                }
          }
          controls={false}
          disabled={!isEditMode}
        />
      </Form.Item>
    </Col>
  );
};
