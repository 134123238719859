import { Col, Menu, Row, Spin, Typography, Divider } from "antd";
import { Switch } from "react-router-dom";
import { Container, CustomBgImage, PrivateRoute, Spacer } from "components";
import { FC } from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import AddKid from "./AddKid";
import Skills from "./Skills";
import Attendance from "./Attendance";
import styled from "styled-components";
import Gallery from "./Gallery";
import DayLogs from "./DayLogs";
import Health from "./Health";
import Badges from "./Badges";
import { useAppSelector } from "store";
import MedalBank from "./MedalBank";
import { Financials } from "./Financials";
import { Kid as KidType } from "screens/Kids/Kids";
import { EmployeeRole } from "enums";

const { Title, Text } = Typography;

const Kid: FC = (props: any) => {
  const selectedKidId = props.match.params.kidId;
  const isNurse = useAppSelector(store => store.user.access.isNurse);

  const role = useAppSelector(store => store.user.role);

  const isSuperAdmin = role === EmployeeRole.SUPER_ADMIN;

  const { data: kidData, error: kidError } = useSWR<KidType>(`child/config/${selectedKidId}`);

  const path = String(props?.location?.pathname).split("/");
  const pathAtEnd = path?.[path.length - 1];

  const showChildView = pathAtEnd !== "fees";

  const isPending = !kidData && !kidError;

  const kidId = props.match.params.kidId;
  const schoolId = props.match.params.id;

  const defaultPath = `/main/schools/${schoolId}/kids/${kidId}`;

  if (isPending) {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spacer size={40} />
        <Spin spinning />
      </div>
    );
  }
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Menu
          defaultSelectedKeys={["addKid"]}
          mode="horizontal"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {!isNurse && isSuperAdmin && (
            <Menu.Item key="financials">
              <Link to={{ pathname: `${defaultPath}/financials/summary`, state: kidData }}>Financials</Link>
            </Menu.Item>
          )}
          <Menu.Item key="addKid">
            <Link
              to={{
                pathname: `${defaultPath}/addKid`,
                state: {
                  document: kidData,
                },
              }}
            >
              Child profile
            </Link>
          </Menu.Item>
          {!isNurse && (
            <Menu.Item key="skills">
              <Link to={{ pathname: `${defaultPath}/skills`, state: kidData }}>Skills</Link>
            </Menu.Item>
          )}
          {!isNurse && (
            <Menu.Item key="attendance">
              <Link to={{ pathname: `${defaultPath}/attendance`, state: kidData }}>Attendance</Link>
            </Menu.Item>
          )}
          {!isNurse && (
            <Menu.Item key="gallery">
              <Link to={{ pathname: `${defaultPath}/gallery`, state: kidData }}>Gallery</Link>
            </Menu.Item>
          )}
          {!isNurse && (
            <Menu.Item key="dayLogs">
              <Link to={{ pathname: `${defaultPath}/dayLogs`, state: kidData }}>Day logs</Link>
            </Menu.Item>
          )}

          <Menu.Item key="health">
            <Link to={{ pathname: `${defaultPath}/health`, state: kidData }}>Health</Link>
          </Menu.Item>
          {!isNurse && (
            <Menu.Item key="badges">
              <Link to={{ pathname: `${defaultPath}/badges`, state: kidData }}>Tokens</Link>
            </Menu.Item>
          )}
          {!isNurse && (
            <Menu.Item key="medalBank">
              <Link to={{ pathname: `${defaultPath}/medalBank`, state: kidData }}>Medal Bank</Link>
            </Menu.Item>
          )}
        </Menu>
        <Spacer />
        <Row align="top">
          {showChildView && (
            <Col
              span={4}
              style={{
                backgroundColor: "white",
                borderRadius: "4px",
                overflow: "hidden",
                boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
              }}
            >
              <CustomBgImage type="carer" src={kidData?.image ?? ""} />
              <div
                style={{
                  padding: "12px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title
                  level={4}
                  style={{
                    color: "#E50A5C",
                    textAlign: "center",
                    margin: "0px",
                  }}
                >
                  {`${kidData?.firstName ?? ""} ${kidData?.lastName ?? ""}`}
                </Title>
                <Divider style={{ margin: "10px 0px" }} />
                <Text strong style={{ fontSize: "1.2rem" }}>
                  Carers
                </Text>
                <Spacer size={10} />
                <Row gutter={[12, 12]}>
                  {kidData?.childPerson?.map((item: any) => (
                    <Col span={12}>
                      <Link to={`/main/schools/${kidData.schoolId}/carers/${item.personId}`}>
                        <CustomBgImage type="carer" src={item.person.personImage ?? ""} size={10} />
                        <Spacer size={5} />
                        <Text strong style={{ textAlign: "center" }}>
                          {item.person.firstName} {item.person.lastName}
                        </Text>
                      </Link>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          )}
          <Col span={showChildView ? 19 : 24} offset={showChildView ? 1 : 0}>
            <AnimatePresence>
              <Switch>
                <PrivateRoute path="/main/schools/:id/kids/:kidId/financials" component={Financials} />
                <motion.div
                  key={props.location.pathname}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <PrivateRoute path="/main/schools/:id/kids/:kidId/addKid" component={AddKid} />
                  <PrivateRoute path="/main/schools/:id/kids/:kidId/skills" component={Skills} />
                  <PrivateRoute
                    path="/main/schools/:id/kids/:kidId/attendance"
                    component={(props: any) => {
                      return (
                        <Attendance
                          {...props}
                          attendanceUrl={`attendance/config/child/${props.match.params.kidId}`}
                          role="child"
                        />
                      );
                    }}
                  />
                  <PrivateRoute path="/main/schools/:id/kids/:kidId/gallery" component={Gallery} />
                  <PrivateRoute path="/main/schools/:id/kids/:kidId/dayLogs" component={DayLogs} />
                  <PrivateRoute path="/main/schools/:id/kids/:kidId/health" component={Health} />
                  <PrivateRoute path="/main/schools/:id/kids/:kidId/badges" component={Badges} />
                  <PrivateRoute path="/main/schools/:id/kids/:kidId/medalBank" component={MedalBank} />
                </motion.div>
              </Switch>
            </AnimatePresence>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Kid;

const StyledText = styled(Text)`
  font-size: 1.3rem;
  text-align: center;
`;
