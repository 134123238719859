import { UtilityCategory } from "types/models";

export const getTaxesFeesListUrl = (schoolId: number) => `school/${schoolId}/financials/settings/fees-taxes`;

export const updateTaxesFeesListUrl = (schoolId: number) =>
  `school/${schoolId}/financials/settings/fee-taxes`;

export type TaxData = {
  id: number;
  name: string;
  tax: {
    id: null | number;
  };
};

export interface TaxesDataFees {
  annualFees: TaxData[];
  campsFees: TaxData[];
  oneTimeFees: TaxData | null;
  otherFees: TaxData[];
  termFees: TaxData[];
  utilityFees: (TaxData & { category: UtilityCategory })[];
}

export type FeeTax = { schoolId: number; feeId: number; taxId: number };

export type FeeTaxes = {
  annualFee: FeeTax[];
  oneTimeFee: FeeTax[];
  campsFee: FeeTax[];
  termsFee: FeeTax[];
  othersFee: FeeTax[];
  foodFee: FeeTax[];
  materialFee: FeeTax[];
  transportFee: FeeTax[];
};

export const getTaxesListUrl = (schoolId: number) => `school/${schoolId}/financials/settings/taxes`;

export interface Tax {
  id: number;
  name: string;
  amount: number;
}

export const getCreateTaxUrl = (schoolId: number) => `school/${schoolId}/financials/settings/taxes`;

export const getDeleteTaxUrl = (schoolId: number, taxId: number) =>
  `school/${schoolId}/financials/settings/taxes/${taxId}`;

export type createTaxBody = {
  name: string;
  amount: number;
};

export const getUpdateTaxUrl = (schoolId: number, id: number) =>
  `school/${schoolId}/financials/settings/taxes/${id}`;

export type FinancialsSchoolSettingsType = {
  oneTimeFees: boolean;
  annualFeesRecurringRegistration: boolean;
  annualFeesMedical: boolean;
  annualFeesOther: boolean;
  termFees: boolean;
  campFees: boolean;
  foodTransportMaterialFees: boolean;
  otherfees: boolean;
  discounts: boolean;
  taxes: boolean;
  id: number;
};

export interface SchoolFinancialSetting {
  campsCount: number;
  currencyCount: number;
  holidays: number;
  termsCount: number;
  timesCount: number;
}

export const getCreateSchoolSettings = (schoolId: string) => `school/${schoolId}/financials/settings`;

export const getUtilityFeesList = (schoolId: number) => `school/${schoolId}/financials/settings/utility-fees`;

export const postUtilityFee = (schoolId: number) => `school/${schoolId}/financials/settings/utility-fees`;

export type UtilityFee = {
  name: string;
  amount: number;
  category: Uppercase<UtilityCategory>;
  timeId: number;
};

export const updateUtilityFee = (schoolId: number, utilityFeeId: number) =>
  `school/${schoolId}/financials/settings/utility-fees/${utilityFeeId}`;

export const deleteUtilityFee = (schoolId: number, utilityFeeId: number) =>
  `school/${schoolId}/financials/settings/utility-fees/${utilityFeeId}`;
