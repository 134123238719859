import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {} from 'react-router';
import 'antd/dist/antd.css';
import './App.css';
import 'react-phone-number-input/style.css';
import { Login, Main, School } from './screens';
import { PrivateRoute } from 'components';
import { useAppSelector } from 'store';
import { mainAdminPanelRoles } from 'const/access';

function App() {
  const { role } = useAppSelector((store) => store.user);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/main/schools/:id" component={School} />
          {role && mainAdminPanelRoles.includes(role) && (
            <>
              <PrivateRoute path="/main" component={Main} />
            </>
          )}
          <Route path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
