import { FC } from "react";
import { Layout } from "antd";
import { DashboardNavigation2, FinancialRoute, PrivateRoute, TopNavigation } from "components";
import { useAppSelector } from "store";
import { AnimatePresence, motion } from "framer-motion";
import { Switch, useLocation } from "react-router-dom";
import { Carers, Documents, Holidays, Partners, PlanTemplates, Schools } from "screens";
import Countries from "screens/Countries";
import Languages from "screens/Languages";
import Religions from "screens/Religions";
import Shifts from "screens/Shifts";
import AddSchool from "screens/AddSchool";
import AddPartner from "screens/AddPartner";
import Skills from "screens/Skills";
import Domains from "screens/Domains";
import Staff from "screens/Staff";
import { ViewType } from "enums";
import Kids from "screens/Kids";
import AddStaff from "screens/AddStaff";
import AddCarer from "screens/AddCarer";
import TermUnits from "screens/TermUnits";
import Vacations from "screens/Vacations";
import CampsList from "screens/Camps/CampsList";
import AddCamp from "screens/Camps/AddCamp";
import EditCamp from "screens/Camps/EditCamp";
import TermList from "screens/TermsV2/TermsList";
import AddTerm from "screens/TermsV2/AddTerm";
import EditTerm from "screens/TermsV2/EditTerm";

const { Sider, Content } = Layout;

const Main: FC = props => {
  const location = useLocation();

  return (
    <>
      <TopNavigation />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sider
          style={{
            height: "100vh",
            position: "sticky",
            top: "0",
          }}
        >
          <DashboardNavigation2 />
        </Sider>
        <Layout
          style={{
            minHeight: "100vh",
          }}
        >
          <Content
            style={{
              padding: "24px 16px 0",
              minHeight: "100%",
              backgroundColor: "#f8f8f8",
            }}
          >
            <AnimatePresence>
              <motion.div
                key={location.pathname}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Switch>
                  <PrivateRoute path="/main/schools" component={Schools} exact />
                  <PrivateRoute path="/main/skills" component={Skills} exact />
                  <PrivateRoute path="/main/domains" component={Domains} exact />
                  <PrivateRoute path="/main/partners" component={Partners} exact />
                  <PrivateRoute
                    path="/main/carers"
                    component={(props: any) => <Carers {...props} viewType={ViewType.MAIN_PANEL} />}
                    exact
                  />
                  <PrivateRoute
                    path="/main/addCarer/:carerId"
                    component={(props: any) => <AddCarer {...props} viewType={ViewType.MAIN_PANEL} />}
                    exact
                  />
                  <PrivateRoute
                    path="/main/kids"
                    component={(props: any) => <Kids {...props} viewType={ViewType.MAIN_PANEL} />}
                    exact
                  />
                  <PrivateRoute
                    path="/main/staff"
                    component={(props: any) => <Staff {...props} viewType={ViewType.MAIN_PANEL} />}
                    exact
                  />
                  <PrivateRoute
                    path="/main/addStaff"
                    component={(props: any) => <AddStaff {...props} viewType={ViewType.MAIN_PANEL} />}
                    exact
                  />
                  <PrivateRoute
                    path="/main/addStaff/:staffId"
                    component={(props: any) => <AddStaff {...props} viewType={ViewType.MAIN_PANEL} />}
                    exact
                  />
                  <PrivateRoute path="/main/documents" component={Documents} exact />
                  <PrivateRoute path="/main/countries" component={Countries} exact />
                  <PrivateRoute path="/main/languages" component={Languages} exact />
                  <PrivateRoute path="/main/religions" component={Religions} exact />
                  <PrivateRoute path="/main/holidays/:holidayId" component={Holidays} exact />
                  <PrivateRoute path="/main/shifts" component={Shifts} exact />
                  <PrivateRoute path="/main/camps" component={CampsList} exact />
                  <PrivateRoute path="/main/v2/terms" component={TermList} exact />
                  <PrivateRoute path="/main/addCamp" component={AddCamp} exact />
                  <PrivateRoute path="/main/addTerm" component={AddTerm} exact />
                  <PrivateRoute path="/main/schools/:id/camps/edit/:campId" component={EditCamp} exact />
                  <PrivateRoute path="/main/schools/:id/terms/edit/:termId" component={EditTerm} exact />
                  <PrivateRoute path="/main/vacation" component={Vacations} exact />
                  <PrivateRoute path="/main/addSchool" component={AddSchool} exact />
                  <PrivateRoute path="/main/addPartner" component={AddPartner} exact />
                  <PrivateRoute path="/main/planTemplates" component={PlanTemplates} exact />
                  <PrivateRoute path="/main/termUnits" component={TermUnits} exact />
                  <FinancialRoute path="/main/invoice" />
                </Switch>
              </motion.div>
            </AnimatePresence>
          </Content>
        </Layout>
      </div>
    </>
  );
};

export default Main;
