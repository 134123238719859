import styled from 'styled-components';

const Card = styled.div`
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.02);
  padding: 24px;
`;

export default Card;
