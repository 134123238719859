import { Button, Input, Space, Spin, Table, Typography } from 'antd';
import { Container, DeleteButton, TopNavigation } from 'components';
import {
  SearchOutlined,
  UnorderedListOutlined,
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { FC, useRef, useState } from 'react';
import useSWR from 'swr';
import CountryModal, { InitialData } from './CountryModal';
import { useHistory } from 'react-router';
import { CountryType } from 'types';
import { axiosAuth } from 'helpers';

const { Title } = Typography;

const { Column } = Table;

const Countries: FC = () => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<InitialData | null>(null);
  // @ts-ignore
  const searchInput = useRef<null | Input>(null);
  const { data, error, revalidate } = useSWR<CountryType[]>('country');
  const isPending = !data && !error;

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
  return (
    <>
      <Spin spinning={isPending}>
        <Container>
          <Table
            title={() => {
              return (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Title
                    level={3}
                    style={{
                      color: '#E50A5C',
                      paddingBottom: '0px',
                      marginBottom: '0px',
                    }}
                  >
                    Countries
                  </Title>
                  <Button
                    color="#BDD000"
                    type="primary"
                    style={{
                      backgroundColor: '#BDD000',
                      borderColor: '#BDD000',
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setInitialData(null);
                      setTimeout(() => {
                        setIsModalVisible(true);
                      }, 0);
                    }}
                  >
                    Add new
                  </Button>
                </div>
              );
            }}
            dataSource={data}
            pagination={{
              pageSize: 20,
            }}
          >
            <Column
              title="Title"
              key="title"
              dataIndex="title"
              render={(title: CountryType['title']) => (
                <Space size="middle">{title}</Space>
              )}
              width="60%"
              sorter={(a, b) => {
                return a.title.localeCompare(b.title);
              }}
              sortDirections={['descend']}
              {...getColumnSearchProps('title')}
            />
            <Column
              onFilter={(value, record: any) => record.status === value}
              filters={[
                { text: 'Active', value: 10 },
                { text: 'Inactive', value: 0 },
              ]}
              filterMultiple={false}
              title="Status"
              key="status"
              dataIndex="status"
              width="25%"
              render={(status: CountryType['status']) => (
                <Space size="middle">
                  {status === 10 ? 'Active' : 'Inactive'}
                </Space>
              )}
            />
            <Column
              title=""
              width="15%"
              render={(
                data,
                {
                  code,
                  title,
                  status,
                  moday,
                  wednesday,
                  tuesday,
                  thursday,
                  friday,
                  saturday,
                  sunday,
                  id,
                }: CountryType
              ) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'flex-end',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="text"
                      icon={<UnorderedListOutlined />}
                      style={{ marginRight: '10px', color: '#00A1E1' }}
                      onClick={() => history.push(`holidays/${id}`)}
                    />
                    <Button
                      type="text"
                      icon={<EditTwoTone twoToneColor="#00A1E1" />}
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        setInitialData(null);
                        let workDays = [];
                        if (moday) {
                          workDays.push('monday');
                        }
                        if (tuesday) {
                          workDays.push('tuesday');
                        }
                        if (wednesday) {
                          workDays.push('wednesday');
                        }
                        if (thursday) {
                          workDays.push('thursday');
                        }
                        if (friday) {
                          workDays.push('friday');
                        }
                        if (saturday) {
                          workDays.push('saturday');
                        }
                        if (sunday) {
                          workDays.push('sunday');
                        }

                        const initialData: InitialData = {
                          code,
                          title,
                          status,
                          workDays,
                          id,
                        };
                        setInitialData(initialData);
                        setTimeout(() => {
                          setIsModalVisible(true);
                        }, 0);
                      }}
                    />
                    <DeleteButton
                      onSubmit={async () => {
                        await axiosAuth.get(`country/delete/${id}`);
                        await revalidate();
                      }}
                      type="icon"
                    />
                  </div>
                );
              }}
            />
          </Table>
        </Container>
      </Spin>
      <CountryModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        afterSubmit={revalidate}
      />
    </>
  );
};

export default Countries;
