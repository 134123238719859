import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

const normFile = (
  info: UploadChangeParam<UploadFile<any>>,
  isBase64?: boolean
) => {
  let fileList = [...info.fileList];
  fileList = fileList.slice(-1);
  fileList = fileList.map((file) => {
    if (file.response) {
      file.url = file.response.url;
    }
    return file;
  });

  return fileList;
};

export default normFile;
