import { FC, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  Select,
  Checkbox,
  Row,
  Col,
  DatePicker,
} from 'antd';
import { Spacer } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import { axiosAuth } from 'helpers';
import { ErrorMessage } from 'enums';

const { Title } = Typography;
const { TextArea } = Input;

export type InitialData = {
  title: string;
  description: string;
  date: [Dayjs, Dayjs] | null;
  id: number;
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  initialData: InitialData | null;
  countryId: number;
  afterSubmit: () => Promise<void | boolean>;
};

const HolidayModal: FC<Props> = ({
  isVisible,
  onClose,
  initialData,
  countryId,
  afterSubmit,
}) => {
  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const [form] = Form.useForm();
  const title = initialData ? 'Update Holiday' : 'Create Holiday';
  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    }, 0);
  }, [JSON.stringify(initialData)]);

  const onFinish = async (values: any) => {
    setIsUpdatePending(true);
    if (initialData) {
      const { date, title, description, id } = values;
      const [startDate, endDate] = date;
      await axiosAuth.post(`holiday/update/${initialData.id}`, {
        dateStart: dayjs(startDate).toDate(),
        dateEnd: dayjs(endDate).toDate(),
        countryId,
        title,
        description,
      });
    } else {
      const { date, ...rest } = values;
      const [startDate, endDate] = date;
      await axiosAuth.post(`holiday/create/${countryId}`, {
        dateStart: dayjs(startDate).toDate(),
        dateEnd: dayjs(endDate).toDate(),
        ...rest,
      });
    }
    await afterSubmit();
    setIsUpdatePending(false);
    onClose();
  };

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          {title}
        </Title>
      }
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form
        name="basic"
        initialValues={initialData ?? {}}
        onFinish={onFinish}
        form={form}
      >
        <Row>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            style={{ flex: 1, marginRight: '20px' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            style={{ flex: 1 }}
          >
            <Select>
              <Select.Option value={10}>Active</Select.Option>
              <Select.Option value={0}>Inactive</Select.Option>
            </Select>
          </Form.Item>
        </Row>
        <Spacer size={10} />
        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
        >
          <DatePicker.RangePicker style={{ width: '100%' }} />
        </Form.Item>
        <Spacer size={10} />
        <Form.Item label="Description" name="description">
          <TextArea />
        </Form.Item>
        <Spacer size={10} />
        <Form.Item wrapperCol={{ offset: 20 }}>
          <Button
            loading={isUpdatePending}
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#BDD000',
              borderColor: '#BDD000',
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default HolidayModal;
