import { Table, Space } from "antd";
import Column from "antd/lib/table/Column";
import { Spacer } from "components";

interface Props {
  completedEvents: { name: string }[];
}

export const CompletedEvents = ({ completedEvents }: Props) => {
  return (
    <>
      <Spacer />
      <Table
        dataSource={completedEvents}
        pagination={{
          pageSize: 5,
        }}
      >
        <Column
          title="Completed Terms, Camps, Programs"
          key="name"
          dataIndex="name"
          render={value => <Space size="middle">{value}</Space>}
        />
      </Table>
    </>
  );
};
