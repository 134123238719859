import { Button, Space, Table, Typography } from 'antd';
import { DeleteButton, Spacer } from 'components';
import groupBy from 'helpers/groupBy';
import {
  EditTwoTone,
  DeleteTwoTone,
  UnorderedListOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { FC, useMemo, useState } from 'react';
import useSWR from 'swr';
import SubCategoryModal from './SubCategoryModal';
import { axiosAuth } from 'helpers';

const { Column } = Table;
const { Title } = Typography;

type Props = {
  onDetailsClick: (subCategory: DevelopmentCategoryType) => void;
  subCategoryId: number | null;
};

type DevelopmentCategoryType = {
  createdAt: number;
  createdBy: number;
  id: number;
  journeyCategoryId: number;
  name: string;
  rank: number;
  status: number;
  updatedAt: number;
  updatedBy: number;
};

const DevelopmentCategory: FC<Props> = ({ onDetailsClick, subCategoryId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState<{} | null>(null);

  const { data, revalidate } = useSWR<DevelopmentCategoryType[]>(
    subCategoryId ? `journey-sub-category/${subCategoryId}` : null
  );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title
          level={3}
          style={{
            color: '#E50A5C',
            paddingBottom: '0px',
            marginBottom: '0px',
          }}
        >
          Learning outcomes
        </Title>
        <Button
          color="#BDD000"
          type="primary"
          style={{
            backgroundColor: '#BDD000',
            borderColor: '#BDD000',
          }}
          icon={<PlusOutlined />}
          onClick={() => {
            setInitialData(null);
            setTimeout(() => {
              setIsModalVisible(true);
            }, 0);
          }}
        >
          Add new
        </Button>
      </div>
      <Spacer />
      <Table dataSource={data} pagination={false}>
        <Column
          title="rank"
          key="rank"
          dataIndex="rank"
          render={(rank: string) => <Space size="middle">{rank}</Space>}
          width="10%"
        />
        <Column
          title="name"
          key="name"
          dataIndex="name"
          render={(name: string) => <Space size="middle">{name}</Space>}
          width="50%"
        />
        <Column
          title="status"
          key="status"
          dataIndex="status"
          render={(status: number) => (
            <Space size="middle">{status === 10 ? 'Active' : 'Inactive'}</Space>
          )}
          width="10%"
        />
        <Column
          title=""
          key="actions"
          dataIndex=""
          render={(dataItem: DevelopmentCategoryType) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignContent: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  type="text"
                  icon={<EditTwoTone twoToneColor="#00A1E1" />}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    setInitialData(dataItem);
                    setTimeout(() => {
                      setIsModalVisible(true);
                    }, 0);
                  }}
                />
                <Button
                  type="text"
                  icon={<UnorderedListOutlined style={{ color: '#00A1E1' }} />}
                  onClick={() => onDetailsClick(dataItem)}
                  style={{ marginRight: '10px' }}
                />
                <DeleteButton
                  onSubmit={async () => {
                    await axiosAuth.get(
                      `journey-sub-category/delete/${dataItem.id}`
                    );
                    await revalidate();
                  }}
                  type="icon"
                />
              </div>
            );
          }}
          width="10%"
        />
      </Table>
      <Spacer size={40} />
      <SubCategoryModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        initialData={initialData}
        afterSubmit={revalidate}
        subCategoryId={subCategoryId ?? 0}
      />
    </>
  );
};

export default DevelopmentCategory;
