export const getOneTimeFeeUrl = (schoolId: string) =>
  `/school/${schoolId}/financials/settings/one-time-fee`;

export const editOneTimeFeeUrl = (schoolId: string, feeId: number) =>
  `/school/${schoolId}/financials/settings/one-time-fee/${feeId}`;

export const getRequiredSettingsUrl = (schoolId: string) =>
  `/school/${schoolId}/financials/settings/required`;

export const getSchoolFinancialSettings = (schoolId: string) =>
  `school/${schoolId}/financials/settings`;

export type OneTimeFeeRequest = {
  oneTimeFees: boolean;
};

export interface OneTimeFeeResponse {
  amount: number;
  createdAt?: Date;
  id: number;
}
