import { Button, Typography, message } from "antd";
import styled from "styled-components";
import { SummaryInfo } from "../components";
import { FinancialsSupraTable } from "./components";
import useSWR from "swr";
import { NoDataInfo } from "screens/Financials/components";
import { useState } from "react";
import { axiosAuth } from "helpers";
import { getSupraSendInvoiceUrl } from "./helpers";
import { getSupraFinancialDataUrl } from "./helpers/getSupraFinancialDataUrl";

export interface ISchoolBalance {
  schoolId: number;
  balance: number;
  lastPaymentDate: string;
  lastPayment: number;
  overdue: number;
  schoolName: string;
  schoolImage: string | null;
}
export interface ISupraFinancialData {
  overdue: number;
  revenue: number;
  schoolBalances: ISchoolBalance[];
}

export const FinancialsSupra = () => {
  const [selectedSchoolId, setSelectedSchoolId] = useState<number[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { Title } = Typography;

  const { data: supraFinancialData, revalidate } = useSWR<ISupraFinancialData>(getSupraFinancialDataUrl());

  const sendInvoice = async () => {
    const requestBody = { schoolIds: selectedSchoolId };

    try {
      if (!selectedSchoolId || selectedSchoolId?.length <= 0) return;
      setIsLoading(true);

      await axiosAuth.post(getSupraSendInvoiceUrl(), requestBody);
      message.success("Invoice has been send");
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  if (!supraFinancialData) {
    return (
      <NoDataContainer>
        <NoDataInfo title="No financial history data" />
      </NoDataContainer>
    );
  }

  const parsedSupraFinancialData = supraFinancialData.schoolBalances.map(data => ({
    ...data,
    key: data.schoolId,
  }));

  const summary = [
    { name: "Revenue", price: supraFinancialData.revenue },
    { name: "Overdue", price: supraFinancialData.overdue },
  ];

  const sendInvoiceDisabled = selectedSchoolId?.length === 0 || !selectedSchoolId;

  return (
    <>
      <InvoiceContainer>
        <TitleAndButtonContainer>
          <Title level={3} style={{ textAlign: "left", color: "#E50A5C" }}>
            Summary
          </Title>
          <Button
            disabled={sendInvoiceDisabled}
            loading={isLoading}
            type="primary"
            htmlType="button"
            style={{
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
            }}
            onClick={sendInvoice}
          >
            Send invoice
          </Button>
        </TitleAndButtonContainer>
        <SummaryContainer>
          <AmountContainer>
            {summary.map(({ name, price }) => (
              <SummaryInfo title={name} price={price} />
            ))}
          </AmountContainer>
        </SummaryContainer>
      </InvoiceContainer>
      <FinancialsSupraTable
        dataSource={parsedSupraFinancialData}
        revalidate={revalidate}
        setSelectedSchoolId={setSelectedSchoolId}
      />
    </>
  );
};

const SummaryContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;
const AmountContainer = styled.div`
  display: flex;
`;
const InvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 32px;
  border-radius: 10px;
`;

const TitleAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
