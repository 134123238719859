import { Card, Spin, Tabs, Typography } from "antd";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import { TermRequest } from "types/rest/terms";
import TermInfoPanel from "../TermInfoPanel/TermInfoPanel";
import TermSummaryPanel from "../TermSummaryPanel";

const { TabPane } = Tabs;

const { Title } = Typography;

type Props = RouteComponentProps<{ id?: string }, any, { id?: string }>;

const AddTerm = ({ match, location }: Props) => {
  const [activeKey, setActiveKey] = useState("1");
  const [terms, setTerms] = useState<TermRequest | null>(null);
  const [disableSummary, setDisableSummary] = useState(true);
  const [disableCreateTab, setDisableCreateTab] = useState(false);

  const schoolId = match.params.id ?? location.state?.id;
  const isPending = !schoolId;

  const handleChangeTab = (item: string) => {
    setActiveKey(item);
  };

  const handleSaveData = (terms: TermRequest) => {
    setTerms(terms);
    setActiveKey("2");
    setDisableSummary(false);
    setDisableCreateTab(true);
  };

  const handleChangeTabs = () => {
    setActiveKey("1");
    setDisableCreateTab(false);
    setDisableSummary(true);
  };

  return (
    <Spin spinning={isPending}>
      {schoolId && (
        <Card>
          <Title level={4} style={{ color: "#E50A5C", margin: "0px" }}>
            Add term
          </Title>
          <div style={{ marginTop: 20 }}>
            <Tabs
              type="card"
              activeKey={activeKey}
              onChange={(item) => handleChangeTab(item)}
              tabBarGutter={10}
            >
              <TabPane
                tab="Create term"
                key={1}
                closable={false}
                disabled={disableCreateTab}
              >
                <TermInfoPanel saveTerm={handleSaveData} schoolId={schoolId} />
              </TabPane>
              <TabPane
                tab="Summary"
                key={2}
                closable={false}
                disabled={disableSummary}
              >
                <TermSummaryPanel
                  terms={terms}
                  changeTabs={handleChangeTabs}
                  schoolId={schoolId}
                />
              </TabPane>
            </Tabs>
          </div>
        </Card>
      )}
    </Spin>
  );
};

export default AddTerm;
