import React, { useEffect, useState } from "react";
import { Spin, Switch, Typography } from "antd";
import useSWR from "swr";
import { Spacer } from "components";
import { RouteComponentProps } from "react-router-dom";
import {
  AnnualFeeResponse,
  AnnualFeeType,
  CreateAnnualSchoolSettingsRequest,
  FinancialsSchoolSettingsType,
  getAnnualFeesUrl,
  getCreateSchoolSettings,
  getSchoolFinancialSettings,
} from "types";
import { axiosAuth, parseAnnualFeesData } from "helpers";
import { useForm } from "antd/lib/form/Form";
import AnnualFee from "./AnnualFee/AnnualFee";

const { Text } = Typography;
type Props = RouteComponentProps<{ id?: string }, any, { id?: string }>;

const annualTables = [
  AnnualFeeType.RECURRING_REGISTRATION_FEE,
  AnnualFeeType.MEDICAL_FEE,
  AnnualFeeType.OTHERS_FEE,
];

const AnnualFees = ({ match, location }: Props) => {
  const [form] = useForm();

  const schoolId = match.params.id ?? location.state?.id;
  const [hasRegistretionFee, setHasRegistratonFee] = useState(false);

  const { data: schoolSettingsData, error: schoolSettingsError } = useSWR<FinancialsSchoolSettingsType>(
    schoolId ? getSchoolFinancialSettings(schoolId) : null
  );

  const { data: schoolData } = useSWR(schoolId ? `school/${schoolId}` : null);
  const schoolCurrency = schoolData?.currency;

  const { data: annualFeeData, revalidate } = useSWR<AnnualFeeResponse[]>(
    schoolId ? getAnnualFeesUrl(schoolId) : null
  );

  const handleSwitchOneTimeFee = (val: boolean) => {
    setHasRegistratonFee(val);
    sendAnnualFeeSettings(val);
  };

  const sendAnnualFeeSettings = (val: boolean) => {
    if (!schoolId) return;
    const body: CreateAnnualSchoolSettingsRequest = {
      annualFeesMedical: val,
      annualFeesOther: val,
      annualFeesRecurringRegistration: val,
    };
    axiosAuth.put(getCreateSchoolSettings(schoolId), body);
  };

  useEffect(() => {
    if (schoolSettingsData) {
      const { annualFeesMedical, annualFeesOther, annualFeesRecurringRegistration } = schoolSettingsData;
      const hasFee = annualFeesMedical || annualFeesOther || annualFeesRecurringRegistration;
      setHasRegistratonFee(hasFee);
    }
  }, [schoolSettingsData]);

  const isPending = !annualFeeData;

  if (isPending) {
    return <Spin spinning />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Text style={{ fontSize: 14, marginRight: 15 }}>
          Charge a RECURRING application, registration or enrollment Fee (that is payable every year)?
        </Text>
        <Switch
          checkedChildren=""
          unCheckedChildren=""
          defaultChecked
          onChange={val => {
            handleSwitchOneTimeFee(val);
          }}
          checked={hasRegistretionFee ?? false}
        />
      </div>
      {annualTables.map((item, index) => {
        return (
          <AnnualFee
            type={item}
            key={index}
            hasRegistretionFee={hasRegistretionFee}
            schoolId={schoolId}
            revalidate={revalidate}
            fee={annualFeeData ? parseAnnualFeesData(annualFeeData, item)[0] : null}
            schoolCurrency={schoolCurrency}
          />
        );
      })}
      <Spacer size={80} />
    </>
  );
};

export default AnnualFees;
