import { greaterThanZeroValidator } from "helpers";

export const discountEditableCellRules = (
  title: string,
  isNumberInput: boolean,
  isPercentageInput: boolean,
  disableNegaitiveDigits: boolean
) => {
  const defaultRule = {
    required: true,
    message: `Please Input ${title}!`,
  };

  const amountPercentageRules = {
    max: 100,
    type: "number" as any,
    message: `Maximum value for percentage is 100%!`,
  };

  const rulesArr = [];

  rulesArr.push(defaultRule);

  if (isNumberInput && disableNegaitiveDigits) {
    rulesArr.push({
      validator: (_: any, value: any) => greaterThanZeroValidator(_, value),
    });
  }

  if (isPercentageInput) {
    rulesArr.push(amountPercentageRules);
  }

  return rulesArr;
};
