export const employee = [
  'Rhian Thomas',
  'Khoulood Jaber',
  'Sharon Martinez',
  'Samar Taha',
  'Lean Abano',
  'Marites Cabarrubias',
  'Angelei Begino',
  'Flora  Gubaton',
  'Cokie Modina',
  'Rose Diasana',
  'Samar Mohammed Alhalal',
  'Zara Bakri',
  'Tina De Bernardo',
  'Lavinia Irimia',
  'Inas Mahboub',
  'Hala  Mansour',
  'Hadeer Samy',
  'Vera Mikhael',
  'Fenie Apilado',
  'Laiza Atienza',
  'Kristal Basacoy',
  'Kathleen Gapunay',
  'Jinna Masicampo',
  'Ria Joy Rodriguez',
  'Fareeda AlBash',
  'Reyen Flores',
  'Shefat Islam',
  'Ghada Janahi',
  'Sarah Dawood  Salman Sadeq',
  'Courtney Smith',
  'Lilia  Sword',
  'Cyril Villanueva',
  'Arianne Zgela',
  'Hala Ibrahim',
  'Bikash Karki',
  'Jeya Jenganlall',
  'Sri Devi Muppiti',
  'Sarah Hassiman',
  'fatima Adlawan',
  'Victoriya Amir Aslanova',
  'Khanim Aghayeva',
  'Nabat Gahramanova',
  'Nigar Khalilova',
  'Irada Safarova',
  'Konul  Akhundova',
  'Gulshan Mammadova',
  'Gulnara Abdullayeva',
  'Leyla Aghayeva',
  'Sahila Aslanova',
  'Nurana Bayramova',
  'Arzu Zeinalova',
  'Zumrud Bayramova',
  'Asmar Jabiyeva',
  'Aytan  Nadirli',
  'Vusala Naghiyeva',
  'Nurana Shikhaliyeva',
  'Gulnara Mammadli',
  'Ayshan Mammadzada',
  'Irada Haybatova',
  'Irada Aslanova',
  'Sabina Aliyeva',
  'Elkhan Jabiyev',
  'Jesse Beer',
  'Zeyva Yusifzada',
  'Adila Aliyeva',
  'Viktoriya Kadirova',
  'Najiba Heybatova',
  'Vugar Huseynov',
  'James Allen Beery',
  'Pərvinaz Məmmədova',
  'Nərgiz İbrahimova',
  'fun testing',
];

export const child = [
  'Franko Lorenzo Ibasco',
  'Pepper Rae Ingemansson',
  'Osama Al Mufti',
  'Eloise  Jaques',
  'Arthur Chubarov',
  'Sultan Al Khoori',
  'Ahmed Al Khoori',
  'Eliana Boyd',
  'Humaid Al Mheiri',
  'Buchen Zhang',
  'Hortense De Noell',
  'Sofiia Osina',
  'Wilber Grainger',
  'Mais Nimer',
  'Wyatt Boyd',
  'Mera Abdel Rahim',
  'Hamda Al Muhairi',
  'Hessa Al Mheiri',
  ' Fatima  Al Yammahi',
  ' Luna  Jaber ',
  'Adeli  Bolat ',
  'Carlotta Felugo ',
  'Elias Ansari',
  'Albarys Saken',
  'Aurik Roy Sarkar',
  'Jesse Zayd Abadi',
  'Naufal  Khurram',
  'Chaaru Jijo ',
  'Sara Abubshait',
  'Joori AlHaddad',
  'Mohamed Alghatam',
  'Niamh Doyle',
  'Samara Dhaduk',
  'Sara Badaawi',
  'Turki Alderete',
  'Aurelian Giacchetti',
  'Charles Petraborg',
  'Deena Alwardi',
  'Reika Kawarazaki',
  'Yasied Al Hajri',
  'Amir Al Abbas',
  'Talia  Hammami ',
  'Yasien Moradi',
  'Sayf  Abumettleq',
  'Dali Joujou',
  'Carmen Vicens',
  'Eva  Corneanu',
  'Gabriella  Pegg ',
  'Charlie  Parker ',
  'Ranesh  Tudu',
  'Felix Buelow',
  'Katie  Nally',
  'Anastasia Xenakis',
  'Nayla Abdulqader',
  'Abdulla Al Mosawi',
  'Reem Alwardi',
  'Enaya Raheel',
  'Talia Eljack ',
  ' Ahmad  Al Suwaidi',
  'Noa Dijanic',
  'Jayden Soliman',
  'Finley Pollard',
  'Maddi Calahorra Arranz',
  'Tia Saliba',
  'Mariam Abduo',
  'Kaiah Lockman',
  'Fajer Al Mansoori',
  'Al Fares Khan',
  'Shamma Al Yammahi',
  'Al Reem Aldhanhani',
  'Panache Dhumale',
  'Aya Boulaksil',
  'Clelia  Cocco ',
  'Arsema  Mesfin',
  'Hamdan Al Mazrouei',
  ' Leo  Chopra ',
  'Fatima Al Ahmed ',
  'Mohamed Al Banna',
  'Bayan  Elalani ',
  'William  Connolly ',
  'Johanna Macleod ',
  'Kaysan  Brown ',
  'Aadhav  Prinu ',
  'Talia Abd Elhai',
  'Adam  Budair',
  'Amal   Ahmad',
  'Zainab  Kassem ',
  'Alyssa Fazekas',
  'Leo  Kessler',
  'Juntaro  Takanishi ',
  'Alexander  Trad',
  'Stamatia Kosmidi ',
  'Zahia  Al Shamsi ',
  'Mimi  Zhang ',
  'Meera  Al Katheeri ',
  'Katie  Dickinson ',
  'Ira  Clark ',
  'Grace  Lemoine ',
  'Faris  Al Bostani ',
  'Mohamad Al Bostani',
];

export const normChild = child.map((item) => ({ label: item, value: item }));
export const normEmployee = employee.map((item) => ({
  label: item,
  value: item,
}));
