import { FC, useState } from 'react';
import { Spin, Row, Col, Typography, Empty, Form, Input, Button } from 'antd';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { Container, Spacer, UnexpectedError } from 'components';
import { axiosAuth } from 'helpers';

const { Title, Text } = Typography;

type Supplier = {
  address: string;
  createdAt: number;
  createdby: number | null;
  email: string;
  fullName: string;
  id: number;
  image: string | null;
  mobilePhone: string;
  officePhone: string;
  role: string;
  schoolId: number;
  status: number;
  updatedAt: number;
  updatedBy: number | null;
  websiteUrl: string;
};

const Suppliers: FC = (props: any) => {
  const schoolId = props.match.params.id;
  const [searchData, setSearchData] = useState<Supplier[] | null>(null);
  const { data, error } = useSWR<Supplier[]>(`school/suppliers/${schoolId}`);

  const isPending = !data && !error;

  const onSearch = async (values: any) => {
    const body = values;
    const data = (await axiosAuth.post(`supplier/search/${schoolId}`, body))
      .data;
    setSearchData(data);
  };

  const validData = searchData ?? data;

  const renderContent = () => {
    if (error) {
      return <UnexpectedError />;
    }
    return (
      <>
        <Form
          name="basic"
          onFinish={onSearch}
          autoComplete="off"
          onFinishFailed={() => {}}
          style={{
            backgroundColor: 'white',
            cursor: 'pointer',
            borderRadius: '4px',
            overflow: 'hidden',
            boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.02)',
            padding: '24px 24px 0px 24px',
          }}
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item name="fullName">
                <Input placeholder="name" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="role">
                <Input placeholder="role" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="address"
                style={{
                  marginBottom: '10px',
                }}
              >
                <Input placeholder="address" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 0]} align="middle" justify="space-between">
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  color="red"
                  style={{
                    backgroundColor: '#BDD000',
                    outlineColor: '#BDD000',
                    borderColor: '#BDD000',
                  }}
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Spacer size={48} />
        <Row gutter={[48, 48]} justify="space-between">
          {validData?.map((item) => (
            <Col span={4} key={item.id}>
              <Link to={`addSupplier/${item.id}`}>
                <div
                  style={{
                    backgroundColor: 'white',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    overflow: 'hidden',
                    boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.02)',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      padding: '24px',
                    }}
                  >
                    <Title
                      level={4}
                      style={{
                        color: '#E50A5C',
                        textAlign: 'center',
                      }}
                    >
                      {item.role}
                    </Title>
                    <Spacer size={10} />
                    <Text
                      type="secondary"
                      strong
                      style={{ fontSize: '1.3rem' }}
                    >
                      {item.fullName}
                    </Text>
                    <Spacer size={10} />
                    <Text type="secondary" style={{ fontSize: '1.3rem' }}>
                      {item.address}
                    </Text>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </>
    );
  };

  return (
    <Container>
      <Spin spinning={isPending}>{renderContent()}</Spin>
      <Spacer size={100} />
    </Container>
  );
};

export default Suppliers;
