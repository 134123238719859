export const discountType = {
  0: 'Percentage',
  10: 'Amount',
};

export const discountTypeList = Object.entries({
  ...discountType,
}).map(([key, val]) => ({
  label: val,
  value: parseInt(key),
}));

export const discountTypeFilter = Object.entries({
  ...discountType,
}).map(([key, val]) => ({
  text: val,
  value: parseInt(key),
}));
