import { Card, Spin, Tabs, Typography } from "antd";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import SummaryPanel from "../SummaryPanel";
import CreateCampPanel from "../CreateCampPanel";
import { CampRequest } from "types/rest";

const { TabPane } = Tabs;

const { Title } = Typography;

type Props = RouteComponentProps<{ id?: string }, any, { id?: string }>;

const AddCamp = ({ match, location }: Props) => {
  const [activeKey, setActiveKey] = useState("1");
  const [camps, setCamps] = useState<CampRequest | null>(null);
  const [disableSummary, setDisableSummary] = useState(true);
  const [disableCreateTab, setDisableCreateTab] = useState(false);

  const schoolId = match.params.id ?? location.state?.id;
  const isPending = !schoolId;

  const handleChangeTab = (item: string) => {
    setActiveKey(item);
  };

  const handleSaveData = (camps: CampRequest) => {
    setCamps(camps);
    setActiveKey("2");
    setDisableSummary(false);
    setDisableCreateTab(true);
  };

  const handleChangeTabs = () => {
    setActiveKey("1");
    setDisableCreateTab(false);
    setDisableSummary(true);
  };

  return (
    <Spin spinning={isPending}>
      {schoolId && (
        <Card>
          <Title level={4} style={{ color: "#E50A5C", margin: "0px" }}>
            Add camp
          </Title>
          <div style={{ marginTop: 20 }}>
            <Tabs
              type="card"
              activeKey={activeKey}
              onChange={(item) => handleChangeTab(item)}
              tabBarGutter={10}
            >
              <TabPane
                tab="Create camp"
                key={1}
                closable={false}
                disabled={disableCreateTab}
              >
                <CreateCampPanel saveCamps={(camps) => handleSaveData(camps)} />
              </TabPane>
              <TabPane
                tab="Summary"
                key={2}
                closable={false}
                disabled={disableSummary}
              >
                <div>
                  <SummaryPanel
                    camps={camps}
                    changeTabs={handleChangeTabs}
                    schoolId={schoolId}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Card>
      )}
    </Spin>
  );
};

export default AddCamp;
