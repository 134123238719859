import { Button, Col, Form, FormInstance, Row, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { getSchoolYearUrl, AcademicYearResponse } from "types/rest/school";
import NewSchoolYearModal from "components/AcademicYearModal";
import { SelectValue } from "antd/es/select";
import useSWR from "swr";
import { useForm } from "antd/es/form/Form";

interface Props {
  schoolId: string;
  onChange: (value: SelectValue) => void;
  defaultValue?: number;
}

const AcademicYears = ({ schoolId, onChange, defaultValue }: Props) => {
  const [isAddSchoolYearModalVisible, setIsAddSchoolYearModalVisible] = useState(false);

  const [form] = useForm();

  const { data: academicYears, revalidate } = useSWR<AcademicYearResponse[]>(
    schoolId ? getSchoolYearUrl(schoolId) : null
  );

  const schoolYears = academicYears?.map(item => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const handleCancelSchoolYearModal = () => {
    setIsAddSchoolYearModalVisible(false);
  };

  const handleOnSubmitSchoolYear = () => {
    revalidate();
    setIsAddSchoolYearModalVisible(false);
  };

  useEffect(() => {
    if (defaultValue) {
      form?.setFieldsValue({ schoolYear: defaultValue });
      onChange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <Form form={form} name="basic" initialValues={{ schoolYear: defaultValue }}>
        <Row>
          <Col span={24}>
            <div
              style={{
                display: "flex",
              }}
            >
              <Form.Item name="schoolYear" style={{ width: "100%" }}>
                <Select
                  showSearch
                  options={schoolYears}
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleString() ?? "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                  onChange={(value, label) => {
                    if (!value || !label) return;
                    onChange(value);
                  }}
                />
              </Form.Item>
              <Button
                type="text"
                icon={
                  <PlusCircleOutlined
                    style={{ marginLeft: 10 }}
                    onClick={() => setIsAddSchoolYearModalVisible(true)}
                  />
                }
                onClick={() => setIsAddSchoolYearModalVisible(true)}
              />
            </div>
          </Col>
        </Row>
      </Form>
      <NewSchoolYearModal
        isVisible={isAddSchoolYearModalVisible}
        onCancel={handleCancelSchoolYearModal}
        onSubmit={handleOnSubmitSchoolYear}
        schoolId={schoolId}
      />
    </>
  );
};

export default AcademicYears;
