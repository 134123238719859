import {
  Button,
  Form,
  Upload,
  Typography,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Divider,
  Switch,
  Checkbox,
  Timeline,
  message,
} from 'antd';
import { EditFilled } from '@ant-design/icons';
import {
  CustomLabel,
  CustomPhoneInput,
  DeleteButton,
  Spacer,
} from 'components';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { ClassType, Kid } from 'screens/Kids/Kids';
import dayjs from 'dayjs';
import { axiosAuth, normFile } from 'helpers';
import Documents from 'components/Documents';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { ErrorMessage, ViewType } from 'enums';
import getImageBase64 from 'helpers/getImageBase64';

type SupplierType = {
  fullName: string;
  schoolId: number;
  status: number;
  role: string;
  address: string;
  officePhone: string;
  mobliePhone: string;
  websiteUrl: string;
  email: string;
  id: number;
};

const { Title } = Typography;

type Props = {} & RouteComponentProps<
  { id?: string; supplierId?: string },
  any,
  { preselectedCarerId: number[] | undefined }
>;

const AddSupplier = (props: Props) => {
  const [isAddSupplierPending, setIsAddSupplierPending] = useState(false);
  const supplierId = props.match.params.supplierId;
  const schoolId = props.match.params.id;
  const history = useHistory();

  const {
    data: suppliersListData,
    error: suppliersListError,
    revalidate,
  } = useSWR<SupplierType[]>(`school/suppliers/${schoolId}`);

  const supplier = useMemo(() => {
    if (!suppliersListData || !supplierId) {
      return undefined;
    }
    return suppliersListData.find((item) => item.id === parseInt(supplierId));
  }, [suppliersListData, supplierId]);

  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(!supplierId);

  const title = supplier
    ? `Supplier - ${supplier.fullName}`
    : 'Add new supplier';

  const initialValues = supplier
    ? {
        ...supplier,
      }
    : {
        status: 10,
      };

  useEffect(() => {
    form.resetFields();
  }, [JSON.stringify(initialValues)]);

  return (
    <>
      <Form
        name="form_1"
        form={form}
        style={{
          backgroundColor: 'white',
          borderRadius: '4px',
          overflow: 'hidden',
          boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.02)',
          padding: '24px',
        }}
        initialValues={{ ...initialValues }}
        onFinish={async (values) => {
          setIsAddSupplierPending(true);
          if (supplierId) {
            await axiosAuth.post(`supplier/update/${schoolId}`, {
              ...values,
              schoolId,
            });
            message.success('Supplier updated succesfully');
            setIsEditMode(false);
          } else {
            await axiosAuth.post(`supplier/create/${schoolId}`, values);
            message.success('Supplier created succesfully');
            history.goBack();
          }
          await revalidate();
          setIsAddSupplierPending(false);
        }}
      >
        <Row>
          <Col flex={1}>
            <Title
              level={4}
              style={{ color: '#E50A5C', margin: '0px', textAlign: 'center' }}
            >
              {title}
            </Title>
          </Col>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.status !== next.status}
          >
            {({ getFieldValue, setFieldsValue }) => {
              return (
                <Form.Item name="status">
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Active"
                    onChange={(val) => setFieldsValue({ status: val ? 10 : 0 })}
                    checked={getFieldValue('status') === 10}
                    disabled={!isEditMode}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Row>
        {supplierId && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <Switch
              checkedChildren="Edit mode"
              unCheckedChildren="Edit mode"
              defaultChecked
              onChange={(val) => setIsEditMode(val)}
              checked={isEditMode}
            />
            <Spacer />
          </div>
        )}
        <Row gutter={[24, 0]}>
          <Col span={6}>
            <Form.Item
              name="fullName"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Full Name</CustomLabel>}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Input
                disabled={!isEditMode}
                style={
                  isEditMode
                    ? {}
                    : {
                        border: 'none',
                        backgroundColor: 'white',
                        padding: '4px 11px 4px 0px',
                      }
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="role"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Role</CustomLabel>}
              rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
            >
              <Input
                disabled={!isEditMode}
                style={
                  isEditMode
                    ? {}
                    : {
                        border: 'none',
                        backgroundColor: 'white',
                        padding: '4px 11px 4px 0px',
                      }
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="address"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Address</CustomLabel>}
            >
              <Input
                disabled={!isEditMode}
                style={
                  isEditMode
                    ? {}
                    : {
                        border: 'none',
                        backgroundColor: 'white',
                        padding: '4px 11px 4px 0px',
                      }
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="officePhone"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Office phone</CustomLabel>}
            >
              <CustomPhoneInput
                international
                value=""
                onChange={() => {}}
                disabled={!isEditMode}
              />
            </Form.Item>
          </Col>
          <Col
            span={6}
            offset={6}
            onClick={() => {
              if (!isEditMode) {
                const websiteUrl = form.getFieldValue('websiteUrl');
                if (websiteUrl) {
                  window.open(websiteUrl, '_blank');
                }
              }
            }}
            style={{ cursor: isEditMode ? 'auto' : 'pointer' }}
          >
            <Form.Item
              name="websiteUrl"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Website url</CustomLabel>}
            >
              <Input
                disabled={!isEditMode}
                style={
                  isEditMode
                    ? {}
                    : {
                        border: 'none',
                        backgroundColor: 'white',
                        padding: '4px 11px 4px 0px',
                        cursor: 'pointer',
                      }
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="email"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Email</CustomLabel>}
            >
              <Input
                disabled={!isEditMode}
                style={
                  isEditMode
                    ? {}
                    : {
                        border: 'none',
                        backgroundColor: 'white',
                        padding: '4px 11px 4px 0px',
                      }
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="mobliePhone"
              labelCol={{ span: 24 }}
              label={<CustomLabel>Mobile phone</CustomLabel>}
            >
              <CustomPhoneInput
                international
                value=""
                onChange={() => {}}
                disabled={!isEditMode}
              />
            </Form.Item>
          </Col>
        </Row>
        {isEditMode && (
          <>
            <Divider style={{ margin: '10px 0px', marginBottom: '20px' }} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <Form.Item>
                  <Button
                    loading={isAddSupplierPending}
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: '#BDD000',
                      borderColor: '#BDD000',
                    }}
                  >
                    Submit
                  </Button>
                </Form.Item>
                {supplierId && (
                  <DeleteButton
                    onSubmit={async () => {
                      await axiosAuth.get(`supplier/delete/${supplierId}`);
                      await revalidate();
                      history.goBack();
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </Form>
      <Spacer size={40} />
    </>
  );
};

export default AddSupplier;
