import { useState } from "react";
import { Button, Col, Form, Input, message, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { ErrorMessage } from "enums";
import { axiosAuth } from "helpers";
import { PaymentCategoryTypeDto } from "types";
import { getAddPaymentOrRefundUrl } from "../../helpers";
import styled from "styled-components";

interface IProps {
  schoolId: number;
  onFinish: () => void;
  revalidate: () => Promise<boolean>;
  form: FormInstance<any>;
}

export type IPaymentCategoryWithoutRefund = "PAYMENT" | "ADJUSTMENT REFUND" | "ADJUSTMENT INCREASE";

interface IFormValues {
  amount: number;
  type: IPaymentCategoryWithoutRefund;
}

export const AddSupraPaymentModal = ({ schoolId, onFinish, revalidate, form }: IProps) => {
  const [sectionType, setPaymentType] = useState<"Adjustment" | "Payment">("Payment");

  const getPaymentType = (amountSymbol: number) => {
    if (sectionType === "Adjustment" && Number(amountSymbol) === 1) {
      return PaymentCategoryTypeDto.ADJUSTMENT_INCREASE;
    }

    return PaymentCategoryTypeDto.ADJUSTMENT_REFUND;
  };

  const handleSubmit = async (values: IFormValues & { amountSymbol?: number }) => {
    const { amount, amountSymbol } = values;

    const requestBody = {
      amount: Number(amount),
      type: amountSymbol ? getPaymentType(amountSymbol) : PaymentCategoryTypeDto.PAYMENT,
    };

    try {
      if (!schoolId) return;

      await axiosAuth.post(getAddPaymentOrRefundUrl(schoolId), requestBody);

      message.success("Payment has been saved");
      revalidate();
      onFinish();
    } catch (err: unknown) {
      message.error("Something went wrong");
    } finally {
      form.resetFields();
    }
  };

  return (
    <div>
      <Form form={form} onFinish={handleSubmit}>
        <Row style={{ marginBottom: 40 }}>
          <Col>
            <Button
              style={
                sectionType === "Payment" ? { backgroundColor: "#1890FF", color: "white" } : { marginLeft: 0 }
              }
              onClick={() => setPaymentType("Payment")}
            >
              Payment
            </Button>
            <Button
              style={
                sectionType === "Adjustment"
                  ? { marginLeft: 10, backgroundColor: "#1890FF", color: "white" }
                  : { marginLeft: 10 }
              }
              onClick={() => setPaymentType("Adjustment")}
            >
              Adjustment
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ display: "flex" }}>
            <div>
              <Row>
                {sectionType === "Adjustment" && (
                  <Col>
                    <Form.Item
                      name="amountSymbol"
                      label="Amount"
                      rules={[{ required: true, message: "" }]}
                      style={{ marginRight: 5 }}
                    >
                      <Select
                        options={[
                          { label: "+", value: "1" },
                          { label: "-", value: "2" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={sectionType === "Adjustment" ? 11 : 24}>
                  <Form.Item
                    name="amount"
                    label={sectionType === "Adjustment" ? "" : "Amount"}
                    rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
                  >
                    <Input
                      placeholder={sectionType === "Adjustment" ? "Adjustment amount" : "Payment amount"}
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {sectionType === "Adjustment" && (
                <AmountSignDescription>
                  If '+' is selected, the balance will be decreased by the specified amount, while if '-' is
                  selected, the balance will be increased by the specified amount.
                </AmountSignDescription>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="text"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#BDD000",
                    borderColor: "#BDD000",
                    color: "#fff",
                  }}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const AmountSignDescription = styled.span`
  font-size: 12px;
`;
