import { Col, Divider, Input, Row, Select, Spin, Typography } from "antd";
import { Container, Spacer, TopNavigation } from "components";
import {
  EditFilled,
  SearchOutlined,
  UpOutlined,
  DownOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { FC, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { AnimatePresence, motion, usePresence } from "framer-motion";
import { SchoolItem } from "./SchoolItem";
import { DocumentType } from "screens/Documents/Documents";
import { employeeRolesList } from "const";
import { EmployeeRole } from "enums";

const { Option, OptGroup } = Select;

const transition = { type: "spring", stiffness: 500, damping: 50, mass: 1 };

export type SchoolType = {
  country: {
    code: string;
    createdAt: number;
    createdBy: number | null;
    friday: number;
    id: number;
    moday: number;
    saturday: number;
    status: number;
    sunday: number;
    thursday: number;
    title: string;
    tuesday: number;
    updatedAt: number;
    updatedBy: number | null;
    wednesday: number;
  };
  countChildren: number;
  countEmployees: number;
  annualFee: string;
  city: string;
  countryId: number;
  createdAt: number;
  createdBy: number;
  currency: string;
  id: number;
  image: string;
  lang: string;
  medicalFee: string;
  name: string;
  partnerId: number;
  registrationFee: string;
  status: number;
  supportAdmin: string;
  timeZone: string;
  typeOfSchool: string;
  updatedAt: number;
  updatedBy: number;
  document?: DocumentType[];
  linkedinLink?: string;
  facebookLink?: string;
  instagramLink?: string;
  termsPerYear?: string;
  address?: string;
  maxCapacity?: number;
  firstOpen?: string;
  managerFirstName?: string;
  managerLastName?: string;
  managerPhone?: string;
  managerEmail?: string;
  employee: {
    firstName: string;
    lastName: string;
    image?: string;
    role: number;
  }[];
};

const Schools: FC = () => {
  const { data, error } = useSWR<SchoolType[]>("school");
  const isPending = !data && !error;
  const [searchName, setSearchName] = useState("");
  const [sort, setSort] = useState(null);

  const normalizedListData = useMemo(() => {
    let newData = data?.filter(item => item.name.toLowerCase().includes(searchName.toLowerCase()));
    if (sort) {
      if (sort === "asc_partner") {
        newData = newData?.sort((a, b) => a.partnerId - b.partnerId);
      } else if (sort === "asc_country") {
        newData = newData?.sort((a, b) => a.country.title.localeCompare(b.country.title));
      } else if (sort === "asc_alphabetically") {
        newData = newData?.sort((a, b) => a.name.localeCompare(b.name));
      } else if (sort === "desc_partners") {
        newData = newData?.sort((a, b) => b.partnerId - a.partnerId);
      } else if (sort === "desc_country") {
        newData = newData?.sort((a, b) => b.country.title.localeCompare(a.country.title));
      } else if (sort === "desc_alphabetically") {
        newData = newData?.sort((a, b) => b.name.localeCompare(a.name));
      }
    }
    return newData;
  }, [data, searchName, sort]);

  return (
    <Spin spinning={isPending}>
      <Spacer size={40} />
      <Container>
        <Row gutter={[24, 0]}>
          <Col span={6} offset={14}>
            <Input
              placeholder="Search school"
              suffix={<SearchOutlined style={{ color: "#00000073" }} />}
              onChange={e => {
                const val = e.target.value;
                setSearchName(val);
              }}
            />
          </Col>
          <Col span={4}>
            <Select
              placeholder="Sort by"
              style={{ width: "100%" }}
              allowClear
              onChange={e => {
                setSort(e as any);
              }}
            >
              <OptGroup label="Asc">
                <Option value="asc_partner">
                  Partner <CaretUpOutlined style={{ color: "#00000073" }} />
                </Option>
                <Option value="asc_country">
                  Country <CaretUpOutlined style={{ color: "#00000073" }} />
                </Option>
                <Option value="asc_alphabetically">
                  Alphabetically
                  <CaretUpOutlined style={{ color: "#00000073" }} />
                </Option>
              </OptGroup>
              <OptGroup label="Desc">
                <Option value="desc_partners">
                  Partner <CaretDownOutlined style={{ color: "#00000073" }} />
                </Option>
                <Option value="desc_country">
                  Country <CaretDownOutlined style={{ color: "#00000073" }} />
                </Option>
                <Option value="desc_alphabetically">
                  Albhabetically
                  <CaretDownOutlined style={{ color: "#00000073" }} />
                </Option>
              </OptGroup>
            </Select>
          </Col>
        </Row>
        <Spacer />
        <Row gutter={[48, 48]}>
          {normalizedListData?.map(item => {
            const validSchoolUsers = item.employee.filter(
              user => user && user.role === EmployeeRole.SCHOOL_MANAGER
            );
            return <SchoolItem key={item.id} item={item} schoolUsers={validSchoolUsers} />;
          })}
        </Row>
      </Container>
      <Spacer size={140} />
    </Spin>
  );
};

export default Schools;
