import { DiscountCategory, DiscountConfig } from "types/rest/child";
import { FeeContainer } from "../../styles";
import { Radio } from "antd";
import Title from "antd/lib/typography/Title";
import { Spacer } from "components";
import { useField } from "formik";
import filterDiscountByCategory from "../../helpers/filterDiscountByCategory";

type Props = {
  discounts: DiscountConfig[];
  setFieldValue: (field: string, value: number | null, shouldValidate?: boolean | undefined) => void;
};

const FeeConfigurationDiscounts = ({ discounts, setFieldValue }: Props) => {
  const [field] = useField("discounts");

  const siblingDiscounts = filterDiscountByCategory(discounts, DiscountCategory.Sibling);
  const otherDiscounts = filterDiscountByCategory(discounts, DiscountCategory.Other);
  const corporateDiscounts = filterDiscountByCategory(discounts, DiscountCategory.Corporate);

  const siblingDiscountId = field.value?.sibling;
  const corporateDiscountId = field.value?.corporate;
  const otherDiscountId = field.value?.other;

  const pickSiblingDiscount = (value: number) => {
    if (value === siblingDiscountId) return setFieldValue("discounts.sibling", null);
    setFieldValue("discounts.other", null);
    setFieldValue("discounts.sibling", value);
  };

  const pickCorporateDiscount = (value: number) => {
    if (value === corporateDiscountId) return setFieldValue("discounts.corporate", null);
    setFieldValue("discounts.other", null);
    setFieldValue("discounts.corporate", value);
  };

  const pickOtherDiscount = (value: number) => {
    if (value === otherDiscountId) return setFieldValue("discounts.other", null);
    setFieldValue("discounts.sibling", null);
    setFieldValue("discounts.corporate", null);
    setFieldValue("discounts.other", value);
  };

  return (
    <FeeContainer>
      <Title level={4} style={{ lineHeight: 1, marginBlockEnd: 0 }}>
        Discounts
      </Title>
      <Spacer size={8} />
      <Title level={5}>Sibling</Title>
      <Radio.Group style={{ display: "flex", flexDirection: "column" }} value={siblingDiscountId}>
        {siblingDiscounts?.map(({ id, name }) => (
          <Radio value={id} onClick={() => pickSiblingDiscount(id)}>
            <p>{name}</p>
          </Radio>
        ))}
      </Radio.Group>
      <Spacer size={8} />
      <Title level={5}>Corporate</Title>
      <Radio.Group style={{ display: "flex", flexDirection: "column" }} value={corporateDiscountId}>
        {corporateDiscounts?.map(({ id, name }) => (
          <Radio value={id} onClick={() => pickCorporateDiscount(id)}>
            <p>{name}</p>
          </Radio>
        ))}
      </Radio.Group>
      <Spacer size={8} />
      <Title level={5}>Other</Title>
      <Radio.Group style={{ display: "flex", flexDirection: "column" }} value={otherDiscountId}>
        {otherDiscounts?.map(({ id, name }) => (
          <Radio value={id} onClick={() => pickOtherDiscount(id)}>
            <p>{name}</p>
          </Radio>
        ))}
      </Radio.Group>
    </FeeContainer>
  );
};

export default FeeConfigurationDiscounts;
