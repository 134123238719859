import { useEffect } from "react";
import {} from "react-router";
import "antd/dist/antd.css";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import "./App.css";
import { fetchAccountData } from "store/user";
import { useAppSelector } from "store";
import App from "App";

function BaseApp() {
  const { isInitialSetupPending } = useAppSelector((store) => store.user);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchAccountData());
    };
    fetchData();
  }, [dispatch]);

  return (
    <Spin spinning={isInitialSetupPending}>
      <App />
    </Spin>
  );
}

export default BaseApp;
