import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Spin,
  Row,
  Col,
  Typography,
  Pagination,
  DatePicker,
  message,
} from "antd";
import { Collapse } from "react-collapse";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import useSWR from "swr";
import { RouteComponentProps } from "react-router-dom";
import { Container, Spacer } from "components";
import { Kid } from "screens/Kids/Kids";
import { Language } from "screens/Languages/Languages";
import { Item } from "./Item";
import { axiosAuth } from "helpers/axios";
import { CountryType } from "types";
import { ErrorMessage, ViewType } from "enums";
import { SchoolType } from "screens/Schools/Schools";
import { DocumentType } from "screens/Documents/Documents";

const numEachPage = 25;

export type Carer = {
  address: string;
  appointedClassId: number | null;
  countryId: number;
  createdAt: number;
  createdBy: number;
  dateOfBirth: string;
  email: string;
  emergencyAddress: string | null;
  emergencyKnownMedicalProblems: string | null;
  emergencyName: string | null;
  emergencyPhoneNumber: string | null;
  emergencyRelationship: string | null;
  employeeIsDeleted: number;
  firstName: string;
  highestQualification: string | null;
  personImage: string;
  passportDetails: string;
  nationalIdNumber: string;
  homePhone: string;
  mobilePhone: string;
  workPhone: string;
  id: number;
  image: string;
  lang: string;
  childPerson: {
    authKey: string;
    child: Kid;
    childId: number;
    personId: number;
    role: number;
  }[];
  languageId: number;
  lastName: string;
  role: number;
  schoolId: number;
  shiftId: number;
  startDate: string;
  status: number;
  updatedAt: number;
  updatedBy: number;
  userId: number | null;
  visaExpiry: string;
  document: DocumentType[];
};

type CarersRes = {
  element: Carer;
  nameOfSchool: string;
};

const { Option } = Select;

type Props = {
  viewType: ViewType;
} & RouteComponentProps<{ id: string | undefined }>;

const Carers: FC<Props> = ({ viewType, match: { params } }) => {
  const [isSearchPending, setIsSearchPending] = useState(false);
  const [searchData, setSearchData] = useState<any[] | null>(null);
  const [pagination, setPagination] = useState({
    minValue: 0,
    maxValue: numEachPage,
  });

  const getCarersUrls = () => {
    if (viewType === ViewType.MAIN_PANEL) {
      return {
        carersListUrl: "parent/list",
        carersQueryUrl: "parent/search",
      };
    }
    if (viewType === ViewType.SCHOOL_DETAILS && params.id) {
      return {
        carersListUrl: `school/carers/${params.id}`,
        carersQueryUrl: `parent/search/${params.id}`,
      };
    }
    return {
      carersListUrl: null,
      carersQueryUrl: null,
    };
  };

  const { carersListUrl, carersQueryUrl } = getCarersUrls();

  const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);

  const { data, error } = useSWR<CarersRes[]>(carersListUrl);

  const { data: schoolData, error: schoolError } =
    useSWR<SchoolType[]>("school");
  const isSchoolPending = !schoolData && !schoolError;

  const { data: countries, error: countriesError } =
    useSWR<CountryType[]>(`country`);

  const isCountriesPending = !countries && !countriesError;

  const { data: languages, error: laguagesError } =
    useSWR<Language[]>(`language`);

  const isLanguagesPending = !languages && !laguagesError;

  const { data: religions, error: religionsError } =
    useSWR<Language[]>(`religion`);

  const isReligionsPending = !religions && !religionsError;

  const onSearch = async (values: any) => {
    try {
      setIsSearchPending(true);
      if (carersQueryUrl) {
        const body = values;
        const data = (await axiosAuth.post(carersQueryUrl, body)).data;
        setSearchData(data);
      }
      setIsSearchPending(false);
    } catch (e) {
      message.error(ErrorMessage.UNEXPECTED);
      setIsSearchPending(false);
    }
  };

  const isPending =
    (!data && !error) ||
    isCountriesPending ||
    isLanguagesPending ||
    isReligionsPending ||
    isSchoolPending ||
    isSearchPending;

  const countriesList = useMemo(
    () =>
      countries?.map((item) => ({ label: item.title, value: item.id } ?? [])),
    [countries]
  );

  const religionsList = useMemo(
    () =>
      religions?.map((item) => ({ label: item.title, value: item.id } ?? [])),
    [religions]
  );

  const schoolsList = useMemo(
    () =>
      schoolData?.map(
        (item: any) => ({ label: item.name, value: item.id } ?? [])
      ),
    [schoolData]
  );

  const onPaginationChange = (value: number, pageSize: number | undefined) => {
    const currentPageSize = pageSize || numEachPage;
    setPagination({
      minValue: value !== 0 ? (value - 1) * currentPageSize : 0,
      maxValue: value !== 0 ? value * currentPageSize : currentPageSize,
    });
  };

  useEffect(() => {
    setPagination({ minValue: 0, maxValue: numEachPage });
  }, [JSON.stringify(searchData)]);

  const validData = searchData ?? data;

  return (
    <Container>
      <div>
        <Form
          name="basic"
          onFinish={onSearch}
          autoComplete="off"
          onFinishFailed={() => {}}
          style={{
            backgroundColor: "white",
            cursor: "pointer",
            borderRadius: "4px",
            overflow: "hidden",
            boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
            padding: "24px 24px 0px 24px",
          }}
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item name="firstName">
                <Input placeholder="First name" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="lastName">
                <Input placeholder="Last name" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="email"
                style={{
                  marginBottom: "10px",
                }}
              >
                <Input placeholder="email" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="status">
                <Select placeholder="Status" allowClear>
                  <Option value={10}>Active</Option>
                  <Option value={0}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 0]} align="middle" justify="space-between">
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  color="red"
                  style={{
                    backgroundColor: "#BDD000",
                    outlineColor: "#BDD000",
                    borderColor: "#BDD000",
                  }}
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
                onClick={() => {
                  setIsFilterCollapsed((prev) => !prev);
                }}
              >
                <p style={{ color: "#a9a9a9", marginRight: "10px" }}>
                  More filters
                </p>
                {isFilterCollapsed ? (
                  <UpOutlined style={{ color: "#a9a9a9" }} />
                ) : (
                  <DownOutlined style={{ color: "#a9a9a9" }} />
                )}
              </div>
            </Col>
          </Row>
          <Collapse isOpened={isFilterCollapsed}>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item name="childFirstName">
                  <Input placeholder="Child first name" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="childLastName">
                  <Input placeholder="Child last name" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="childCountry">
                  <Select
                    allowClear
                    placeholder="Child country"
                    mode="multiple"
                    showArrow
                    options={countriesList}
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleString() ?? "")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="childAgeFrom">
                  <DatePicker
                    picker="month"
                    placeholder="From birth date"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="childAgeTo">
                  <DatePicker
                    picker="month"
                    placeholder="Till birth date"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="childReligion">
                  <Select
                    allowClear
                    showArrow
                    placeholder="Religion"
                    mode="multiple"
                    options={religionsList}
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleString() ?? "")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="childGender">
                  <Select
                    allowClear
                    placeholder="gender"
                    mode="multiple"
                    showArrow
                  >
                    <Option value={10}>Male</Option>
                    <Option value={20}>Female</Option>
                    <Option value={0}>Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              {viewType === ViewType.MAIN_PANEL && (
                <Col span={6}>
                  <Form.Item name="school">
                    <Select
                      allowClear
                      placeholder="school"
                      mode="multiple"
                      showArrow
                      options={schoolsList}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Collapse>
        </Form>
        <Spacer size={48} />
        <Spin spinning={isPending}>
          <Row gutter={[48, 48]} justify="start">
            {validData &&
              validData
                .slice(pagination.minValue, pagination.maxValue)
                .map((item) => {
                  if ("element" in item) {
                    return (
                      <Item
                        element={item.element}
                        nameOfSchool={item.nameOfSchool}
                        key={item.element.id}
                      />
                    );
                  }
                  return (
                    <Item
                      element={item}
                      nameOfSchool={undefined}
                      key={item.id}
                    />
                  );
                })}
          </Row>
          <Spacer size={40} />
          <Row justify="end">
            <Col>
              <Pagination
                defaultPageSize={numEachPage}
                onChange={onPaginationChange}
                total={validData?.length ?? 0}
              />
            </Col>
          </Row>
        </Spin>
      </div>
      <Spacer size={100} />
    </Container>
  );
};

export default Carers;
