import { Button } from "antd";
import Title from "antd/lib/typography/Title";
import { PlusOutlined } from "@ant-design/icons";
import { Spacer } from "components";
import { UtilityTabs } from "types";

type Props = {
  pickedTab: UtilityTabs;
  openUtilityModal: () => void;
};

const UtilityHeader = ({ pickedTab, openUtilityModal }: Props) => {
  return (
    <>
      <Spacer size={24} />
      <div
        style={{
          backgroundColor: "#FFF",
          paddingBlock: 28,
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* From design perspective it should be the same as the button below to ensure equal paddings */}
        <Button
          style={{
            backgroundColor: "#BDD000",
            borderColor: "#BDD000",
            color: "white",
            marginLeft: 16,
            visibility: "hidden",
          }}
          icon={<PlusOutlined />}
        >
          Add new
        </Button>
        <Title
          level={3}
          style={{
            color: "#E50A5C",
            paddingBottom: "0px",
            marginBottom: "0px",
            textAlign: "center",
          }}
        >
          {pickedTab}
        </Title>
        <Button
          style={{
            backgroundColor: "#BDD000",
            borderColor: "#BDD000",
            color: "white",
            marginRight: 16,
          }}
          onClick={openUtilityModal}
          icon={<PlusOutlined />}
        >
          Add new
        </Button>
      </div>
    </>
  );
};

export default UtilityHeader;
