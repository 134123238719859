import { Select } from "antd";
import { FC } from "react";
import { useField } from "formik";
import { SelectValue } from "antd/lib/select";

type Props = {
  name: string;
  label: string;
  onChange: (value: SelectValue) => void;
};

const EvaluationSelect: FC<Props> = ({ name, label, onChange }) => {
  const [field] = useField(name);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "10px 0",
      }}
    >
      <p style={{ marginRight: "auto" }}>{label}</p>
      <Select
        value={field.value}
        options={[
          { title: "1", value: 1 },
          { title: "2", value: 2 },
          { title: "3", value: 3 },
          { title: "4", value: 4 },
          { title: "5", value: 5 },
        ]}
        onChange={(value) => onChange(value)}
      />
    </div>
  );
};

export default EvaluationSelect;
