import { Col, Divider, Row, Typography, Image } from "antd";
import { Spacer } from "components";
import { useCallback, useState } from "react";
import useSWR from "swr";

const { Title, Text } = Typography;

interface Medal {
  name: string;
  type: number;
  text: string;
  image_front: string;
  image_back: string;
  count?: number;
}

interface MedalProps {
  medal: Medal;
}

export enum CATEGORY {
  RESPECT = "RESPECT",
  RESPONSIBILITY = "RESPONSIBILITY",
  BRAVERY = "BRAVERY",
  EMPATHY = "EMPATHY",
  PEACEFULNESS = "PEACEFULNESS",
  GRATITUDE = "GRATITUDE",
}

const Medal = ({ medal }: MedalProps) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlipImage = useCallback(() => {
    setIsFlipped((prevState) => !prevState);
  }, []);

  const getMessageColor = (name: string) => {
    switch (name) {
      case CATEGORY.BRAVERY:
        return "#a42489";
      case CATEGORY.RESPONSIBILITY:
        return "#98d502";
      case CATEGORY.RESPECT:
        return "#ea8602";
      case CATEGORY.EMPATHY:
        return "#d92252";
      case CATEGORY.PEACEFULNESS:
        return "#3aaaea";
      case CATEGORY.GRATITUDE:
        return "#ffef26";
      default:
        return "#FFFFFF";
    }
  };

  return (
    <div
      style={{
        paddingBlock: 20,
        minHeight: 270,
        backgroundColor: isFlipped ? getMessageColor(medal.name) : "white",
      }}
    >
      <div onClick={handleFlipImage}>
        {isFlipped ? (
          <>
            <div style={{ minHeight: 280 }}>
              <Image
                src={`https://${medal.image_back}`}
                preview={false}
                width={270}
              />
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  width: "90%",
                  backgroundColor: "white",
                  borderRadius: 10,
                  marginTop: 20,
                  marginRight: "auto",
                  marginLeft: "auto",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    paddingTop: 10,
                    fontSize: 13,
                    maxWidth: 270,
                    marginLeft: "auto",
                    marginRight: "auto",
                    minHeight: 100,
                  }}
                >
                  {medal.text}
                </Text>
              </div>
            </div>
          </>
        ) : (
          <div style={{ minHeight: 280 }}>
            <Image
              preview={false}
              src={`https://${medal.image_front}`}
              width={270}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const MedalBank = (props: any) => {
  const { data } = useSWR<Medal[]>(
    props?.location?.state?.id
      ? `medals-child/${props.location.state.id}`
      : null
  );

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "4px",
        overflow: "hidden",
        boxShadow: "2px 2px 6px 1px rgba(0,0,0,0.02)",
        padding: "24px",
      }}
    >
      <Title level={2} style={{ color: "#E50A5C", margin: "0px" }}>
        Medal Bank
      </Title>
      <Divider />
      <Row style={{ marginBottom: 10 }}>
        <Col span={3}>
          <div
            style={{
              backgroundColor: "#ededed",
              display: "flex",
              justifyContent: "center",
              fontSize: 15,
              padding: 20,
              fontWeight: 600,
            }}
          >
            Count
          </div>
        </Col>
        <Col span={21}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: 15,
              backgroundColor: "#ededed",
              padding: 20,
              fontWeight: 600,
            }}
          >
            Medal
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 24]}>
        {data?.map((item, index) => (
          <>
            <Col span={3}>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 20,
                }}
              >
                {item.count}
              </div>
            </Col>
            <Col span={21} key={index}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Medal medal={item} />
              </div>
            </Col>
          </>
        ))}
      </Row>
      <Spacer />
    </div>
  );
};

export default MedalBank;
