import { Typography } from "antd";
import styled from "styled-components";
import { ExportToCsv, SummaryInfo } from "../components";
import { MultiSchoolFinancialsTable } from "./components";
import { NoDataInfo } from "screens/Financials/components";

const placeholderData = {
  summary: [
    { name: "Revenue", price: 5000 },
    { name: "Refunds", price: 0 },
    { name: "Overdue", price: 0 },
  ],
  dataSource: [
    {
      id: "1",
      nameAndLastName: "Paweł Kowalski",
      image:
        "https://images.unsplash.com/photo-1627639679638-8485316a4b21?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=928&q=80",
      schoolName: "SP 13",
      group: "Słoneczka",
      lastPayment: 400,
      balance: -1200,
      monthlyBalance: -1200,
      overdueAmount: 0,
      overdueDays: 0,
    },
    {
      id: "2",
      nameAndLastName: "Jakub Nowak",
      image:
        "https://images.unsplash.com/photo-1627639679638-8485316a4b21?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=928&q=80",
      schoolName: "SP 13",
      group: "Grzybki",
      lastPayment: 600,
      balance: 800,
      monthlyBalance: 800,
      overdueAmount: 20,
      overdueDays: 10,
    },
    {
      id: "3",
      nameAndLastName: "Malina Jagodowa",
      image:
        "https://images.unsplash.com/photo-1627639679638-8485316a4b21?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=928&q=80",
      schoolName: "SP 33",
      group: "Słoneczka",
      lastPayment: 300,
      balance: -1600,
      monthlyBalance: -1600,
      overdueAmount: 40,
      overdueDays: 0,
    },
    {
      id: "4",
      nameAndLastName: "Piotr Kowalski",
      image:
        "https://images.unsplash.com/photo-1627639679638-8485316a4b21?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=928&q=80",
      schoolName: "SP 33",
      group: "Grzybki",
      lastPayment: 900,
      balance: 1200,
      monthlyBalance: 1200,
      overdueAmount: 0,
      overdueDays: 0,
    },
  ],
};

export interface IMultiSchoolFinancialRecord {
  id: string;
  nameAndLastName: string;
  image: string;
  schoolName: string;
  group: string;
  lastPayment: number;
  balance: number;
  monthlyBalance: number;
  overdueAmount: number;
  overdueDays: number;
}

interface Props {
  schoolId?: string;
}

export const FinancialMultiSchool = ({ schoolId }: Props) => {
  const { Title } = Typography;

  //TODO Remove when multischool will be ready, right now only single school and HQ admin is supported
  return <NoDataInfo title="Work in progress ..." />;

  return (
    <>
      <InvoiceContainer>
        <Title level={3} style={{ textAlign: "left", color: "#E50A5C" }}>
          Summary
        </Title>
        <SummaryContainer>
          <AmountContainer>
            {/* {placeholderData.summary.map(({ name, price }) => (
              <SummaryInfo title={name} price={price} />
            ))} */}
          </AmountContainer>
          <ExportToCsv dataSource={placeholderData.dataSource} fileTitle={"Invoice_summary.csv"} />
        </SummaryContainer>
      </InvoiceContainer>
      <MultiSchoolFinancialsTable dataSource={placeholderData.dataSource} schoolId={schoolId} />
    </>
  );
};

const SummaryContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;
const AmountContainer = styled.div`
  display: flex;
`;

const InvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 32px;
  border-radius: 10px;
`;
