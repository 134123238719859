import { Checkbox } from 'antd';
import styled, { css } from 'styled-components';

const CustomCheckbox = styled(Checkbox)<{ color: string }>`
  ${({ color }) =>
    color &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${color};
        border-color: ${color};
      }
    `}
`;

export default CustomCheckbox;
