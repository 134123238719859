import { FeeTaxes, TaxData, TaxesDataFees, UtilityCategory } from "types";

const mapToNormalizedTaxData = (taxesData: TaxData[] | undefined) => {
  if (!taxesData) return [];
  return taxesData.map(item => ({
    feeId: item.id,
    name: item.name,
    taxId: item.tax.id,
  }));
};

export const getNormalizedTaxesData = (taxesData: TaxesDataFees | undefined) => {
  if (!taxesData) return [];
  const { oneTimeFees, annualFees, campsFees, termFees, otherFees, utilityFees } = taxesData;
  const annualTaxes = mapToNormalizedTaxData(annualFees);

  const campsTaxes = mapToNormalizedTaxData(campsFees);

  const oneTimeTaxes =
    oneTimeFees !== null
      ? [
          {
            feeId: oneTimeFees.id,
            name: oneTimeFees.name,
            taxId: oneTimeFees.tax.id,
          },
        ]
      : [];

  const otherTaxes = mapToNormalizedTaxData(otherFees);

  const termTaxes = mapToNormalizedTaxData(termFees);

  const foodTaxes = mapToNormalizedTaxData(
    utilityFees.filter(utility => utility.category === UtilityCategory.Food)
  );

  const materialTaxes = mapToNormalizedTaxData(
    utilityFees.filter(utility => utility.category === UtilityCategory.Materials)
  );

  const transportTaxes = mapToNormalizedTaxData(
    utilityFees.filter(utility => utility.category === UtilityCategory.Transport)
  );

  return [
    { name: "One time fees", data: oneTimeTaxes, key: "oneTimeFee" },
    { name: "Annual fees", data: annualTaxes, key: "annualFee" },
    { name: "Term fees", data: termTaxes, key: "termsFee" },
    { name: "Camp fees", data: campsTaxes, key: "campsFee" },
    { name: "Other fees", data: otherTaxes, key: "othersFee" },
    { name: "Food fees", data: foodTaxes, key: "foodFee" },
    { name: "Material fees", data: materialTaxes, key: "materialFee" },
    { name: "Transport fees", data: transportTaxes, key: "transportFee" },
  ];
};

export const updateTaxFee = ({
  prevFeeTaxes,
  feeId,
  groupKey,
  schoolId,
  taxId,
}: {
  prevFeeTaxes: FeeTaxes;
  feeId: number;
  groupKey: keyof FeeTaxes;
  schoolId: number;
  taxId: number;
}) => {
  const hasTax = prevFeeTaxes[groupKey].find(item => item.feeId === feeId);
  if (hasTax) {
    const updatedFeeTaxes = prevFeeTaxes[groupKey].map(item => {
      if (item.feeId === feeId) {
        return {
          ...item,
          taxId,
        };
      }
      return item;
    });
    return { ...prevFeeTaxes, [groupKey]: updatedFeeTaxes };
  } else {
    return {
      ...prevFeeTaxes,
      [groupKey]: [...prevFeeTaxes[groupKey], { feeId, taxId }],
    };
  }
};

export const handleUpdateMultipleTaxesFromGroup = ({
  groupKey,
  taxId,
  prevFeeTaxes,
  groupTaxes,
}: {
  groupKey: keyof FeeTaxes;
  taxId: number;
  prevFeeTaxes: FeeTaxes;
  groupTaxes:
    | {
        name: string;
        data: {
          feeId: number;
          name: string;
          taxId: number | null;
        }[];
        key: string;
      }[]
    | undefined;
}) => {
  const groupedTaxes = groupTaxes?.find(item => item.key === groupKey);
  if (!groupedTaxes) return prevFeeTaxes;

  const updatedFeeTaxes = groupedTaxes.data?.map(item => {
    return {
      feeId: item.feeId,
      taxId,
    };
  });

  return { ...prevFeeTaxes, [groupKey]: updatedFeeTaxes };
};
