import { SmartGoalsTypes } from "types";

export const smartGoalsValues: SmartGoalsTypes = {
  capabilityAnswer: "",
  supportAnswer: "",
  improvementAnswer: "",
  specificAnswer: "",
  measurableAnswer: "",
  agreedAnswer: "",
  realisticAnswer: "",
  timelineAnswer: "",
  additionalComments: "",
};

type SmartGoalsItems = {
  title: string;
  items: {
    key: keyof SmartGoalsTypes;
    id: number;
    description: string;
  }[];
};

export const smartGoalsItems: SmartGoalsItems = {
  title: "Smart Goals",
  items: [
    {
      key: "capabilityAnswer",
      id: 0,
      description:
        " What area of the Kipina Program do you feel most confident or capable in?",
    },
    {
      key: "supportAnswer",
      id: 1,
      description: "What area of Kipina Program do you need more support in?",
    },
    {
      key: "improvementAnswer",
      id: 2,
      description:
        "What would you specifically like to improve on or implement?",
    },
    {
      key: "specificAnswer",
      id: 3,
      description: "SMART GOAL - Specific (I will):",
    },
    {
      key: "measurableAnswer",
      id: 4,
      description: "SMART GOAL - Measurable: (My actions will be measured by):",
    },
    {
      key: "agreedAnswer",
      id: 5,
      description:
        "SMART GOAL - Agreed upon (This involves the following stakeholders):",
    },
    {
      key: "realisticAnswer",
      id: 6,
      description: "SMART GOAL - Realistic (It will impact my students by):",
    },
    {
      key: "timelineAnswer",
      id: 7,
      description: "SMART GOAL - Timeline:",
    },
    {
      key: "additionalComments",
      id: 8,
      description: "Additional Comments",
    },
  ],
};
