import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space, message } from "antd";
import Title from "antd/lib/typography/Title";
import { getUtilityCategoryName } from "../../helpers";
import { axiosAuth } from "helpers";
import { Utility, UtilityFee, UtilityTabs, postUtilityFee, updateUtilityFee } from "types";
import { ErrorMessage } from "enums";
import { useEffect } from "react";

type Props = {
  tab: UtilityTabs;
  schoolId: number;
  visible: boolean;
  closeModal: () => void;
  editUtilityData?: Utility;
  timesOptions: { value: number; label: string }[];
};

const initialUtilityValues = {
  name: "",
  amount: "",
  timeId: undefined,
};

const UtilitiesModal = ({ tab, visible, closeModal, schoolId, timesOptions, editUtilityData }: Props) => {
  const [form] = Form.useForm();

  const handleCloseModal = () => {
    form.resetFields();
    closeModal();
  };

  const addNewUtility = async (values: { name: string; amount: string; timeId: number }) => {
    const body: UtilityFee = {
      name: values.name,
      amount: Number(values.amount),
      timeId: values.timeId,
      category: getUtilityCategoryName(tab),
    };

    try {
      await axiosAuth.post(postUtilityFee(schoolId), body);
      form.resetFields();
      closeModal();
    } catch (e) {
      message.error(
        `Adding ${getUtilityCategoryName(tab)} fee failed, please try again, or contact support.`
      );
    }
  };

  const editUtility = async (values: { name: string; amount: string; timeId: number }) => {
    const body: UtilityFee = {
      name: values.name,
      amount: Number(values.amount),
      timeId: values.timeId,
      category: getUtilityCategoryName(tab),
    };
    try {
      await axiosAuth.put(updateUtilityFee(schoolId, Number(editUtilityData?.id)), body);
      form.resetFields();
      closeModal();
    } catch (e) {
      message.error(
        `Editing ${getUtilityCategoryName(tab)} fee failed, please try again, or contact support.`
      );
    }
  };

  useEffect(() => {
    if (editUtilityData) {
      form.setFieldsValue(editUtilityData);
    } else {
      form.setFieldsValue(initialUtilityValues);
    }
  }, [editUtilityData]);

  return (
    <Modal visible={visible} footer={null} onCancel={handleCloseModal} destroyOnClose>
      <div style={{ flexDirection: "column", display: "flex" }}>
        <Title
          level={3}
          style={{
            color: "#E50A5C",
          }}
        >
          {tab} fees
        </Title>
        <Form
          form={form}
          initialValues={editUtilityData ? editUtilityData : initialUtilityValues}
          onFinish={editUtilityData ? editUtility : addNewUtility}
        >
          <Row>
            <Col span={8}>
              <p>Select days/times: </p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="timeId"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: ErrorMessage.REQUIRED }]}
              >
                <Select options={timesOptions} />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ margin: "8px 0" }} />
          <Row>
            <Col span={8}>
              <p>Name:</p>
            </Col>
            <Col span={16}>
              <Form.Item name="name">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ margin: "8px 0" }} />

          <Row>
            <Col span={8}>
              <p>Enter amount per month:</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="amount"
                style={{ width: "100%" }}
                rules={[
                  { required: true, message: ErrorMessage.REQUIRED },
                  {
                    validator: (_, value) => {
                      if (value && value > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Amount must be greater than 0");
                      }
                    },
                  },
                ]}
              >
                <InputNumber type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Button
            style={{
              backgroundColor: "#BDD000",
              borderColor: "#BDD000",
              color: "white",
              alignSelf: "flex-end",
              marginTop: 16,
            }}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default UtilitiesModal;
