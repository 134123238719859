import { Steps } from 'antd';
import { Container, Spacer } from 'components';
import { useState } from 'react';
import DevelopmentCategory from './DevelopmentCategory';
import SubCategory from './SubCategory';
import DevelopmentItem from './DevelopmentItem';

const { Step } = Steps;

type Item = {
  id: number;
  name: string;
};

const Domains = () => {
  const [current, setCurrent] = useState(0);
  const [subCategory, setSubCategory] = useState<Item | null>(null);
  const [itemDetails, setItemDetails] = useState<Item | null>(null);

  const renderContent = () => {
    if (current === 0) {
      return (
        <DevelopmentCategory
          onDetailsClick={({ id, name = '' }) => {
            setSubCategory({ id, name });
            setCurrent(1);
          }}
        />
      );
    }
    if (current === 1) {
      return (
        <SubCategory
          onDetailsClick={({ id, name = '' }) => {
            setItemDetails({ id, name });
            setCurrent(2);
          }}
          subCategoryId={subCategory?.id ?? null}
        />
      );
    }
    if (current === 2) {
      return <DevelopmentItem itemId={itemDetails?.id ?? null} />;
    }
    return null;
  };

  return (
    <>
      <Spacer />
      <Container>
        <Steps current={current} progressDot>
          <Step
            title="Domains"
            onClick={() => {
              setCurrent(0);
              setSubCategory(null);
              setItemDetails(null);
            }}
            description={subCategory?.name ? `(${subCategory.name})` : ''}
          />
          <Step
            title="Learning outcomes"
            description={itemDetails?.name ? `(${itemDetails.name})` : ''}
            onClick={() => {
              if (subCategory) {
                setCurrent(1);
                setItemDetails(null);
              }
            }}
            disabled={!subCategory}
          />
          <Step
            title="Targets"
            onClick={() => {
              if (itemDetails) {
                setCurrent(2);
              }
            }}
            disabled={!itemDetails}
          />
        </Steps>
        <Spacer size={40} />
        {renderContent()}
      </Container>
    </>
  );
};

export default Domains;
