import { FC } from 'react';

const CustomLabel: FC = ({ children }) => {
  return (
    <label
      style={{
        fontSize: '1.4rem',
        color: '#932B7C',
        fontWeight: 'bold',
      }}
    >
      {children}
    </label>
  );
};

export default CustomLabel;
